import React, {useContext, useEffect, useState} from "react";
import {Button, Icons} from "@dv/react";
import AuthContext from "@contexts/Auth/AuthContext";

const EmailVerificationForm: React.FC<any> = ({styles, setFormPhase}) => {

    const authContext = useContext(AuthContext);

    const verifyEmail = (e) => {
        e.preventDefault();

        authContext.verifyEmail({
            verificationCode: authContext.state.verificationCode
        }, () => {
            setFormPhase('login');
        });
    }

    useEffect(() => {
        if (authContext.state.isVerified === true) {
            setFormPhase('login');
        }
    }, [authContext.state.isVerified]);

    return (
        <div className={styles.forgot_password_form}>
            <h1><Icons.ArrowLeftIcon
                onClick={() => {
                    setFormPhase('login');
                }}/> E-posta Onayı</h1>
            <h4>Lütfen e-postanıza gelen onay kodunu giriniz</h4>

            <form onSubmit={verifyEmail}>
                <div className={`input_group full_width`}>
                    <label>
                        Onay Kodu
                    </label>
                    <div className={`input_section highlighted`}>
                        <input type="text" value={authContext.state.verificationCode} onChange={(e) => {
                            authContext.setVerificationCode(e.target.value);
                        }} placeholder={"Onay kodunuzu giriniz"} required/>
                    </div>
                </div>

                <div className={styles.actions}>
                    <Button>İlerle</Button>
                </div>
            </form>
        </div>
    )
}

export default EmailVerificationForm;
