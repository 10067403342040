import React, {useContext, useEffect, useState} from 'react';
import CanceledOrderContext from "@contexts/CanceledOrder/CanceledOrderContext";
import Step from "@antd/Step";
import {ORDER_CANCELLATION_STEP_LABELS, ORDER_CANCELLATION_STEPS, OWNER_TYPES} from "@utils/constants/order.config";
import {PulseLoader} from "react-spinners";
import {Button, Icons, Tag, Tooltip} from "@dv/react";
import moment from "moment";

interface CanceledOrderProps {
    orderId?: any;
    detailToggle?: any;
    styles: any;
}

const CanceledOrderDetail: React.FC<CanceledOrderProps> = ({orderId, detailToggle, styles}) => {

    const [detail, setDetail] = useState(null);
    const canceledOrderContext = useContext(CanceledOrderContext);

    useEffect(() => {
        if (detailToggle === false) {
            canceledOrderContext.getCanceledOrderDetail(orderId, (orderDetail) => {
                console.log('OD', orderDetail);
                setDetail(orderDetail);
            });
        }
    }, [detailToggle]);

    return canceledOrderContext.state.selectedOrder !== orderId || canceledOrderContext.state.detailLoading === false ? <>
        <div className={`${styles.step} ${styles.order_detail_steps}`}>
            <Step items={ORDER_CANCELLATION_STEP_LABELS}
                  current={ORDER_CANCELLATION_STEPS[detail?.status]} direction={"horizontal"}/>
        </div>
        <div className={styles.order_detail}>
            <div className={styles.order_block}>
                <div className={styles.container}>
                    <div className={styles.container_block}>
                        {/*
                        <div className={styles.step}>
                            <Step
                                items={ORDER_CANCELLATION_STEP_LABELS}
                                current={ORDER_CANCELLATION_STEPS[detail?.status]} direction={"vertical"}/>
                        </div>
                        */}
                        <div className={`${styles.items} no-gap`}>
                            <h3>İptal Edilen Ürünler</h3>
                            <div className={styles.order_items_table}>
                                <table>
                                    <tr>
                                        <th>Ürün Adı</th>
                                        <th style={{minWidth: 75}} className={styles.right_align}>Adet</th>
                                        <th className={styles.right_align}>Birim Fiyat</th>
                                        <th className={styles.right_align}>Toplam Fiyat</th>
                                    </tr>
                                    {
                                        detail?.items?.map((item, i) => (
                                            <tr key={i}>
                                                <td className={"flex ai-center border-bottom-none"}>
                                                    <div className={"flex fd-column"}>
                                                        <div className={"flex ai-center"}>
                                                            <div className={"pie-10"}>
                                                                <img alt={`${item?.id}_product_image`}
                                                                     height={75} width={75}
                                                                     src={item?.thumbnail}/>
                                                            </div>
                                                            <div className={"flex fd-column"}>
                                                                <span className={"pbe-5"}
                                                                      style={{fontSize: 14}}>{item.name}
                                                                    <b>{item.variantTitle}</b></span>
                                                                {item.expiration &&
                                                                    <span
                                                                        style={{fontSize: 11}}><b>Miat:</b> {moment(item.expiration).format('DD MMMM YYYY')}</span>
                                                                }
                                                                <span
                                                                    style={{fontSize: 11}}><b>Marka:</b> {item.brandName}</span>
                                                                <span
                                                                    style={{fontSize: 11}}><b>Ambalaj İçeriği:</b> {item.packageContent}</span>
                                                                <span
                                                                    style={{fontSize: 11}}><b>Ürün Kodu:</b> {item.sku}</span>
                                                                <span
                                                                    style={{fontSize: 11}}><b>Katalog No:</b> {item.catalogNumber}</span>
                                                                {
                                                                    item?.options && item?.options?.map((option, i) => (
                                                                        <span key={i}
                                                                              style={{fontSize: 11}}><b>{option?.propertyName}:</b> {option?.propertyValueName}</span>
                                                                    ))
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>

                                                <td className={"flex gap-5 border-top-none"}>
                                                    {item?.hasCampaign && item?.isCampaignApplied &&
                                                        <Tag variant={"campaign"} campaign={item?.campaign}/>}

                                                    {item?.isGift === 1 && <Tag variant={"gift"}/>}

                                                    {(item?.submittedForCancelApproval === 1 && item?.isCancelApproved === null) &&
                                                        <div className={"tags"}>
                                                            <div
                                                                className={"tag tag-m blue_secondary ta-center"}>
                                                                <span>İptal Onayı Bekliyor</span>
                                                            </div>
                                                        </div>
                                                    }

                                                    {item?.isCancelApproved !== null &&
                                                        <>
                                                            {item?.isCancelApproved === 1 &&
                                                                <div className={"tags"}>
                                                                    <div
                                                                        className={"tag tag-m green_secondary ta-center"}>
                                                                        <span>İptal Talebi Onaylandı</span>
                                                                    </div>
                                                                </div>
                                                            }
                                                            {item?.isCancelApproved === 0 &&
                                                                <div className={"tags"}>
                                                                    <div
                                                                        className={"tag tag-m red_secondary ta-center"}>
                                                                        <span>İptal Talebi Reddedildi</span>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </>
                                                    }

                                                    {item?.isCanceled === 1 &&
                                                        <div className={"tags"}>
                                                            <div
                                                                className={"tag tag-m green_secondary ta-center"}>
                                                                {item?.canceledAmount ?
                                                                    <span>{item?.canceledAmount} Adet Ürün İptal Edildi</span> :
                                                                    <span>Ürün İptal Edildi</span>}
                                                            </div>
                                                        </div>
                                                    }

                                                    {item?.isRefund === 1 && item?.isIyzicoRefund === 0 &&
                                                        <div className={"tags"}>
                                                            <div
                                                                className={"tag tag-m orange_secondary ta-center"}>
                                                                <span>İade İstendi</span>
                                                            </div>
                                                        </div>
                                                    }

                                                    {item?.isRefund === 1 && item?.isIyzicoRefund === 1 &&
                                                        <div className={"tags"}>
                                                            <div
                                                                className={"tag tag-m red_secondary ta-center"}>
                                                                <span>İade Edildi</span>
                                                            </div>
                                                        </div>
                                                    }

                                                    {item?.isExchange === 1 && item?.isExchanged === null &&
                                                        <div className={"tags"}>
                                                            <div
                                                                className={"tag tag-m orange_secondary ta-center"}>
                                                                <span>Değişim İstendi</span>
                                                            </div>
                                                        </div>
                                                    }

                                                    {item?.isExchange === 1 && item?.isExchanged === 1 &&
                                                        <div className={"tags"}>
                                                            <div
                                                                className={"tag tag-m red_secondary ta-center"}>
                                                                <span>Değişim Yapıldı</span>
                                                            </div>
                                                        </div>
                                                    }

                                                </td>

                                                <td style={{minWidth: 75}} className={styles.right_align}>
                                                    {item?.isCancelApproved ?
                                                        <>
                                                            <s>{item?.amount} Adet</s>
                                                            <br/>
                                                            {item?.amount - item?.canceledAmount} Adet
                                                        </>
                                                        :
                                                        <>{item.amount} Adet</>
                                                    }
                                                </td>
                                                <td className={styles.right_align}>
                                                    {item?.isGift === 0 ? <>{item?.amountPrice?.toFixed(2)}{item?.currency?.symbol || '₺'}</>
                                                        : 'HEDİYE'}
                                                </td>
                                                <td className={styles.right_align}>
                                                    {(item?.isCancelApproved && (item?.canceledAmount && item?.canceledPrice)) ?
                                                        <>
                                                            <s>{item.totalPrice?.toFixed(2)}₺</s>
                                                            <br/>
                                                            {(item?.amountPrice * (item?.amount - item?.canceledAmount))?.toFixed(2)}₺
                                                        </>
                                                        :
                                                        <>{item.totalPrice?.toFixed(2)}₺</>
                                                    }
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </table>
                            </div>
                            {/*
                                detail?.items?.map(item => (
                                    <div key={item.listingId} className={styles.item}>
                                        <div className={styles.image}>
                                            <img alt={`${item?.id}_product_image`}
                                                 src={item?.thumbnail}/>
                                        </div>
                                        <div className={styles.item_detail}>
                                            <div className={styles.block}>
                                                <div className={styles.name}>
                                                    <b>{item?.name} {item?.variantTitle}</b>
                                                </div>
                                                <div className={styles.order_font_size}>
                                                    Adet: {item?.amount}
                                                </div>
                                            </div>

                                            <div className={styles.block}>
                                                <div className={styles.title}>
                                                    MARKA
                                                </div>
                                                <div className={`${styles.description} flex ai-center gap-10`}>
                                                    <img alt={`brand-${item?.brandName}`} src={item?.brandLogo}
                                                         height={35} width={40}/>
                                                    {item?.brandName}
                                                </div>
                                            </div>

                                            <div className={styles.block}>
                                                <div className={styles.title}>
                                                    ÜRÜN KODU
                                                </div>
                                                <div className={styles.description}>
                                                    {item?.sku}
                                                </div>
                                            </div>

                                            <div className={styles.block}>
                                                <div className={styles.title}>
                                                    KATALOG NUMARASI
                                                </div>
                                                <div className={styles.description}>
                                                    {item?.catalogNumber}
                                                </div>
                                            </div>

                                            {
                                                item?.options && item?.options?.map((option, i) => (
                                                    <div key={i} className={styles.block}>
                                                        <div className={styles.title}>
                                                            {option?.propertyName}
                                                        </div>
                                                        <div className={styles.description}>
                                                            {option?.propertyValueName}
                                                        </div>
                                                    </div>
                                                ))
                                            }

                                            {item?.hasCampaign && item?.isCampaignApplied &&
                                                <div className={styles.block}>
                                                    <div className={styles.title}>
                                                        KAMPANYALI ÜRÜN
                                                    </div>
                                                    <div className={`${styles.description} ${styles.orange}`}>
                                                        <Tag variant={"campaign"} campaign={item?.campaign}/>
                                                    </div>
                                                </div>
                                            }

                                            {item?.isGift === 1 &&
                                                <div className={styles.block}>
                                                    <div className={styles.title}>
                                                        KAMPANYALI ÜRÜN
                                                    </div>
                                                    <div className={`${styles.description} ${styles.orange}`}>
                                                        <Tag variant={"gift"}/>
                                                    </div>
                                                </div>
                                            }

                                            <div className={styles.block}>
                                                <div className={styles.title}>
                                                    SATIŞ FİYATI
                                                </div>
                                                <div
                                                    className={`${styles.description} ${styles.orange}`}>
                                                    {item?.amountPrice?.toFixed(2)} {item?.currency?.symbol || '₺'}
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                ))
                            */}
                        </div>
                    </div>
                </div>
                {/*
                <div className={styles.summary_block}>
                    <div className={styles.summary}>

                        <div className={styles.block}>
                            <div className={styles.title}>
                                TOPLAM SATIŞ TUTARI
                            </div>
                            <div className={styles.value}>
                                {((detail?.isCargoFree === 1 ? (detail?.totalPrice - detail?.totalLostSalePrice) : (detail?.totalPrice - (detail?.cargoPrice + detail?.totalLostSalePrice))))?.toFixed(2)} ₺
                            </div>
                        </div>
                        <div className={styles.block}>
                            <div className={styles.title}>
                                KAMPANYA
                            </div>
                            <div className={styles.value}>
                                {(detail?.campaignAppliedPrice * -1)?.toFixed(2)} ₺
                            </div>
                        </div>
                        <div className={styles.block}>
                            <div className={`${styles.title} flex ai-center gap-5`}>
                                DP İSKONTO
                                {detail?.discountCode && `(${detail?.discountType === 0 ? '%' : ''} ${detail?.discountValue}${detail?.discountType === 1 ? 'TL' : ''})`}

                                <Tooltip
                                    title={"Bu iskonto tutarı, Dentalpiyasa tarafından karşılanmaktadır. İlk ödeme gününde hesabınıza aktarılacaktır."}>
                                    <Icons.InfoIcon/>
                                </Tooltip>

                            </div>
                            <div className={styles.value}>
                                {(detail?.discountPrice * -1)?.toFixed(2)} ₺
                            </div>
                        </div>
                        <div className={styles.block} style={{
                            border: '.5px solid var(--primary-orange)',
                            borderRadius: '6px',
                            paddingBlock: '6px',
                            borderBottomRightRadius: 0,
                            borderTopRightRadius: 0,
                            backgroundColor: 'var(--secondary-orange)'
                        }}>
                            <div className={styles.title} style={{color: 'var(--primary-orange)'}}>
                                FATURA KESİLECEK TUTAR (KDV DAHİL)
                            </div>
                            <div className={styles.value} style={{color: 'var(--primary-orange)'}}>
                                {((detail?.isCargoFree === 1 ? (detail?.totalPrice - (detail?.campaignAppliedPrice + detail?.discountPrice + detail?.totalLostSalePrice)) : ((detail?.totalPrice - detail?.cargoPrice) - (detail?.campaignAppliedPrice + detail?.discountPrice + detail?.totalLostSalePrice))))?.toFixed(2)} ₺
                            </div>
                        </div>

                        {detail?.payer === 100 || detail?.payer === 300 &&
                            <div className={styles.block}>
                                <div className={`${styles.title} flex ai-center gap-5`}>
                                    TAHMİNİ KARGO ÜCRETİ
                                    <Tooltip
                                        title={'Kargo desileri tahminidir. Kargo firması tarafından nihai desi miktarları netleştirildiğinde, tarafınız ile mahsuplaşma yapılacaktır.'}>
                                        <Icons.InfoIcon/>
                                    </Tooltip>
                                </div>
                                <div className={styles.value}>
                                    {detail?.payer &&
                                        ((detail?.payer === 200) ? `${detail?.cargoPrice * -1} ₺`
                                            :
                                            OWNER_TYPES[detail?.payer] ? `${OWNER_TYPES[detail?.payer]} Ödedi` : detail?.isCargoFree === 1 ? `${detail?.cargoPrice * -1} ₺` : 'HEKİM ÖDEDİ')}
                                </div>
                            </div>
                        }

                        <div className={styles.block}>
                            <div className={`${styles.title} flex ai-center gap-5`}>
                                TAHMİNİ TOPLAM DESİ
                            </div>
                            <div className={styles.value}>
                                {detail?.totalDesi?.toFixed(2)}
                            </div>
                        </div>
                        <div className={styles.block}>
                            <div className={styles.title}>
                                KOMİSYON
                            </div>
                            <div className={styles.value}>
                                {(detail?.commissionPrice * -1)?.toFixed(2)} ₺
                            </div>
                        </div>
                        <div className={styles.block}
                             style={{borderBottom: '.5px solid var(--primary-gray)', paddingBlockEnd: '6px'}}>
                            <div className={styles.title}>
                                ARA TOPLAM
                            </div>
                            <div className={styles.value}>
                                {((detail?.totalPrice - detail?.totalLostSalePrice) - (((detail?.payer === 300 ? 0 : detail?.cargoPrice) + (detail?.discountPrice + detail?.commissionPrice + detail?.campaignAppliedPrice))))?.toFixed(2)} ₺
                            </div>
                        </div>
                        <div className={styles.block}>
                            <div className={styles.title} style={{paddingBlockStart: '6px'}}>
                                IYZICO'DAN YATACAK TUTAR
                            </div>
                            <div className={styles.value}>
                                {((detail?.totalPrice - detail?.totalLostSalePrice) - (((detail?.payer === 300 ? 0 : detail?.cargoPrice) + (detail?.discountPrice + detail?.commissionPrice + detail?.campaignAppliedPrice))))?.toFixed(2)} ₺
                            </div>
                        </div>
                        <div className={styles.block}>
                            <div className={styles.title}>
                                DENTALPİYASA'DAN YATACAK TUTAR
                            </div>
                            <div className={styles.value}>
                                {detail?.discountPrice?.toFixed(2)} ₺
                            </div>
                        </div>
                        <div className={styles.block}
                             style={{
                                 border: '.5px solid var(--secondary-gray)',
                                 borderRadius: '6px',
                                 paddingBlock: '6px',
                                 borderBottomRightRadius: 0,
                                 borderTopRightRadius: 0,
                                 backgroundColor: 'var(--primary-gray)'
                             }}>
                            <div className={styles.title} style={{color: 'var(--secondary-gray)'}}>
                                NET DEPO TUTARI
                            </div>
                            <div className={styles.value} style={{color: 'var(--secondary-gray)'}}>
                                {((detail?.totalPrice - detail?.totalLostSalePrice) - (((detail?.payer === 300 ? 0 : detail?.cargoPrice) + detail?.commissionPrice + detail?.campaignAppliedPrice)))?.toFixed(2)} ₺
                            </div>
                        </div>
                    </div>
                </div>
                    */}
            </div>
        </div>
    </> : canceledOrderContext.state.selectedOrder === orderId &&
        <PulseLoader size={20} margin={10} color="var(--primary-orange)"/>
}

export default CanceledOrderDetail;
