import React, {useContext, useEffect, useState} from "react";

import styles from '@dv/scss/lib/CanceledOrders.module.scss';

import {Title, Icons, Input, Button, Tooltip, Tag} from '@dv/react/lib';

import Tabs from "@organisms/Tabs";
import TabPane from "@organisms/Tabs/TabPane";
import TabContent from "@organisms/Tabs/TabContent";
import Layout from "@organisms/Layout";
import CardTable from "@organisms/CardTable";
import CanceledOrderContext from "@contexts/CanceledOrder/CanceledOrderContext";
import RangePicker from "@antd/RangePicker";

const CanceledOrders: React.FC<any> = () => {
    const canceledOrderContext = useContext(CanceledOrderContext);
    const [currentTab, setCurrentTab] = useState(1);
    const _canceledOrdersCount = {600: 0, 610: 0, 620: 0};
    const [canceledOrdersCount, setCanceledOrdersCount] = useState(_canceledOrdersCount);

    useEffect(() => {
        canceledOrderContext.getCanceledOrders();
    }, []);

    const getCanceledOrdersCountByStatus = (orders) => {
        for (const order of orders) {
            setCanceledOrdersCount({
                ...canceledOrdersCount,
                [order.status]: canceledOrdersCount[order.status] + 1
            });
        }
    }

    useEffect(() => {
        if (canceledOrderContext.state.orders.all !== null) {
            setCanceledOrdersCount(_canceledOrdersCount);
            getCanceledOrdersCountByStatus(canceledOrderContext.state.orders.all);
        }
    }, [canceledOrderContext.state.orders]);

    const getCanceledOrdersWithFilter = (e) => {
        e.preventDefault();
        const formType = e.target.getAttribute('form-type');
        canceledOrderContext.searchCanceledOrders(formType);
    }

    return (
        <Layout title="Canceled Orders">
            <div className={styles.container}>
                <Title variant="badge" title={"İPTAL EDİLEN SİPARİŞ"} icon={<Icons.RollbackIcon/>}/>
                <Tabs
                    current={currentTab}
                    setCurrent={setCurrentTab}
                    tabs={[
                        <TabPane key={1}>
                            <div className={styles.tab_pane_content}>
                                <h4>TÜM İPTALLER</h4>
                                <h5>{canceledOrderContext.state.orders.all !== null && `${canceledOrderContext.state.orders.all.length} PAKET`}</h5>
                            </div>
                        </TabPane>,
                        <TabPane key={2}>
                            <div className={styles.tab_pane_content}>
                                <h4>MÜŞTERİLERİN İPTAL ETTİKLERİ</h4>
                                <h5>{canceledOrderContext.state.orders.byCustomer !== null && `${canceledOrderContext.state.orders.byCustomer.length} PAKET`}</h5>
                            </div>
                        </TabPane>,
                        <TabPane key={3}>
                            <div className={styles.tab_pane_content}>
                                <h4>BENİM İPTAL ETTİKLERİM</h4>
                                <h5>{canceledOrderContext.state.orders.byVendor !== null && `${canceledOrderContext.state.orders.byVendor.length} PAKET`}</h5>
                            </div>
                        </TabPane>,
                        <TabPane key={4}>
                            <div className={styles.tab_pane_content}>
                                <h4>DENTAL PİYASA'NIN İPTAL ETTİKLERİ</h4>
                                <h5>{canceledOrderContext.state.orders.byAdmin !== null && `${canceledOrderContext.state.orders.byAdmin.length} PAKET`}</h5>
                            </div>
                        </TabPane>,
                    ]}>
                    <TabContent key={1}>
                        <div className="tab_content">
                            <form form-type="all" onSubmit={getCanceledOrdersWithFilter}
                                  className={styles.filter_section}>
                                <Input label={"SİPARİŞ NO"} type={"text"}
                                       value={canceledOrderContext.state.filterInputs.all.orderNo}
                                       tooltip={<Tooltip title="Açıklama"><Icons.InfoIcon/></Tooltip>}
                                       onChange={(e) => {
                                           canceledOrderContext.setFilterInputs('all', 'orderNo', e.target.value);
                                       }}/>

                                <div className={`input_group`}>
                                    <label>
                                        SİPARİŞ TARİHİ
                                        <Tooltip
                                            title="Siparişleri tarihe göre filtrelemenize yardımcı olur."><Icons.InfoIcon/></Tooltip>
                                    </label>
                                    <div className="">
                                        <RangePicker onChange={async (values, _dateString) => {
                                            canceledOrderContext.setFilterInputDates('all', values);
                                        }}/>
                                    </div>
                                </div>

                                <Button variant={"primary"} loading={false} disabled={false}>Ara</Button>
                                <Button variant={"primary-green"} loading={false} disabled={false}>Excel İndir</Button>
                            </form>
                        </div>

                        <CardTable variant={"canceled-order"}
                                   filters={true}
                                   pagination={{
                                       total: canceledOrderContext.state.orders.all?.length || 0,
                                       defaultPageSize: 2,
                                       defaultCurrent: 1,
                                       setSizeChanger: null
                                   }} data={canceledOrderContext.state.orders.all}/>

                    </TabContent>
                    <TabContent key={2}>
                        <div className="tab_content">
                            <form form-type="byCustomer" onSubmit={getCanceledOrdersWithFilter}
                                  className={styles.filter_section}>

                                <Input label={"SİPARİŞ NO"} type={"text"}
                                       value={canceledOrderContext.state.filterInputs.byCustomer.orderNo}
                                       tooltip={<Tooltip title="Açıklama"><Icons.InfoIcon/></Tooltip>}
                                       onChange={(e) => {
                                           canceledOrderContext.setFilterInputs('byCustomer', 'orderNo', e.target.value);
                                       }}/>

                                <div className={`input_group`}>
                                    <label>
                                        SİPARİŞ TARİHİ
                                        <Tooltip
                                            title="Siparişleri tarihe göre filtrelemenize yardımcı olur."><Icons.InfoIcon/></Tooltip>
                                    </label>
                                    <div className="">
                                        <RangePicker onChange={(values, _dateString) => {
                                            canceledOrderContext.setFilterInputDates('byCustomer', values);
                                        }}/>
                                    </div>
                                </div>

                                <Button variant={"primary"} loading={false} disabled={false}>Ara</Button>
                                <Button variant={"primary-green"} loading={false} disabled={false}>Excel
                                    İndir</Button>
                            </form>
                        </div>

                        <CardTable variant={"canceled-order"}
                                   filters={true}
                                   pagination={{
                                       total: canceledOrderContext.state.orders.byCustomer?.length || 0,
                                       defaultPageSize: 2,
                                       defaultCurrent: 1,
                                       setSizeChanger: null
                                   }} data={canceledOrderContext.state.orders.byCustomer}/>

                    </TabContent>
                    <TabContent key={3}>
                        <div className="tab_content">
                            <form form-type="byVendor" onSubmit={getCanceledOrdersWithFilter}
                                  className={styles.filter_section}>

                                <Input label={"SİPARİŞ NO"} type={"text"}
                                       value={canceledOrderContext.state.filterInputs.byVendor.orderNo}
                                       tooltip={<Tooltip title="Açıklama"><Icons.InfoIcon/></Tooltip>}
                                       onChange={(e) => {
                                           canceledOrderContext.setFilterInputs('byVendor', 'orderNo', e.target.value);
                                       }}/>

                                <div className={`input_group`}>
                                    <label>
                                        SİPARİŞ TARİHİ
                                        <Tooltip
                                            title="Siparişleri tarihe göre filtrelemenize yardımcı olur."><Icons.InfoIcon/></Tooltip>
                                    </label>
                                    <div className="">
                                        <RangePicker onChange={(values, _dateString) => {
                                            canceledOrderContext.setFilterInputDates('byVendor', values);
                                        }}/>
                                    </div>
                                </div>

                                <Button variant={"primary"} loading={false} disabled={false}>Ara</Button>
                                <Button variant={"primary-green"} loading={false} disabled={false}>Excel İndir</Button>
                            </form>
                        </div>

                        <CardTable variant={"canceled-order"}
                                   filters={true}
                                   pagination={{
                                       total: canceledOrderContext.state.orders.byVendor?.length || 0,
                                       defaultPageSize: 2,
                                       defaultCurrent: 1,
                                       setSizeChanger: null
                                   }} data={canceledOrderContext.state.orders.byVendor}/>

                    </TabContent>
                    <TabContent key={4}>
                        <div className="tab_content">
                            <form form-type="byAdmin" onSubmit={getCanceledOrdersWithFilter}
                                  className={styles.filter_section}>

                                <Input label={"SİPARİŞ NO"} type={"text"}
                                       value={canceledOrderContext.state.filterInputs.byAdmin.orderNo}
                                       tooltip={<Tooltip title="Açıklama"><Icons.InfoIcon/></Tooltip>}
                                       onChange={(e) => {
                                           canceledOrderContext.setFilterInputs('byAdmin', 'orderNo', e.target.value);
                                       }}/>

                                <div className={`input_group`}>
                                    <label>
                                        SİPARİŞ TARİHİ
                                        <Tooltip
                                            title="Siparişleri tarihe göre filtrelemenize yardımcı olur."><Icons.InfoIcon/></Tooltip>
                                    </label>
                                    <div className="">
                                        <RangePicker onChange={(values, _dateString) => {
                                            canceledOrderContext.setFilterInputDates('byAdmin', values);
                                        }}/>
                                    </div>
                                </div>

                                <Button variant={"primary"} loading={false} disabled={false}>Ara</Button>
                                <Button variant={"primary-green"} loading={false} disabled={false}>Excel
                                    İndir</Button>
                            </form>
                        </div>

                        <CardTable variant={"canceled-order"}
                                   filters={true}
                                   pagination={{
                                       total: canceledOrderContext.state.orders.byAdmin?.length || 0,
                                       defaultPageSize: 2,
                                       defaultCurrent: 1,
                                       setSizeChanger: null
                                   }} data={canceledOrderContext.state.orders.byAdmin}/>
                    </TabContent>
                </Tabs>
            </div>
        </Layout>
    )
};

export default CanceledOrders;
