import {Fragment} from 'react';

const KvkkAgreement = () => {

    return (
        <Fragment>
            <b>Kişisel Verilerin İşlenmesine İlişkin Rıza Metni</b>
            <br/> <br/>
            Şirketimiz Dentalpiyasa Elektronik Ticaret ve Bilişim Hizmetleri Anonim Şirketi (“Dentalpiyasa”) tarafından www.Dentalpiyasa.com internet sitesi üzerinden yürütülen faaliyetlerle ilgili olarak sizleri bilgilendirmek isteriz. Bir elektronik ticaret platformu olan Dentalpiyasa’da alışveriş yapabilmek için üyelik şartı bulunmaktadır. Dolayısıyla platformumuzda üyelik bir hizmet koşulu olarak sunulmaktadır. Üye olmadan alışveriş yapma seçeneğinden farklı olarak üyelik seçeneği; alışkanlıkları, alışveriş geçmişi, incelemeleri takip edilip değerlendirilerek kendisine özgü kişiselleştirilmiş bir hizmet almak isteyen kullanıcılar için tasarlanmıştır. Kişiye özgü bu avantajların sunulması için ise bazı kişisel verilerinizin aşağıda detaylandırıldığı şekilde belirtilen amaçlarla işlenmesi, anlaşmalı profesyonel iş ortaklarımızla paylaşılması ve teknoloji alanındaki tedarikçilerimiz tarafından sağlanan programlarımıza ve/veya sistemlerimize kaydedilmesi gerekebilmektedir. Siz müşterilerimizin memnuniyetinin sağlanması için işlenmesi gereken kişisel verilerinizden bazıları; Kişisel Verilerin Korunması Kanunu’nda yer alan ve rızaya tabi olmayan veri işleme şartlarının kapsamında olmaması nedeniyle, ancak siz müşterilerimizin konu özelinde beyan edeceği açık rızaya bağlıdır. Rıza vermek istemiyorsanız, “Üye Olmadan Devam Et” seçeneği ile sitemize üye olmadan alışveriş yapabilirsiniz.
            <br/><br/>
            Açık rızanıza bağlı olarak;
            <br/> <br/>
            <b>a. İşlenen Kişisel Veriler ve Toplama Yöntemleri:</b>
            <br/>
            Siz Müşterilerimizin çevrimiçi ve/veya basılı formlar vasıtasıyla paylaştığı kimlik bilgileriniz (isim, soyisim, doğum tarihi, cinsiyet, Diploma ve Tıbbi Cihaz Satış Merkezinde bulunan sair veriler), iletişim bilgileriniz (e-posta adresi, fatura ve teslimat adresleri, cep telefonu numarası); internet sitemizi kullanımız sırasında toplanan müşteri işlem bilgileriniz (talep bilgileri, sipariş bilgileri, fatura bilgileri, müşteri yorumları), pazarlama bilgileriniz (çerez kayıtları, alışveriş geçmişi, açık veya örtülü kullanıcı reytingleri), işlem güvenliği bilgileri (IP Adresi Bilgileri, İnternet Sitesi Giriş Çıkış Bilgileri, Kullanıcı Adı Bilgileri, Şifre Bilgileri, Bağlantı Zamanı / Süresi gibi Trafik Verileri) ile internet tarayıcınız ve/veya Şirketimiz mobil uygulamasındaki veya internet sitemizi kullanırken internet tarayıcınızdaki tercihlerinize göre toplanabilecek lokasyon verileriniz işlenecektir.
            <br/> <br/>
            <b>b. İşlenen Kişisel Verilerin İşleme Amaçları ve Hukuki Sebebi:</b>
            <br/>
            Yukarıda sayılan kişisel verileriniz, rıza göstererek Dentalpiyasa üyesi olmanız halinde; size özel reklam, promosyon ve kampanyaların oluşturulması, çapraz satış yapılması, hedef kitle belirlenmesi, müşteri hareketleriniz takip edilerek kullanıcı deneyiminizi arttırıcı faaliyetlerin yürütülmesi ve Dentalpiyasa’ya ait internet sitesi ile mobil uygulamanın işleyişinin geliştirilmesi ve müşteri ihtiyaçlarına göre kişiselleştirilmesi, doğrudan ve doğrudan olmayan pazarlama, kişiye özel pazarlama ve yeniden pazarlama faaliyetlerinin yürütülmesi, kişiye özel segmentasyon, hedefleme, analiz ve şirket içi raporlama faaliyetlerinin yürütülmesi, pazar araştırmaları, müşteri memnuniyeti aktivitelerinin planlanması ve icrası ile müşteri ilişkileri yönetimi süreçlerinin planlanması ve icrası amaçlarıyla dahil olmak üzere dahil olmak üzere Dentalpiyasa’nın ve Dentalpiyasa ile anlaşmalı profesyonel iş ortaklarının ürün ve/veya hizmetlerinin satış ve pazarlama süreçlerinin planlanması ve icrası, Dentalpiyasa’nın sunduğu ürün ve/veya hizmetlere bağlılık oluşturulması ve/veya arttırılması süreçlerinin planlanması ve icrası, tüm bu açıklanan kapsamlarda sizinle iletişim faaliyetlerinin yürütülmesi kapsamında; KVKK’nın 5. maddesinin 1. fıkrası “açık rıza” hukuki sebebine bağlı olarak işlenebilecek ve aynı amaçlar ve hukuki sebep kapsamında yurtiçi ve yurtdışındaki üçüncü kişilerle paylaşılabilecektir.
            <br/> <br/>
            <b> c. İşlenen Kişisel Verilere İlişkin Haklarınız:</b>
            <br/> <br/>
            <b>KVKK’nın 11. maddesi kapsamında;</b>
                <br/>
            •	Kişisel verilerinizin işlenip işlenmediğini öğrenme,
                <br/>
            •	Kişisel verileriz işlenmişse buna ilişkin bilgi talep etme,
                <br/>
            •	Kişisel verilerinizin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme,
                <br/>
            •	Yurt içinde veya yurt dışında kişisel verilerinizin aktarıldığı üçüncü kişileri bilme,
                <br/>
            •	Kişisel verilerinizin eksik veya yanlış işlenmiş olması halinde bunların düzeltilmesini isteme,
                <br/>
            •	KVKK mevzuatında öngörülen şartlar çerçevesinde kişisel verilerinizin silinmesini veya yok edilmesini isteme,
                <br/>
            •	Eksik veya yanlış verilerinizin düzeltilmesi ile kişisel verilerinizin silinmesi veya yok edilmesini talep etmesi halinde, bu durumun kişisel verilerinizin aktarıldığı üçüncü kişilere bildirilmesini isteme,
                <br/>
            •	İşlenen verilerinizin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle aleyhe bir sonucun ortaya çıkması durumunda sonuca itiraz etme ve
                <br/>
            •	Kişisel verilerinizin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması halinde bu zararın giderilmesini talep etme,
            haklarına sahipsiniz.
            <br/> <br/>
            Yukarıda yer alan tüm kişisel verilerinizin belirtilen işleme amaçları ile sınırlı olmak üzere işlenmesine hiçbir baskı altında kalmadan ve açık biçimde muvafakat ettiğinizi, üye ol sayfasındaki “Kayıt Ol” butonuna tıklayarak kabul etmiş olacaksınız.
        </Fragment>
    )
};

export default KvkkAgreement
