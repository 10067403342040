import React, {useContext, useEffect, useState} from "react";
import {Button, Icons} from "@dv/react";
import AuthContext from "@contexts/Auth/AuthContext";

const ForgotPasswordForm: React.FC<any> = ({styles, setFormPhase}) => {

    const authContext = useContext(AuthContext);

    const forgotPassword = (e) => {
        e.preventDefault();

        authContext.forgotPassword({
            email: authContext.state.fpEmail
        });
    }

    useEffect(() => {
        if (authContext.state.isForgotPasswordVerify === true) {
            setFormPhase('forgot-password-verify');
        }
    }, [authContext.state.isForgotPasswordVerify]);

    return (
        <div className={styles.forgot_password_form}>
            <h1><Icons.ArrowLeftIcon
                onClick={() => {
                    setFormPhase('login');
                }}/> Şifremi unuttum</h1>
            <h4>Lütfen e-posta adresinizi giriniz</h4>

            <form onSubmit={forgotPassword}>
                <div className={`input_group full_width`}>
                    <label>
                        E-posta
                    </label>
                    <div className={`input_section highlighted`}>
                        <input type="text" value={authContext.state.fpEmail} onChange={(e) => {
                            authContext.setForgotPasswordEmail(e.target.value);
                        }} placeholder={"E-postanızı giriniz"} required/>
                    </div>
                </div>

                <div className={styles.actions}>
                    <Button>Kod Gönder</Button>
                </div>
            </form>
        </div>
    )
}

export default ForgotPasswordForm;
