export const ReducerTypes = {
    GET_REFUND_ORDERS: 'GET_REFUND_ORDERS',
    SET_SELECTED_ORDER: 'SET_SELECTED_ORDER',
    GET_REFUND_ORDER_DETAIL: 'GET_REFUND_ORDER_DETAIL',
    SEARCH_REFUND_ORDERS: 'SEARCH_REFUND_ORDERS',
    SET_ORDER_STATUS: 'SET_ORDER_STATUS',
    SET_FILTER_INPUTS: 'SET_FILTER_INPUTS',
    PROCESS_FAIL: 'PROCESS_FAIL',
    SET_DETAIL_LOADING: 'SET_DETAIL_LOADING',
    SET_REFUND_ORDER_APPROVEMENT: 'SET_REFUND_ORDER_APPROVEMENT',
    CLEAR_ERRORS: 'CLEAR_ERRORS'
}

export interface IRefundOrderContext {
    orders?: {
        all: object[],
        refundStarted: object[],
        onCargo: object[],
        receivedVendor: object[],
        refundApproved: object[],
        refundCanceled: object[],
        refundCreatedShipment: object[]
        refundDeliveredShipment: object[]
        refundDelivered: object[]
    };
    selectedOrder: number;
    searchFilter?: object;
    filterInputs?: {
        all: object,
        refundStarted: object,
        onCargo: object,
        receivedVendor: object
        refundApproved: object,
        refundCanceled: object,
        refundCreatedShipment: object,
        // refundDeliveredShipment: object,
        // refundDelivered: object
    };
    processStatus?: boolean;
    loading?: boolean;
    detailLoading?: boolean;
    error?: any;
}

export type RefundOrderContextType = {
    state?: IRefundOrderContext | any;
    getRefundOrders: () => void;
    getRefundOrderDetail: (orderId: string, orderRefundId: string, cb: (orderDetail) => void) => void;
    searchRefundOrders: (type: string) => void;
    setFilterInputs: (inputType: string, key: string, value: any) => void;
    setFilterInputDates: (inputType: string, values: object[]) => void;
    setOrderStatus: (orderId: string, status: number, cb?: () => void) => void;
    setDetailLoading: (isLoading: boolean) => void;
    setRefundOrderApprovement: (formData: { orderId: any, orderStatusId?: any, approvement: number }, cb?: () => void) => void;
    clearErrors: () => void;
};


