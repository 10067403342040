import React from 'react';
import {notification} from 'antd';
import type {NotificationPlacement} from 'antd/es/notification';

type NotificationType = 'success' | 'info' | 'warning' | 'error';

export interface NotificationProps {
    props: {
        type?: NotificationType;
        title?: string;
        description: string;
        duration?: number;
        placement?: NotificationPlacement;
    }
}


const notify = ({
                    type = 'success',
                    title,
                    description,
                    duration = 3,
                    placement = 'top'
                }) => {

    return notification[type]({
        message: title,
        description,
        duration,
        placement,
        style: {
            borderRadius: '6px',
            borderColor: 'var(--secondary-orange)',
            borderWidth: '1px',
            borderStyle: 'solid',
            ':global(.ant-notification-notice-message)': {
                color: 'blue'
            }
        }
    });
};

export default notify;
