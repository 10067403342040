import {useReducer} from 'react';
import ProductContext from './ProductContext';
import ProductReducer from './ProductReducer';
import {localApi} from '@utils/axiosInstances';
import {ReducerTypes, IProductContext} from '../types/Product';
import notify from "@antd/Notification";

const ProductState = (props: any) => {

    const initialState: IProductContext = {
        products: null,
        selectedProduct: null,
        selectedProductVariants: null,
        processStatus: null,
        loading: null,
        error: null
    };

    const [state, dispatch] = useReducer(ProductReducer, initialState);

    // Select Product
    const selectProduct = async productId => {
        try {
            const res = await localApi.get('/vendor/products/' + productId);
            dispatch({
                type: ReducerTypes.SELECT_PRODUCT,
                payload: res?.data
            });
        } catch (err) {
            console.log('product not selected', err);
            dispatch({
                type: ReducerTypes.PROCESS_FAIL,
                payload: err?.response?.data?.message
            });
        }
    };

    // Update Products
    const updateProducts = async (formData, cb) => {
        try {
            const res = await localApi.put('/vendor/products/set-stocks', JSON.stringify(formData));

            dispatch({
                type: ReducerTypes.UPDATE_PRODUCTS,
                payload: res?.data
            });

            if (cb) {
                cb();
            }

            notify({
                type: 'success',
                title: 'İşlem Başarılı',
                description: 'Ürün başarılı bir şekilde güncellendi.'
            });
        } catch (err) {
            console.log('ERROR HERE', err);
            dispatch({
                type: ReducerTypes.PROCESS_FAIL,
                payload: err?.response?.data?.message
            });
        }
    };

    // Clear Errors
    const clearErrors = () => dispatch({type: ReducerTypes.CLEAR_ERRORS});

    return (
        <ProductContext.Provider
            value={{
                state: {...state},
                selectProduct,
                updateProducts,
                clearErrors
            }}
        >
            {props.children}
        </ProductContext.Provider>
    );
};

export default ProductState;
