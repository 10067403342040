export const ReducerTypes = {
    GET_PRODUCTS: 'GET_PRODUCTS',
    GET_PRODUCTS_EXCEL: 'GET_PRODUCTS_EXCEL',
    UPLOAD_PRODUCTS_EXCEL: 'UPLOAD_PRODUCTS_EXCEL',
    UPLOAD_PRODUCTS_EXCEL_ERROR: 'UPLOAD_PRODUCTS_EXCEL_ERROR',
    CLEAR_EXCEL_ERROR: 'CLEAR_EXCEL_ERROR',
    GET_ALL_PRODUCTS: 'GET_ALL_PRODUCTS',
    SET_ID_FOR_PRICE_ANALYSIS: 'SET_ID_FOR_PRICE_ANALYSIS',
    GET_PRODUCT_PRICE_ANALYSES: 'GET_PRODUCT_PRICE_ANALYSES',
    SET_SEARCH_PRODUCTS_TEXT: 'SET_SEARCH_PRODUCTS_TEXT',
    FILTER_PRODUCTS_BY_SEARCH_TEXT: 'FILTER_PRODUCTS_BY_SEARCH_TEXT',
    GET_CURRENCIES: 'GET_CURRENCIES',
    DELETE_PRODUCT: 'DELETE_PRODUCT',
    PROCESS_FAIL: 'PROCESS_FAIL',
    CLEAR_ERRORS: 'CLEAR_ERRORS',
    SET_LOADING: 'SET_LOADING'
}

export interface IUserContext {
    products?: object[];
    allProducts?: object[];
    uploadProductsPayload?: object[];
    uploadedProducts?: object[];
    uploadedProductsError?: object[];
    productPriceAnalyses?: object[];
    idForPriceAnalysis: number;
    searchedProducts?: object[];
    searchProductsText?: string;
    currencies?: object[];
    processStatus?: boolean;
    loading?: boolean;
    error?: any;
}

export type UserContextType = {
    state?: IUserContext | any;
    getProducts: () => void;
    getProductsExcel: () => void;
    getProductsExcel2: () => void;
    prepareProductUploadConclusion: (file, cb?: (data) => void) => void;
    uploadProductsExcelAsFile: (file, cb?: () => void) => void;
    uploadProductsExcel: (data, cb?: () => void) => void;
    getAllProducts: () => void;
    getProductPriceAnalyses: (cb?: () => void) => void;
    setSearchProductsText: (text: string) => void;
    filterProductsBySearchText: (text: string) => void;
    setIdForPriceAnalysis: (baseProductId: number) => void;
    getCurrencies: () => void;
    deleteProduct: (listingId: string) => void;
    setLoading: (isLoading: boolean) => void;
    clearErrors: () => void;
    test: (e) => void;
};


