import {ReducerTypes} from '../types/User';

export default (state, action) => {
    switch (action.type) {
        case ReducerTypes.GET_PRODUCTS:
            return {
                ...state,
                products: action.payload,
                searchedProducts: action.payload,
                loading: null,
                error: null,
                processStatus: true
            };
        case ReducerTypes.GET_ALL_PRODUCTS:
            return {
                ...state,
                allProducts: action.payload,
                loading: null,
                error: null,
                processStatus: true
            };
        case ReducerTypes.SET_ID_FOR_PRICE_ANALYSIS:
            return {
                ...state,
                idForPriceAnalysis: action.payload
            };
        case ReducerTypes.GET_PRODUCT_PRICE_ANALYSES:
            return {
                ...state,
                productPriceAnalyses: action.payload,
                loading: null,
                error: null,
                processStatus: true
            };
        case ReducerTypes.GET_CURRENCIES:
            return {
                ...state,
                currencies: action.payload,
                loading: false,
                error: null,
                processStatus: true
            };
        case ReducerTypes.DELETE_PRODUCT:
            return {
                ...state,
                loading: false,
                error: null,
                processStatus: true
            };
        case ReducerTypes.PROCESS_FAIL:
            localStorage.removeItem('dp_vendor_token');
            return {
                ...state,
                token: null,
                isAuthenticated: false,
                loading: false,
                user: null,
                error: action.payload || null,
            };
        case ReducerTypes.SET_SEARCH_PRODUCTS_TEXT:
            return {
                ...state,
                searchProductsText: action.payload
            };
        case ReducerTypes.FILTER_PRODUCTS_BY_SEARCH_TEXT:
            return {
                ...state,
                searchedProducts: state.products?.filter(p => {
                    console.log('PPP', p);
                    if (p.name.toLocaleLowerCase().includes(action.payload.toLocaleLowerCase())) {
                        return p;
                    }

                    if (p.brand?.name.toLocaleLowerCase().includes(action.payload.toLocaleLowerCase())) {
                        return p;
                    }

                    if (p.category?.name.toLocaleLowerCase().includes(action.payload.toLocaleLowerCase())) {
                        return p;
                    }
                })
            };
        case ReducerTypes.UPLOAD_PRODUCTS_EXCEL:
            return {
                ...state,
                uploadedProducts: action.payload
            };
        case ReducerTypes.UPLOAD_PRODUCTS_EXCEL_ERROR:
            return {
                ...state,
                uploadedProductsError: action.payload
            };
        case ReducerTypes.CLEAR_EXCEL_ERROR:
            return {
                ...state,
                uploadedProductsError: []
            };
        case ReducerTypes.SET_LOADING:
            return {
                ...state,
                loading: action.payload
            };
        case ReducerTypes.CLEAR_ERRORS:
            return {
                ...state,
                error: null
            };
        default:
            return state;
    }
};
