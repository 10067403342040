import {useReducer} from 'react';
import AddProductContext from './AddProductContext';
import AddProductReducer from './AddProductReducer';
import {localApi} from '@utils/axiosInstances';
import {ReducerTypes, IAddProductContext, newProductStockInputEmptyFields} from '../types/AddProduct';
import notify from "@antd/Notification";

const AddProductState = (props: any) => {

    const initialState: IAddProductContext = {
        baseProductId: null,
        searchTerm: null,
        products: null,
        beforeCurrencyType: null,
        selectedProduct: null,
        selectedProductVariants: null,
        processStatus: null,
        loading: false,
        error: null,
        newProductStocksValidation: false,
        newProductStocks: [],
        newProductStockInputs: newProductStockInputEmptyFields
    };

    const [state, dispatch] = useReducer(AddProductReducer, initialState);

    // Set Search Term
    const setSearchTerm = async (text) => {
        try {
            dispatch({type: ReducerTypes.SET_SEARCH_TERM, payload: text});
        } catch (err) {
            dispatch({type: ReducerTypes.PROCESS_FAIL, payload: err?.response?.data?.message});
        }
    }

    // Search Products
    const searchProducts = async (term) => {
        try {
            await setLoading(true);
            const res = await localApi.get(`/vendor/products/search?term=${term}`, {timeout: 60000});
            dispatch({type: ReducerTypes.SEARCH_PRODUCTS, payload: res?.data});
            setLoading(false);
        } catch (err) {
            dispatch({type: ReducerTypes.PROCESS_FAIL, payload: err?.response?.data?.message});
        }
    };

    // Select Product
    const selectProduct = async productId => {
        try {
            const res = await localApi.get('/vendor/products/search/' + productId, {timeout: 30000});

            dispatch({
                type: ReducerTypes.SELECT_PRODUCT,
                payload: res?.data
            });
        } catch (err) {
            console.log('product not selected', err);
            dispatch({
                type: ReducerTypes.PROCESS_FAIL,
                payload: err?.response?.data?.message
            });
        }
    };

    // Add Products
    const addProducts = async (cb) => {
        try {

            await setLoading(true);

            const res = await localApi.post('/vendor/products/set-stocks', JSON.stringify(state.newProductStocks?.filter(e => e.onValidation === true).map((el) => ({
                productId: parseInt(el.id),
                productStockId: el.productStockId,
                baseProductId: state?.baseProductId,
                currencyType: parseInt(el.currencyType),
                amount: parseFloat(String(el.amount)),
                marketPrice: parseFloat(el.marketPrice),
                price: parseFloat(el.price),
                warehouseIncome: el.warehouseIncome,
                minBatchAmount: parseFloat(el.minBatchAmount),
                batchUnitPrice: parseFloat(el.batchUnitPrice),
                expiration: el.expiration
            }))), {timeout: 30000});

            dispatch({
                type: ReducerTypes.ADD_PRODUCTS,
                payload: res?.data
            });

            await setLoading(false);

            cb();

            notify({
                type: 'success',
                title: 'İşlem Başarılı',
                description: 'Ürün başarılı bir şekilde eklendi/güncellendi.'
            });


        } catch (err) {
            console.log('ERROR HERE', err);
            dispatch({
                type: ReducerTypes.PROCESS_FAIL,
                payload: err?.response?.data?.message
            });
        }
    };

    // Set Product Stock Inputs
    const setProductStockInputs = async (data, cb) => {
        try {
            await dispatch({
                type: ReducerTypes.SET_PRODUCT_STOCK_INPUTS,
                payload: data
            });

            if (cb) {
                cb();
            }

        } catch (err) {
            console.log('ERROR HERE', err);
            dispatch({
                type: ReducerTypes.PROCESS_FAIL,
                payload: err
            });
        }
    };

    // Set Product Stock Inputs
    const setNewProductStock = async (id, data, cb) => {
        try {
            await dispatch({
                type: ReducerTypes.SET_NEW_PRODUCT_STOCK,
                id: id,
                payload: data
            });

            if (cb) {
                cb();
            }

        } catch (err) {
            console.log('ERROR HERE', err);
            dispatch({
                type: ReducerTypes.PROCESS_FAIL,
                payload: err
            });
        }
    };

    // Mutate New Product Stocks
    const mutateNewProductStocks = async (key) => {
        try {
            dispatch({
                type: ReducerTypes.MUTATE_NEW_PRODUCT_STOCKS,
                payload: key
            });
        } catch (err) {
            dispatch({
                type: ReducerTypes.PROCESS_FAIL,
                payload: err
            });
        }
    }

    const selectNewProductStocks = async (checked) => {
        try {
            dispatch({
                type: ReducerTypes.SELECT_NEW_PRODUCT_STOCKS,
                payload: checked
            });
        } catch (err) {
            dispatch({
                type: ReducerTypes.PROCESS_FAIL,
                payload: err
            });
        }
    }

    const validateNewProductStocks = async () => {
        try {

            const inAppropriateValueInputs = ['expiration', 'minPrice', 'packageContent', 'catalogNo', 'variantTitle', 'minPriceUSD', 'minPriceEUR', 'minBatchAmount', 'batchUnitPrice', 'productStockId', 'propertyValues', 'currencyShortName'];
            let noChangeValidation = false;
            let inputValueValidation = true;
            let hasEmptyValidation = true;

            for (const newProductStock of state.newProductStocks) {

                if (newProductStock.onValidation) {
                    noChangeValidation = true;

                    if (newProductStock.price > newProductStock.marketPrice) {
                        inputValueValidation = false;
                    } else {
                        if (newProductStock.batchUnitPrice >= newProductStock.price) {
                            inputValueValidation = false;
                        }
                    }

                    if (newProductStock.hasExpiration === true) {
                        if (newProductStock.expiration === '' || newProductStock.expiration === null) {
                            hasEmptyValidation = false
                        }
                    }

                    if ((newProductStock.minBatchAmount !== '' && newProductStock.minBatchAmount != 0 && newProductStock.minBatchAmount !== null) && (newProductStock.minBatchAmount < 2)) {
                        hasEmptyValidation = false;
                    }

                    for (const [key, value] of Object.entries(newProductStock)) {

                        console.log('KEY', key, 'VAL', value);

                        if (!inAppropriateValueInputs.includes(key)) {

                            if (value === undefined || value === null || value === '' || value === 0) {

                                if (key === 'amount') {
                                    if (value === undefined || value === null || value === '') {
                                        hasEmptyValidation = false;
                                    }
                                } else {
                                    hasEmptyValidation = false;
                                }
                            }
                        }
                    }
                }
            }


            dispatch({
                type: ReducerTypes.VALIDATE_NEW_PRODUCT_STOCKS,
                payload: ((hasEmptyValidation === true && inputValueValidation === true) && noChangeValidation === true)
            });
        } catch (err) {
            dispatch({
                type: ReducerTypes.PROCESS_FAIL,
                payload: err
            });
        }
    }

    const setLoading = (isLoading) => dispatch({type: ReducerTypes.SET_LOADING, payload: isLoading});

    // Clear Errors
    const clearErrors = () => dispatch({type: ReducerTypes.CLEAR_ERRORS});

    return (
        <AddProductContext.Provider
            value={{
                state: {...state},
                setSearchTerm,
                searchProducts,
                selectProduct,
                setNewProductStock,
                setProductStockInputs,
                mutateNewProductStocks,
                validateNewProductStocks,
                selectNewProductStocks,
                addProducts,
                setLoading,
                clearErrors
            }}
        >
            {props.children}
        </AddProductContext.Provider>
    );
};

export default AddProductState;
