import React, {Fragment, useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

import styles from '@dv/scss/lib/Products.module.scss';

import Layout from "@organisms/Layout";
import {Icons, Title, Card, Button, Input, Tooltip, Tag} from "@dv/react";
import AntTable from '@antd/Table';
import Popconfirm from "@antd/Popconfirm";
import UserContext from "@contexts/User/UserContext";
import PriceAnalysesModal from "@organisms/Modal/PriceAnalysesModal";
import userState from "@contexts/User/UserState";
import UploadMyProductsConclusionModal from "@organisms/Modal/UploadMyProductsConclusionModal";
import UploadProductInfoModal from "@organisms/Modal/UploadProductInfoModal";

const Products: React.FC<any> = () => {

    const userContext = useContext(UserContext);
    const [uploadFile, setUploadFile] = useState(null);
    const [uploadFile2, setUploadFile2] = useState(null);
    const navigate = useNavigate();
    const [uploadConclusionData, setUploadConclusionData] = useState(undefined);
    const [uploadConclusionModal, setUploadConclusionModal] = useState(false);
    const [uploadInfoModal, setUploadInfoModal] = useState(false);

    const [modals, setModals] = useState({
        productAnalyses: false,
    });

    const updateModal = (key, value) => {
        setModals({
            ...modals,
            [key]: value
        });
    }

    const navigateProductUpdate = (id) => {
        navigate(`/products/${id}/add`);
    }

    useEffect(() => {
        userContext.getProducts();
    }, []);

    return (
        <Layout title="Products">
            <div className={styles.container}>
                <Title variant="badge" title={"ÜRÜNLER"} icon={<Icons.ProductIcon/>}/>
                <Card>
                    <div className={styles.content}>
                        <div className={styles.search_section}>
                            <Input label={"ÜRÜN ARA"} type={"text"}
                                   placeholder={"Ürün adı, markası veya katalog numarasıyla ara"}
                                   tooltip={<Tooltip placement={"right"}
                                                     title="Bu bölüm, tüm katalogdan arama yapmaz. Yalnızca envanterinizdeki ürünleri arar."><Icons.InfoIcon/></Tooltip>}
                                   value={userContext.state.searchProductsText}
                                   onChange={(e) => {
                                       userContext.setSearchProductsText(e.target.value)
                                   }}/>
                            <Button variant={"primary"} loading={false} disabled={false}>Ara</Button>


                            <label className={styles.upload_products_label} onClick={() => {
                                if (localStorage.getItem('dont_show_upload_intro') === '1') {
                                    userContext.getProductsExcel2();
                                } else {
                                    setUploadInfoModal(true);
                                }
                            }}>
                                Ürün Listesini İndir
                            </label>

                            <label htmlFor={"uploadProductsInput"} className={styles.upload_products_label}>
                                Ürün Listesini Yükle
                            </label>

                            <input id={"uploadProductsInput"} type="file" value={uploadFile} onChange={(e) => {
                                // userContext.uploadProductsExcel(e);
                                userContext.prepareProductUploadConclusion(e, (data) => {
                                    setUploadConclusionData(data);
                                    setUploadConclusionModal(true);
                                });
                            }} style={{display: 'none'}}/>

                            {/*
                            <label htmlFor={"uploadProductsInput2"} className={styles.upload_products_label}>
                                test
                            </label>
                            <input id={"uploadProductsInput2"} type="file" value={uploadFile2} onChange={(e) => {
                                // userContext.uploadProductsExcel(e);
                                userContext.test(e);
                            }} style={{display: 'none'}}/>
                            */}


                        </div>
                    </div>
                </Card>

                {/*
                <Table type="complex" filters={true}
                       pagination={{
                           total: 50,
                           defaultPageSize: 25,
                           defaultCurrent: 1,
                           setSizeChanger: null
                       }} headers={[
                    <Header style={{flex: 3}}>TÜMÜNÜ SEÇ</Header>,
                    <Header style={{flex: 1}}>FİYAT DURUMU <Icons.InfoIcon color={"white"}/></Header>,
                    <Header style={{flex: 1}}>FİYAT ANALİZ</Header>,
                    <Header style={{flex: 1}}>PARA CİNSİ</Header>,
                    <Header style={{flex: 1}}>FİYATI</Header>,
                    <Header style={{flex: 1}}>MİKTAR</Header>,
                    <Header style={{flex: 1}}>DURUMU</Header>,
                    <Header style={{flex: 1}}>İŞLEMLER</Header>
                ]}>

                    {userContext.state.products !== null && userContext.state.products.map(product =>
                        <Row style={{justifyContent: 'center', textAlign: 'center'}}>
                            <Cell style={{flex: 3}}>
                                <div className={styles.tcell_product}>
                                    <div className={styles.tcell_product_image}>
                                        <img
                                            src={product.thumbnail}/>
                                    </div>
                                    <div className={styles.tcell_product_info}>
                                        <div className={styles.tcell_product_info_name}>
                                            {product.name}
                                        </div>
                                        <div className={styles.tcell_product_info_description}>
                                            Ambalaj: {product.packageContent}
                                        </div>
                                    </div>
                                </div>
                            </Cell>
                            <Cell style={{flex: 1}}><Tag label={"En iyi fiyat"} icon={"DoneIcon"}/></Cell>
                            <Cell style={{flex: 1}}>--</Cell>
                            <Cell style={{flex: 1}}>{product.currency}</Cell>
                            <Cell style={{flex: 1}}>{product.price} ₺</Cell>
                            <Cell style={{flex: 1}}>{product.amount}</Cell>
                            <Cell style={{flex: 1}}>{product.isActive ? 'Aktif' : 'Pasif'}</Cell>
                            <Cell style={{
                                flex: 1,
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "flex-start",
                                gap: "24px"
                            }}>
                                <Button variant={"just_icon"} type={"button"}><Icons.CopyIcon/></Button>
                                <Button variant={"just_icon"} type={"button"}><Icons.TrashIcon
                                    color={"var(--primary-gray)"}/></Button>
                            </Cell>
                        </Row>
                    )}

                    <Row style={{justifyContent: 'center', textAlign: 'center'}}>
                        <Cell style={{flex: 3}}>
                            <div className={styles.tcell_product}>
                                <div className={styles.tcell_product_image}>
                                    <img
                                        src={"https://images.everydayhealth.com/images/dental-health/csdentalhealthtoolsforbettersmilearticle.jpg?sfvrsn=53e57552_0"}/>
                                </div>
                                <div className={styles.tcell_product_info}>
                                    <div className={styles.tcell_product_info_name}>
                                        Torna Endo Absorbent Paper Points Emici Kağıt Noktaları-02 Açılı-15 200'lü
                                    </div>
                                    <div className={styles.tcell_product_info_description}>
                                        Ambalaj: 1 x 200 Adet
                                    </div>
                                </div>
                            </div>
                        </Cell>
                        <Cell style={{flex: 1}}><Tag label={"En iyi fiyat"} icon={"DoneIcon"}/></Cell>
                        <Cell style={{flex: 1}}>--</Cell>
                        <Cell style={{flex: 1}}>Türk lirası</Cell>
                        <Cell style={{flex: 1}}>52.05 ₺</Cell>
                        <Cell style={{flex: 1}}>12</Cell>
                        <Cell style={{flex: 1}}>Yüklendi</Cell>
                        <Cell style={{
                            flex: 1,
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            gap: "24px"
                        }}>
                            <Button variant={"just_icon"} type={"button"}><Icons.CopyIcon/></Button>
                            <Button variant={"just_icon"} type={"button"}><Icons.TrashIcon
                                color={"var(--primary-gray)"}/></Button>
                        </Cell>
                    </Row>
                </Table>
                */}



                <AntTable
                    columns={[
                        {
                            title: 'Ürün Adı',
                            dataIndex: 'name',
                            key: 'name',
                            width: '30%',
                            render: (_, data) => <div className={styles.tcell_product} onClick={() => {
                                navigateProductUpdate(data?.baseProductId);
                            }}>
                                <div className={styles.tcell_product_image}>
                                    <img src={data?.thumbnail}/>
                                </div>
                                <div className={styles.tcell_product_info}>
                                    <div className={styles.tcell_product_info_name}>
                                        {data?.name}
                                    </div>
                                    {data.hasVariant === 0 &&
                                      <div className={styles.tcell_product_info_description}>
                                        <div className={styles.tcell_product_info_description_title}>Ambalaj:</div>
                                        <div className={styles.tcell_product_info_description_content}>
                                            {data?.packageContent}
                                        </div>
                                      </div>
                                    }
                                </div>
                            </div>
                        },
                        {
                            title: <div className="flex gap-10 ai-center">Fiyat Durumu <Icons.InfoIcon color={"white"}/>
                            </div>,
                            dataIndex: 'priceStatus',
                            key: 'priceStatus',
                            width: '12%',
                            render: (_, {hasVariant, isBestPrice, baseProductId}) =>
                                <div className={"no-wrap"}>
                                    {hasVariant === 0 ? (isBestPrice === 1 ?
                                                <Tag label={"En iyi fiyat"} icon={"DoneIcon"}/>
                                                : <Tag label={"Yüksek fiyat"} icon={"NotDoneIcon"} colorVariant={"red"}/>
                                        ) :
                                        <div onClick={() => {
                                            userContext.setIdForPriceAnalysis(baseProductId);
                                            updateModal('productAnalyses', true);
                                        }}>
                                            <Tag label={"Fiyat Analizi"} colorVariant={"orange"} icon={"Coins2Icon"}
                                                 cursor={"pointer"}/>
                                        </div>}
                                </div>
                        },
                        {
                            title: 'Fiyat Analizi',
                            dataIndex: 'priceAnalysis',
                            className: 'best_price_cell',
                            key: 'priceAnalysis',
                            render: (_, {
                                amount,
                                hasVariant,
                                isBestPrice,
                                bestPrice,
                                bestPriceUSD,
                                bestPriceEUR,
                            }) =>
                                <div className={"tags flex fd-column ai-start"}>
                                    {
                                        (hasVariant === 0 && amount < 1) &&
                                      <div className={"tag red"}>
                                        <Tooltip
                                          title={"Stok miktarı 0 olduğu için şu an bu ürün satışta değildir."}>
                                          <span>Satışta Değildir</span>
                                        </Tooltip>
                                      </div>
                                    }
                                    {
                                        (hasVariant === 0 && isBestPrice === 0) &&
                                      <div className={"tag orange"}>
                                        <Tooltip
                                          title={<div className={"flex fd-column"}>
                                              <span>En iyi fiyat:</span>
                                              {bestPrice} ₺
                                              / {bestPriceUSD?.toFixed(2)} $
                                              / {bestPriceEUR?.toFixed(2)} €
                                          </div>}>
                                          <span>{bestPrice} ₺</span>
                                        </Tooltip>
                                      </div>
                                    }
                                </div>
                        },
                        {
                            title: 'Para Birimi',
                            dataIndex: 'currencyName',
                            key: 'currencyName',
                            render: (_, {currencyShortName}) => currencyShortName
                        },
                        {
                            title: 'Fiyatı',
                            dataIndex: 'price',
                            key: 'price',
                            render: (_, {
                                originalPrice,
                                currencySymbol
                            }) => originalPrice !== null ? `${originalPrice} ${currencySymbol}` : 'Varyantlı Ürün'
                        },
                        {
                            title: 'Stok',
                            dataIndex: 'amount',
                            key: 'amount',
                            render: (_, {amount}) => amount !== null ? amount : 'Varyantlı Ürün'
                        },
                        {
                            title: 'Durum',
                            dataIndex: 'isActive',
                            key: 'isActive',
                            render: (_, {isActive}) => <div className={"flex ai-center"}>{isActive ?
                                <Icons.DoneIcon/> :
                                <Icons.NotDoneIcon/>}
                            </div>
                        },
                        {
                            title: 'İşlemler',
                            dataIndex: 'actions',
                            key: 'actions',
                            render: (_, data) => <div className="flex gap-10 ai-center">
                                <Button onClick={() => navigateProductUpdate(data?.baseProductId)} variant={"just_icon"}
                                        type={"button"}><Icons.EditIcon/></Button>
                                <Popconfirm
                                    title={"Bu ürünü kaldırdığınızda, ürün pasif olacak, stoğu sıfırlanacak ve müşteriler tarafından reserve edilen ürünler kaldırılacaktır. Emin misiniz? "}
                                    loading={userContext.state.loading}
                                    onConfirm={() => {
                                        userContext.deleteProduct(data?.baseProductId);
                                    }}>
                                    <Button variant={"just_icon"} type={"button"} onClick={() => {
                                        // userContext.deleteProduct(data.listingId);
                                    }}><Icons.TrashIcon
                                        color={"var(--primary-gray)"}/></Button>
                                </Popconfirm>
                            </div>
                        },
                    ]} data={userContext.state.searchedProducts !== null ? userContext.state.searchedProducts : []}
                    loading={userContext.state.loading}/>
            </div>

            <PriceAnalysesModal show={modals.productAnalyses} setShow={(value) => {
                updateModal('productAnalyses', value);
            }}/>

            <UploadMyProductsConclusionModal title={"Ürün Karşılaştırma"} show={uploadConclusionModal}
                                             setShow={setUploadConclusionModal} data={uploadConclusionData}/>

            <UploadProductInfoModal title={"Toplu Ürün Listesi Bilgilendirme"} show={uploadInfoModal}
                                    setShow={setUploadInfoModal}/>
        </Layout>
    );
};

export default Products;
