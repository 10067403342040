import {ReducerTypes} from '../types/CanceledOrder';

const CANCELED_ORDER_STATUS_TYPES = {
    100: 'byCustomer',
    200: 'byVendor',
    300: 'byAdmin'
};

export default (state, action) => {
    switch (action.type) {
        case ReducerTypes.GET_CANCELED_ORDERS:
            return {
                ...state,
                orders: {
                    ...state.orders,
                    all: action.payload,
                    ...action.payload.reduce(
                        (accumulator, currentValue) => {
                            accumulator[CANCELED_ORDER_STATUS_TYPES[currentValue.canceledBy]].push(currentValue);
                            return accumulator;
                        },
                        {
                            byCustomer: [],
                            byVendor: [],
                            byAdmin: []
                        })
                },
                loading: false,
                error: null,
                processStatus: true
            };
        case ReducerTypes.SET_SELECTED_ORDER:
            return {
                ...state,
                selectedOrder: action.payload
            };
        case ReducerTypes.GET_CANCELED_ORDER_DETAIL:
            return {
                ...state,
                detailLoading: false,
                error: null,
                processStatus: true
            };
        case ReducerTypes.SEARCH_CANCELED_ORDERS:
            return {
                ...state,
                orders: {
                    ...state.orders,
                    [action.payload.type]: action.payload.data
                },
                loading: false,
                error: null,
                processStatus: true
            };
        case ReducerTypes.SET_FILTER_INPUTS:
            return {
                ...state,
                filterInputs: {
                    ...state.filterInputs,
                    ...action.payload
                }
            }
        case ReducerTypes.PROCESS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload || null,
                processStatus: false,
            };
        case ReducerTypes.SET_DETAIL_LOADING:
            return {
                ...state,
                detailLoading: action.payload
            };
        case ReducerTypes.CLEAR_ERRORS:
            return {
                ...state,
                error: null
            };
        default:
            return state;
    }
};
