const STEPS = {
  step1: {
    title: "campaign_info",
    name: "step1",
    step: 0
  },
  step2: {
    title: "campaign_scope",
    name: "step2",
    step: 1
  },
  step3: {
    title: "campaign_reward_product",
    name: "step3",
    step: 2
  },
  step4: {
    title: "campaign_criterias",
    name: "step4",
    step: 3
  }
};

export {
  STEPS
}
