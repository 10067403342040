import React from 'react';

import styles from '@dv/scss/lib/Layout.module.scss';

import Navbar from "@organisms/Layout/Navbar";
import Footer from "@organisms/Layout/Footer";

interface LayoutProps {
    title: string,
    children?: React.ReactNode
}

const Layout: React.FC<LayoutProps> = ({title, children}) => {
    return (
        <div className={styles.layout}>
            <head><title>DV | {title}</title></head>
            <div>
                <Navbar/>
                <div className={styles.content_wrapper}>
                    {children}
                </div>
                <Footer/>
            </div>
        </div>
    )
}

export default Layout;
