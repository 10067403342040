export const ReducerTypes = {
    GET_BASE_CAMPAIGNS: 'GET_BASE_CAMPAIGNS',
    GET_CAMPAIGNS: 'GET_CAMPAIGNS',
    SET_SELECTED_BASE_PRODUCT: 'SET_SELECTED_BASE_PRODUCT',
    SET_CONDITION_AMOUNT: 'SET_CONDITION_AMOUNT',
    FILL_PRODUCT_LISTS: 'FILL_PRODUCT_LISTS',
    SET_FILTER_TEXT: 'SET_FILTER_TEXT',
    FILTER_PRODUCT_LIST: 'FILTER_PRODUCT_LIST',
    SET_NEW_CAMPAIGN: 'SET_NEW_CAMPAIGN',
    SET_NEW_CAMPAIGN_DATES: 'SET_NEW_CAMPAIGN_DATES',
    CREATE_CAMPAIGN: 'CREATE_CAMPAIGN',
    DELETE_CAMPAIGN: 'DELETE_CAMPAIGN',
    PROCESS_FAIL: 'PROCESS_FAIL',
    SET_LOADING: 'SET_LOADING',
    CLEAR_ERRORS: 'CLEAR_ERRORS'
}

export interface ICampaignContext {
    baseCampaigns?: object[];
    campaigns?: object[];
    selectedCampaignBaseProduct?: object;
    selectedGiftBaseProduct?: object;
    campaignProductFilterText?: string;
    campaignConditionAmount?: number;
    giftProductFilterText?: string;
    giftConditionAmount?: number;
    campaignProductList?: object[];
    campaignProductSearchList?: object[];
    giftProductList?: object[];
    giftProductSearchList?: object[];
    newCampaign?: object;
    processStatus?: boolean;
    loading?: boolean;
    error?: any;
}

export type CampaignContextType = {
    state?: ICampaignContext | any;
    getBaseCampaigns: () => void,
    getCampaigns: () => void,
    setSelectedBaseProduct: (type: string, value: object) => void,
    setConditionAmount: (type: string, value: number) => void,
    fillProductLists: (type: string, data: any) => void,
    setFilterText: (type: string, value: string) => void,
    filterProductList: (type: string, text: string) => void,
    createCampaign: (data: object, cb?: () => void) => void,
    setNewCampaign: (key: string, value: any) => void,
    setNewCampaignDates: (data: { startDate: any, endDate: any }) => void,
    deleteCampaign: (campaignId: any, cb?: () => void) => void,
    setLoading: (isLoading: boolean) => void;
    clearErrors: () => void;
};


