import {ReducerTypes} from '../types/Order';

const ORDER_STATUS_TYPES = {
    100: 'new',
    200: 'willBeShipped',
    250: 'shippingCreated',
    300: 'shipped',
    400: 'inDistributed',
    500: 'delivered',
    510: 'payoutApproved'
};

export default (state, action) => {
    switch (action.type) {
        case ReducerTypes.GET_ORDERS:
            return {
                ...state,
                orders: {
                    ...state.orders,
                    all: action.payload,
                    ...action.payload.reduce(
                        (accumulator, currentValue) => {
                            accumulator[ORDER_STATUS_TYPES[currentValue.status]].push(currentValue);
                            return accumulator;
                        },
                        {
                            new: [],
                            willBeShipped: [],
                            shippingCreated: [],
                            shipped: [],
                            inDistributed: [],
                            delivered: [],
                            payoutApproved: []
                        })
                },
                loading: false,
                error: null,
                processStatus: true
            };
        case ReducerTypes.SET_SELECTED_ORDER:
            return {
                ...state,
                selectedOrder: action.payload
            };
        case ReducerTypes.GET_ORDER_DETAIL:
            return {
                ...state,
                detailLoading: false,
                error: null,
                processStatus: true
            };
        case ReducerTypes.SEARCH_ORDERS:
            return {
                ...state,
                orders: {
                    ...state.orders,
                    [action.payload.type]: action.payload.data
                },
                loading: false,
                error: null,
                processStatus: true
            };
        case ReducerTypes.SET_FILTER_INPUTS:
            return {
                ...state,
                filterInputs: {
                    ...state.filterInputs,
                    ...action.payload
                }
            }
        case ReducerTypes.ADD_ORDER_INVOICE:
        case ReducerTypes.SET_ORDER_STATUS:
            return {
                ...state
            };
        case ReducerTypes.PROCESS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload || null,
                processStatus: false,
            };
        case ReducerTypes.SET_DETAIL_LOADING:
            return {
                ...state,
                detailLoading: action.payload
            };
        case ReducerTypes.SET_LOADING:
            return {
                ...state,
                loading: action.payload
            };
        case ReducerTypes.SET_CARGO_DESI_PRICE_MODAL_TOGGLE:
            return {
                ...state,
                cargoDesiPriceModalToggle: action.payload
            };
        case ReducerTypes.CLEAR_ERRORS:
            return {
                ...state,
                error: null
            };
        default:
            return state;
    }
};
