export const CURRENCIES_BY_TYPE = {
  "TRY": 1,
  "USD": 2,
  "EUR": 3,
  1: "TRY",
  2: "USD",
  3: "EUR"
};


export const CURRENCY_SYMBOLS_BY_TYPE = {
  1: "₺",
  2: "$",
  3: "€"
};
