import React from 'react';
import styles from '@dv/scss/lib/Modal.module.scss';
import Modal from "@organisms/Modal";
import {ARVATO_PRICE_TABLE_BY_DESI} from "@utils/constants/cargo.config";

const CargoDesiPriceTableModal = ({title, show, setShow}) => {
    return (
        <Modal
            title={title}
            show={show}
            onClose={() => setShow(false)}
            onCancel={() => setShow(false)}
            onConfirm={() => {
                setShow(false);
            }}
            footer={false}
        >
            <section className={styles.cargo_desi_price_table_modal}>
                <div className={styles.line}>
                    <div className={styles.desi}>
                        Desi Miktarı ---
                    </div>
                    <div className={styles.price}>
                        KDV Hariç - PHB' li Fiyat
                    </div>
                </div>
                {ARVATO_PRICE_TABLE_BY_DESI.map(el => (
                    <div className={styles.line}>
                        <div className={styles.desi}>
                            {el.minDesi} Desi
                        </div>
                        <div className={styles.price}>
                            {el.localPrice} ₺
                        </div>
                    </div>
                ))}
            </section>
        </Modal>
    )
}

export default CargoDesiPriceTableModal;
