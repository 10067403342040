import React, {useState, useEffect} from 'react';
import {Button, Checkbox, Icons, Input, Title} from "@dv/react/lib";
import {STEPS} from "@utils/constants/addCampaign.config";
import Table, {Cell, Header, Row} from "@organisms/Table";

const Step2: React.FC<any> = ({styles, /*campaignForm, setCampaignForm,*/ setNavigation}) => {

    useEffect(() => {
        setNavigation(STEPS.step2);
    }, []);

    const onSubmit = (e) => {
        e.preventDefault();
        setNavigation(STEPS.step3);
    }

    const onPrev = (e) => {
        e.preventDefault();
        setNavigation(STEPS.step1);
    }

    const [newVariantContainer, setNewVariantContainer] = useState(false);

    return <form className={styles.form} onSubmit={onSubmit}>
        <div className={styles.input_group}>
            <Checkbox id={"1"} label={"Gireceğiniz tüm varyantların ambalaj içeriği aynı mı ?"} checked={true}/>
        </div>

        <div className={styles.input_group}>
            <div className={styles.input_group_block}>
                <Input label={"AMBALAJ İÇERİĞİ"} type={"text"} value={""} onChange={() => {
                }} width={360} placeholder={"Ambalaj içeriği girin"}/>
                <Button variant={"primary"} type={"submit"}>Kaydet</Button>
            </div>
        </div>

        <div className={styles.input_group}>
            <div className={styles.input_group_block}>
                <Button variant={'option'}>Ambalaj 1 x 200 Adet</Button>
            </div>
        </div>

        <div className={styles.input_group}>
            {newVariantContainer ?
                <div className={styles.input_group_block}>
                    <Input label={"VARYANT İSMİ"} type={"text"} value={""} onChange={() => {
                    }} fullWidth placeholder={"Ürün adını giriniz"}/>
                    <Input label={"AMBALAJ İÇERİĞİ"} type={"text"} value={""} onChange={() => {
                    }} fullWidth placeholder={"Ambalaj içeriği giriniz"}/>
                    <Button variant={"primary"} onClick={() => setNewVariantContainer(false)}>Kaydet</Button>
                </div>
                :
                <div className={styles.input_group_block}>
                    <div className={`${styles.element} ${styles.flex_end}`}>
                        <Button variant={"outlined-primary"} onClick={() => setNewVariantContainer(true)}>Yeni Varyant
                            Ekle</Button>
                    </div>
                </div>
            }
        </div>

        <div className={styles.input_group}>
            <Table type={"complex"} filters={false} headers={[
                <Header style={{flex: '5', justifyContent: "flex-start"}}>
                    Varyant Adı</Header>,
                <Header style={{flex: '1', justifyContent: "flex-end", textAlign: "right"}}>Düzenle</Header>
            ]}>
                <Row>
                    <Cell style={{flex: '5', alignItems: "flex-start"}}>
                        <div className={styles.variant_table_name_cell}>
                            <div className={styles.title}>
                                Kırmızı
                            </div>
                            <div className={styles.description}>
                                Ambalaj 1 x 200 Adet
                            </div>
                        </div>
                    </Cell>
                    <Cell style={{
                        flexDirection: "row",
                        flex: '1',
                        alignItems: "flex-end",
                        justifyContent: "flex-end",
                        gap: "24px"
                    }}>
                        <Button variant={"just_icon"}><Icons.CopyIcon/></Button>
                        <Button variant={"just_icon"}><Icons.TrashIcon color={"var(--primary-gray)"}/></Button>
                    </Cell>
                </Row>
                <Row>
                    <Cell style={{flex: '5', alignItems: "flex-start"}}>
                        <div className={styles.variant_table_name_cell}>
                            <div className={styles.title}>
                                Beyaz
                            </div>
                            <div className={styles.description}>
                                Ambalaj 1 x 200 Adet
                            </div>
                        </div>
                    </Cell>
                    <Cell style={{
                        flexDirection: "row",
                        flex: '1',
                        alignItems: "flex-end",
                        justifyContent: "flex-end",
                        gap: "24px"
                    }}>
                        <Button variant={"just_icon"} type={"button"}><Icons.CopyIcon/></Button>
                        <Button variant={"just_icon"} type={"button"}><Icons.TrashIcon
                            color={"var(--primary-gray)"}/></Button>
                    </Cell>
                </Row>
            </Table>
        </div>

        <div className={styles.actions}>
            <Button variant={"primary-gray"} type={"button"} onClick={onPrev}>Geri</Button>
            <Button variant={"primary"} type={"submit"}>ÜRÜN ONAYINA GÖNDER</Button>
        </div>
    </form>
}

export default Step2;
