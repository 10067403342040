import React from 'react';

import styles from '@dv/scss/lib/Footer.module.scss';
import PaymentLogos from '@assets/images/payment_logos.png';
import BarcodeLogo from '@assets/images/barcode_logo.png';

const Navbar: React.FC<any> = () => {

    return (
        <div className={styles.footer}>
            <div className={styles.block}>
                <ul className={styles.navigations}>
                    <li>İşlem Rehberi</li>
                    <li>Şartlar & Koşullar</li>
                    <li>Kişisel Verilerin Korunması</li>
                    <li>Satış Sözleşmesi & Teslimat İade</li>
                </ul>
                <div className={styles.copyright}>
                    © Copyright 2023 Dental Piyasa. Tüm Haklı Saklıdır.
                </div>
            </div>
            <div className={styles.sponsors}>
                <img src={BarcodeLogo}/>
                <img src={PaymentLogos}/>
            </div>
        </div>
    )
}

export default Navbar;
