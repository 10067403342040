import React, {useEffect, useState} from 'react';
import {Button, Icons, Input, RadioItem, Title} from "@dv/react";
import {STEPS} from "@utils/constants/productSuggestion.config";
import Upload from "@antd/Upload";
import {AWS_IMAGE_BUCKETS} from "@utils/BucketUploader";

const Step1: React.FC<any> = ({styles, /*suggestionForm, setSuggestionForm,*/ setNavigation}) => {

    const [url, setUrl] = useState(undefined);

    useEffect(() => {
        setNavigation(STEPS.step1);
    }, []);

    const onSubmit = (e) => {
        e.preventDefault();
        setNavigation(STEPS.step2);
    }

    return <form className={styles.form} onSubmit={onSubmit}>
        <div className={styles.input_group}>
            <Input label={"ÜRÜN ADI"} type={"text"} value={""} onChange={() => {
            }} placeholder={"Ürün adı giriniz"}/>
        </div>

        <div className={styles.input_group}>
            <Input label={"KATEGORİ"} type={"text"} value={""} onChange={() => {
            }} placeholder={"Kategori seçiniz"}/>
        </div>

        <div className={styles.input_group}>
            <Input label={"MARKA"} type={"text"} value={""} onChange={() => {
            }} placeholder={"Marka seçiniz"}/>
        </div>

        <div className={styles.input_group}>
            <Input label={"ÜRÜN AÇIKLAMA"} type={"textarea"} rows={"5"} value={""} onChange={() => {
            }} placeholder={"Ürün açıklamasını giriniz"}/>
        </div>

        <div className={styles.input_group}>
            <Title variant={"info"} title={'Ürün Resmi'} icon={<Icons.InfoIcon/>}/>
            <Upload bucketOptions={{
                bucketName: AWS_IMAGE_BUCKETS.NAMES.DOCUMENT.name,
                filePath: AWS_IMAGE_BUCKETS.NAMES.PUBLIC.PATHS.suggestion
            }} cssVariant={"gray"} url={url} setUrl={setUrl}/>
        </div>

        <div className={styles.actions}>
            <Button variant={"primary"} type={"submit"}>Devam Et</Button>
        </div>
    </form>
}

export default Step1;
