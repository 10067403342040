import React, {useContext} from 'react';
import styles from '@dv/scss/lib/Modal.module.scss';
import Modal from "@organisms/Modal";
import {Button} from "@dv/react";
import OrderContext from "@contexts/Order/OrderContext";

const CancelOrderModal = ({title, show, setShow, setModals, selectedOrderStatusId}) => {
    const orderContext = useContext(OrderContext);

    return (
        <Modal
            title={title}
            show={show}
            onClose={() => setShow(false)}
            onCancel={() => setShow(false)}
            footerComponent={
                <div className={"modal_footer"}>
                    <Button variant="primary-gray"
                            onClick={() => setShow(false)}>Vazgeç</Button>
                    <Button onClick={async () => {
                        setModals({
                            approveOrderModal: false,
                            declineOrderModal: false,
                            partialCancelModal: true
                        });
                    }}>Parçalı İptal</Button>
                    <Button onClick={() => {
                        orderContext.cancelOrderStatus(selectedOrderStatusId);
                        setShow(false);
                    }}>Siparişi Tümüyle İptal Et</Button>
                </div>
            }
        >
            <div className={styles.approve_modal_content}>
                <div className={styles.title}>
                    Siparişi iptal etmek istediğinize emin misiniz?
                </div>
                <div className={styles.description}>
                    Siparişi reddetmek depo puanınızı ve satışlarınızı olumsuz etkileyecektir.
                </div>
            </div>
        </Modal>
    )
}

export default CancelOrderModal;
