import {Fragment, useContext, useEffect} from "react";
import {Navigate, useLocation, useNavigate} from 'react-router-dom';
import AuthContext from "@contexts/Auth/AuthContext";
import Login from '@pages/Login';

function RequireAuth({
                         children,
                         isAuthenticated,
                         user
                     }: { children: JSX.Element, isAuthenticated: boolean, user: any }) {
    const authContext = useContext(AuthContext);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        authContext.setUser(user);
    }, [user]);

    if (isAuthenticated === undefined) {
        return <Fragment/>;
    } else {
        return (isAuthenticated ? children : <Login></Login>)
    }

    //<Navigate to={'/login'}/>
}

export default RequireAuth;
