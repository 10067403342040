export const ReducerTypes = {
    GET_ORDERS: 'GET_ORDERS',
    SET_SELECTED_ORDER: 'SET_SELECTED_ORDER',
    GET_ORDER_DETAIL: 'GET_ORDER_DETAIL',
    SEARCH_ORDERS: 'SEARCH_ORDERS',
    SET_FILTER_INPUTS: 'SET_FILTER_INPUTS',
    SET_ORDER_STATUS: 'SET_ORDER_STATUS',
    CANCEL_ORDER_STATUS: 'CANCEL_ORDER_STATUS',
    ADD_ORDER_INVOICE: 'ADD_ORDER_INVOICE',
    PROCESS_FAIL: 'PROCESS_FAIL',
    SET_LOADING: 'SET_LOADING',
    SET_DETAIL_LOADING: 'SET_DETAIL_LOADING',
    SET_CARGO_DESI_PRICE_MODAL_TOGGLE: 'SET_CARGO_DESI_PRICE_MODAL_TOGGLE',
    CLEAR_ERRORS: 'CLEAR_ERRORS'
}

export interface IOrderContext {
    orders?: {
        all: object[],
        new: object[],
        willBeShipped: object[],
        shippingCreated: object[],
        shipped: object[],
        inDistributed: object[],
        delivered: object[],
        payoutApproved: object[]
    };
    selectedOrder: number;
    searchFilter?: object;
    filterInputs?: {
        all: object,
        new: object,
        willBeShipped: object,
        shippingCreated: object,
        shipped: object,
        inDistributed: object,
        delivered: object,
        payoutApproved: object,
    };
    cargoDesiPriceModalToggle?: boolean;
    processStatus?: boolean;
    loading?: boolean;
    detailLoading?: boolean;
    error?: any;
}

export type OrderContextType = {
    state?: IOrderContext | any;
    getOrders: () => void;
    getOrderDetail: (orderId: string, cb: (orderDetail) => void) => void;
    addOrderInvoice: (orderId: number, url: string, cb: (err) => void) => void;
    searchOrders: (type: string) => void;
    setFilterInputs: (inputType: string, key: string, value: any) => void;
    setFilterInputDates: (inputType: string, values: object[]) => void;
    setOrderStatus: (orderId: string, status: number, cb?: () => void) => void;
    cancelOrderStatus: (orderStatusId: string, cb?: () => void) => void;
    submitForCancelApproval: (orderStatusId: string, orderItemId: string, cb?: () => void) => void;
    submitForCancelApprovalBatch: (orderStatusId: string, orderItemIds: string[], cb?: () => void) => void;
    setCargoDesiPriceModalToggle: (toggle: boolean) => void;
    setLoading: (isLoading: boolean) => void;
    setDetailLoading: (isLoading: boolean) => void;
    clearErrors: () => void;
};


