import React, {useContext, useEffect, useState} from "react";
import {Link} from 'react-router-dom';
import styles from "@dv/scss/lib/Payments.module.scss";
import {Card, Button, Icons, Title, Tooltip} from "@dv/react";
import Layout from "@organisms/Layout";

import 'moment/locale/tr';
import moment from 'moment';

import PayoutContext from "@contexts/Payout/PayoutContext";
import AuthContext from "@contexts/Auth/AuthContext";
import Loader from "@organisms/Loader";
import RangePicker from "@antd/RangePicker";
import Table, {Cell, Header, Row} from "@organisms/Table";

export interface PaymentsProps {
    children: any;
}

const PaymentsPage: React.FC<any> = () => {

    const authContext = useContext(AuthContext);
    const user = authContext.state.user;
    const payoutContext = useContext(PayoutContext);
    const dashboard = payoutContext.state.dashboard;
    const selectedWeekPayout = payoutContext.state.selectedWeekPayout;
    const payoutDates = payoutContext.state.payoutDates;
    const selectedPayoutDate = payoutContext.state.selectedPayoutDate;
    const payoutHistory = payoutContext.state.payoutHistory;

    const [startDate, setStartDate] = useState<any>();
    const [endDate, setEndDate] = useState<any>();

    useEffect(() => {
        payoutContext.getDashboard();
        payoutContext.getPayoutHistory();
    }, []);

    console.log('_W', payoutDates);

    return (
        <Layout title="Payments">
            <div className={styles.container}>
                <section className={styles.section}>
                    <div className={styles.block}>
                        <Title variant="badge" title={"Haftalık Tahmini Hakediş"}
                               icon={<Icons.CoinsIcon color={"white"}/>}/>
                        <div className={`${styles.history}`}>
                            <Card>
                                <div className={styles.payment_header}>
                                    <div className={styles.header}>
                                        <h3 className={'flex gap-5 ai-center'}>Tahmini Ödenecek Tutar <Tooltip
                                            placement={"right"}
                                            title="Hekime ulaştıktan sonra 7 günlük onay süresi tamamlanan siparişleri kapsamaktadır. Sonradan oluşacak iadeler veya birim bazlı kısmi iptallar bu rakamı değiştirebileceği için tahminidir."><Icons.InfoIcon/></Tooltip>
                                        </h3>
                                        <h4>Ödeme Günü: <b>{dashboard?.currentWeek?.paymentDate || '-'}</b></h4>
                                    </div>

                                    <div className={styles.info}>
                                        <div className={styles.price}>
                                            {dashboard?.currentWeek?.totalPayout ? `${dashboard?.currentWeek?.totalPayout.toFixed(2)} ₺` : '0.00 ₺'}
                                        </div>

                                        <div className={styles.description}>
                                            <Icons.InfoIcon/> {dashboard?.currentWeek?.totalPayout === 0 || dashboard?.currentWeek?.totalPayout === null ? <>{dashboard?.currentWeek?.weekStartDate && dashboard?.currentWeek?.weekEndDate ?
                                            <b>{dashboard?.currentWeek?.weekStartDate} - {dashboard?.currentWeek?.weekEndDate}</b> : 'Bu'} tarih
                                            aralığında ödemeniz bulunmamaktadır.</> : <>
                                            <b>{dashboard?.currentWeek?.weekStartDate} - {dashboard?.currentWeek?.weekEndDate}</b> tarihleri
                                            arası
                                            tahmini ödemeniz</>}
                                        </div>
                                    </div>

                                    <div className={styles.summary}>
                                        <div className={styles.summary_block}>
                                            <div className={styles.summary_block_title}>Toplam Satış Tutarı <Tooltip
                                                title="Herhangi bir kampanya veya komisyon indirimi uygulanmamış brüt toplam satış fiyatını göstermektedir."><Icons.InfoIcon/></Tooltip>
                                            </div>
                                            <div

                                                className={`${styles.summary_block_value}`}>{dashboard?.currentWeek?.totalSalePrice ? `+${dashboard?.currentWeek?.totalSalePrice?.toFixed(2)} ₺` : '-'} {/*<Loader type={"paymentDashboardPrice"}/>*/}
                                            </div>
                                        </div>
                                        <div className={styles.summary_block_line}></div>
                                        <div className={styles.summary_block}>
                                            <div className={styles.summary_block_title}>Kampanya Tutarı <Tooltip
                                                title="X al Y öde veya Çok Al Az Öde kampanyaları kapsamında hekime yapılmış indirimi göstermektedir."><Icons.InfoIcon/></Tooltip>
                                            </div>
                                            <div
                                                className={`${styles.summary_block_value} ${styles.secondary_gray}`}>{dashboard?.currentWeek?.totalCampaignPrice ? `-${dashboard?.currentWeek?.totalCampaignPrice?.toFixed(2)} ₺` : '-'}
                                            </div>
                                        </div>
                                        <div className={styles.summary_block_line}></div>
                                        <div className={styles.summary_block}>
                                            <div className={styles.summary_block_title}>POS & DP Komisyonu <Tooltip
                                                title="Dentalpiyasa ve ödeme kuruluşu tarafından ürün bazında kesilen komisyon tutarı."><Icons.InfoIcon/></Tooltip>
                                            </div>
                                            <div
                                                className={`${styles.summary_block_value} ${styles.secondary_gray}`}>{dashboard?.currentWeek?.commissionPayout ? `-${dashboard?.currentWeek?.commissionPayout?.toFixed(2)} ₺` : '-'}
                                            </div>
                                        </div>
                                        <div className={styles.summary_block_line}></div>
                                        <div className={styles.summary_block}>
                                            <div className={styles.summary_block_title}>Kargo Kesintisi <Tooltip
                                                title="Dentalpiyasa tarafından hesaplanan tahmini kargo ücretidir. Bu bedel, her ay sonunda kargo firmasından gelen nihai desiler sonucunda kesinleştirilerek mahsuplaşılır ve tarafınıza faturası yollanır."><Icons.InfoIcon/></Tooltip>
                                            </div>
                                            <div
                                                className={`${styles.summary_block_value} ${styles.secondary_gray}`}>{dashboard?.currentWeek?.totalCargoPrice ? `-${dashboard?.currentWeek?.totalCargoPrice?.toFixed(2)} ₺` : '-'}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={`${styles.header} ${styles.info}`}>
                                        <Tooltip title="Açıklama"><Icons.InfoIcon/></Tooltip> <h5>Ödemeleriniz
                                        için <b>{user?.commercial?.iban}</b> IBAN numaralı hesabınız kullanılacaktır.
                                    </h5>
                                    </div>
                                </div>
                            </Card>
                        </div>
                    </div>

                    <div className={styles.block}>
                        <Title variant="badge" title={"Gelecek Ödeme"}
                               icon={<Icons.CoinsIcon color={"white"}/>}/>
                        <Card>
                            <div className={`${styles.payments}`}>

                                <div className={styles.payments_block}>
                                    {dashboard?.salePriceOnCargoPhase ?
                                        <>
                                            <div className={styles.logo}>
                                                <Icons.ProductBoxMarkIcon/>
                                            </div>


                                            <div className={`${styles.content} ${styles.green}`}>
                                                <div className={styles.title}>
                                                    Tahmini Toplam Gelecek Ödeme <Tooltip
                                                    title="En son gerçekleşen ödemeden bugüne kadar oluşan siparişlerin hakedişidir. Bu hesaplama, olası iptal ve iadeleri kapsamadığı için tahminidir."><Icons.InfoIcon/></Tooltip>
                                                </div>
                                                <div className={styles.description}>
                                                    +{dashboard?.salePriceOnCargoPhase} ₺ {dashboard?.salePriceOnCargoPhase === 0 && ' - Siparişiniz bulunmamaktadır'}
                                                </div>
                                            </div>
                                        </>
                                        : dashboard?.salePriceOnCargoPhase !== 0 && <Loader type={"ratingMetric"}/>}
                                </div>


                                <div className={styles.payments_block}>
                                    {dashboard?.salePriceOnPendingPayoutPhase ?
                                        <>
                                            <div className={styles.logo}>
                                                <Icons.ProductBoxCheckedMarkIcon/>
                                            </div>


                                            <div className={`${styles.content} ${styles.red}`}>
                                                <div className={`${styles.title}`}>
                                                    İptal Sebebiyle Toplam Kayıp <Tooltip
                                                    title="İptal edilen siparişler sebebiyle gerçekleşen toplam gelir kaybını göstermektedir."><Icons.InfoIcon/></Tooltip>
                                                </div>
                                                <div className={styles.description}>
                                                    -{dashboard?.salePriceOnPendingPayoutPhase} ₺ {dashboard?.salePriceOnPendingPayoutPhase === 0 && ' - Siparişiniz bulunmamaktadır'}
                                                </div>
                                            </div>
                                        </>
                                        : dashboard?.salePriceOnPendingPayoutPhase !== 0 &&
                                        <Loader type={"ratingMetric"}/>}
                                </div>
                            </div>
                        </Card>
                    </div>

                    <div className={styles.block}>
                        <Title variant="badge" title={"Geçmiş Ödemelerim"}
                               icon={<Icons.CoinsIcon color={"white"}/>}/>

                        <Card>
                            <form className={styles.search_section}>

                                {/*<Input label={"ARAMA TÜRÜ"} type={"text"}
                               value={''}
                               placeholder={"Sipariş Numarası"}
                               tooltip={<Tooltip title="Açıklama"><Icons.InfoIcon/></Tooltip>}
                               onChange={() => {
                               }}
                        />*/}

                                <div className={`input_group flex`}>
                                    <label>
                                        SİPARİŞ TARİHİ
                                        <Tooltip
                                            title="Siparişleri tarihe göre filtrelemenize yardımcı olur."><Icons.InfoIcon/></Tooltip>
                                    </label>
                                    <div className="">
                                        <RangePicker showTime={false} format={"DD MMMM YYYY"}
                                                     onChange={(_value, _dateString) => {
                                                         if (_value[0]) {
                                                             setStartDate(_value[0]);
                                                         }
                                                         if (_value[1]) {
                                                             setEndDate(_value[1]);
                                                         }
                                                     }}/>
                                    </div>
                                </div>
                                <Button variant={"primary"} type={"button"} loading={false}
                                        disabled={(startDate && endDate) ? false : true} onClick={() => {
                                    payoutContext.getPayoutHistory(moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'));
                                }}>Filtrele</Button>
                                <Button type={"button"} variant={"outlined-primary"} onClick={() => {
                                    payoutContext.getPayoutHistoryExcel();
                                }}>CARİ HESAP EKSTRESİNİ OLUŞTUR</Button>

                            </form>
                        </Card>

                        <div className={styles.table_wrapper}>
                            <div className={styles.table}>
                                {payoutHistory && payoutHistory?.map((el, i) => (
                                    <Card key={i} data={el} variant={"payment-history"} detail={
                                        <>
                                            <div className={"flex fd-column gap-10"}>
                                                {el?.orders?.map((el2, i2) => (
                                                    <Card key={i2} data={el2} variant={"payment-history-order"} detail={
                                                        <>
                                                            <label className="no-wrap orange_label_link" style={{display: 'none', marginBottom: 5}}>CARİ
                                                                BİLGİLERİ
                                                                İÇİN TIKLAYINIZ</label>
                                                            <Table type="complex" headers={[
                                                                <Header style={{flex: 10, whiteSpace: 'no-wrap'}}>ÜRÜN
                                                                    ADI</Header>,
                                                                <Header
                                                                    style={{
                                                                        flex: 3,
                                                                        justifyContent: 'end',
                                                                        textAlign: 'right'
                                                                    }}>SATIŞ
                                                                    TUTARI <Tooltip placement={"topRight"}
                                                                                    title="Herhangi bir kampanya veya komisyon indirimi uygulanmamış brüt toplam satış fiyatını göstermektedir."><Icons.InfoIcon
                                                                        color={"white"}/></Tooltip></Header>,
                                                                <Header
                                                                    style={{
                                                                        flex: 3,
                                                                        justifyContent: 'end',
                                                                        textAlign: 'right'
                                                                    }}>DP
                                                                    İNDİRİM <Tooltip placement={"topRight"}
                                                                                     title="Dentalpiyasa’nın kendi komisyonundan feragat ederek hekimlere yaptığı indirimdir. Bu bedel, ödeme gününde ayrıca resmi banka hesabından tarafınıza komisyon indirimi olarak iade edilir"><Icons.InfoIcon
                                                                        color={"white"}/></Tooltip></Header>,
                                                                <Header
                                                                    style={{
                                                                        flex: 3,
                                                                        justifyContent: 'end',
                                                                        textAlign: 'right'
                                                                    }}>İADE
                                                                    TUTARI <Tooltip placement={"topRight"}
                                                                                    title="İptal veya iade sonucu kazanılamayan hakediş miktarını ifade etmektedir."><Icons.InfoIcon
                                                                        color={"white"}/></Tooltip></Header>,
                                                                <Header
                                                                    style={{
                                                                        flex: 3,
                                                                        justifyContent: 'end',
                                                                        textAlign: 'right'
                                                                    }}>KAMPANYA
                                                                    TUTARI <Tooltip placement={"topRight"}
                                                                                    title="X al Y öde veya Çok Al Az Öde kampanyaları kapsamında hekime yapılmış indirimi göstermektedir."><Icons.InfoIcon
                                                                        color={"white"}/></Tooltip></Header>,
                                                                <Header
                                                                    style={{
                                                                        flex: 3,
                                                                        justifyContent: 'end',
                                                                        textAlign: 'right'
                                                                    }}>POS & DP KOMİSYONU
                                                                    <Tooltip placement={"topRight"}
                                                                             title="Dentalpiyasa ve ödeme kuruluşu tarafından ürün bazında kesilen komisyon tutarı."><Icons.InfoIcon
                                                                        color={"white"}/></Tooltip></Header>,
                                                                <Header
                                                                    style={{
                                                                        flex: 3,
                                                                        justifyContent: 'end',
                                                                        textAlign: 'right'
                                                                    }}>KARGO KESİNTİSİ
                                                                    <Tooltip placement={"topRight"}
                                                                             title="Dentalpiyasa tarafından hesaplanan tahmini kargo ücretidir. Bu bedel, her ay sonunda kargo firmasından gelen nihai desiler sonucunda kesinleştirilerek mahsuplaşılır ve tarafınıza faturası yollanır."><Icons.InfoIcon
                                                                        color={"white"}/></Tooltip></Header>,
                                                                <Header
                                                                    style={{
                                                                        flex: 3,
                                                                        justifyContent: 'end',
                                                                        textAlign: 'right'
                                                                    }}>DP
                                                                    ÖDEME <Tooltip placement={"topRight"}
                                                                                   title="Dentalpiyasa tarafından hekime yapılan iskontonun, komisyon bedelinden düşülerek tarafınıza geri ödemesidir."><Icons.InfoIcon
                                                                        color={"white"}/></Tooltip></Header>,
                                                                <Header
                                                                    style={{
                                                                        flex: 3,
                                                                        justifyContent: 'end',
                                                                        textAlign: 'right'
                                                                    }}>IYZICO
                                                                    ÖDEME <Tooltip placement={"topRight"}
                                                                                   title="Ürün satışı sonucu gerçekleşen hakedişin ödemesidir. Iyzico ödeme kuruluşu tarafından yapılır."><Icons.InfoIcon
                                                                        color={"white"}/></Tooltip></Header>
                                                            ]}>
                                                                {el2?.items && [...el2?.items].map((item, i) => (
                                                                    <Row key={i}>
                                                                        <Cell
                                                                            style={{
                                                                                flex: 10,
                                                                                flexDirection: 'row',
                                                                                gap: 10,
                                                                                alignItems: 'center',
                                                                                justifyContent: 'start'
                                                                            }}>
                                                                            <img width={50} height={50}
                                                                                 src={item?.thumbnail}
                                                                                 alt={item?.productName + '_thumbnail'}/>
                                                                            {item?.productName} {item?.productVariantTitle}
                                                                        </Cell>
                                                                        <Cell
                                                                            style={{
                                                                                flex: 3,
                                                                                alignItems: 'end',
                                                                                paddingRight: 0
                                                                            }}><b>{item?.totalPrice?.toFixed(2)} ₺</b></Cell>
                                                                        <Cell
                                                                            style={{
                                                                                flex: 3,
                                                                                alignItems: 'end',
                                                                                paddingRight: 0,
                                                                                color: 'var(--primary-red)'
                                                                            }}><b>-{item?.dpDiscountPrice?.toFixed(2)} ₺</b></Cell>
                                                                        <Cell
                                                                            style={{
                                                                                flex: 3,
                                                                                alignItems: 'end',
                                                                                paddingRight: 0,
                                                                                color: 'var(--primary-red)'
                                                                            }}><b>{item?.iyzicoSubmerchantRefundPrice?.toFixed(2)} ₺</b></Cell>
                                                                        <Cell
                                                                            style={{
                                                                                flex: 3,
                                                                                alignItems: 'end',
                                                                                paddingRight: 0,
                                                                                color: 'var(--primary-red)'
                                                                            }}><b>-{item?.campaignAppliedPrice?.toFixed(2)} ₺</b></Cell>
                                                                        <Cell
                                                                            style={{
                                                                                flex: 3,
                                                                                alignItems: 'end',
                                                                                paddingRight: 0,
                                                                                color: 'var(--primary-red)'
                                                                            }}><b>-{item?.commissionPrice?.toFixed(2)} ₺</b></Cell>
                                                                        <Cell
                                                                            style={{
                                                                                flex: 3,
                                                                                alignItems: 'end',
                                                                                paddingRight: 0,
                                                                                color: 'var(--primary-red)'
                                                                            }}><b>-{item?.cargoPrice?.toFixed(2)} ₺</b></Cell>
                                                                        <Cell
                                                                            style={{
                                                                                flex: 3,
                                                                                alignItems: 'end',
                                                                                paddingRight: 0,
                                                                                color: 'var(--primary-green)'
                                                                            }}><b>{item?.dpDiscountPrice?.toFixed(2)} ₺</b></Cell>
                                                                        <Cell
                                                                            style={{
                                                                                flex: 3,
                                                                                alignItems: 'end',
                                                                                paddingRight: 0,
                                                                                color: 'var(--primary-green)'
                                                                            }}><b>{item?.netPrice?.toFixed(2)} ₺</b></Cell>
                                                                    </Row>
                                                                ))}
                                                            </Table>
                                                        </>
                                                    }/>
                                                ))}
                                            </div>
                                        </>
                                    }/>
                                ))}
                            </div>
                        </div>
                    </div>

                    {/*
                    <div className={styles.block}>
                        <div className={styles.cards}>
                            <div className={`${styles.big_card} ${styles.progress}`}>
                                <Title variant="badge" title={"BU HAFTAKİ HAKEDİŞİM"}
                                       icon={<Icons.QualityDiamondIcon/>}/>
                                <Card>
                                    {<div className={styles.payment_progress}>
                                        <div className={styles.payment_progress_block}>
                                            <div className={styles.logo}>
                                                <Icons.WalletMarkIcon/>
                                            </div>
                                            <h5>{dashboard?.weekStartDate} - {dashboard?.weekEndDate}</h5>
                                            {dashboard?.weeklyTotalPayout === 0 || dashboard?.weeklyTotalPayout === null ?
                                                <h6>Tarihleri arasında ödemeniz bulunmamaktadır</h6> :
                                                <h3>{dashboard?.weeklyTotalPayout?.toFixed(2)} ₺</h3>}
                                        </div>
                                    </div>
                                    }
                                </Card>
                            </div>
                            <div className={`${styles.big_card} ${styles.history}`}>
                                <Title variant="badge" title={"ÖDEME GEÇMİŞİM"} icon={<Icons.NewspaperIcon/>}/>
                                <Card>
                                    {<div className={styles.payment_history}>
                                        <div className={styles.header}>
                                            <h5>Toplam Ödeme Tutarı</h5>
                                            <Link to={"/payments/history"}><h5 className={styles.link}>Tüm geçmiş
                                                ödemeler <Icons.ChevronRightCircleIcon/></h5></Link>
                                        </div>
                                        <div className={styles.options}>
                                            {payoutDates?.map((payout, i) => (
                                                <Button key={i} onClick={() => {
                                                    payoutContext.setSelectedPayoutDate(payout?.date);
                                                    // setSelectedPayoutDate(payout?.date);
                                                    let x = new Date(payout?.date);
                                                    x.setDate(x.getDate() - 1);
                                                    payoutContext.getPayoutWeek(moment(x).format('YYYY-MM-DD'))
                                                }}
                                                        variant={payout?.date === selectedPayoutDate ? 'option_selected' : 'option'}>
                                                    {moment(payout?.date).format('DD.MM.YYYY')}
                                                </Button>
                                            ))}
                                        </div>

                                        <div className={styles.info}>
                                            <div className={styles.price}>
                                                {selectedWeekPayout?.submerchantPrice ? `${selectedWeekPayout?.submerchantPrice?.toFixed(2)} ₺` : '-'}
                                            </div>

                                            <div className={styles.description}>
                                                <Icons.InfoIcon/> {selectedWeekPayout?.submerchantPrice ? 'Mevcut hakediş tutarınız' : 'Hesap bakiyeniz 0,00 ₺ olduğu için ödeme yapılmadı.'}
                                            </div>
                                        </div>

                                        <div className={styles.summary}>
                                            <div className={styles.summary_block}>
                                                <div className={styles.summary_block_title}>TOPLAM SATIŞIM</div>
                                                <div
                                                    className={styles.summary_block_value}>{selectedWeekPayout?.totalSalePrice ? `${selectedWeekPayout?.totalSalePrice?.toFixed(2)} ₺` : '-'}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    }
                                </Card>
                            </div>
                        </div>
                    </div>
                    */}
                </section>
            </div>
        </Layout>
    )
};

export default PaymentsPage;
