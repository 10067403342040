import React, {useState, useContext, useEffect} from 'react';

import {useNavigate} from "react-router-dom";

import AuthContext from "@contexts/Auth/AuthContext";
import notify from "@antd/Notification";

const Logout: React.FC<any> = () => {

    const navigate = useNavigate();

    const authContext = useContext(AuthContext);

    useEffect(() => {
        authContext.logout();
    }, []);

    useEffect(() => {
        if (authContext.state.error !== null)
            notify({
                type: 'error',
                title: 'Bir hata ile karşılaşıldı',
                description: authContext.state.error
            });
        else if (authContext.state.isAuthenticated == null)
            navigate('/login');
        authContext.clearErrors();
    }, [authContext.state.error]);

    return (
        <div>
            Girişe yönlendiriliyorsunuz.
        </div>
    )
}

export default Logout;
