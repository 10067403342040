import React, {useContext, useEffect, useState} from 'react';
import {Button, Icons, Input, Tag, Title, Tooltip} from "@dv/react/lib";
import {STEPS} from "@utils/constants/addCampaign.config";
import Table, {Cell, Header, Row} from "@organisms/Table";
import UserContext from "@contexts/User/UserContext";
import CampaignContext from "@contexts/Campaign/CampaignContext";
import {PulseLoader} from "react-spinners";
import AntTable from "@antd/Table/Table";

const Step2: React.FC<any> = ({styles, campaignForm, setNavigation}) => {
    const userContext = useContext(UserContext);
    const campaignContext = useContext(CampaignContext);

    const [validation, setValidation] = useState(false);

    useEffect(() => {
        setNavigation(STEPS.step2);
        userContext.getProducts();
        userContext.getAllProducts();
    }, []);

    useEffect(() => {
        campaignContext.fillProductLists('campaign', userContext.state.products);
    }, [userContext.state.products]);

    useEffect(() => {
        campaignContext.fillProductLists('gift', userContext.state.allProducts);
    }, [userContext.state.allProducts]);

    const onSubmit = (e) => {
        e.preventDefault();
        setNavigation(STEPS.step3);
    }

    const onPrev = (e) => {
        e.preventDefault();
        setNavigation(STEPS.step1);
    }

    useEffect(() => {
        if (campaignForm.campaignType === "discount_by_quantity") {
            setValidation(campaignContext.state.selectedCampaignBaseProduct !== null);
        } else if (campaignForm.campaignType === "buy_together_discount") {
            setValidation(campaignContext.state.selectedCampaignBaseProduct !== null && campaignContext.state.selectedGiftBaseProduct !== null);
        } else {
            setValidation(false);
        }
    }, [campaignContext.state]);

    return <form className={styles.form} onSubmit={onSubmit}>
        <div className={styles.input_group}>
            <Title variant="header" title={"Kampanya Ürünlerini Seçiniz"}/>
        </div>

        <div className={styles.input_group}>
            <div className={styles.input_group_block}>
                <Input label={"KAMPANYA UYGULANACAK ÜRÜN"}
                       placeholder={"Ürün adı, markası veya katalog numarasıyla ara"}
                       tooltip={<Tooltip placement={"right"}
                                         title="Bu ürün kampanya uygulanacak ürün olacaktır. Mevcut satışta olan ürünlerini göreceksiniz."><Icons.InfoIcon/></Tooltip>}
                       type={"text"} value={campaignContext.state.campaignProductFilterText} onChange={(e) => {
                    campaignContext.setFilterText('campaignProductFilterText', e.target.value);
                    campaignContext.filterProductList('campaign', e.target.value);
                }} fullWidth/>
                <Button variant={"primary"} type={"button"}>Ara</Button>
            </div>
        </div>

        {campaignContext.state.campaignProductList ?
            <div className={styles.table_container}>
                {campaignContext.state.selectedCampaignBaseProduct &&
                  <div className={styles.input_group_block}>
                    <p>Seçili Ürün:</p>
                    <div className={"tags"}>
                      <div className={"tag tag-m green"}>
                        <span>{campaignContext.state.selectedCampaignBaseProduct?.name}</span>
                      </div>
                    </div>
                  </div>
                }
                <AntTable
                    columns={[
                        {
                            title: 'Ürün Bilgileri',
                            dataIndex: 'name',
                            key: 'name',
                            render: (_, data) => <div className={styles.tcell_product}>
                                <div className={styles.tcell_product_image}>
                                    <img src={data?.thumbnail}/>
                                </div>
                                <div className={styles.tcell_product_info}>
                                    <div className={styles.tcell_product_info_name}>
                                        {data?.name}
                                    </div>
                                    {data.hasVariant === 0 &&
                                      <div className={styles.tcell_product_info_description}>
                                        <div className={styles.tcell_product_info_description_title}>Ambalaj:</div>
                                        <div className={styles.tcell_product_info_description_content}>
                                            {data?.packageContent}
                                        </div>
                                      </div>
                                    }
                                </div>
                            </div>
                        },
                        {
                            title: 'Seç',
                            dataIndex: 'chooseProduct',
                            key: 'chooseProduct',
                            render: (_, data) => campaignContext.state.selectedCampaignBaseProduct?.baseProductId === data?.baseProductId ?
                                <div className={"tags"}>
                                    <div className={"tag tag-m green"}>
                                        <span>Seçili Ürün</span>
                                    </div>
                                </div>
                                :
                                <div className={styles.tcell_product}>
                                    <div className={"tags cursor_pointer"} onClick={() => {
                                        campaignContext.setSelectedBaseProduct('selectedCampaignBaseProduct', data);
                                    }}>
                                        <div className={"tag tag-m orange"}>
                                            <span>Seç</span>
                                        </div>
                                    </div>
                                </div>
                        },
                    ]}
                    data={campaignContext.state.campaignProductSearchList}
                    loading={campaignContext.state.loading} scroll={{x: undefined, y: 600}}/>
            </div>
            : <PulseLoader size={20} margin={10} color="var(--primary-orange)"/>}

        {campaignForm.campaignType === "buy_together_discount" &&
          <>
            <div className={styles.input_group}>
              <div className={styles.input_group_block}>
                <Input label={"HEDİYE ÜRÜNÜ"}
                       placeholder={"Ürün adı, markası veya katalog numarasıyla ara"}
                       tooltip={<Tooltip placement={"right"}
                                         title="Bu ürün kampanya uygulanacak ürün olacaktır. Mevcut satışta olan ürünlerini göreceksiniz."><Icons.InfoIcon/></Tooltip>}
                       type={"text"} value={campaignContext.state.giftProductFilterText} onChange={(e) => {
                    campaignContext.setFilterText('giftProductFilterText', e.target.value);
                    campaignContext.filterProductList('gift', e.target.value);
                }} fullWidth/>
                <Button variant={"primary"} type={"button"}>Ara</Button>
              </div>
            </div>
              {campaignContext.state.giftProductList ?
                  <div className={styles.table_container}>
                      {campaignContext.state.selectedGiftBaseProduct &&
                        <div className={styles.input_group_block}>
                          <p>Seçili Ürün:</p>
                          <div className={"tags"}>
                            <div className={"tag tag-m green"}>
                              <span>{campaignContext.state.selectedGiftBaseProduct?.name}</span>
                            </div>
                          </div>
                        </div>
                      }
                      <AntTable
                          columns={[
                              {
                                  title: 'Ürün Bilgileri',
                                  dataIndex: 'name',
                                  key: 'name',
                                  render: (_, data) => <div className={styles.tcell_product}>
                                      <div className={styles.tcell_product_image}>
                                          <img src={data?.thumbnail}/>
                                      </div>
                                      <div className={styles.tcell_product_info}>
                                          <div className={styles.tcell_product_info_name}>
                                              {data?.name} <b>{data?.variantTitle}</b>
                                          </div>
                                          {data.hasVariant === 0 &&
                                            <div className={styles.tcell_product_info_description}>
                                              <div className={styles.tcell_product_info_description_title}>Ambalaj:
                                              </div>
                                              <div className={styles.tcell_product_info_description_content}>
                                                  {data?.packageContent}
                                              </div>
                                            </div>
                                          }
                                      </div>
                                  </div>
                              },
                              {
                                  title: 'Fiyat',
                                  dataIndex: 'price',
                                  key: 'price',
                                  render: (_, data) => <>{data?.price} {data?.currencySymbol}</>
                              },
                              {
                                  title: 'Seç',
                                  dataIndex: 'chooseProduct',
                                  key: 'chooseProduct',
                                  render: (_, data) => campaignContext.state.selectedGiftBaseProduct?.productStockId === data?.productStockId ?
                                      <div className={"tags"}>
                                          <div className={"tag tag-m green"}>
                                              <span>Seçili Ürün</span>
                                          </div>
                                      </div>
                                      :
                                      <div className={styles.tcell_product}>
                                          <div className={"tags cursor_pointer"} onClick={() => {
                                              campaignContext.setSelectedBaseProduct('selectedGiftBaseProduct', data);
                                          }}>
                                              <div className={"tag tag-m orange"}>
                                                  <span>Seç</span>
                                              </div>
                                          </div>
                                      </div>
                              },
                          ]}
                          data={campaignContext.state.giftProductSearchList}
                          loading={campaignContext.state.loading} scroll={{x: undefined, y: 600}}/>
                  </div>
                  : <PulseLoader size={20} margin={10} color="var(--primary-orange)"/>}
          </>
        }

        <div className={styles.actions}>
            <Button variant={"primary-gray"} type={"button"} onClick={onPrev}>Geri</Button>
            <Button variant={"primary"} type={"submit"} disabled={!validation}>Devam Et</Button>
        </div>
    </form>
}

export default Step2;
