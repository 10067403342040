const STEPS = {
  step1: {
    title: "product_info",
    name: "step1",
    step: 0
  },
  step2: {
    title: "product_content",
    name: "step2",
    step: 1
  },
  step3: {
    title: "confirmation",
    name: "step3",
    step: 2
  }
};

export {
  STEPS
}
