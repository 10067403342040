import React, {Fragment, useState} from 'react';

import {AWS_IMAGE_BUCKETS, createBucketRequest} from "@utils/BucketUploader";
import {createUuidFilename} from "@utils/useUUID";

import {UploadOutlined, InboxOutlined} from '@ant-design/icons';
import {Button, message, Upload as AntUpload, UploadProps} from 'antd';
import type {RcFile, UploadListType} from 'antd/es/upload/interface';

import '@dv/scss/lib/Upload.antd.css';

const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = error => reject(error);
    });

const beforeUpload = file => {
    const isPNG = file.type === 'image/png';
    if (!isPNG) {
        message.error(`${file.name} is not a png file`);
    }
    return isPNG || AntUpload.LIST_IGNORE;
}

export interface IUploadProps {
    bucketOptions: { bucketName: string, filePath: (filename: string) => string };
    url: string;
    setUrl: (url: string) => void;
    multiple?: boolean;
    accept?: string;
    listType?: UploadListType;
    cssVariant?: string;
    tooltip?: any;
}

const Upload: React.FC<IUploadProps> = ({
                                            bucketOptions,
                                            multiple,
                                            accept,
                                            listType,
                                            url,
                                            setUrl,
                                            cssVariant,
                                            tooltip
                                        }) => {

    const uploadImage = async (options) => {
        const {onSuccess, onError, file, onProgress} = options;

        try {
            const filename = [file.uid, file.name.split('.').at(-1)].join('.');
            const {assetURL} = await createBucketRequest({
                bucketName: bucketOptions.bucketName,
                filePath: bucketOptions.filePath(filename)
            }, file, file.uid, onProgress);
            setUrl(assetURL);
            onSuccess("Ok");
        } catch (err) {
            console.error(err);
            onError({err});
        }
    }

    const onRemove = async () => {
        setUrl(undefined);
    }

    return (
        <AntUpload
            accept={accept || null}
            multiple={multiple || false}
            customRequest={uploadImage}
            onRemove={onRemove}
            listType={listType || 'picture'}
            className={`ant-upload-list ${cssVariant}`}
        >
            {url === undefined &&
              <Button className="ant-custom-button"
                      style={{width: '100% !important', backgroundColor: 'red !important'}}
                      icon={<InboxOutlined/>}>{tooltip || 'Dosya eklemek için sürükleyin ya da seçin'}</Button>}
        </AntUpload>
    )
};

export default Upload;
