import {useReducer} from 'react';

import {localApi, API_URL} from '@utils/axiosInstances';
import setAuthToken from '@utils/setAuthToken';
import notify from "@antd/Notification";

import AuthContext from './AuthContext';
import AuthReducer from './AuthReducer';
import {ReducerTypes, IAuthContext} from '../types/Auth';


const AuthState = (props: any) => {

    const initialState: IAuthContext = {
        token: localStorage.getItem('dp_vendor_token'),
        fpEmail: null,
        isAuthenticated: null,
        isVerified: null,
        isConfirmed: null,
        isRegistered: null,
        isForgotPasswordVerify: null,
        loading: false,
        user: null,
        exchangeRates: null,
        error: null,
    };

    const [state, dispatch] = useReducer(AuthReducer, initialState);

    // Set User
    const setUser = async (user) => {
        try {
            dispatch({type: ReducerTypes.SET_USER, payload: user});
        } catch (err) {
            dispatch({type: ReducerTypes.AUTH_ERROR});
        }
    };

    // Load User
    const loadUser = async (token) => {
        try {
            const res = await localApi.get('/vendor/me', {
                headers: {
                    'x-dental-vendor-token': token
                }
            });

            setAuthToken(token);

            dispatch({type: ReducerTypes.USER_LOADED, payload: res?.data});
        } catch (err) {
            dispatch({type: ReducerTypes.AUTH_ERROR});
        }
    };

    // Register User
    const register = async (formData, cb) => {
        try {
            await setLoading(true);
            const res = await localApi.post('/vendor/register', JSON.stringify(formData));
            await setLoading(false);

            console.log('REGISTER RES DATA', res?.data);

            dispatch({
                type: ReducerTypes.REGISTER_SUCCESS,
                payload: res?.data
            });

            notify({
                type: 'success',
                title: 'Başarılı',
                description: 'Başarılı bir şekilde kayıt yaptınız.'
            });

            if (cb)
                cb();

            //await loadUser();
        } catch (err) {
            dispatch({
                type: ReducerTypes.REGISTER_FAIL,
                payload: err?.response?.data?.message
            });
        }
    };

    // Login Use
    const login = async formData => {
        try {
            const res = await localApi.post('/auth/vendor/login', JSON.stringify(formData));
            dispatch({
                type: ReducerTypes.LOGIN_SUCCESS,
                payload: res?.data
            });
            setAuthToken(res?.data.token);
            //await loadUser();
        } catch (err) {
            console.log('ERROR HERE', err);
            dispatch({
                type: ReducerTypes.LOGIN_FAIL,
                payload: err?.response?.data?.message
            });
        }
    };

    const approveAgreement = async (cb) => {
        try {
            const res = await localApi.get('/vendor/me/approve-iyzico-agreement');

            if (cb) {
                cb();
            }
        } catch (err) {
        }
    }

    // Logout
    const logout = async () => {
        try {
            await localApi.delete('/auth/vendor/logout');
            setAuthToken();
            dispatch({type: ReducerTypes.LOGOUT});
        } catch (err) {
            console.log('ERROR HERE', err);
            dispatch({
                type: ReducerTypes.AUTH_ERROR,
                payload: err?.response?.data?.message
            });
        }
    }

    // Change Password
    const changePassword = async (formData, cb) => {
        try {
            await localApi.put('/vendor/change-password', JSON.stringify(formData));
            if (cb)
                cb();
            dispatch({type: ReducerTypes.LOGOUT});
        } catch (err) {
            console.log('ERROR HERE', err);
            dispatch({
                type: ReducerTypes.CHANGE_PASSWORD_ERROR,
                payload: err?.response?.data?.message
            });
        }
    }

    // Set Forgot Password Email
    const setForgotPasswordEmail = (email) => {
        try {
            dispatch({type: ReducerTypes.SET_FP_EMAIL, payload: email});
        } catch (err) {
            dispatch({
                type: ReducerTypes.AUTH_ERROR,
                payload: err?.response?.data?.message
            });
        }
    }

    // Forgot Password
    const forgotPassword = async (formData) => {
        try {
            const res = await localApi.post('/vendor/forgot-password', JSON.stringify(formData));
            console.log('RES', res);
            dispatch({type: ReducerTypes.FORGOT_PASSWORD});
        } catch (err) {
            console.log('ERROR HERE', err);
            dispatch({
                type: ReducerTypes.AUTH_ERROR,
                payload: err?.response?.data?.message
            });
        }
    }

    // Forgot Password Verify
    const forgotPasswordVerify = async (formData, cb) => {
        try {
            const res = await localApi.put('/vendor/forgot-password-verify', JSON.stringify(formData));

            dispatch({type: ReducerTypes.FORGOT_PASSWORD_VERIFY});
            cb();

            notify({
                type: 'success',
                title: 'İşlem Başarılı',
                description: 'Şifreniz başarılı bir şekilde değiştirildi.'
            });

        } catch (err) {
            console.log('ERROR HERE', err);
            dispatch({
                type: ReducerTypes.AUTH_ERROR,
                payload: err?.response?.data?.message
            });
        }
    }

    // Set Verification Code
    const setVerificationCode = (verificationCode) => {
        try {
            dispatch({type: ReducerTypes.SET_VERIFICATION_CODE, payload: verificationCode});
        } catch (err) {
            dispatch({
                type: ReducerTypes.VERIFICATION_ERROR,
                payload: err?.response?.data?.message
            });
        }
    }

    // Verify Email
    const verifyEmail = async (formData, cb) => {
        try {
            // await localApi.put('/vendor/verify', JSON.stringify(formData));

            console.log('FORM DATA', formData);

            fetch(`${API_URL}/vendor/verify`, {
                "headers": {
                    "Content-Type": "application/json",
                    "x-dental-vendor-token": localStorage.getItem('dp_vendor_token')
                },
                // "referrerPolicy": "strict-origin-when-cross-origin",
                "body": JSON.stringify(formData),
                "method": "PUT",
                // "mode": "cors",
                // "credentials": "include"
            }).then(async (res) => {
                const result = await res.json();

                if (!result.error) {

                    if (cb)
                        cb();

                    notify({
                        type: 'success',
                        title: 'İşlem Başarılı',
                        description: 'E-posta onayınız tamamlandı.'
                    });

                    dispatch({type: ReducerTypes.VERIFY_EMAIL});
                } else {
                    dispatch({
                        type: ReducerTypes.VERIFICATION_ERROR,
                        payload: result.message || result.detail
                    });
                }


            }).catch((err) => {
                console.log('ERROR HERE', err);
                dispatch({
                    type: ReducerTypes.VERIFICATION_ERROR,
                    payload: err?.response?.data?.message
                });
            });
        } catch (err) {
            console.log('ERROR HERE', err);
            dispatch({
                type: ReducerTypes.VERIFICATION_ERROR,
                payload: err?.response?.data?.message
            });
        }
    }

    // Update Me
    const updateMe = async formData => {
        try {
            const res = await localApi.put('/vendor/me', JSON.stringify(formData));
            dispatch({
                type: ReducerTypes.UPDATE_ME,
                payload: res?.data
            });
            await loadUser(localStorage.getItem('dp_vendor_token'));

        } catch (err) {
            await loadUser(localStorage.getItem('dp_vendor_token'));
            console.log('ERROR HERE', err);
            notify({
                type: 'error',
                title: 'Bir hata oluştu',
                description: err?.response?.data?.detail
            });
        }
    };


    // Get Exchange Rates
    const getExchangeRates = async () => {
        try {
            const res = await localApi.get('/vendor/me/exchange-rates');
            dispatch({
                type: ReducerTypes.GET_EXCHANGE_RATES_SUCCESS,
                payload: res?.data
            });
        } catch (err) {
            dispatch({
                type: ReducerTypes.GET_EXCHANGE_RATES_FAIL,
                payload: err?.response?.data?.message
            });
        }
    };

    // Set Loading
    const setLoading = (isLoading) => dispatch({type: ReducerTypes.SET_LOADING, payload: isLoading});

    // Clear Errors
    const clearErrors = () => dispatch({type: ReducerTypes.CLEAR_ERRORS});

    // Close Vendor
    const setVendorStatus = async (isClosed) => {
        try {
            await localApi.put('/vendor/me/close', JSON.stringify({isClosed}));
            await loadUser(localStorage.getItem('dp_vendor_token'));

            notify({
                type: 'success',
                title: 'İşlem başarılı',
                description: isClosed === 1 ? 'Deponuz başarılı şekilde tatil moduna geçti.' : 'Deponuz yeniden aktif edilidi.'
            });

        } catch (err) {
            await loadUser(localStorage.getItem('dp_vendor_token'));
            notify({
                type: 'error',
                title: 'Bir hata oluştu',
                description: err?.response?.data?.detail
            });
        }
    }

    return (
        <AuthContext.Provider
            value={{
                state: {...state},
                register,
                setUser,
                loadUser,
                login,
                logout,
                approveAgreement,
                changePassword,
                setForgotPasswordEmail,
                forgotPassword,
                forgotPasswordVerify,
                setVerificationCode,
                verifyEmail,
                updateMe,
                setVendorStatus,
                getExchangeRates,
                setLoading,
                clearErrors
            }}
        >
            {props.children}
        </AuthContext.Provider>
    );
};

export default AuthState;
