import React, {useContext, useEffect} from 'react';
import styles from '@dv/scss/lib/Modal.module.scss';
import Modal from "@organisms/Modal";
import UserContext from "@contexts/User/UserContext";
import {Button, Icons, Tag, Tooltip} from "@dv/react";
import Popconfirm from "@antd/Popconfirm";
import AntTable from "@antd/Table/Table";

const PriceAnalysesModal = ({show, setShow}) => {
    const userContext = useContext(UserContext);
    useEffect(() => {
        if (show === true) {
            userContext.getProductPriceAnalyses();
        }
    }, [show]);

    return (
        <Modal
            title={"Ürün Fiyat Analizi"}
            show={show}
            onClose={() => setShow(false)}
            onCancel={() => setShow(false)}
            onConfirm={() => {
                setShow(false);
            }}
            footer={false}
        >
            <section className={styles.price_analyses_modal}>
                {
                    userContext.state.productPriceAnalyses?.map((p, i) => (
                        <div key={i} className={styles.product_section}>
                            <div className={styles.title}>
                                <span>{p?.name}</span> <span><b>{p?.variantTitle}</b></span>
                            </div>
                            <div className={styles.table}>
                                {p?.analyses?.length > 0 ?
                                    p?.myPrice === null ?
                                        <span
                                            className={styles.not_selling_text}>Bu ürünü satmıyorsunuz.</span>
                                        :
                                        <AntTable
                                            columns={[
                                                {
                                                    title: 'Fiyatım',
                                                    dataIndex: 'myPrice',
                                                    key: 'myPrice',
                                                    render: (_, {isBestPrice}) => <div
                                                        className={styles.price}>{p?.myPrice} ₺
                                                        {isBestPrice ?
                                                            <Icons.ThumbUpIcon/> : <Icons.ThumbDownIcon/>}

                                                        <Tooltip
                                                            title={<>{p?.myPrice} ₺ / {p?.myPriceUSD?.toFixed(2)} $
                                                                / {p?.myPriceEUR?.toFixed(2)} €</>}><Icons.InfoIcon/></Tooltip>

                                                    </div>
                                                },
                                                {
                                                    title: 'En İyi Fiyat',
                                                    dataIndex: 'price',
                                                    key: 'price',
                                                    render: (_, {
                                                        price,
                                                        priceUSD,
                                                        priceEUR,
                                                        isBestPrice
                                                    }) => isBestPrice ? '-' : <div
                                                        className={styles.price}>
                                                        {price} ₺
                                                        <Tooltip
                                                            title={<>{price} ₺ / {priceUSD?.toFixed(2)} $
                                                                / {priceEUR?.toFixed(2)} €</>}><Icons.InfoIcon/></Tooltip>
                                                    </div>

                                                }
                                            ]} data={p.analyses !== null ? p.analyses : []}
                                            loading={userContext.state.loading}/>
                                    : p?.myPrice !== null ?
                                        <span
                                            className={styles.single_seller_text}>Bu ürünü sadece siz satıyorsunuz.</span>
                                        :
                                        <span
                                            className={styles.not_selling_text}>Bu ürünü satmıyorsunuz.</span>}
                            </div>
                        </div>
                    ))
                }
            </section>
        </Modal>
    )
}

export default PriceAnalysesModal;
