import React, {Fragment, useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useDebouncedCallback} from 'use-debounce';
import {PulseLoader} from "react-spinners";

import styles from '@dv/scss/lib/ChooseProduct.module.scss';

import Layout from "@organisms/Layout";
import {Icons, Title, Card, Button, Input, Tooltip, Tag} from "@dv/react";
import {ProductSellers} from "@utils/constants/addProduct.config";
import Table, {Cell, Header, Row} from "@organisms/Table";
import AddProductContext from "@contexts/AddProduct/AddProductContext";

const ChooseProduct: React.FC<any> = () => {
    let navigate = useNavigate();

    const addProductContext = useContext(AddProductContext);
    const searchTerm = addProductContext.state.searchTerm;

    const navigateProductAdd = (id) => {
        navigate(`/products/${id}/add`);
    }

    const debounced = useDebouncedCallback((value) => {
        addProductContext.searchProducts(encodeURIComponent(value));
    }, 500);

    useEffect(() => {
        if (searchTerm !== '' && searchTerm !== null) {
            addProductContext.searchProducts(encodeURIComponent(searchTerm));
        }
    }, []);

    return (
        <Layout title="Choose Product">
            <div className={styles.container}>
                <Title variant="badge" title={"ÜRÜN EKLE"} icon={<Icons.PlusCircleIcon/>}/>
                <Card>
                    <div className={styles.content}>
                        <div className={styles.search_section}>
                            <Input label={"ÜRÜN ARA"} type={"text"} value={searchTerm}
                                   onChange={(e) => {
                                       addProductContext.setSearchTerm(e.target.value);
                                       debounced(e.target.value);
                                   }}
                                   placeholder={"Ürün adı, markası veya katalog numarasıyla ara"}
                            />
                            <Button variant={"primary"} loading={addProductContext.state.loading}
                                    disabled={false}>Ara</Button>
                        </div>

                        {/*
                        <Tag
                            variant={"warning"}
                            label={
                                <div className={"flex fd-column"}>
                                    <p className={"no-margin"}>Daha önceden eklediğiniz ürünler aramada
                                        gözükmeyecektir.</p>
                                    <p className={"no-margin"}>Eklediğiniz ürünlere ulaşmak istiyorsanız, <a
                                        onClick={() => navigate(`/products`)}>Satıştaki Ürünleriniz</a> kısmına
                                        tıklayınız.</p>
                                </div>
                            }
                            icon={"InfoIcon"}/>
                        */}

                        <div className={styles.search_products_table}>
                            <Table type={"standard"} filters={false} headers={[
                                <Header style={{flex: '5', justifyContent: "flex-start"}}>Ürün Adı</Header>,
                            ]}>
                                {!addProductContext.state.loading ?
                                    (addProductContext.state.products !== null && addProductContext.state?.products.length !== 0) ? addProductContext.state.products.map((el) => (
                                        <Row product-id={el.id} key={el.id}
                                             onClick={() => navigateProductAdd(el.id)}>
                                            <Cell style={{flex: '5', alignItems: "flex-start"}}>
                                                <div className={styles.product_card}>
                                                    <div className={styles.image}>
                                                        <img src={el?.thumbnail}/>
                                                    </div>
                                                    <div className={styles.product}>
                                                        {el?.name}
                                                        {el?.onSell > 0 &&
                                                          <div className={"tags"}>
                                                            <div className={"tag green"}>
                                                              <span>Mevcut Satıştaki Varyant Ürün Sayısı : {el?.onSell}</span>
                                                            </div>
                                                            <div className={"tag red"}>
                                                              <span>Satışta Olmayan Varyant Ürün Sayısı : {el?.offSell}</span>
                                                            </div>
                                                          </div>
                                                        }
                                                    </div>
                                                </div>
                                            </Cell>
                                        </Row>
                                    )) : (searchTerm === null ? <h4>Ürün görmek için arama yapınız</h4> :
                                        <h3>Ürün bulunamadı</h3>) :
                                    <PulseLoader size={20} margin={10} color="var(--primary-orange)"/>}
                            </Table>
                        </div>
                    </div>
                </Card>
            </div>
        </Layout>
    );
};

export default ChooseProduct;
