import React, {useContext, useEffect, useState} from "react";

import 'moment/locale/tr';
import moment from "moment";

import styles from '@dv/scss/lib/OrderPayments.module.scss';

import Layout from "@organisms/Layout";
import {Icons, Title, Card, Button, Input, Tooltip} from "@dv/react";
import Step from "@antd/Step";
import Select from "@antd/Select";
import Pagination from "@antd/Pagination";
import PayoutContext from "@contexts/Payout/PayoutContext";
import {PAYOUT_STATUS_STEP_LABELS, PAYOUT_STATUS_STEPS} from "@utils/constants/order.config";
import RangePicker from "@antd/RangePicker";

const OrderPayments: React.FC<any> = () => {

    const [filter, setFilter] = useState({
        orderNo: '',
        startDate: moment(),
        endDate: moment()
    });
    const payoutContext = useContext(PayoutContext);
    const searchedPayoutOrders = payoutContext.state.searchedPayoutOrders;

    useEffect(() => {
        payoutContext.getPayoutOrders();
    }, []);

    const filterPayoutOrders = async (e) => {
        e.preventDefault();
    }

    return (
        <Layout title="Order Payments">
            <div className={styles.container}>
                <Title variant="badge" title={"TÜM SİPARİŞ ÖDEMELERİ"} icon={<Icons.NewspaperIcon/>}/>
                <Card>
                    <form className={styles.filter_section} onSubmit={filterPayoutOrders}>
                        <Input label={"SİPARİŞ NO"} type={"text"}
                               value={filter.orderNo}
                               placeholder={"Sipariş Numarası"}
                               tooltip={<Tooltip title="Açıklama"><Icons.InfoIcon/></Tooltip>}
                               onChange={(e) => {
                                   setFilter({
                                       ...filter,
                                       orderNo: e.target.value
                                   });
                               }}/>
                        <div className={`input_group`}>
                            <label>
                                SİPARİŞ TARİHİ
                                <Tooltip
                                    title="Siparişleri tarihe göre filtrelemenize yardımcı olur."><Icons.InfoIcon/></Tooltip>
                            </label>
                            <div className="">
                                <RangePicker onChange={(value, _dateString) => {
                                    setFilter({
                                        ...filter,
                                        startDate: value[0],
                                        endDate: value[1]
                                    });
                                }}/>
                            </div>
                        </div>

                        <Button variant={"primary"} loading={false} disabled={false}>Ara</Button>
                        <Button variant={"primary-green"} loading={false} disabled={false}>Excel İndir</Button>
                    </form>
                </Card>

                <div className={styles.table_wrapper}>
                    <div className={styles.filter}>
                        <Select
                            options={[
                                {value: 'date_desc', label: 'Sipariş Tarihi (Eskiden Yeniye)'},
                                {value: 'date_asc', label: 'Sipariş Tarihi (Yeniden Eskiye)'}
                            ]}
                            showSearch
                            placeholder="Filtre Seçiniz"
                            onChange={() => {

                            }}
                        />

                        <Pagination total={50} defaultPageSize={25} defaultCurrent={1}
                                    setSizeChanger={() => {
                                    }}/>

                    </div>
                    <div className={styles.table}>
                        {
                            searchedPayoutOrders && searchedPayoutOrders?.map((row, i) => (
                                <Card key={i} data={row} steps={PAYOUT_STATUS_STEPS}
                                      stepLabels={PAYOUT_STATUS_STEP_LABELS} variant={"order-payment"} detail={(
                                    <div className={styles.order_detail}>
                                        <div className={styles.order_block}>
                                            <div className={styles.container}>
                                                <div className={styles.container_block}>
                                                    <div className={styles.step}>
                                                        <Step
                                                            items={PAYOUT_STATUS_STEP_LABELS}
                                                            current={PAYOUT_STATUS_STEPS[row?.status]}
                                                            direction={"vertical"}/>
                                                    </div>
                                                    <div className={styles.items}>
                                                        {
                                                            row?.items?.map(item => (
                                                                <div key={item.listingId} className={styles.item}>
                                                                    <div className={styles.image}>
                                                                        <img alt={`${item?.id}_product_image`}
                                                                             src={item?.thumbnail}/>
                                                                    </div>
                                                                    <div className={styles.item_detail}>
                                                                        <div className={styles.block}>
                                                                            <div className={styles.name}>
                                                                                <b>{item?.name} {item?.variantTitle}</b>
                                                                            </div>
                                                                            <div className={styles.order_font_size}>
                                                                                Adet: {item?.amount}
                                                                            </div>
                                                                        </div>

                                                                        <div className={styles.block}>
                                                                            <div className={styles.title}>
                                                                                ÜRÜN KODU
                                                                            </div>
                                                                            <div className={styles.description}>
                                                                                {item?.sku}
                                                                            </div>
                                                                        </div>

                                                                        <div className={styles.block}>
                                                                            <div className={styles.title}>
                                                                                KATALOG NUMARASI
                                                                            </div>
                                                                            <div className={styles.description}>
                                                                                {item?.catalogNumber}
                                                                            </div>
                                                                        </div>

                                                                        {
                                                                            item?.options && item?.options?.map((option, i) => (
                                                                                <div key={i} className={styles.block}>
                                                                                    <div className={styles.title}>
                                                                                        {option?.propertyName}
                                                                                    </div>
                                                                                    <div className={styles.description}>
                                                                                        {option?.propertyValueName}
                                                                                    </div>
                                                                                </div>
                                                                            ))
                                                                        }

                                                                        <div className={styles.block}>
                                                                            <div className={styles.title}>
                                                                                SATIŞ FİYATI
                                                                            </div>
                                                                            <div
                                                                                className={`${styles.description} ${styles.orange}`}>
                                                                                {item?.amountPrice?.toFixed(2)} {item?.currency?.symbol || '₺'}
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={styles.summary_block}>
                                                <div className={styles.summary}>
                                                    <div className={styles.block}>
                                                        <div className={styles.title}>
                                                            ARA TOPLAM
                                                        </div>
                                                        <div className={styles.value}>
                                                            {row?.totalPrice} ₺
                                                        </div>
                                                    </div>
                                                    <div className={styles.block}>
                                                        <div className={styles.title}>
                                                            KARGO
                                                        </div>
                                                        <div className={styles.value}>
                                                            {row?.isCargoFree !== 1 ? row?.cargoPrice : 0.00} ₺
                                                        </div>
                                                    </div>
                                                    <div className={styles.block}>
                                                        <div className={styles.title}>
                                                            İSKONTO {row?.discountCode && `(${row?.discountType === 0 ? '%' : ''} ${row?.discountValue}${row?.discountType === 1 ? 'TL' : ''})`}
                                                        </div>
                                                        <div className={styles.value}>
                                                            {row?.discountPrice} ₺
                                                        </div>
                                                    </div>
                                                    <div className={styles.block}>
                                                        <div className={styles.title}>
                                                            TOPLAM SİPARİŞ TUTARI
                                                        </div>
                                                        <div className={styles.value}>
                                                            {((row?.totalPrice + (row?.isCargoFree !== 1 ? row?.cargoPrice : 0.00)) - row?.discountPrice)?.toFixed(2)} ₺
                                                        </div>
                                                    </div>
                                                    <div className={styles.block}>
                                                        <div className={styles.title}>
                                                            TOPLAM KOMİSYON
                                                        </div>
                                                        <div className={styles.value}>
                                                            {(row?.commissionPrice)} ₺
                                                        </div>
                                                    </div>
                                                    <div className={styles.block}>
                                                        <div className={styles.title}>
                                                            NET DEPO TUTARI
                                                        </div>
                                                        <div className={styles.value}>
                                                            {(row?.totalPrice - (row?.commissionPrice + row?.discountPrice))?.toFixed(2)} ₺
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}/>
                            ))
                        }
                    </div>
                </div>

            </div>
        </Layout>
    );
};

export default OrderPayments;
