import React, {useContext, useEffect, useState} from "react";
import {Button, Icons} from "@dv/react";
import AuthContext from "@contexts/Auth/AuthContext";

const VendorVerification: React.FC<any> = ({styles, setFormPhase}) => {

    const authContext = useContext(AuthContext);

    useEffect(() => {
        if (authContext.state.isConfirmed === true) {
            setFormPhase('login');
        }
    }, [authContext.state.isConfirmed]);

    return (
        <div className={styles.forgot_password_form}>
            <h1><Icons.ArrowLeftIcon
                onClick={() => {
                    setFormPhase('login');
                }}/>Deponuz inceleniyor</h1>
            <h4>Deponuz şu an inceleme aşamasında.</h4>

            <form>
                <div className={`input_group full_width`}>
                    <label className={styles.verification_label}>
                        Yaklaşık 4 saat içerisinde inceleme süreci tamamlanacaktır. E-posta ile bilgilendirileceksiniz.
                    </label>
                </div>
            </form>
        </div>
    )
}

export default VendorVerification;
