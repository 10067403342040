import React, {useContext, useEffect, useState} from 'react';
import styles from '@dv/scss/lib/OrderItemDetail.module.scss';

interface OrderItemProps {
    data?: any;
}

const OrderItemDetail: React.FC<OrderItemProps> = ({data}) => {

    console.log('ORDER_ITEM_DATA_', data)

    return <div className={styles.order_item_container}>
        <div className={styles.product_wrapper}>
            <div className={styles.image_block}>
                <img src={data?.product_thumbnail}/>
            </div>
            <div className={styles.product_block}>
                <div className={styles.block}>
                    <div className={styles.title}>
                        ÜRÜN ADI
                    </div>
                    <div className={styles.description}>
                        {data?.product_name} <b>{data?.product_variant_title}</b>
                    </div>
                </div>
                <div className={styles.block}>
                    <div className={styles.title}>
                        MARKA
                    </div>
                    <div className={styles.description}>
                        {data?.brand_name}
                    </div>
                </div>
                <div className={styles.block}>
                    <div className={styles.title}>
                        ÜRÜN KODU
                    </div>
                    <div className={styles.description}>
                        {data?.product_sku}
                    </div>
                </div>
                <div className={styles.block}>
                    <div className={styles.title}>
                        AMBALAJ İÇERİĞİ
                    </div>
                    <div className={styles.description}>
                        {data?.product_packaging_content}
                    </div>
                </div>
            </div>
        </div>
        <div className={styles.order_wrapper}>
            <div className={styles.order_detail_block}>
                <div className={styles.block}>
                    <div className={styles.title}>
                        SİPARİŞ NUMARASI
                    </div>
                    <div className={styles.description}>
                        {data?.order_id}
                    </div>
                </div>
                <div className={styles.block}>
                    <div className={styles.title}>
                        SATIŞ ADETİ
                    </div>
                    <div className={styles.description}>
                        {data?.amount}
                    </div>
                </div>
                <div className={styles.block}>
                    <div className={styles.title}>
                        SATIŞ FİYATI (ADET)
                    </div>
                    <div className={styles.description}>
                        {data?.total_price / data?.amount} TL
                    </div>
                </div>
                <div className={styles.block}>
                    <div className={styles.title}>
                        SATIŞ FİYATI (TOPLAM)
                    </div>
                    <div className={styles.description}>
                        {data?.total_price} TL
                    </div>
                </div>
                <hr/>
                <div className={styles.block}>
                    <div className={styles.title}>
                        KAMPANYA
                    </div>
                    <div className={styles.description}>
                        0 TL
                    </div>
                </div>
                <div className={styles.block}>
                    <div className={styles.title}>
                        DP İSKONTO (₺)
                    </div>
                    <div className={styles.description}>
                        {data?.discount_price} TL
                    </div>
                </div>
                <hr/>
                <div className={styles.block}>
                    <div className={styles.title}>
                        ALINAN KOMİSYON (%)
                    </div>
                    <div className={styles.description}>
                        %{data?.commission_percent}
                    </div>
                </div>
                <div className={styles.block}>
                    <div className={styles.title}>
                        ALINAN KOMİSYON (₺)
                    </div>
                    <div className={styles.description}>
                        {data?.commission_price} TL
                    </div>
                </div>
                <hr/>
                <div className={styles.block}>
                    <div className={styles.title}>
                        IYZICO'DAN YATACAK TUTAR
                    </div>
                    <div className={styles.description}>
                        {data?.total_price - data?.iyzico_commission_price} TL
                    </div>
                </div>
                <div className={styles.block}>
                    <div className={styles.title}>
                        DENTALPİYASA'DAN YATACAK TUTAR
                    </div>
                    <div className={styles.description}>
                        {data?.discount_price} TL
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default OrderItemDetail;
