import React from 'react';

import 'moment/locale/tr';
import moment from 'moment';
import locale from 'antd/es/locale/tr_TR';

import type {DatePickerProps} from 'antd';
import {DatePicker as AntDatePicker, ConfigProvider} from 'antd';

import '@dv/scss/lib/DatePicker.antd.css';

const DatePicker: React.FC<any> = ({onChange, refId, picker, defaultValue, format, disabled = false, ...rest}) => {
    return (
        <ConfigProvider locale={locale}>
            <AntDatePicker
                disabledDate={d => !d || d.isBefore(moment().format('YYYY-MM-DD'))} className="datepicker_primary"
                defaultValue={defaultValue} format={format} picker={picker}
                onChange={(_p1, _p2) => {
                    if (refId) {
                        onChange(_p1, _p2, refId);
                    } else {
                        onChange(_p1, _p2);
                    }
                }} disabled={disabled} {...rest}/>
        </ConfigProvider>
    )
}

export default DatePicker;

