import {Button} from "@dv/react";
import {Link} from "react-router-dom";
import React, {useContext, useState} from "react";
import AuthContext from "@contexts/Auth/AuthContext";

const LoginForm: React.FC<any> = ({styles, setFormPhase}) => {

    const authContext = useContext(AuthContext);

    const [loginForm, setLoginForm] = useState({
        email: undefined,
        fpEmail: undefined,
        password: undefined
    });

    const login = (e) => {
        e.preventDefault();

        authContext.login({
            email: loginForm.email,
            password: loginForm.password,
            deviceId: navigator.productSub
        });
    }

    const [isVisible, setIsVisible] = useState(false);

    const toggleVisible = () => {
        if (isVisible) return setIsVisible(false);
        setIsVisible(true);
    }

    return (
        <div className={styles.login_form}>
            <h1>Depo Girişi</h1>

            <form onSubmit={login}>
                <div className={`input_group full_width`}>
                    <label>
                        E-posta
                    </label>
                    <div className={`input_section highlighted`}>
                        <input type="text" value={loginForm.email} onChange={(e) => {
                            setLoginForm({
                                ...loginForm,
                                email: e.target.value
                            })
                        }} placeholder={"E-postanızı giriniz"} required/>
                    </div>
                </div>
                <div className={`input_group full_width`}>
                    <label>
                        Şifre
                    </label>
                    <div className={`input_section highlighted`}>
                        <input
                            type={isVisible ? 'text' : 'password'}
                            value={loginForm.password} onChange={(e) => {
                            setLoginForm({
                                ...loginForm,
                                password: e.target.value
                            })
                        }} placeholder={"Şifrenizi giriniz"} required/>
                        <button type='button' className={`toggle_password_view ${isVisible ? 'is-visible' : ''}`}
                                onClick={toggleVisible} title='Parolayı Göster'></button>
                    </div>
                </div>

                <label className={styles.forgot_password}>
                    <a onClick={() => {
                        setFormPhase('forgot-password');
                    }}>Şifremi unuttum</a>
                </label>

                <div className={styles.actions}>
                    <Button>Giriş Yap</Button>
                    veya
                    <Link to="/register"><Button variant="secondary">Kaydol</Button></Link>
                </div>
            </form>
        </div>
    )
}

export default LoginForm;
