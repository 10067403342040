import React, {useContext, useEffect, useState} from 'react';
import {Button, Icons, Input, RadioItem, Title, Tooltip} from "@dv/react/lib";
import {STEPS} from "@utils/constants/addCampaign.config";
import CampaignContext from "@contexts/Campaign/CampaignContext";
import {useNavigate} from "react-router-dom";

const DUMMY_BASE_CAMPAIGN_IDS = {
    discount_by_quantity: 1,
    buy_together_discount: 2,
}

const Step4: React.FC<any> = ({styles, campaignForm, setNavigation}) => {
    const navigate = useNavigate();

    const campaignContext = useContext(CampaignContext);
    const state = campaignContext.state;
    const {
        selectedCampaignBaseProduct,
        campaignConditionAmount,
        selectedGiftBaseProduct,
        giftConditionAmount
    } = campaignContext.state;

    useEffect(() => {
        setNavigation(STEPS.step4);
    }, []);

    const onSubmit = (e) => {
        e.preventDefault();
        campaignContext.createCampaign({
            name: state.newCampaign.name,
            baseCampaignId: DUMMY_BASE_CAMPAIGN_IDS[campaignForm.campaignType],
            preConditionQuantity: state.campaignConditionAmount || null,
            preConditionBaseProductId: state.selectedCampaignBaseProduct.baseProductId || null,
            giftQuantity: state.giftConditionAmount || null,
            giftBaseProductId: state.selectedGiftBaseProduct?.productStockId || null,
            startDate: null,
            endDate: null
        }, () => {
            navigate('/campaigns');
        });
    }

    const onPrev = (e) => {
        e.preventDefault();
        setNavigation(STEPS.step3);
    }

    const [criteriaType, setCriteriaType] = useState('basket_price');

    return <form className={styles.form} onSubmit={onSubmit}>
        <div className={styles.input_group}>
            <Title variant="header" title={"KAMPANYA ÖZETİ"}/>
        </div>

        <div className={styles.product_detail}>
            <div className={styles.image}>
                <img src={selectedCampaignBaseProduct?.thumbnail}/>
            </div>

            <div className={styles.detail}>
                <div className={styles.name}>
                    {selectedCampaignBaseProduct?.name}
                </div>
                <div className={styles.category}>
                    <div className={"tags"}>
                        <div className={"tag tag-m orange"}>
                            <span>KAMPANYA ÜRÜNÜ</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles.logo}>
                <div className={"tags"}>
                    {campaignForm.campaignType === "discount_by_quantity" &&
                      <>
                        <div className={"tag tag-m orange"}>
                          <span>{campaignConditionAmount} AL</span>
                        </div>
                        <div className={"tag tag-m green"}>
                          <span>{giftConditionAmount} ÖDE</span>
                        </div>
                      </>
                    }
                    {campaignForm.campaignType === "buy_together_discount" &&
                      <>
                        <div className={"tag tag-m orange"}>
                          <div className={"tag tag-m orange"}>
                            <span>{campaignConditionAmount} ADET</span>
                          </div>
                          <span>ALINDIĞINDA</span>
                        </div>
                      </>
                    }
                </div>
            </div>
        </div>

        {campaignForm.campaignType === "buy_together_discount" &&
          <div className={styles.product_detail}>
            <div className={styles.image}>
              <img src={selectedGiftBaseProduct?.thumbnail}/>
            </div>

            <div className={styles.detail}>
              <div className={styles.name}>
                  {selectedGiftBaseProduct?.name} {selectedGiftBaseProduct?.variantTitle}
              </div>
              <div className={styles.category}>
                <div className={"tags"}>
                  <div className={"tag tag-m green"}>
                    <span>HEDİYE ÜRÜNÜ</span>
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.logo}>
              <div className={"tags"}>
                <div className={"tag tag-m green"}>
                  <div className={"tag tag-m green"}>
                    <span>{giftConditionAmount} ADET</span>
                  </div>
                  <span>HEDİYE</span>
                </div>
              </div>
            </div>
          </div>
        }

        <div className={styles.actions}>
            <Button variant={"primary-gray"} type={"button"} onClick={onPrev}>Geri</Button>
            <Button variant={"primary"} type={"submit"}>Onayla ve Bitir</Button>
        </div>
    </form>
}

export default Step4;
