import React, {useContext, useEffect, useState} from "react";
import {Button, Icons, Input} from "@dv/react";
import AuthContext from "@contexts/Auth/AuthContext";

const ForgotPasswordVerifyForm: React.FC<any> = ({styles, setFormPhase}) => {

    const authContext = useContext(AuthContext);

    const [verificationCode, setVerificationCode] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [passwordRepeat, setPasswordRepeat] = useState('');
    const [fpValidation, setFpValidation] = useState(true);
    const fpValidate = () => {
        setFpValidation(verificationCode === '' || (newPassword !== passwordRepeat) || (newPassword === ''))
    }

    const forgotPasswordVerify = (e) => {
        e.preventDefault();

        authContext.forgotPasswordVerify({
            email: authContext.state.fpEmail,
            verifyCode: verificationCode,
            password: newPassword
        }, () => {
            setFormPhase('login');
        });
    }

    useEffect(() => {
        fpValidate();
    }, [verificationCode, newPassword, passwordRepeat]);

    return (
        <div className={styles.forgot_password_form}>
            <h1><Icons.ArrowLeftIcon
                onClick={() => {
                    setFormPhase('login');
                }}/> Gelen onay kodunu giriniz</h1>
            <h4>Lütfen e-posta adresinize gelen onay kodunu giriniz</h4>

            <form onSubmit={forgotPasswordVerify}>
                <div className={`input_group full_width`}>
                    <label>
                        Onay Kodu
                    </label>
                    <div className={`input_section highlighted`}>
                        <input type="text" value={verificationCode} onChange={(e) => {
                            setVerificationCode(e.target.value);
                        }} placeholder={"Onay kodunu giriniz"} required/>
                    </div>
                </div>

                <div className={`input_group full_width`}>
                    <label>
                        Yeni Şifre
                    </label>

                    <Input type="password" value={newPassword} onChange={(e) => {
                        setNewPassword(e.target.value);
                    }} placeholder={"Yeni şifrenizi giriniz"} required/>

                </div>

                <div className={`input_group full_width`}>
                    <label>
                        Yeni Şifre Tekrarı
                    </label>

                    <Input type="password" value={passwordRepeat} onChange={(e) => {
                        setPasswordRepeat(e.target.value);
                    }} placeholder={"Yeni şifrenizi giriniz"} required/>

                    {newPassword !== passwordRepeat &&
                      <label style={{
                          color: "var(--dv-form-error-color, #d12727)",
                          fontSize: "11px"
                      }} className={styles.validation_error_text}>Şifre tekrarı, yeni şifreniz ile aynı
                        olmalı.</label>
                    }
                </div>

                <div className={styles.actions}>
                    <Button disabled={fpValidation}>Şifre Değiştir</Button>
                </div>
            </form>
        </div>
    )
}

export default ForgotPasswordVerifyForm;
