import React from 'react';
import styles from '@dv/scss/lib/Modal.module.scss';
import Modal from "@organisms/Modal";
import {Button} from "@dv/react";

const IyzicoAgreementModal = ({title, show, setShow, onConfirm}) => {

    return (
        <Modal
            title={title}
            show={show}
            onClose={() => setShow(false)}
            onCancel={() => setShow(false)}
            footerComponent={
                <div className={styles.iyzico_agreement_modal_footer_section}>
                    <Button onClick={() => {
                        if (onConfirm) {
                            onConfirm();
                        }
                    }}>Okudum, Onaylıyorum.</Button>
                </div>
            }
        >
            <section className={styles.iyzico_agrement_modal}>
                <iframe src={"https://www.iyzico.com/pazaryeri-satici-anlasma"}/>
            </section>
        </Modal>
    )
}

export default IyzicoAgreementModal;
