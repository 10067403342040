import React, {useContext, useEffect, useState} from 'react';

import moment from "moment";
import Barcode from 'react-barcode';

import axiosAPI from "@utils/axiosAPI";
import {useLocation, useParams} from "react-router-dom";
import AuthContext from "@contexts/Auth/AuthContext";

import styles from '@dv/scss/lib/CargoPrint.module.scss';
import Logo from '@assets/images/BrandLogo.jpg';
import {SHIPPERS} from "@utils/constants/shippers.config";
import notify from "@antd/Notification";

import {Icons} from "@dv/react";

// import ExampleBarcode from '@assets/images/example_barcode.gif';

function useQuery() {
    const {search} = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const CargoPrintPage: React.FC<any> = () => {
    const {orderId} = useParams();
    const query = useQuery();
    const refundType = query.get('refund_type');

    const authState = useContext(AuthContext);
    const [cargoPrint, setCargoPrint] = useState(null);
    const [printFirst, setPrintFirst] = useState(false);

    useEffect(() => {
        axiosAPI({
            url: `/vendor/orders/${orderId}/cargo-print?${refundType ? `refund_type=${refundType}` : ''}`,
            requestMethod: "GET",
            token: authState.state.token
        }).then((res) => {
            if (res.result !== 'error') {
                setCargoPrint(res?.data);
            } else {
                notify({
                    type: 'error',
                    title: 'Bir hata ile karşılaşıldı',
                    description: res.detail
                });
            }
        }).catch((err) => {
            console.log('CARGO PRINT FETCH ERR', err);
            notify({
                type: 'error',
                title: 'Bir hata ile karşılaşıldı',
                description: err.detail
            });
        });
    }, []);

    useEffect(() => {
        if (cargoPrint !== null) {
            if (printFirst === false) {
                setPrintFirst(true);

                setTimeout(() => {
                    printPage();
                }, 500);
            }
        }
    }, [cargoPrint])

    const printPage = () => {
        window.print();
    }

    return (
        <section className={styles.cargo_print}>
            <table>
                <tr className={styles.header}>
                    <h3><b>KARGO FİRMASI:</b> {SHIPPERS[cargoPrint?.shipperId]?.name}</h3>
                    <h4>{moment(cargoPrint?.createdAt).format('DD MMMM YYYY dddd, HH:mm')}</h4>
                </tr>
                <tr className={styles.logo}>
                    <img alt="logo" src={Logo}/>
                </tr>
                <tr className={styles.description}>
                    <div className={styles.row}>
                        <span className={styles.label}><b>Alıcı Bilgileri</b></span>
                        <span className={styles.text}></span>
                    </div>

                    <div className={styles.row}>
                        <span className={styles.label}>Ad Soyad:</span>
                        <span className={styles.text}>{cargoPrint?.receiverName}</span>
                    </div>

                    <div className={styles.row}>
                        <span className={styles.label}>Adres:</span>
                        <span className={styles.text}>{cargoPrint?.receiverAddress}</span>
                    </div>

                    <div className={styles.row}>
                        <span className={styles.label}>İl:</span>
                        <span className={styles.text}>{cargoPrint?.receiverCityName}</span>
                    </div>

                    <div className={styles.row}>
                        <span className={styles.label}>İlçe:</span>
                        <span className={styles.text}>{cargoPrint?.receiverTownName}</span>
                    </div>

                    <div className={styles.row}>
                        <span className={styles.label}>Posta Kodu:</span>
                        <span className={styles.text}>{cargoPrint?.receiverPostalCode}</span>
                    </div>
                    {/*
                    <div className={styles.row}>
                        <span className={styles.label}>Ev/Cep Telefonu:</span>
                        <span className={styles.text}>{cargoPrint?.receiverPhone}</span>
                    </div>

                    <div className={styles.row}>
                        <span className={styles.label}>E-posta:</span>
                        <span className={styles.text}>{cargoPrint?.receiverEmail}</span>
                    </div>
                    */}
                </tr>
                <tr className={styles.integration_codes}>
                    {cargoPrint?.integrationId &&
                        <div className={styles.integration_code}>
                            <h3>Entegrasyon Kodu: {/*<span>{cargoPrint?.integrationId}</span>*/}</h3>
                            <Barcode value={cargoPrint?.integrationId}/>
                        </div>
                    }
                    {cargoPrint?.shipmentId &&
                        <div className={styles.integration_code}>
                            <h3>Gönderi Kodu: {/*<span>{cargoPrint?.shipmentId}</span>*/}</h3>
                            <Barcode value={cargoPrint?.shipmentId}/>
                        </div>
                    }
                </tr>
                <tr className={styles.order_description}>
                    <div className={styles.row}>
                        <span className={styles.label}><b>Sipariş Bilgileri</b></span>
                        <span className={styles.text}></span>
                    </div>

                    <div className={styles.row}>
                        <span className={styles.label}>Sipariş Numarası:</span>
                        <span className={styles.text}>{orderId}</span>
                    </div>

                    <div className={styles.row}>
                        <span className={styles.label}>Kargo Referans Numarası:</span>
                        <span className={styles.text}>{cargoPrint?.integrationId}</span>
                    </div>
                </tr>

                <tr className={styles.order_items}>
                    <div className={styles.row}>
                        <span className={styles.label}><b>Sipariş Ürünleri</b></span>
                        <span className={styles.text}></span>
                    </div>
                    {cargoPrint?.items?.map((el, i) => (
                        <div key={i} className={"flex fd-column"}>
                            <div className={styles.row}>
                                <div className={styles.item_image}>
                                    <img src={el?.thumbnail} width={50} height={50}/>
                                </div>
                                <div className={"flex fd-column"}>
                                    <span
                                        className={styles.text}>{el?.name} {el?.variantTitle} ({el?.amount} adet)</span>
                                    {el?.expiration && <span
                                        className={styles.text}>Miat: {moment(el?.expiration).format('MMMM YYYY')}</span>}
                                </div>
                            </div>
                            {(i === 1 && cargoPrint?.items?.length > 2) &&
                                <div id="printNextRow" className={`${styles.row} ${styles.no_display} d-none fd-column ai-center jc-center`} style={{fontSize: 20, color: 'red'}}>
                                    ÇIKTININ 2. SAYFASI MEVCUTTUR
                                    <Icons.ChevronDownIcon color={'red'}/>
                                </div>
                            }
                        </div>
                    ))}
                </tr>

                <div className="flex jc-center">
                    <button id="printButton" className={styles.print_button} onClick={() => {
                        printPage();
                    }}>
                        YAZDIR
                    </button>
                </div>
            </table>
        </section>
    )
}

export default CargoPrintPage;
