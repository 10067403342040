import {initialStateData, ReducerTypes} from '../types/Dashboard';

export default (state, action) => {
    switch (action.type) {
        case ReducerTypes.GET_DATA:
            return {
                ...state,
                ...action.payload,
                data: initialStateData,
                loading: true,
                error: null,
            };
        case ReducerTypes.GET_DATA_SUCCESS:
            return {
                ...state,
                ...action.payload,
                data: action.payload,
                loading: false,
                error: null,
            };
        case ReducerTypes.DASHBOARD_ERROR:
        case ReducerTypes.GET_DATA_FAIL:
            return {
                ...state,
                token: null,
                isAuthenticated: false,
                isForgotPasswordVerify: false,
                loading: false,
                user: null,
                error: action.payload || null,
            };
        case ReducerTypes.CLEAR_ERRORS:
            return {
                ...state,
                error: null
            };
        default:
            return state;
    }
};
