import {API_URL} from './axiosInstances';

const axiosAPI = async (params: Object) => {

    // @ts-ignore
    const {url, token, noToken, cookies, requestMethod, requestData} = params;

    const fetchResponse = {
        result: 'error', message: 'Error!', data: undefined, error: undefined, detail: undefined
    };

    await fetch(`${API_URL}${url}`, {
        method: requestMethod || 'GET',
        headers: {
            "Content-Type": "application/json",
            ...(!noToken ? {"x-dental-vendor-token": token ? token : cookies?.dp_vendor_token} : {}),
        },
        body: requestMethod && (requestMethod.toUpperCase() === 'POST' || requestMethod.toUpperCase() === 'PUT') ? requestData : null,
    })
        .then(async response => {
            if (!response.ok || response?.status !== 200) {
                const errorResult = await response.json();
                console.log(errorResult);
                throw {
                    ...errorResult,
                    result: 'error'
                }
            }
            return response.json();
        })
        .then(res => {
            fetchResponse.result = "success";
            fetchResponse.data = res;
            fetchResponse.message = res.message;
        })
        .catch(error => {
            Object.assign(fetchResponse, error);
        });

    return fetchResponse;
};

export default axiosAPI;
