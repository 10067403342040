import React, {useContext, useEffect} from 'react';

import styles from '@dv/scss/lib/Dashboard.module.scss';

import Layout from "@organisms/Layout";
import {Anchor, Card, Icons, Title} from "@dv/react";
import {Link} from "react-router-dom";
import DashboardContext from "@contexts/Dashboard/DashboardContext";

const ProductsBelow5: React.FC<any> = () => {
    const dashboardContext = useContext(DashboardContext);
    const data = dashboardContext.state.data;

    useEffect(() => {
        dashboardContext.getData();
    }, []);

    return (
        <Layout title="5 Stok Altı Ürünler">
            <div className={styles.cards}>
                <div className={styles.container}>
                    <section className={styles.section}>
                        <div className={styles.block}>
                            <div className={`${styles.title} flex gap-15 ai-center`}>
                                <Anchor variant={"back_history_badge"} />
                                <Title variant="badge" title={"5 ve ALTI STOKTAKİ ÜRÜNLER"}
                                       icon={<Icons.NewspaperIcon/>}/>
                            </div>

                            <div className={styles.stocks}>

                                <Card>
                                    <div className={styles.stocks_block}>
                                        {
                                            data?.below5StockProducts?.length > 0 ?
                                                data?.below5StockProducts?.map(el => (
                                                    <Link to={`/products/${el?.base_product_id}/add`}>
                                                        <div className={styles.stocks_block_container}>
                                                            <div className={styles.img}>
                                                                <img src={el?.thumbnail} width={50} height={50}/>
                                                            </div>
                                                            <div className={styles.content}>
                                                                <div className={styles.name}>
                                                                    <span>{el?.product_name}</span>
                                                                </div>
                                                                <div className={styles.description}>
                                                                    <span
                                                                        className={styles.amount}>{el?.amount} Adet</span>
                                                                    <span
                                                                        className={styles.price}>{el?.currency_price}{el?.currency}</span>
                                                                </div>
                                                            </div>
                                                            <div className={styles.end_line}>
                                                                <Icons.ChevronRightCircleIcon/>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                ))
                                                :
                                                'Stoğu azalan ürününüz yok.'
                                        }
                                    </div>
                                </Card>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </Layout>
    )
}

export default ProductsBelow5;
