import React from "react";
import styles from "@dv/scss/lib/CardTable.module.scss";

import 'moment/locale/tr';
import moment from "moment";

import Select from "@antd/Select";
import Pagination from "@antd/Pagination";
import {Button, Card, Icons, Tag} from "@dv/react";
import Step from "@antd/Step";
import {ORDER_STATUS_STEP_LABELS, ORDER_STATUS_STEPS} from "@utils/constants/order.config";
import {SHIPPERS} from "@utils/constants/shippers.config";
import ArasKargoLogo from '@assets/images/aras-kargo-logo.png';
import MngKargoLogo from '@assets/images/mng-kargo-logo.png';
import CanceledOrderDetail from "@organisms/CanceledOrderDetail";
import OrderDetail from "@organisms/OrderDetail";
import RefundOrderDetail from "@organisms/RefundOrderDetail";

export interface CardTableProps {
    children?: any;
    variant?: any;
    data?: any;
    pagination?: any;
    filters?: any;
    onConfirm?: any;
    onCancel?: any;
}

const CardTable: React.FC<CardTableProps> = ({children, variant, data, pagination, filters, onConfirm, onCancel}) => {
    let Rows;
    switch (variant) {
        case 'order-dummy':
            Rows = data.map(row => (
                <Card variant={"order-dummy"} data={row} detail={(
                    <div className={styles.order_detail}>
                        <div className={styles.order_block}>
                            <div className={styles.container}>
                                <div className={styles.container_block}>
                                    <div className={styles.step}>
                                        <Step
                                            items={row?.steps?.titles}
                                            current={row?.steps?.current} direction={"vertical"}/>
                                    </div>
                                    <div className={styles.items}>
                                        <div className={styles.item}>
                                            <div className={styles.image}>
                                                <img alt={`${row?.id}_product_iamge`}
                                                     src={row?.productImage}/>
                                            </div>
                                            <div className={styles.item_detail}>
                                                <div className={styles.block}>
                                                    <div className={styles.name}>
                                                        {row?.name} {row?.variantTitle}
                                                    </div>
                                                    <div className={styles.order_font_size}>
                                                        Adet: {row?.quantity}
                                                    </div>
                                                </div>

                                                <div className={styles.block}>
                                                    <div className={styles.title}>
                                                        ÜRÜN KODU
                                                    </div>
                                                    <div className={styles.description}>
                                                        {row?.productCode}
                                                    </div>
                                                </div>

                                                <div className={styles.block}>
                                                    <div className={styles.title}>
                                                        KATALOG NUMARASI
                                                    </div>
                                                    <div className={styles.description}>
                                                        {row?.catalogNumber}
                                                    </div>
                                                </div>

                                                {
                                                    row?.options?.map((option, i) => (
                                                        <div className={styles.block}>
                                                            <div className={styles.title}>
                                                                ÜRÜN SEÇENEĞİ {i}
                                                            </div>
                                                            <div className={styles.description}>
                                                                {option}
                                                            </div>
                                                        </div>
                                                    ))
                                                }

                                                <div className={styles.block}>
                                                    <div className={styles.title}>
                                                        SATIŞ FİYATI
                                                    </div>
                                                    <div
                                                        className={`${styles.description} ${styles.orange}`}>
                                                        {row?.sellPrice?.toFixed(2)}
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.summary_block}>
                                <div className={styles.cargo}>
                                    <div className={styles.cargo_title}>
                                        <Icons.DeliveredBoxIcon
                                            color={"var(--primary-gray)"}/> {row?.cargoCompany} - {row?.cargoStatus}
                                    </div>
                                </div>
                                <div className={styles.summary}>
                                    <div className={styles.block}>
                                        <div className={styles.title}>
                                            ARA TOPLAM
                                        </div>
                                        <div className={styles.value}>
                                            {row?.totalPrice} ₺
                                        </div>
                                    </div>
                                    <div className={styles.block}>
                                        <div className={styles.title}>
                                            KARGO
                                        </div>
                                        <div className={styles.value}>
                                            {row?.isCargoFree !== 1 ? row?.cargoPrice : 0.00} ₺
                                        </div>
                                    </div>
                                    <div className={styles.block}>
                                        <div className={styles.title}>
                                            İSKONTO {row?.discountCode && `(${row?.discountType === 0 ? '%' : ''} ${row?.discountValue}${row?.discountType === 1 ? 'TL' : ''})`}
                                        </div>
                                        <div className={styles.value}>
                                            {row?.discountPrice} ₺
                                        </div>
                                    </div>
                                    <div className={styles.block}>
                                        <div className={styles.title}>
                                            TOPLAM SİPARİŞ TUTARI
                                        </div>
                                        <div className={styles.value}>
                                            {((row?.totalPrice + (row?.isCargoFree !== 1 ? row?.cargoPrice : 0.00)) - row?.discountPrice)?.toFixed(2)} ₺
                                        </div>
                                    </div>
                                    <div className={styles.block}>
                                        <div className={styles.title}>
                                            TOPLAM KOMİSYON
                                        </div>
                                        <div className={styles.value}>
                                            {row?.totalCommission}
                                        </div>
                                    </div>
                                    <div className={styles.block}>
                                        <div className={styles.title}>
                                            NET DEPO TUTARI
                                        </div>
                                        <div className={styles.value}>
                                            {(row?.totalPrice - (row?.commissionPrice + row?.iyzicoCommissionPrice))?.toFixed(2)} ₺
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.actions_block}>
                            <Button order-status-id={row?.orderStatusId} variant={"compact outlined"} onClick={(e) => {
                                const selectedId = e.target.getAttribute('order-status-id');
                                onCancel(selectedId);
                            }}>Siparişi İptal Et</Button>
                            <Button order-id={row?.id} variant={"compact outlined-primary"} onClick={onConfirm}>Siparişi
                                Onayla</Button>
                        </div>
                    </div>
                )}/>
            ))
            break;
        case 'order':
            Rows = data !== null ? data.map((row, i) => (
                <Card key={i} variant={"order"} data={row}>
                    <OrderDetail onConfirm={onConfirm} onCancel={onCancel} styles={styles}/>
                </Card>
            )) : [];
            break;
        case 'order-will-be-shipped':
            Rows = data !== null ? data.map((row, i) => (
                <Card key={i} variant={"order-will-be-shipped"} data={row}>
                    <OrderDetail onConfirm={onConfirm} onCancel={onCancel} styles={styles}/>
                </Card>
            )) : [];
            break;
        case 'order-shipping-record-created':
            Rows = data !== null ? data.map((row, i) => (
                <Card key={i} variant={"order-shipping-record-created"} data={row}>
                    <OrderDetail onConfirm={onConfirm} onCancel={onCancel} styles={styles}/>
                </Card>
            )) : [];
            break;
        case 'order-shipped':
            Rows = data !== null ? data.map((row, i) => (
                <Card key={i} variant={"order-shipped"} data={row}>
                    <OrderDetail onConfirm={onConfirm} onCancel={onCancel} styles={styles}/>
                </Card>
            )) : [];
            break;
        case 'order-in-distributed':
            Rows = data !== null ? data.map((row, i) => (
                <Card variant={"order-in-distributed"} key={i} data={row}>
                    <OrderDetail onConfirm={onConfirm} onCancel={onCancel} styles={styles}/>
                </Card>
            )) : [];
            break;
        case 'order-delivered':
            Rows = data !== null ? data.map((row, i) => (
                <Card key={i} variant={"order-shipped"} data={row}>
                    <OrderDetail onConfirm={onConfirm} onCancel={onCancel} styles={styles}/>
                </Card>
            )) : [];
            break;
        case 'payout-approved':
            Rows = data !== null ? data.map((row, i) => (
                <Card key={i} variant={"order-shipped"} data={row}>
                    <OrderDetail onConfirm={onConfirm} onCancel={onCancel} styles={styles}/>
                </Card>
            )) : [];
            break;
        case 'canceled-order':
            Rows = data !== null ? data.map((row, i) => (
                <Card key={i} variant={"canceled-order"} data={row}>
                    <CanceledOrderDetail styles={styles}/>
                </Card>
            )) : [];
            break;
        case 'refund-order':
            Rows = data !== null ? data.map((row, i) => (
                <Card key={i} variant={"refund-order"}
                      className={row?.vendorApprovement === null ? '' : (row?.vendorApprovement === 1 ? 'bg_green' : 'bg_red')}
                      data={row}>
                    <RefundOrderDetail onConfirm={onConfirm} styles={styles}/>
                </Card>
            )) : [];
            break;
        case 'refund-on-cargo':
            Rows = data !== null ? data.map((row, i) => (
                <Card key={i} variant={"refund-on-cargo"} data={row}>
                    <RefundOrderDetail onConfirm={onConfirm} styles={styles}/>
                </Card>
            )) : [];
            break;
        case 'received-vendor':
            Rows = data !== null ? data.map((row, i) => (
                <Card key={i} variant={"received-vendor"}
                      className={row?.vendorApprovement === null ? '' : (row?.vendorApprovement === 1 ? 'bg_green' : 'bg_red')}
                      data={row}>
                    <RefundOrderDetail onConfirm={onConfirm} onCancel={onCancel} styles={styles}/>
                </Card>
            )) : [];
            break;
        case 'refund-approved':
            Rows = data !== null ? data.map((row, i) => (
                <Card key={i} variant={"refund-approved"}
                      className={row?.vendorApprovement === null ? '' : (row?.vendorApprovement === 1 ? 'bg_green' : 'bg_red')}
                      data={row}>
                    <RefundOrderDetail styles={styles}/>
                </Card>
            )) : [];
            break;
        case 'refund-canceled':
            Rows = data !== null ? data.map((row, i) => (
                <Card key={i} variant={"refund-canceled"}
                      className={row?.vendorApprovement === null ? '' : (row?.vendorApprovement === 1 ? 'bg_green' : 'bg_red')}
                      data={row}>
                    <RefundOrderDetail styles={styles}/>
                </Card>
            )) : [];
            break;
        default:
            return children;
    }

    return <div className={styles.table_wrapper}>
        {(filters || pagination) &&
          <div className={styles.filter}>
              {filters &&
                <Select
                  options={[
                      {value: 'date_desc', label: 'Sipariş Tarihi (Eskiden Yeniye)'},
                      {value: 'date_asc', label: 'Sipariş Tarihi (Yeniden Eskiye)'}
                  ]}
                  showSearch
                  placeholder="Filtre Seçiniz"
                  onChange={() => {
                  }}
                />
              }
              {pagination &&
                <Pagination total={pagination?.total} defaultPageSize={pagination.defaultPageSize}
                            defaultCurrent={pagination.defaultCurrent}
                            setSizeChanger={(e) => pagination.setSizeChanger(e)}/>
              }
          </div>
        }

        <div className={styles.table}>
            {Rows && Rows?.length > 0 ? Rows : 'Herhangi bir satır/veri bulunmamaktadır.'}
        </div>
    </div>
};

export default CardTable;
