import React, {useState} from "react";
import styles from "@dv/scss/lib/Tabs.module.scss";
import TabPane, {TabPaneProps} from "@organisms/Tabs/TabPane";

export interface TabsProps {
    tabs: any,
    current?: any,
    setCurrent?: any,
    children: any
}

const Tabs: React.FC<TabsProps> = ({tabs, current, setCurrent, children}) => {
    const [selectedTabKey, setSelectedTabKey] = useState(current || 1);
    const [selectedTab, setSelectedTab] = useState(tabs[0]);

    return (
        <div className={styles.tab_view}>
            <div className={styles.tabs}>
                {React.Children.map(tabs, (Tab, i) => (
                    <div
                        key={i}
                        className={
                            current == Tab.key
                                ? `${styles.tab} ${styles.selected}`
                                : styles.tab
                        }
                        onClick={() => {
                            if (setCurrent)
                                setCurrent(Tab.key);
                            setSelectedTabKey(Tab.key);
                            setSelectedTab(Tab);
                        }}
                    >
                        {Tab}
                    </div>
                ))}
            </div>


            <div className={styles.content}>
                {children.find(e => e.key == current)}
            </div>

        </div>
    );
};

export default Tabs;
