// Get all Mondays in year from provided date
// Default today's date
export default function getWednesdays(d?: any) {
    let currentDate = new Date();
    // Copy d if provided
    d = d ? new Date(+d) : new Date();
    d.setMonth(0);
    // Set to start of month
    d.setDate(1);
    console.log('D_', d);
    // Store end year and month
    var endYear = d.getFullYear() + 1;
    var endMonth = d.getMonth();
    console.log(endMonth);

    // Set to first Monday
    d.setDate(d.getDate() + (10 - (d.getDay() || 7)) % 7);
    var wednesdays = [new Date(+d)];

    // Create Dates for all Mondays up to end year and month
    while (d < currentDate) {
        wednesdays.push(new Date(d.setDate(d.getDate() + 7)));
    }

    console.log('_W_',wednesdays);

    return wednesdays.map(function (mon) {
        return mon.toLocaleString(void 0, {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric'
        })
    });
}