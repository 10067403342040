import React, {Fragment, useContext, useEffect, useState} from 'react';

import {Link, useNavigate} from "react-router-dom";

import axiosAPI from "@utils/axiosAPI";
import AuthContext from "@contexts/Auth/AuthContext";
import {YEARS} from "@utils/constants/register.config";

import styles from '@dv/scss/lib/Register.module.scss';

import Logo from '@assets/images/BrandLogo.jpg';
import BarcodeLogo from "@assets/images/barcode_logo.png";
import PaymentLogos from "@assets/images/payment_logos.png";
import Select from "@antd/Select";
import {Button, Checkbox, Input} from "@dv/react";
import InputMask from 'react-input-mask';
import Upload from "@antd/Upload";
import {AWS_IMAGE_BUCKETS} from "@utils/BucketUploader";
import notify from "@antd/Notification";
import ModalComponent from "@organisms/Modal";
import VendorRegisterAgreement from "@organisms/Agreements/VendorRegisterAgreement";
import KvkkAgreement from "@organisms/Agreements/KvkkAgreement";
import TermsAndConditionsAgreement from "@organisms/Agreements/TermsAndConditionsAgreement";

const Register: React.FC<any> = () => {

    const navigate = useNavigate();

    const authContext = useContext(AuthContext);

    useEffect(() => {
        if (authContext.state.error !== null)
            notify({
                type: 'error',
                title: 'Bir hata ile karşılaşıldı',
                duration: 9,
                description: authContext.state.error === 'This tckn is invalid' ? 'Sorumlu Müdür kimliğini e-devletten doğrulayamadık. Nüfus cüzdanınızda yazanla aynı olduğuna dikkat ediniz.' : authContext.state.error
            });
        authContext.clearErrors();
    }, [authContext.state.error]);

    const [cities, setCities] = useState([]);
    const [towns, setTowns] = useState([]);
    const [taxOffices, setTaxOffices] = useState([]);
    const [tckNoValidation, setTckNoValidation] = useState(false);
    const [registerForm, setRegisterForm] = useState({
        firstname: undefined,
        lastname: undefined,
        email: undefined,
        password: undefined,
        name: undefined,
        documentUrl: undefined,
        kepEmail: undefined,
        isIndividual: '1',
        iban: undefined,
        phone: undefined,
        phone2: '',
        tckn: undefined,
        birthYear: undefined,
        commercialTitle: undefined,
        taxOffice: undefined,
        taxNo: undefined,
        mersisNo: '',
        address: undefined,
        postalCode: undefined,
        city: undefined,
        town: undefined,
        termsAgreement: false,
        registerKvkkAgreement: false,
    });
    const [isVisible, setIsVisible] = useState(false);

    const toggleVisible = () => {
        if (isVisible) return setIsVisible(false);
        setIsVisible(true);
    }

    const [registerValidation, setRegisterValidation] = useState(false);
    const [modalTrigger, setModalTrigger] = useState({
        vendorRegisterAgreement: false,
        kvkkAgreement: false,
        termsAndConditionsAgreement: false
    });

    const setModalShow = (key, value) => {
        setModalTrigger({
            ...modalTrigger,
            [key]: value
        })
    }

    const handleOnCityChange = async (value) => {
        setRegisterForm({...registerForm, city: value});

        axiosAPI({
            url: '/public/towns/' + value,
            requestMethod: "GET",
            noToken: true
        }).then((res) => {
            setTowns(res?.data);
        }).catch((err) => {
            console.log('TOWN FETCH ERR', err);
        });
    };

    useEffect(() => {
        Promise.all([
            axiosAPI({
                url: '/public/cities',
                requestMethod: "GET",
                noToken: true
            }),
            axiosAPI({
                url: '/public/tax-offices',
                requestMethod: "GET",
                noToken: true
            })
        ]).then((res) => {
            setCities(res[0].data);
            setTaxOffices(res[1].data);
        }).catch((err) => {
            console.log('ERR', err);
        });
    }, []);

    const register = (e) => {
        e.preventDefault();

        authContext.register({
            firstname: registerForm.firstname,
            lastname: registerForm.lastname,
            email: registerForm.email,
            password: registerForm.password,
            name: registerForm.name,
            documentUrl: registerForm.documentUrl,
            kepEmail: registerForm.kepEmail,
            isIndividual: registerForm.isIndividual,
            iban: registerForm.iban,
            phone: registerForm.phone,
            phone2: registerForm.phone2,
            tckn: registerForm.tckn,
            birthYear: registerForm.birthYear,
            commercialTitle: registerForm.commercialTitle,
            taxOffice: registerForm.taxOffice,
            taxNo: registerForm.taxNo,
            mersisNo: registerForm.mersisNo,
            address: registerForm.address,
            postalCode: registerForm.postalCode,
            city: registerForm.city,
            town: registerForm.town,
            deviceId: '11234',
        }, () => {
            navigate('/login');
        });
    };

    const tckNoValidate = function (value) {
        value = value.toString();
        let isEleven = /^[0-9]{11}$/.test(value);
        let totalX = 0;
        for (let i = 0; i < 10; i++) {
            totalX += Number(value.substr(i, 1));
        }
        let isRuleX = totalX % 10 == value.substr(10, 1);
        let totalY1 = 0;
        let totalY2 = 0;
        for (let i = 0; i < 10; i += 2) {
            totalY1 += Number(value.substr(i, 1));
        }
        for (let i = 1; i < 10; i += 2) {
            totalY2 += Number(value.substr(i, 1));
        }
        let isRuleY = ((totalY1 * 7) - totalY2) % 10 == value.substr(9, 0);
        return isEleven && isRuleX && isRuleY;
    };

    const registerValidate = () => {
        let validation = true;
        if (validateRegisterFormFields() === true && tckNoValidation === true) {
            validation = false;
        }
        setRegisterValidation(validation);
    }

    const validateRegisterFormFields = () => {
        let validation = true;
        for (const [key, value] of Object.entries(registerForm)) {
            if (key !== 'phone2' && key !== 'mersisNo') {
                if (value === undefined || value === '' || value === false)
                    validation = false;
            }
        }
        return validation;
    }

    useEffect(() => {
        registerValidate();
    }, [registerForm])

    return (
        <div className={styles.page}>
            <section className={styles.navbar}>
                <Link to="/"><img alt="logo" src={Logo} width={200} height={50}/></Link>
            </section>
            <section className={styles.content}>
                <h1>Depo Kayıt</h1>
                <form id={"registerForm"} onSubmit={register}>
                    <div className={styles.form_group_row}>
                        <div className="input_group full_width">
                            <label>
                                Sorumlu Müdür Adı *
                            </label>
                            <Input type={"text"} value={registerForm.firstname}
                                   onChange={(e) => setRegisterForm({...registerForm, firstname: e.target.value})}
                                   fullWidth
                                   required/>
                        </div>
                        <div className="input_group full_width">
                            <label>
                                Sorumlu Müdür Soyadı *
                            </label>
                            <Input type={"text"} value={registerForm.lastname}
                                   onChange={(e) => setRegisterForm({...registerForm, lastname: e.target.value})}
                                   fullWidth required/>
                        </div>
                    </div>
                    <div className={styles.form_group_row}>
                        <div className="input_group full_width">
                            <label>
                                Sorumlu Müdür E-posta *
                            </label>
                            <Input type={"email"} value={registerForm.email}
                                   onChange={(e) => setRegisterForm({...registerForm, email: e.target.value})} fullWidth
                                   required/>
                        </div>
                        <div className="input_group full_width">
                            <label>
                                Şifre *
                            </label>
                            <Input type={'password'} value={registerForm.password}
                                   onChange={(e) => setRegisterForm({...registerForm, password: e.target.value})}
                                   required/>
                        </div>
                    </div>
                    <div className={styles.form_group_row}>
                        <div className="input_group full_width">
                            <label>
                                Depo Adı *
                            </label>
                            <Input type={"text"} value={registerForm.name}
                                   onChange={(e) => setRegisterForm({...registerForm, name: e.target.value})}
                                   fullWidth
                                   required/>
                        </div>
                    </div>
                    <div className={styles.form_group_row}>
                        <div className="input_group full_width">
                            <label>
                                Tıbbi Cihaz Belgesi *
                            </label>
                            <Upload
                                bucketOptions={{
                                    bucketName: AWS_IMAGE_BUCKETS.NAMES.DOCUMENT.name,
                                    filePath: AWS_IMAGE_BUCKETS.NAMES.DOCUMENT.PATHS.vendor
                                }}
                                accept={"image/*, .doc, .docx,application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, .pdf"}
                                url={registerForm.documentUrl}
                                setUrl={(url) => {
                                    setRegisterForm({...registerForm, documentUrl: url});
                                }}/>
                        </div>
                    </div>
                    <div className={styles.form_group_row}>
                        <div className="input_group full_width">
                            <label>
                                Kep E-posta Adresi *
                            </label>
                            <Input type={"email"} value={registerForm.kepEmail}
                                   onChange={(e) => setRegisterForm({...registerForm, kepEmail: e.target.value})}
                                   fullWidth
                                   required/>
                        </div>
                        <div className="input_group full_width ">
                            <label>
                                Müşteri Tipi *
                            </label>
                            <Select
                                theme={"input"}
                                options={[
                                    {value: '1', label: 'Şahış Şirketi'},
                                    {value: '0', label: 'Limited/A.Ş.'}
                                ]}
                                placeholder="Müşteri Tipi"
                                defaultValue='1'
                                onChange={(value) => {
                                    setRegisterForm({...registerForm, isIndividual: value})
                                }}
                                required
                            />
                        </div>
                    </div>
                    <div className={styles.form_group_row}>
                        <div className="input_group full_width">
                            <label>
                                Ticaret Ünvanı *
                            </label>
                            <Input type={"text"} value={registerForm.commercialTitle}
                                   onChange={(e) => setRegisterForm({...registerForm, commercialTitle: e.target.value})}
                                   fullWidth
                                   required/>
                        </div>
                        <div className="input_group full_width">
                            <label>
                                Cep Telefonu *
                            </label>
                            <InputMask mask="599 999 99 99" value={registerForm.phone} maskChar={''}
                                       onChange={(e) => setRegisterForm({...registerForm, phone: e.target.value})}>
                                {(inputProps) =>
                                    <Input {...inputProps} disableUnderline type={"phone"} placeholder={'5** *** ** **'}
                                           fullWidth
                                           required/>
                                }
                            </InputMask>
                        </div>
                    </div>
                    <div className={styles.form_group_row}>
                        <div className="input_group full_width">
                            <label>
                                Vergi Dairesi *
                            </label>
                            <Select
                                theme={"input"}
                                options={taxOffices.map(e => ({value: e.id, label: e.name}))}
                                placeholder="Vergi Dairesi Seçiniz"
                                showSearch
                                loading={taxOffices.length < 1}
                                filterOption={(input, option) => {
                                    const value = input.replaceAll('i', 'İ');
                                    const op = option.children.replaceAll('i', 'İ');
                                    return op.toUpperCase().indexOf(value.toUpperCase()) >= 0
                                }}
                                onChange={(value) => {
                                    setRegisterForm({...registerForm, taxOffice: value})
                                }}
                                required
                            />
                        </div>
                        <div className="input_group full_width">
                            <label>
                                {registerForm.isIndividual == '1' ? "Şirket Sahibi T.C. Kimlik No *" : "Vergi No *"}
                            </label>
                            <InputMask mask={registerForm.isIndividual == '1' ? '99999999999' : '9999999999'}
                                       value={registerForm.taxNo} maskChar={''}
                                       onChange={(e) => setRegisterForm({...registerForm, taxNo: e.target.value})}>
                                {(inputProps) => <Input {...inputProps} type={"text"} fullWidth required/>}
                            </InputMask>
                        </div>
                    </div>
                    <div className={styles.form_group_row}>
                        {registerForm.isIndividual == '0' &&
                          <div className="input_group full_width">
                            <label>
                              Mersis No *
                            </label>
                            <InputMask mask={"9999999999999999"} value={registerForm.mersisNo}
                                       onChange={(e) => setRegisterForm({...registerForm, mersisNo: e.target.value})}>
                                {(inputProps) => <Input {...inputProps} type={"text"} fullWidth required/>}
                            </InputMask>
                          </div>
                        }
                        <div className="input_group full_width">
                            <label>
                                IBAN No *
                            </label>
                            <InputMask mask="TR\ 99 9999 9999 9999 9999 9999 99" value={registerForm.iban} maskChar={''}
                                       onChange={(e) => setRegisterForm({...registerForm, iban: e.target.value})}>
                                {(inputProps) => <Input {...inputProps} type={"text"} fullWidth required/>}
                            </InputMask>
                        </div>
                    </div>
                    <div className={styles.form_group_row}>
                        <div className="input_group full_width">
                            <label>
                                Sorumlu Müdür T.C. Kimlik No *
                            </label>
                            <InputMask mask={"99999999999"} value={registerForm.tckn} maskChar={''}
                                       onChange={(e) => {
                                           setTckNoValidation(tckNoValidate(e.target.value));
                                           setRegisterForm({...registerForm, tckn: e.target.value})
                                       }}>
                                {(inputProps) => <Input {...inputProps} type={"text"} fullWidth required/>}
                            </InputMask>
                            {!tckNoValidation && <span className={"validation-error-text"}>Lütfen geçerli T.C. Kimlik numarası giriniz.</span>}
                        </div>
                        <div className="input_group full_width">
                            <label>
                                Sorumlu Müdür Doğum Yılı *
                            </label>
                            <Select
                                theme={"input"}
                                options={YEARS}
                                showSearch
                                placeholder="Doğum Yılınızı Seçiniz"
                                onChange={(value) => {
                                    setRegisterForm({...registerForm, birthYear: value})
                                }}
                            />
                        </div>
                    </div>
                    <div className={styles.form_group_row}>
                        <div className="input_group full_width">
                            <label>
                                Cep Telefonu 2
                            </label>
                            <InputMask mask="599 999 99 99" value={registerForm.phone2} maskChar={''}
                                       onChange={(e) => setRegisterForm({...registerForm, phone2: e.target.value})}>
                                {(inputProps) => <Input {...inputProps} type={"phone"} placeholder={'5** *** ** **'}
                                                        fullWidth/>}
                            </InputMask>
                        </div>
                    </div>
                    <div className={styles.form_group_row}>
                        <div className="input_group full_width" style={{flex: 12}}>
                            <label>
                                Adres *
                            </label>
                            <Input type={"text"} value={registerForm.address}
                                   onChange={(e) => setRegisterForm({...registerForm, address: e.target.value})}
                                   fullWidth/>
                        </div>
                        <div className="input_group full_width" style={{flex: 4}}>
                            <label>
                                Posta Kodu *
                            </label>
                            <InputMask mask={"99999"} value={registerForm.postalCode} maskChar={''}
                                       onChange={(e) => setRegisterForm({...registerForm, postalCode: e.target.value})}>
                                {(inputProps) => <Input {...inputProps} type={"text"} minLength={6} maxLength={6}
                                                        fullWidth/>}
                            </InputMask>
                        </div>
                    </div>
                    <div className={styles.form_group_row}>
                        <div className="input_group full_width ">
                            <label>
                                Şehir *
                            </label>
                            <Select
                                theme={"input"}
                                options={cities.map(e => ({value: e.id, label: e.name}))}
                                placeholder="Şehir seçiniz"
                                showSearch
                                onSearch={(_val: any) => {
                                }}
                                filterOption={(input, option) => {
                                    const value = input.replaceAll('i', 'İ');
                                    return option.children.indexOf(value.toUpperCase()) >= 0
                                }}
                                loading={cities.length < 1}
                                onChange={handleOnCityChange}
                                required
                            />
                        </div>
                        <div className="input_group full_width ">
                            <label>
                                İlçe *
                            </label>
                            <Select
                                theme={"input"}
                                options={towns.map(e => ({value: e.id, label: e.name}))}
                                filterOption={(input, option) => {
                                    const value = input.replaceAll('i', 'İ');
                                    return option.children.indexOf(value.toUpperCase()) >= 0
                                }}
                                placeholder="İlçe seçiniz"
                                showSearch
                                onChange={(value) => {
                                    setRegisterForm({...registerForm, town: value})
                                }}
                                required
                            />
                        </div>
                    </div>
                    <div className={`${styles.form_group_row} ${styles.checkbox}`}>
                        <Checkbox id={"register_kvkk_agreement"} checked={registerForm.registerKvkkAgreement}
                                  value={registerForm.registerKvkkAgreement ? 1 : 0} name="terms" onChange={() => {
                            setRegisterForm({
                                ...registerForm,
                                registerKvkkAgreement: !registerForm.registerKvkkAgreement
                            });
                        }} required/>
                        <div className={styles.agreements_label}>
                            <a id="read-term-agreement" onClick={() => {
                                setModalShow('vendorRegisterAgreement', true);
                            }}>Depo üyelik sözleşmesini</a> ve <a onClick={() => {
                            setModalShow('kvkkAgreement', true);
                        }} id="privacy-agreement">Kvkk rıza metni ile çerez
                            politikasını kabul
                            ediyorum.</a>
                        </div>
                    </div>
                    <div className={`${styles.form_group_row} ${styles.checkbox}`}>
                        <Checkbox id={"terms_conditions_agreement"} checked={registerForm.termsAgreement}
                                  value={registerForm.termsAgreement ? 1 : 0} name="terms" onChange={() => {
                            setRegisterForm({
                                ...registerForm,
                                termsAgreement: !registerForm.termsAgreement
                            });
                        }} required/>
                        <div className={styles.agreements_label}>
                            <a id="read-term-agreement" onClick={() => {
                                setModalShow('termsAndConditionsAgreement', true);
                            }}>Genel İşlem Şartlar'ını kabul ediyorum.</a>
                        </div>
                    </div>
                </form>
                <div className={styles.form_group_row}>
                    <div className="input_group full_width">
                        <Button variant={"secondary"} type={"submit"} form={"registerForm"}
                                disabled={registerValidation} loading={authContext.state.loading}>Kayıt Ol</Button>
                    </div>
                </div>
            </section>
            <section className={styles.footer}>
                <ul className={styles.footer_links}>
                    <li><a href="https://dentalpiyasa.com/islem-rehberi/" target="_blank" rel="noopener noreferrer">İşlem
                        Rehberi</a></li>
                    <li><a href="https://dentalpiyasa.com/sartlar-kosullar/" target="_blank" rel="noopener noreferrer">Şartlar
                        ve Koşullar</a></li>
                    <li><a href="https://dentalpiyasa.com/iade-politikasi/" target="_blank" rel="noopener noreferrer">İade
                        Politikası</a></li>
                    <li><a href="https://dentalpiyasa.com/kisisel-verilerin-korunmasi/" target="_blank"
                           rel="noopener noreferrer">Kişisel Verilerin Korunması</a></li>
                    <li><a href="https://dentalpiyasa.com/mesafeli-satis-sozlesmesi/" target="_blank"
                           rel="noopener noreferrer">Satış Sözleşmesi &amp; Teslimat ve İade</a></li>
                </ul>
                <div className={styles.footer_copyright}>
                    <div className={styles.footer_copyright_text}>
                        © Copyright 2023 Dental Piyasa. Tüm Haklı Saklıdır.
                    </div>
                    <div className={styles.footer_copyright_sponsors}>
                        <img src={BarcodeLogo}/>
                        <img src={PaymentLogos}/>
                    </div>
                </div>
            </section>

            <section>
                <ModalComponent title={"Depo Üyelik Sözleşmesi"} show={modalTrigger.vendorRegisterAgreement}
                                onClose={() => {
                                    setModalShow('vendorRegisterAgreement', false);
                                }} footer={false}>
                    <VendorRegisterAgreement/>
                </ModalComponent>

                <ModalComponent title={"KVKK Rıza Metni ile Çerez Politikası"} show={modalTrigger.kvkkAgreement}
                                onClose={() => {
                                    setModalShow('kvkkAgreement', false);
                                }} footer={false}>
                    <KvkkAgreement/>
                </ModalComponent>

                <ModalComponent title={"Şartlar ve Koşullar"} show={modalTrigger.termsAndConditionsAgreement}
                                onClose={() => {
                                    setModalShow('termsAndConditionsAgreement', false);
                                }} footer={false}>
                    <TermsAndConditionsAgreement/>
                </ModalComponent>


            </section>

        </div>
    )
}

export default Register;
