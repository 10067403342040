import React, {useContext, useEffect, useState} from "react";
import Layout from "@organisms/Layout";
import OrderItemDetail from "@organisms/OrderItemDetail";
import styles from '@dv/scss/lib/OrderItemByTxId.module.scss';
import {Icons, Title, Card, Button, Input, Tooltip} from "@dv/react";
import PayoutContext from "@contexts/Payout/PayoutContext";
import notify from "@antd/Notification";

const OrderItemByTxId: React.FC<any> = () => {

    const [paymentTransactionId, setPaymentTransactionId] = useState('');
    const payoutContext = useContext(PayoutContext);

    const searchOrderItemByTxId = (e) => {
        e?.preventDefault();
        payoutContext.searchOrderItemByTxId(paymentTransactionId);
    }

    useEffect(() => {
        if (payoutContext.state.error !== null)
            notify({
                type: 'error',
                title: 'Bir hata ile karşılaşıldı',
                description: payoutContext.state.error
            });
        payoutContext.clearErrors();
    }, [payoutContext.state.error]);

    return <Layout title="Order Payments">
        <div className={styles.container}>
            <Title variant="badge" title={"İŞLEM NUMARASI İLE ÜRÜN HAKEDİŞİ"} icon={<Icons.NewspaperIcon/>}/>
            <Card>
                <form className={styles.search_section} onSubmit={searchOrderItemByTxId}>
                    <Input label={"İŞLEM NUMARASI"} type={"text"}
                           value={paymentTransactionId}
                           placeholder={"İşlem Numarası"}
                           tooltip={<Tooltip placement={"right"}
                               title="İyzico tarafından size ulaşan işlem numarası ile buradan hangi sipariş olduğunu, satılan ürünün hakediş tutarını ve detaylarını öğrenebilirsiniz."><Icons.InfoIcon/></Tooltip>}
                           onChange={(e) => {
                               e.preventDefault();
                               const value = e.target.value.replace(/\D/g, "");
                               setPaymentTransactionId(value);
                           }}
                    />

                    <Button variant={"primary"} type={"button"} loading={payoutContext.state.loading}
                            disabled={paymentTransactionId === ''}
                            onClick={searchOrderItemByTxId}>Ara</Button>
                </form>
            </Card>

            {payoutContext.state.searchedOrderItem &&
                <Card>
                    <OrderItemDetail data={payoutContext.state.searchedOrderItem}/>
                </Card>
            }
        </div>
    </Layout>
}

export default OrderItemByTxId;

