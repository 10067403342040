export const CAMPAIGN_STEPS_BY_RULE_TEMPLATE = {
  start: [{title: 'KAMPANYA BİLGİLERİ'}],
  discount_by_quantity: [{title: "KAMPANYA BİLGİLERİ"}, {title: "KAMPANYA KAPSAMI"}, {title: "KRİTERLER"}, {title: "KAMPANYA ÖZETİ"}],
  buy_together_discount: [{title: "KAMPANYA BİLGİLERİ"}, {title: "KAMPANYA KAPSAMI"}, {title: "KRİTERLER"}, {title: "KAMPANYA ÖZETİ"}]
}


export const STEP_3_LABELS_BY_RULE_TEMPLATE = {
  discount_by_quantity: {
    title: 'X AL Y ÖDE MİKTARLARINI BELİRLEYİN',
    campaignProductTitle: "X AL MİKTARI",
    giftProductTitle: "Y ÖDE MİKTARI",
    errorText: 'X Miktarı Y miktarından küçük ya da eşit olamaz.'
  },
  buy_together_discount: {
    title: 'KAMPANYA VE HEDİYE ÜRÜN MİKTARLARINI BELİRLEYİN',
    campaignProductTitle: "KAMPANYA ÜRÜN MİKTARI",
    giftProductTitle: "HEDİYE ÜRÜN MİKTARI",
    errorText: 'Kampanya Miktarı, Hediye miktarından küçük ya da eşit olamaz.'
  }
}
