export const ReducerTypes = {
    REGISTER_SUCCESS: 'REGISTER_SUCCESS',
    REGISTER_FAIL: 'REGISTER_FAIL',
    SET_USER: 'SET_USER',
    USER_LOADED: 'USER_LOADED',
    SET_FP_EMAIL: 'SET_FP_EMAIL',
    AUTH_ERROR: 'AUTH_ERROR',
    VERIFICATION_ERROR: 'VERIFICATION_ERROR',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAIL: 'LOGIN_FAIL',
    LOGOUT: 'LOGOUT',
    UPDATE_ME: 'UPDATE_ME',
    CHANGE_PASSWORD: 'CHANGE_PASSWORD',
    FORGOT_PASSWORD: 'FORGOT_PASSWORD',
    FORGOT_PASSWORD_VERIFY: 'FORGOT_PASSWORD_VERIFY',
    CHANGE_PASSWORD_ERROR: 'CHANGE_PASSWORD_ERROR',
    SET_VERIFICATION_CODE: 'SET_VERIFICATION_CODE',
    VERIFY_EMAIL: 'VERIFY_EMAIL',
    SET_VENDOR_STATUS: 'SET_VENDOR_STATUS',
    GET_EXCHANGE_RATES_SUCCESS: 'GET_EXCHANGE_RATES_SUCCESS',
    GET_EXCHANGE_RATES_FAIL: 'GET_EXCHANGE_RATES_FAIL',
    SET_LOADING: 'SET_LOADING',
    CLEAR_ERRORS: 'CLEAR_ERRORS'
}

export interface IAuthContext {
    token?: string;
    fpEmail?: string;
    isAuthenticated?: boolean;
    isVerified?: boolean;
    verificationCode?: string;
    isConfirmed?: boolean;
    isRegistered?: boolean;
    isForgotPasswordVerify?: boolean;
    loading?: boolean;
    user?: any;
    exchangeRates?: any;
    error?: any;
}

export type AuthContextType = {
    state?: IAuthContext | any;
    setUser: (user: any) => void;
    loadUser: (token: string) => void;
    register: (formData: any, cb?: () => void) => void;
    login: (formData: any) => void;
    logout: () => void;
    approveAgreement: (cb?: () => void) => void;
    changePassword: (formData: any, cb?: () => void) => void;
    setForgotPasswordEmail: (email: string) => void;
    forgotPassword: (formData: any) => void;
    forgotPasswordVerify: (formData: any, cb?: () => void) => void;
    setVerificationCode: (verificationCode: string) => void;
    verifyEmail: (formData: any, cb?: () => void) => void;
    updateMe: (formData: any) => void;
    setVendorStatus: (isClosed: boolean) => void;
    getExchangeRates: () => void;
    setLoading: (isLoading: boolean) => void;
    clearErrors: () => void;
};


