import React from "react";
import styles from "@dv/scss/lib/Table.module.scss";
import Pagination from "@antd/Pagination";

import Select from "@antd/Select";

export interface TableProps {
    children: any;
    headers?: any;
    rows?: any;
    pagination?: any;
    filters?: any;
    type?: any;
    headersClassName?: any;
}

export const APHeader: React.FC<any> = ({children, type, fitContent, ...others}) => {
    return (
        <div className={`${styles.header} ${styles.complex}`} {...others}>
            {children}
        </div>
    );
}

export const APRow: React.FC<any> = ({children, type, ...others}) => {

    return (
        <div className={`${styles.row} ${styles.complex}`} {...others}>
            {children}
        </div>
    );
}

export const APCell: React.FC<any> = ({children, type, fitContent, ...others}) => {
    return (
        <div className={`${styles.column} ${styles.complex} ${fitContent && 'fit-content'}`} {...others}>
            {children}
        </div>
    );
}

const Table: React.FC<TableProps> = ({children, headers, pagination, filters, headersClassName}) => {
    return (
        <div className={styles.table_wrapper}>
            {
                (filters || pagination) && <div className={styles.filter}>
                    {filters &&
                      <Select
                        options={[
                            {value: 'date_desc', label: 'Sipariş Tarihi (Eskiden Yeniye)'},
                            {value: 'date_asc', label: 'Sipariş Tarihi (Yeniden Eskiye)'}
                        ]}
                        showSearch
                        placeholder="Filtre Seçiniz"
                        onChange={() => {
                        }}
                      />
                    }
                    {
                        pagination &&
                      <Pagination total={pagination?.total} defaultPageSize={pagination.defaultPageSize}
                                  defaultCurrent={pagination.defaultCurrent}
                                  setSizeChanger={(e) => pagination.setSizeChanger(e)}/>
                    }
              </div>
            }
            <div className={`${styles.table} ${styles.complex}`}>
                <div className={`${styles.headers} ${headersClassName}`}>
                    {headers}
                </div>
                <div className={styles.content}>
                    {children}
                </div>
            </div>
        </div>
    )
};

export default Table;
