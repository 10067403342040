import React, {useState} from 'react';
import type {TableProps, ColumnsType} from 'antd/es/table';
import {Popconfirm, Table as AntTable} from 'antd';

import '@dv/scss/lib/Table.antd.css';

interface DataType {
}

interface ITableProps {
    columns: any;
    data: any;
    loading?: boolean;
    pagination?: any;
    scroll?: any;
}

const Table: React.FC<ITableProps> = ({columns, data, loading, pagination = false, ...rest}) => {

    return <AntTable columns={columns} bordered={true} pagination={pagination} dataSource={data}
                     loading={loading || false} {...rest}/>;
};

export default Table;
