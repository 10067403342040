import React, {useState} from 'react';

import styles from '@dv/scss/lib/ProductSuggestion.module.scss';

import Layout from "@organisms/Layout";
import {Icons, Card, Title} from "@dv/react/lib";
import Step from "@antd/Step";
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';

const ProductSuggestion: React.FC<any> = () => {

    const [suggestionStep, setSuggestionStep] = useState({
        title: "product_info",
        name: "step1",
        step: 0
    });

    const [suggestionForm, setSuggestionForm] = useState({
        suggestionType: null
    })

    let block;

    switch (suggestionStep.name) {
        case 'step1':
            block = <Step1 styles={styles} suggestionForm={suggestionForm} setSuggestionForm={setSuggestionForm}
                           setNavigation={setSuggestionStep}/>;
            break;
        case 'step2':
            block = <Step2 styles={styles} suggestionForm={suggestionForm} setSuggestionForm={setSuggestionForm}
                           setNavigation={setSuggestionStep}/>;
            break;
        case 'step3':
            block = <Step3 styles={styles} suggestionForm={suggestionForm} setSuggestionForm={setSuggestionForm}
                           setNavigation={setSuggestionStep}/>;
            break;
        default:
            alert("404");
            block = null;
            break;
    }

    return (
        <div>
            <Layout title={"Product Suggestion"}>
                <div className={styles.container}>
                    <Title variant="badge" title={"ÜRÜN ÖNER"} icon={<Icons.FastDeliveryIcon/>}/>
                    <div className={styles.block_with_nav}>
                        <div className={styles.nav}>
                            <Card className={styles.nav}>
                                <div className={styles.nav_content}>
                                    <Step
                                        items={[{title: "ÜRÜN BİLGİLERİ"}, {title: "AMBALAJ & VARYANT İÇERİĞİ"}, {title: "ONAY"}]}
                                        current={suggestionStep.step}
                                        direction={"vertical"}/>
                                </div>
                            </Card>
                        </div>
                        <Card className={styles.block}>
                            {block}
                        </Card>
                    </div>
                </div>
            </Layout>
        </div>
    );
}

export default ProductSuggestion;
