export const ReducerTypes = {
    SET_SEARCH_TERM: 'SET_SEARCH_TERM',
    SEARCH_PRODUCTS: 'SEARCH_PRODUCTS',
    SELECT_PRODUCT: 'SELECT_PRODUCT',
    SET_PRODUCT_STOCK_INPUTS: 'SET_PRODUCT_INPUTS',
    SELECT_NEW_PRODUCT_STOCKS: 'SELECT_NEW_PRODUCT_STOCKS',
    SET_NEW_PRODUCT_STOCK: 'SET_NEW_PRODUCT_STOCK',
    MUTATE_NEW_PRODUCT_STOCKS: 'MUTATE_NEW_PRODUCT_STOCKS',
    VALIDATE_NEW_PRODUCT_STOCKS: 'VALIDATE_NEW_PRODUCT_STOCKS',
    ADD_PRODUCTS: 'ADD_PRODUCTS',
    SET_LOADING: 'SET_LOADING',
    PROCESS_FAIL: 'PROCESS_FAIL',
    CLEAR_ERRORS: 'CLEAR_ERRORS'
}

export const newProductStockInputEmptyFields = {
    baseProductId: null,
    currencyType: undefined,
    amount: '',
    marketPrice: '',
    price: '',
    warehouseIncome: '',
    minBatchAmount: '',
    batchUnitPrice: '',
    expiration: '',
    validation: false
};

export interface IAddProductContext {
    baseProductId: null | undefined | number,
    searchTerm?: string;
    products?: object[];
    beforeCurrencyType: string;
    newProductStockInputs: object;
    newProductStocksValidation: boolean;
    newProductStocks: object[];
    selectedProduct?: object;
    selectedProductVariants?: object[];
    processStatus?: boolean;
    loading?: boolean;
    error?: any;
}

export type AddProductContextType = {
    state?: IAddProductContext | any;
    setSearchTerm: (text: string) => void;
    searchProducts: (term: string) => void;
    selectProduct: (productId: any) => void,
    addProducts: (cb: any) => void;
    selectNewProductStocks: (checked: boolean) => void;
    setNewProductStock: (id: any, data: any, cb?: () => void) => void;
    mutateNewProductStocks: (key: any) => void;
    validateNewProductStocks: () => void;
    setProductStockInputs: (data: any, cb?: () => void) => void;
    setLoading: (isLoading: boolean) => void;
    clearErrors: () => void;
};


