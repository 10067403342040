import React, {useContext, useEffect} from 'react';

import styles from '@dv/scss/lib/Campaigns.module.scss';
import {Link} from 'react-router-dom';

import Layout from "@organisms/Layout";
import {Card, Button, Icons, Title} from "@dv/react/lib";
import Table, {Header, Row, Cell} from "@organisms/Table";
import CampaignContext from "@contexts/Campaign/CampaignContext";
import AntTable from "@antd/Table/Table";

const Campaigns: React.FC<any> = () => {

    const campaignContext = useContext(CampaignContext);

    useEffect(() => {
        campaignContext.getCampaigns();
    }, [])

    return (
        <Layout title={"My Campaigns"}>
            <div className={styles.container}>
                <Title variant="badge" title={"KAMPANYALAR"} icon={<Icons.CampaignPercentIcon/>}/>
                <div className={styles.block_with_nav}>
                    <div className={styles.nav}>
                        <Card className={styles.nav}>
                            <div className={styles.nav_content}>
                                <h5>Kendi kampanyanızı oluşturmaya hazır mısınız?</h5>
                                <p>
                                    Dilediğiniz zaman, dilediğiniz ürünlerle kampanya oluşturun!
                                    Üstelik artık size sunduğumuz kampanya önerileriyle
                                    başarıya daha hızlı ulaşın!
                                </p>
                                <Link to="/campaigns/add"><Button>Yeni Kampanya Oluştur</Button></Link>
                            </div>
                        </Card>
                    </div>
                    <Card className={styles.block}>
                        <AntTable
                            columns={[
                                {
                                    title: 'Kampanya Türü',
                                    dataIndex: 'baseCampaignName',
                                    key: 'baseCampaignName',
                                    render: (_, {baseCampaignName}) => <p>{baseCampaignName}</p>
                                },
                                {
                                    title: 'Kampanyanızın İsmi',
                                    dataIndex: 'campaignName',
                                    key: 'campaignName',
                                    render: (_, {name}) => <p>{name}</p>
                                },
                                {
                                    title: 'Kampanya Ürünü',
                                    dataIndex: 'campaignProductName',
                                    key: 'campaignProductName',
                                    render: (_, {campaignBaseProductName}) => <p>{campaignBaseProductName}</p>
                                },
                                {
                                    title: 'Hediye Ürünü',
                                    dataIndex: 'giftProductName',
                                    key: 'giftProductName',
                                    render: (_, {giftProductName, giftProductVariantTitle}) => giftProductName ?
                                        <p>{giftProductName} <b>{giftProductVariantTitle}</b></p> : '-'
                                },
                                {
                                    title: 'Kampanya Adeti',
                                    dataIndex: 'campaignBaseProductQuantity',
                                    key: 'campaignBaseProductQuantity',
                                    render: (_, {preConditionQuantity}) => preConditionQuantity ?
                                        <div className={"tags"}>
                                            <div className={"tag tag-m orange"}>
                                                <span>{preConditionQuantity} AL</span>
                                            </div>
                                        </div>
                                        : '-'
                                },
                                {
                                    title: 'Hediye Adeti',
                                    dataIndex: 'giftBaseProductQuantity',
                                    key: 'giftBaseProductQuantity',
                                    render: (_, {giftQuantity, campaignRuleTemplate}) => giftQuantity ?
                                        <div className={"tags"}>
                                            <div className={"tag tag-m green"}>
                                                {campaignRuleTemplate === "discount_by_quantity" &&
                                                  <span>{giftQuantity} ÖDE</span>
                                                }
                                                {campaignRuleTemplate === "buy_together_discount" &&
                                                  <span>{giftQuantity} HEDİYE</span>
                                                }
                                            </div>
                                        </div>
                                        : '-'
                                },
                                {
                                    title: 'Aksiyon',
                                    dataIndex: 'deleteCampaign',
                                    key: 'deleteCampaign',
                                    render: (_, {id}) =>
                                        <div className={"tags"} onClick={() => {
                                            campaignContext.deleteCampaign(id, () => {
                                                campaignContext.getCampaigns();
                                            });
                                        }}>
                                            <div className={"tag tag-m red cursor_pointer"}>
                                                SİL
                                            </div>
                                        </div>
                                },
                            ]}
                            data={campaignContext.state.campaigns}
                            loading={campaignContext.state.loading}/>
                    </Card>
                </div>
            </div>
        </Layout>
    )
};

export default Campaigns;
