import {ReducerTypes} from '../types/Auth';

export default (state, action) => {
    switch (action.type) {
        case ReducerTypes.SET_USER:
            return {
                ...state,
                user: action.payload,
            };
        case ReducerTypes.USER_LOADED:
            return {
                ...state,
                isAuthenticated: true,
                loading: false,
                user: action.payload,
            };
        case ReducerTypes.LOGIN_SUCCESS:
            localStorage.setItem('dp_vendor_token', action.payload.token);
            return {
                ...state,
                ...action.payload,
                user: action.payload,
                isAuthenticated: action.payload.isVerified && action.payload.isConfirmed,
                isVerified: action.payload.isVerified,
                isConfirmed: action.payload.isConfirmed,
                loading: false,
                error: null,
            };
        case ReducerTypes.REGISTER_SUCCESS:
            localStorage.setItem('dp_vendor_token', action.payload.token);
            return {
                ...state,
                token: action.payload.token,
                isRegistered: true,
                isVerified: false,
                error: null,
            };
        case ReducerTypes.UPDATE_ME:
            return {
                ...state
            };
        case ReducerTypes.AUTH_ERROR:
        case ReducerTypes.LOGIN_FAIL:
        case ReducerTypes.LOGOUT:
            localStorage.removeItem('dp_vendor_token');
            return {
                ...state,
                token: null,
                isAuthenticated: false,
                isVerified: null,
                isConfirmed: null,
                isForgotPasswordVerify: false,
                loading: false,
                user: null,
                error: action.payload || null,
            };
        case ReducerTypes.VERIFICATION_ERROR:
            return {
                ...state,
                error: action.payload || null,
            };
        case ReducerTypes.REGISTER_FAIL:
            return {
                ...state,
                isRegistered: false,
                loading: false,
                error: action.payload || null,
            };
        case ReducerTypes.CLEAR_ERRORS:
            return {
                ...state,
                error: null
            };
        case ReducerTypes.SET_FP_EMAIL:
            return {
                ...state,
                fpEmail: action.payload
            }
        case ReducerTypes.SET_VERIFICATION_CODE:
            return {
                ...state,
                verificationCode: action.payload
            }
        case ReducerTypes.VERIFY_EMAIL:
            return {
                ...state,
                isVerified: true
            }
        case ReducerTypes.FORGOT_PASSWORD:
            return {
                ...state,
                isForgotPasswordVerify: true
            };
        case ReducerTypes.FORGOT_PASSWORD_VERIFY:
            return {
                ...state,
                isForgotPasswordVerify: false
            };
        case ReducerTypes.SET_LOADING:
            return {
                ...state,
                loading: action.payload || null
            };
        case ReducerTypes.SET_VENDOR_STATUS:
            return {
                ...state
            };
        case ReducerTypes.CHANGE_PASSWORD_ERROR:
            return {
                ...state,
                error: action.payload || null
            };
        case ReducerTypes.GET_EXCHANGE_RATES_SUCCESS:
            return {
                ...state,
                exchangeRates: action.payload
            };
        case ReducerTypes.GET_EXCHANGE_RATES_FAIL:
            return {
                ...state,
                exchangeRates: null,
                error: action.payload || null
            };
        default:
            return state;
    }
};
