import {useReducer} from 'react';

import {localApi} from '@utils/axiosInstances';
import notify from "@antd/Notification";

import DashboardContext from './DashboardContext';
import DashboardReducer from './DashboardReducer';
import {ReducerTypes, IDashboardContext, initialStateData} from '../types/Dashboard';

const DashboardState = (props: any) => {

    const initialState: IDashboardContext = {
        data: initialStateData,
        loading: false,
        error: null,
    };

    const [state, dispatch] = useReducer(DashboardReducer, initialState);

    // Get Dashboard Data
    const getData = async () => {
        try {
            const res = await localApi.get('/vendor/dashboard');
            dispatch({
                type: ReducerTypes.GET_DATA_SUCCESS,
                payload: res?.data
            });
        } catch (err) {
            dispatch({
                type: ReducerTypes.GET_DATA_FAIL,
                payload: err?.response?.data?.message
            });
        }
    };

    // Clear Errors
    const clearErrors = () => dispatch({type: ReducerTypes.CLEAR_ERRORS});

    return (
        <DashboardContext.Provider
            value={{
                state: {...state},
                getData,
                clearErrors
            }}
        >
            {props.children}
        </DashboardContext.Provider>
    );
};

export default DashboardState;
