import {ReducerTypes} from '../types/Product';

export default (state, action) => {
    switch (action.type) {
        case ReducerTypes.SEARCH_PRODUCTS:
            return {
                ...state,
                products: action.payload,
                loading: false,
                error: null,
                processStatus: true
            };
        case ReducerTypes.SELECT_PRODUCT:
            return {
                ...state,
                selectedProduct: action.payload,
                loading: false,
                error: null,
                processStatus: true
            };
        case ReducerTypes.UPDATE_PRODUCTS:
            return {
                ...state,
                loading: false,
                error: null,
                processStatus: true
            };
        case ReducerTypes.PROCESS_FAIL:
            return {
                ...state,
                token: null,
                isAuthenticated: false,
                loading: false,
                user: null,
                error: action.payload || null,
            };
        case ReducerTypes.CLEAR_ERRORS:
            return {
                ...state,
                error: null
            };
        default:
            return state;
    }
};
