import {Fragment} from 'react';

const TermsAndConditionsAgreement = () => {

    return (
        <Fragment>
            <b>KURUMSAL ÜYELİK SÖZLEŞMESİ ÖNCESİ BİLGİ FORMU</b>
            <br/>
            Ekli Kurumsal Üyelik Sözleşmesi Dentalpiyasa Elektronik Ticaret ve Bilişim Hizmetleri Anonim Şirketi (Dentalpiyasa) tarafından önceden hazırlanmış tip sözleşme niteliğinde olup Türk Borçlar Kanunu kapsamında genel işlem koşulları içermektedir. Aşağıda imzalamayı düşündüğünüz Kurumsal Üyelik Sözleşmesi içeriğinde yer alan genel işlem koşulları hakkında bilgi verilmektedir.
            <br/><br/>
            <b> BİLGİLENDİRME KONULARI</b>
            <br/>
            •	Sözleşmenin tarafı Dentalpiyasa, dentalpiyasa.com üst alan adlı çevrimiçi elektronik ticaret platformunu sahibi olarak işletmekte olup Üyeleri sanal bir pazaryerinde alışveriş yapma amacıyla bir araya getirmektedir. Dentalpiyasa, Websitesi'nde ve bağlı mobil uygulamalarda yer alan herhangi bir ürün veya hizmetin satıcısı konumunda bulunmayıp, 6563 sayılı Elektronik Ticaretin Düzenlenmesi Hakkında Kanun uyarınca yalnızca "aracı hizmet sağlayıcı" ve 5651 sayılı İnternet Ortamında Yapılan Yayınların Düzenlenmesi ve Bu Yayınlar Yoluyla İşlenen Suçlarla Mücadele Edilmesi Hakkında Kanun uyarınca "yer sağlayıcı" konumundadır.
            <br/>
            •	Satıcı/Alıcı, tacir sıfatıyla ticari ve mesleki amaçlarla alım satım işlemlerinde bulunduğundan işlemleri 6502 sayılı Tüketicinin Korunması Hakkında Kanun'un kapsamına girmemektedir.
            <br/>
            •	Dentalpiyasa, satış sözleşmesine taraf değildir. Satıcı, her bir ürün için Sözleşmede sayılan ödeme yöntemlerinden herhangi birini veya hepsini tercih edebilir. Satıcının belirlediği ödeme yöntemlerinden tercih edeceğine uygun olarak gerekli koşulları sağlamak Alıcının yükümlülüğündedir.
            <br/>
            •	Dentalpiyasa, satış sözleşmesine taraf olmamakla birlikte, Satıcıya malın teslimi karşılığında bedeli tahsil edememe; Alıcıya ise bedelin ödenmesine karşılık, malın hiç veya istenilen nitelik ve şartlarda teslim edilmemesi risklerine karşı koruma amaçlı “Dentalpiyasa Güvenli Alışveriş Sistemi” hizmeti sağlayacaktır. Bu Sisteme 'ne göre Alıcı ürünü satın aldıktan sonra, ürün bedeli Satıcı adına ve hesabına Dentalpiyasa’nın anlaşmalı olduğu BDDK lisanslı ödeme kuruluşu tarafından Alıcı'dan tahsil edilir ve ödeme kuruluşu havuz hesabına aktarılır. Satıcı, ürünü belirttiği süre içerisinde Alıcı'ya ulaştırdıktan sonra, Alıcı ürünü inceleyerek siparişe onay verir. Bu onay ile Satıcı gerçekleştirdiği satış işlemi karşılığındaki ürün bedeline hak kazanır ve ödeme, ödeme kuruluşu havuz hesabından Satıcı'nın hesabına Kurumsal Üyelik Sözleşmesi ve eklerinde belirtilen gün içerisinde aktarılır.
            <br/>
            •	Dentalpiyasa, 6698 sayılı Kişisel Verilerin Korunması Kanunu uyarınca veri sorumlusu sıfatıyla üyelik işlemi esnasında ve üyelik boyunca temin edilen Üye’nin kişisel ve ticari bilgilerini kaydedebilecek, saklayacak, güncelleyecek, aşağıda belirtilen kişilere açıklayabilecek, devredebilecek, sınıflandırabilecek ve Kanunda sayılan şekillerde işlenebilecektir. Dentalpiyasa, bu bilgileri Hizmetler’in tam ve gereği gibi işletilmesi için gereken uygulamaların yürütülmesi, istatistiki değerlendirmelerin yapılması amacıyla kendisinin ve iş ortaklarının faaliyetlerinin, uygulamalarının reklamı, pazarlanması, ilanı ve sair amaçlarla, kullanabilecek olup belirtilen sebeplerle sınırlı olmak kaydıyla üçüncü kişilere açıklayabileceği kabul, beyan ve taahhüt edilmektedir.
            <br/>
            •	Dentalpiyasa, Websitesi'nde ve mobil uygulamalarında (Platform) yer alan hiçbir görsel, yazılı veya sair içeriğin gerçekliğinden, güvenilirliğinden, doğruluğundan ya da hukuka uygunluğundan sorumlu değildir ve söz konusu içeriklerin doğruluğunu kontrol etme gibi bir yükümlülüğü olmamasına rağmen ve takdiri tamamen kendisine ait olmak üzere, söz konusu içerikleri dilediği zaman kontrol etme ve gerekli görürse erişime kapatma ve silme hakkına sahiptir.
            <br/>
            •	Satıcı, kendisiyle Platform üzerinden iletişime geçen Alıcılar'ı Platform dışında alışveriş yapmaya teşvik etmesi ve kendisine bu yönde gelen teklifleri kabul etmesi, Dentalpiyasa kampanya ve indirimlerini manipüle eden davranışlarda bulunması halinde, Satıcı, ihlali oluşturan ürün toplam bedelinin veya Dentalpiyasa tarafından manipüle olduğu tespit edilen tutarın 2 (iki) katı ceza ödeyecek veya bu tutar cari hesabından düşülecektir.
            <br/>
            •	Dentalpiyasa tarafından Satıcı'ya uygulanacak olan komisyon oranları, ödeme koşulları ve sair mali şartlar Kurumsal Üyelik Sözleşmesi ve ekleri ile Platform’da belirlenmiştir.
            <br/>
            •	Satıcı'nın satılan ürünler ile ilgili fatura ve teslim fişi düzenlememesi halinde Dentalpiyasa, ürün bedelini Satıcı'ya aktarmama hakkına sahip olacaktır.
            <br/>
            •	Taraflar, herhangi bir sebebe dayanmaksızın ve tazminat ödeme yükümlülüğü altında bulunmaksızın, yazılı olarak bildirimde bulunmak kaydıyla işbu Sözleşme'yi her zaman feshedebilir. Sözleşme’nin Dentalpiyasa tarafından bu suretle feshedilmesi halinde Satıcı, fesih tarihine kadar Dentalpiyasa tarafından sunulan hizmete ilişkin bedelleri ödemeyi kabul ve taahhüt eder.
            <br/>
            •	Taraflar Sözleşme’ye eklerine aykırılık halinde, Dentalpiyasa'nın Sözleşme'yi derhal fesih hakkı bulunduğunu kabul eder. Dentalpiyasa Sözleşme'yi feshetme hakkını kullanmasa dahi, söz konusu aykırılık hallerinde Dentalpiyasa Hizmetler’i geçici olarak durdurma ve askıya alma hakkına her zaman sahip olacaktır.
            <br/>
            •	Dentalpiyasa, Üye'nin Kurumsal Üyelik Sözleşme'nin ihlali nedeniyle maruz kaldığı her türlü zararı, kaybı, masrafı, gideri veya üçüncü kişilerin taleplerini takdiri tamamen kendisinde olmak üzere Üye'nin cari hesabından mahsup edebilir veya Üye'den bizzat tahsil edebilir.
            <br/>
            •	Satıcı'nın Sanal Mağazası'ndan alışveriş yapmış olan Alıcılar'dan gelebilecek iade taleplerinin süreleri dolana kadar, Dentalpiyasa, Satıcı'nın hesabına bloke koyabilir, ödeme talimatını ödeme kuruluşuna yapmayabilir, yapılan tüm satışlara ilişkin iade sürelerinin dolmasının ardından bloke kaldırılır veya ödeme kuruluşuna ilgili bildirimde bulunur.
            <br/>
            •	 Dentalpiyasa gerekli olduğuna kanaat getirdiği takdirde Kurumsal Üyelik Sözleşmesi ve eklerinin hüküm ve şartlarını tek taraflı olarak uygun göreceği herhangi bir zamanda değiştirme hakkına sahiptir. Sözleşme’nin değişen hükümleri, ilan edildikleri tarihte geçerlilik kazanır.
            <br/>
            •	Dentalpiyasa Sözleşme'nin imza tarihinden sonra meydana gelen mevzuat değişiklikleri gereği yapmak zorunda olduğu Sözleşme değişikliklerini Üye'ye bildirmekle yükümlü değildir. Ayrıca Platform üzerinde sunulacak olan Hizmetler’in ve Dentalpiyasa tarafından uygulanan kampanyaların detayları, ürünlerin sergilenme süreleri, ek imkânlar ve teknik özellikler Dentalpiyasa tarafından önceden habersiz olarak değiştirilebilir. Dentalpiyasa tarafından yapılan değişiklikler yayınlanma tarihinde yürürlüğe girer. Üye bu değişikleri Platform’dan takip etmek ve değişikliklerin gereklerini yerine getirmekle yükümlüdür.
            <br/>
            •	Üye, Kurumsal Üyelik Sözleşmesi’nden doğabilecek ihtilaflarda Taraflar'ın resmi defter ve ticari kayıtları ile Dentalpiyasa’nın veri tabanında, sunucularında tutulan e-arşiv kayıtlarının, elektronik bilgilerin ve bilgisayar kayıtlarının, bağlayıcı, kesin ve münhasır delil teşkil edeceğini ve bu maddenin 6100 sayılı Hukuk Muhakemeleri Kanunu’nun 193. maddesi anlamında delil sözleşmesi niteliğinde olduğunu kabul eder.
            <br/>
            •	“Üyelik İçin Gerekli Belge Listesi”, “Yasaklı Ürünler Listesi”, “Dentalpiyasa Güvenli Alışveriş Sistemi” Kurumsal Hizmet Sözleşmesi’nin ekleridir. Sözleşme Öncesi Bilgilendirme Formu ile yine aynı sitede yer alan Kurumsal Üyelik Sözleşmesi (Sözleşme) ve eklerinin ayrıntılı olarak incelenmesi, ayrıca Dentalpiyasa tarafından yapılan bilgilendirmede genel işlem koşulları ve kullanımı hakkında yeterli bilgi edinilmesi, Sözleşmedeki genel işlem koşullarının öğrenilmesi için de zaman ve imkân tanınmıştır. Ayrıca bu belgelerde yer alan bilgilerin amacına uygun olarak makul bir süre incelenmesine elverecek şekilde kaydedilmesini ve değiştirilmeden kopyalanmasını sağlayan ve bu bilgiye aynen ulaşılmasına imkân veren araç ve ortamlar sağlanmıştır.


        </Fragment>
    )
};

export default TermsAndConditionsAgreement;
