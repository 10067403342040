import {ReducerTypes} from '../types/Campaign';

export default (state, action) => {
    switch (action.type) {
        case ReducerTypes.GET_BASE_CAMPAIGNS:
            return {
                ...state,
                baseCampaigns: action.payload,
                loading: false,
                error: null,
                processStatus: true
            };
        case ReducerTypes.GET_CAMPAIGNS:
            return {
                ...state,
                campaigns: action.payload,
                loading: false,
                error: null,
                processStatus: true
            };
        case ReducerTypes.FILL_PRODUCT_LISTS:
            console.log('?', [`${action.payload.type}ProductList`]);
            return {
                ...state,
                [`${action.payload.type}ProductList`]: action.payload.data,
                [`${action.payload.type}ProductSearchList`]: action.payload.data,
            }
        case ReducerTypes.SET_FILTER_TEXT:
            return {
                ...state,
                [action.payload.type]: action.payload.value
            }
        case ReducerTypes.FILTER_PRODUCT_LIST:
            return {
                ...state,
                [`${action.payload.type}ProductSearchList`]: state[`${action.payload.type}ProductList`].filter(p => {
                    if (p.name.toLocaleLowerCase().includes(action.payload.text.toLocaleLowerCase())) {
                        return p;
                    }

                    if (p.brand?.name.toLocaleLowerCase().includes(action.payload.text.toLocaleLowerCase())) {
                        return p;
                    }

                    if (p.category?.name.toLocaleLowerCase().includes(action.payload.text.toLocaleLowerCase())) {
                        return p;
                    }
                }),
            }
        case ReducerTypes.SET_SELECTED_BASE_PRODUCT:
            return {
                ...state,
                [action.payload.type]: action.payload.value
            };
        case ReducerTypes.SET_CONDITION_AMOUNT:
            return {
                ...state,
                [action.payload.type]: action.payload.value
            };
        case ReducerTypes.SET_NEW_CAMPAIGN:
            return {
                ...state,
                newCampaign: {
                    ...state.newCampaign,
                    [action.payload.key]: action.payload.value
                },
                loading: false,
                error: null,
                processStatus: true
            };
        case ReducerTypes.SET_NEW_CAMPAIGN_DATES:
            return {
                ...state,
                newCampaign: {
                    ...state.newCampaign,
                    startDate: action.payload.startDate,
                    endDate: action.payload.endDate
                },
                loading: false,
                error: null,
                processStatus: true
            };
        case ReducerTypes.PROCESS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload || null,
            };
        case ReducerTypes.SET_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case ReducerTypes.CLEAR_ERRORS:
            return {
                ...state,
                error: null
            };
        default:
            return state;
    }
};
