const axios = require('axios');

const ENVIRONMENT_TAG = process.env.REACT_APP_ENV;
const API_PROD_URL = 'https://dental-api.dentalpiyasa.com';
const API_DEV_URL = 'http://192.168.68.138:8080';
const PROXY_URL = '/api';
const EXCEL_SERVICE_URL = process.env.REACT_APP_EXCEL_SERVICE_URL || 'http://excel-service.dentalpiyasa.com';
const API_URL = process.env.REACT_APP_API_URL;

const localApi = axios.create({
  baseURL: API_URL,
  timeout: 10000,
  withCredentials: false,
  headers: {
    "Content-Type": "application/json",
    'x-dental-vendor-token': localStorage.getItem('dp_vendor_token')
  }
});

const excelServiceApi = axios.create({
  baseURL: EXCEL_SERVICE_URL,
  timeout: 10000,
  withCredentials: false,
  headers: {
    "Content-Type": "application/json",
  }
})

module.exports = {
  localApi,
  excelServiceApi,
  API_URL,
  ENVIRONMENT_TAG
};
