import React, {useContext, useEffect, useState} from 'react';
import {Button, Icons, Input, RadioItem, Title, Tooltip} from "@dv/react/lib";
import {STEPS} from "@utils/constants/addCampaign.config";
import CampaignContext from "@contexts/Campaign/CampaignContext";
import {STEP_3_LABELS_BY_RULE_TEMPLATE} from "@utils/constants/campaign.config";

const Step2: React.FC<any> = ({styles, campaignForm, /*setCampaignForm,*/ setNavigation}) => {

    const campaignContext = useContext(CampaignContext);
    const {campaignConditionAmount, giftConditionAmount} = campaignContext.state;

    const [validation, setValidation] = useState(false);

    useEffect(() => {
        setNavigation(STEPS.step3);
    }, []);

    const onSubmit = (e) => {
        e.preventDefault();
        setNavigation(STEPS.step4);
    }

    const onPrev = (e) => {
        e.preventDefault();
        setNavigation(STEPS.step2);
    }

    useEffect(() => {
        if (campaignForm.campaignType === 'discount_by_quantity') {
            setValidation(campaignConditionAmount > 0 && giftConditionAmount > 0 && campaignConditionAmount > giftConditionAmount)
        } else if (campaignForm.campaignType === 'buy_together_discount') {
            setValidation(campaignConditionAmount > 0 && giftConditionAmount > 0)
        }
    }, [campaignConditionAmount, giftConditionAmount]);

    return <form className={styles.form} onSubmit={onSubmit}>
        <div className={styles.input_group}>
            <Title variant="header" title={STEP_3_LABELS_BY_RULE_TEMPLATE[campaignForm.campaignType]?.title}/>
        </div>

        {/*
        <div className={styles.input_group}>
            <div className={styles.input_group_block}>
                <RadioItem key={1} label={"Ödül Ürünü Y TL"} name={"discount_type_radios"}
                           checked={discountType == "basket_price"} setChecked={() => setDiscountType("basket_price")}/>
                <RadioItem key={2} label={"Ödül Ürününe Y TL İndirim"} name={"discount_type_radios"}
                           checked={discountType == "quantity_price"}
                           setChecked={() => setDiscountType("quantity_price")}/>
                <RadioItem key={3} label={"Ödül Ürününe %Y İndirim"} name={"discount_type_radios"}
                           checked={discountType == "percent_discount"}
                           setChecked={() => setDiscountType("percent_discount")}/>
            </div>
        </div>
        */}

        <div className={styles.input_group}>
            <div className={`${styles.input_group_block} ${styles.w50}`}>
                <Input
                    label={STEP_3_LABELS_BY_RULE_TEMPLATE[campaignForm.campaignType]?.campaignProductTitle}
                    type={"number"}
                    tooltip={<Tooltip placement={"right"}
                                      title="açıklama"><Icons.InfoIcon/></Tooltip>}
                    value={campaignConditionAmount}
                    onChange={(e) => {
                        const value = parseInt(e.target.value);
                        campaignContext.setConditionAmount('campaignConditionAmount', Number.isNaN(value) ? 0 : value);
                    }} fullWidth/>
                <Input
                    label={STEP_3_LABELS_BY_RULE_TEMPLATE[campaignForm.campaignType]?.giftProductTitle}
                    type={"number"}
                    tooltip={<Tooltip placement={"right"}
                                      title="açıklama"><Icons.InfoIcon/></Tooltip>}
                    value={giftConditionAmount}
                    onChange={(e) => {
                        const value = parseInt(e.target.value);
                        campaignContext.setConditionAmount('giftConditionAmount', Number.isNaN(value) ? 0 : value);
                    }} fullWidth/>
            </div>
            {
                giftConditionAmount >= campaignConditionAmount &&
              <span
                className={"validation-error-text"}>
                  {campaignForm.campaignType === 'discount_by_quantity' &&
                      STEP_3_LABELS_BY_RULE_TEMPLATE[campaignForm.campaignType]?.errorText
                  }
              </span>
            }
        </div>

        <div className={styles.actions}>
            <Button variant={"primary-gray"} type={"button"} onClick={onPrev}>Geri</Button>
            <Button variant={"primary"} type={"submit"} disabled={!validation}>Devam Et</Button>
        </div>
    </form>
}

export default Step2;
