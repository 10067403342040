import {useReducer} from 'react';
import CampaignContext from './CampaignContext';
import CampaignReducer from './CampaignReducer';
import {localApi} from '@utils/axiosInstances';
import {ReducerTypes, ICampaignContext} from '../types/Campaign';
import notify from "@antd/Notification";

const CampaignState = (props: any) => {

    const initialState: ICampaignContext = {
        baseCampaigns: null,
        campaigns: null,
        // PRODUCT LISTS
        campaignProductList: null,
        campaignProductSearchList: null,
        giftProductList: null,
        giftProductSearchList: null,
        campaignProductFilterText: null,
        selectedCampaignBaseProduct: null,
        campaignConditionAmount: 0,
        selectedGiftBaseProduct: null,
        giftConditionAmount: 0,
        giftProductFilterText: null,
        newCampaign: {
            name: '',
            startDate: null,
            endDate: null,
        },
        processStatus: null,
        loading: null,
        error: null
    };

    const [state, dispatch] = useReducer(CampaignReducer, initialState);

    // Get Base Campaigns

    const getBaseCampaigns = async () => {
        try {
            const res = await localApi.get('/vendor/base-campaigns');
            dispatch({
                type: ReducerTypes.GET_BASE_CAMPAIGNS,
                payload: res?.data
            });
        } catch (err) {
            console.log('base campaigns err', err);
            dispatch({
                type: ReducerTypes.PROCESS_FAIL,
                payload: err?.response?.data?.message
            });
        }
    }

    // Select Campaign
    const getCampaigns = async () => {
        try {
            await setLoading(true);
            const res = await localApi.get('/vendor/campaigns');
            dispatch({
                type: ReducerTypes.GET_CAMPAIGNS,
                payload: res?.data
            });
            await setLoading(false);
        } catch (err) {
            await setLoading(false);
            console.log('product not selected', err);
            dispatch({
                type: ReducerTypes.PROCESS_FAIL,
                payload: err?.response?.data?.message
            });
        }
    };

    // Fill Product Lists
    const fillProductLists = async (type, data) => dispatch({
        type: ReducerTypes.FILL_PRODUCT_LISTS,
        payload: {type, data}
    });

    const setFilterText = async (type, value) => dispatch({
        type: ReducerTypes.SET_FILTER_TEXT,
        payload: {type, value}
    });

    const filterProductList = async (type, text) => dispatch({
        type: ReducerTypes.FILTER_PRODUCT_LIST,
        payload: {type, text}
    });

    // Set New Campaign
    const setNewCampaign = async (key, value) => {
        try {
            dispatch({
                type: ReducerTypes.SET_NEW_CAMPAIGN,
                payload: {
                    key,
                    value
                }
            });
        } catch (err) {
            dispatch({
                type: ReducerTypes.PROCESS_FAIL,
                payload: err?.response?.data?.message
            });
        }
    }

    // Set New Campaign Dates
    const setNewCampaignDates = async (data) => {
        try {
            dispatch({
                type: ReducerTypes.SET_NEW_CAMPAIGN_DATES,
                payload: {
                    startDate: data.startDate,
                    endDate: data.endDate
                }
            });
        } catch (err) {
            dispatch({
                type: ReducerTypes.PROCESS_FAIL,
                payload: err?.response?.data?.message
            });
        }
    }

    // Set Selected Base Product Id
    const setSelectedBaseProduct = async (type, value) => dispatch({
        type: ReducerTypes.SET_SELECTED_BASE_PRODUCT,
        payload: {type, value}
    });

    // Set Condition Amount
    const setConditionAmount = async (type, value) => dispatch({
        type: ReducerTypes.SET_CONDITION_AMOUNT,
        payload: {type, value}
    });

    // Create Campaign
    const createCampaign = async (data, cb) => {
        try {
            const res = await localApi.post('/vendor/campaigns', data, {timeout: 30000});
            dispatch({
                type: ReducerTypes.CREATE_CAMPAIGN,
                payload: res?.data
            });

            if (cb) {
                cb();
            }

            notify({
                type: 'success',
                title: 'İşlem Başarılı',
                description: 'Kampanya başarılı bir şekilde eklendi.'
            });

        } catch (err) {
            console.log('campaign not created', err);

            notify({
                type: 'error',
                title: 'Bir hata ile karşılaşıldı',
                description: err?.response?.data?.detail
            });

            dispatch({
                type: ReducerTypes.PROCESS_FAIL,
                payload: err?.response?.data?.message
            });
        }
    }

    // Delete Campaign
    const deleteCampaign = async (campaignId, cb) => {
        try {
            await localApi.delete(`/vendor/campaigns/${campaignId}`, {timeout: 30000});
            if (cb) {
                cb();
            }
            notify({
                type: 'success',
                title: 'İşlem Başarılı',
                description: 'Kampanya başarılı bir şekilde silindi.'
            });

        } catch (err) {
            console.log('campaign not deleted', err);

            notify({
                type: 'error',
                title: 'Bir hata ile karşılaşıldı',
                description: err?.response?.data?.detail
            });

            dispatch({
                type: ReducerTypes.PROCESS_FAIL,
                payload: err?.response?.data?.message
            });
        }
    }


    // Clear Errors
    const clearErrors = () => dispatch({type: ReducerTypes.CLEAR_ERRORS});

    const setLoading = (isLoading) => dispatch({type: ReducerTypes.CLEAR_ERRORS, payload: isLoading});

    return (
        <CampaignContext.Provider
            value={{
                state: {...state},
                getBaseCampaigns,
                getCampaigns,
                fillProductLists,
                setSelectedBaseProduct,
                setConditionAmount,
                setFilterText,
                filterProductList,
                createCampaign,
                setNewCampaign,
                setNewCampaignDates,
                deleteCampaign,
                setLoading,
                clearErrors
            }}
        >
            {props.children}
        </CampaignContext.Provider>
    );
};

export default CampaignState;
