import React, {useState} from "react";
import {Pagination as AntPagination} from 'antd';

import '@dv/scss/lib/Pagination.antd.css';

export interface PaginationProps {
    total: number;
    defaultPageSize: number;
    defaultCurrent: number;
    setSizeChanger?: (pageSize: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({total, defaultPageSize, defaultCurrent, setSizeChanger}) => {

    function onShowSizeChange(_current, pageSize) {
        setSizeChanger(pageSize);
    }

    return (
        <AntPagination
            showSizeChanger
            onShowSizeChange={onShowSizeChange}
            total={total}
            showTotal={(total, range) => `Toplam ${total} satırdan ${range[0]}-${range[1]} gösteriliyor.`}
            defaultPageSize={defaultPageSize}
            defaultCurrent={defaultCurrent}
        />
    );
};

export default Pagination;
