export const ReducerTypes = {
    GET_DASHBOARD: 'GET_DASHBOARD',
    GET_DASHBOARD_SUCCESS: 'GET_DASHBOARD_SUCCESS',
    GET_DASHBOARD_FAIL: 'GET_DASHBOARD_FAIL',
    DASHBOARD_ERROR: 'DASHBOARD_ERROR',
    GET_PAYOUT_ORDERS: 'GET_PAYOUT_ORDERS',
    GET_PAYOUT_ORDERS_SUCCESS: 'GET_PAYOUT_ORDERS_SUCCESS',
    GET_PAYOUT_ORDERS_FAIL: 'GET_PAYOUT_ORDERS_FAIL',
    GET_PAYOUT_WEEK: 'GET_PAYOUT_WEEK',
    GET_PAYOUT_WEEK_SUCCESS: 'GET_PAYOUT_WEEK_SUCCESS',
    GET_PAYOUT_WEEK_FAIL: 'GET_PAYOUT_WEEK_FAIL',
    SET_SELECTED_PAYOUT_DATE: 'SET_SELECTED_PAYOUT_DATE',
    PAYOUT_ORDERS_ERROR: 'PAYOUT_ORDERS_ERROR',
    GET_PAYOUT_HISTORY: 'GET_PAYOUT_HISTORY',
    GET_PAYOUT_HISTORY_SUCCESS: 'GET_PAYOUT_HISTORY_SUCCESS',
    GET_PAYOUT_HISTORY_FAIL: 'GET_PAYOUT_HISTORY_FAIL',
    SEARCH_ORDER_ITEM_BY_TX_ID: 'SEARCH_ORDER_ITEM_BY_TX_ID',
    SEARCH_ORDER_ITEM_BY_TX_ID_SUCCESS: 'SEARCH_ORDER_ITEM_BY_TX_ID_SUCCESS',
    SEARCH_ORDER_ITEM_BY_TX_ID_FAIL: 'SEARCH_ORDER_ITEM_BY_TX_ID_FAIL',
    PAYOUT_HISTORY_ERROR: 'PAYOUT_HISTORY_ERROR',
    CLEAR_ERRORS: 'CLEAR_ERRORS'
}

export interface IPayoutContext {
    dashboard?: object;
    payoutHistory?: object[];
    payoutHistoryItems?: object[];
    payoutOrders?: object[];
    searchedPayoutOrders?: object[];
    payoutDates?: object[] | string[] | null | undefined;
    selectedPayoutDate?: string | null;
    selectedWeekPayout?: number | null;
    searchedOrderItem?: object | null;
    loading?: boolean;
    error?: any;
}

export type PayoutContextType = {
    state?: IPayoutContext | any;
    getDashboard: () => void;
    getPayoutOrders: () => void;
    getPayoutWeek: (date: string) => void;
    setSelectedPayoutDate: (date: string) => void;
    getPayoutHistory: (startDate?: string, endDate?: string) => void;
    getPayoutHistoryExcel: () => void;
    searchOrderItemByTxId: (paymentTransactionId: string) => void;
    clearErrors: () => void;
};


