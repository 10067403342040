import React, {useEffect, useState} from 'react';
import {Button, Icons, Input, RadioItem, Title} from "@dv/react/lib";
import {STEPS} from "@utils/constants/addCampaign.config";

const Step2: React.FC<any> = ({styles, /*campaignForm, setCampaignForm,*/ setNavigation}) => {

    useEffect(() => {
        setNavigation(STEPS.step3);
    }, []);

    const onSubmit = (e) => {
        e.preventDefault();
        setNavigation(STEPS.step4);
    }

    const onPrev = (e) => {
        e.preventDefault();
        setNavigation(STEPS.step2);
    }

    const [discountType, setDiscountType] = useState('basket_price');

    return <form className={styles.form} onSubmit={onSubmit}>
        <div className={styles.input_group}>
            <Title variant="header" title={"Ödül Ürün Belirleyin"}/>
        </div>

        <div className={styles.input_group}>
            <div className={styles.input_group_block}>
                <RadioItem key={1} label={"Ödül Ürünü Y TL"} name={"discount_type_radios"}
                           checked={discountType == "basket_price"} setChecked={() => setDiscountType("basket_price")}/>
                <RadioItem key={2} label={"Ödül Ürününe Y TL İndirim"} name={"discount_type_radios"}
                           checked={discountType == "quantity_price"}
                           setChecked={() => setDiscountType("quantity_price")}/>
                <RadioItem key={3} label={"Ödül Ürününe %Y İndirim"} name={"discount_type_radios"}
                           checked={discountType == "percent_discount"}
                           setChecked={() => setDiscountType("percent_discount")}/>
            </div>
        </div>

        <div className={styles.input_group}>
            <div className={`${styles.input_group_block} ${styles.w50}`}>
                <Input label={"ÜRÜN FİYATI (TL)"} type={"text"} value={""} onChange={() => {
                }} fullWidth/>
                <Input label={"SİPARİŞ LİMİTİ"} type={"text"} value={""} onChange={() => {
                }} fullWidth/>
            </div>
        </div>

        <div className={styles.actions}>
            <Button variant={"primary-gray"} type={"button"} onClick={onPrev}>Geri</Button>
            <Button variant={"primary"} type={"submit"}>Devam Et</Button>
        </div>
    </form>
}

export default Step2;
