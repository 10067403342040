import React, {useContext, useEffect, useRef, useState} from 'react';
import styles from '@dv/scss/lib/Modal.module.scss';
import Modal from "@organisms/Modal";
import {Button, Tooltip} from "@dv/react";
import UserContext from "@contexts/User/UserContext";
import {CURRENCIES_BY_TYPE, CURRENCY_SYMBOLS_BY_TYPE} from "@utils/constants/currencies.config";
import moment from "moment";

const UploadMyProductsConclusionModal = ({title, show, setShow, data}) => {

    const userContext = useContext(UserContext);

    const {uploadedProductsError} = userContext.state;

    const allItemsCheckboxRef = useRef();
    const [selectedItems, setSelectedItems] = useState([]);

    const onItemSelect = (productStockId) => {
        const hasItem = selectedItems.find(el => el === productStockId);
        if (hasItem) {
            setSelectedItems(selectedItems.filter(el => el !== productStockId));
        } else {
            setSelectedItems((prevState) => [...prevState, productStockId]);
        }
    }

    const onAllItemsSelect = () => {
        if (data?.length === selectedItems?.length) {
            setSelectedItems([]);
        } else {
            setSelectedItems(data?.map(el => el.o_productStockId));
        }
    }


    useEffect(() => {
        if (allItemsCheckboxRef?.current) {
            if (selectedItems?.length > 0 && selectedItems?.length < data?.length) {
                // @ts-ignore
                allItemsCheckboxRef.current.checked = false;
                // @ts-ignore
                allItemsCheckboxRef.current.indeterminate = true;
            } else if (selectedItems?.length === data?.length) {
                // @ts-ignore
                allItemsCheckboxRef.current.checked = true;
                // @ts-ignore
                allItemsCheckboxRef.current.indeterminate = false;
            } else if (selectedItems?.length === 0) {
                // @ts-ignore
                allItemsCheckboxRef.current.checked = false;
                // @ts-ignore
                allItemsCheckboxRef.current.indeterminate = false;
            }
        }

        console.log('data', data);
        console.log('_', selectedItems);
    }, [selectedItems]);

    const onApproved = async () => {

        console.log('D', data);

        const approvedData = await data?.filter(el => selectedItems.find(el2 => el2 === el.o_productStockId)).map(e => ({
            amount: e.n_amount,
            batchUnitPrice: e.n_batchUnitPrice || null,
            currencyType: e.n_currencyType || null,
            expiration: moment(e.n_expiration, 'MM/YYYY').utcOffset('08:00'),
            marketPrice: e.n_marketPrice,
            minBatchAmount: e.n_minBatchAmount || null,
            price: e.n_price,
            baseProductId: e?.baseProductId,
            productName: e.name,
            productStockId: e.n_productStockId,
            warehouseIncome: e.n_warehouseIncome,
        }));

        console.log('approved_data', approvedData);

        userContext.uploadProductsExcel(approvedData, () => {
            setShow(false);
            userContext.getProducts();
        });
    }

    useEffect(() => {
        console.log('uploadedProductsError', uploadedProductsError);
    }, [uploadedProductsError]);

    return (
        <Modal
            title={title}
            show={show}
            contentStyle={{
                maxInlineSize: '95vw',
                maxHeight: '90vh',
            }}
            onClose={() => setShow(false)}
            onCancel={() => setShow(false)}
            onConfirm={() => {
                setShow(false);
            }}
            footerComponent={
                <div className={styles.partial_cancel_modal_footer_section}>
                    <Button onClick={onApproved} disabled={selectedItems?.length === 0}>Seçili Ürünleri Yükle</Button>
                </div>
            }
        >
            <section className={styles.upload_product_conclusion_modal}>
                <table>
                    <tr>
                        <th><input ref={allItemsCheckboxRef} type={"checkbox"} onChange={onAllItemsSelect}/></th>
                        <th>Ürün Adı</th>
                        <th>Katalog No</th>
                        <th>Döviz Türü</th>
                        <th>Stok Miktarı</th>
                        <th className={styles.right_align}>Piyasa Satış Fiyatı</th>
                        <th className={styles.right_align}>DP Fiyatı</th>
                        <th className={styles.right_align}>DP Komisyon</th>
                        <th className={styles.right_align}>Net Depo Tutarı</th>
                        <th className={styles.right_align}>Çok Al Az Öde Miktarı</th>
                        <th className={styles.right_align}>Çok Al Az Öde Tutarı</th>
                        <th className={styles.right_align}>Ürün Miadı</th>
                    </tr>
                    {data?.map((item, i) => (
                        <tr key={i}>
                            <th id={item?.o_productStockId}><input type={"checkbox"}
                                                                   onChange={() => onItemSelect(item?.o_productStockId)}
                                                                   checked={!!selectedItems.find(el => el === item?.o_productStockId)}/>
                            </th>
                            <td>
                                {item?.name}
                                {(item?.variantTitle !== '' || item?.variantTitle !== ' ' || item?.variantTitle) && ' | '}
                                <b>{item?.variantTitle}</b>
                            </td>
                            <td>{item?.catalogNumber}</td>
                            <td>
                                {
                                    CURRENCIES_BY_TYPE[item?.o_currencyType] === CURRENCIES_BY_TYPE[item?.n_currencyType] ?
                                        <Tooltip title={"Değerde değişiklik yapılmamış"}>
                                            <span
                                                className={styles.same}>{CURRENCIES_BY_TYPE[item?.n_currencyType] || 'Bilinmeyen Döviz TÜrü'}</span>
                                        </Tooltip>
                                        :
                                        <>
                                            <Tooltip title={"Eski değer"}>
                                        <span
                                            className={styles.before}>{CURRENCIES_BY_TYPE[item?.o_currencyType]}</span>
                                            </Tooltip>
                                            <Tooltip title={"Yeni değer"}>
                                        <span
                                            className={styles.after}>{CURRENCIES_BY_TYPE[item?.n_currencyType] || 'Bilinmeyen Döviz TÜrü'}</span>
                                            </Tooltip>
                                        </>
                                }
                            </td>
                            <td>
                                {
                                    item?.o_amount === item?.n_amount ?
                                        <Tooltip title={"Değerde değişiklik yapılmamış"}>
                                            <span className={styles.same}>{item?.n_amount}</span>
                                        </Tooltip>
                                        :
                                        <>
                                            <Tooltip title={"Eski değer"}>
                                                <span className={styles.before}>{item?.o_amount}</span>
                                            </Tooltip>
                                            <Tooltip title={"Yeni değer"}>
                                                <span className={styles.after}>{item?.n_amount}</span>
                                            </Tooltip>
                                        </>
                                }
                            </td>
                            <td className={styles.right_align}>
                                {
                                    item?.o_marketPrice === item?.n_marketPrice ?
                                        <Tooltip title={"Değerde değişiklik yapılmamış"}>
                                            <span
                                                className={styles.same}>{item?.n_marketPrice} {CURRENCY_SYMBOLS_BY_TYPE[item?.n_currencyType]}</span>
                                        </Tooltip>
                                        :
                                        <>
                                            <Tooltip title={"Eski değer"}>
                                                <span
                                                    className={styles.before}>{item?.o_marketPrice} {CURRENCY_SYMBOLS_BY_TYPE[item?.n_currencyType]}</span>
                                            </Tooltip>
                                            <Tooltip title={"Yeni değer"}>
                                                <span
                                                    className={styles.after}>{item?.n_marketPrice} {CURRENCY_SYMBOLS_BY_TYPE[item?.n_currencyType]}</span>
                                            </Tooltip>
                                        </>
                                }
                            </td>
                            <td className={styles.right_align}>
                                {
                                    item?.o_price === item?.n_price ?
                                        <Tooltip title={"Değerde değişiklik yapılmamış"}>
                                            <span
                                                className={styles.same}>{item?.n_price} {CURRENCY_SYMBOLS_BY_TYPE[item?.n_currencyType]}</span>
                                        </Tooltip>
                                        :
                                        <>
                                            <Tooltip title={"Eski değer"}>
                                                <span
                                                    className={styles.before}>{item?.o_price} {CURRENCY_SYMBOLS_BY_TYPE[item?.n_currencyType]}</span>
                                            </Tooltip>
                                            <Tooltip title={"Yeni değer"}>
                                                <span
                                                    className={styles.after}>{item?.n_price} {CURRENCY_SYMBOLS_BY_TYPE[item?.n_currencyType]}</span>
                                            </Tooltip>
                                        </>
                                }
                            </td>
                            <td className={styles.right_align}>
                                <span className={styles.info}>{item?.dpCommission}</span>
                            </td>
                            <td className={styles.right_align}>
                                {/*
                                <span className={styles.before}>{item?.o_warehouseIncome} ₺</span>
                                <span className={styles.after}>{item?.n_warehouseIncome} ₺</span>
                                */}
                                <span className={styles.info}>{item?.n_warehouseIncome}</span>
                            </td>

                            <td className={styles.right_align}>
                                {
                                    item?.o_minBatchAmount === item?.n_minBatchAmount ?
                                        <Tooltip title={"Değerde değişiklik yapılmamış"}>
                                            <span className={styles.same}>{item?.n_minBatchAmount || '-'}</span>
                                        </Tooltip>
                                        :
                                        <>
                                            <Tooltip title={"Eski değer"}>
                                                <span className={styles.before}>{item?.o_minBatchAmount || '-'}</span>
                                            </Tooltip>
                                            <Tooltip title={"Yeni değer"}>
                                                <span className={styles.after}>{item?.n_minBatchAmount || '-'}</span>
                                            </Tooltip>
                                        </>
                                }
                            </td>
                            <td className={styles.right_align}>
                                {item?.o_batchUnitPrice === item?.n_batchUnitPrice ?
                                    <Tooltip title={"Değerde değişiklik yapılmamış"}>
                                        <span
                                            className={styles.same}>{item?.n_batchUnitPrice ? `${item?.n_batchUnitPrice} ${CURRENCY_SYMBOLS_BY_TYPE[item?.n_currencyType]}` : '-'}</span>
                                    </Tooltip>
                                    :
                                    <>
                                        <Tooltip title={"Eski değer"}>
                                        <span
                                            className={styles.before}>{item?.o_batchUnitPrice ? `${item?.o_batchUnitPrice} ${CURRENCY_SYMBOLS_BY_TYPE[item?.n_currencyType]}` : '-'}</span>
                                        </Tooltip>
                                        <Tooltip title={"Yeni değer"}>
                                        <span
                                            className={styles.after}>{item?.n_batchUnitPrice ? `${item?.n_batchUnitPrice} ${CURRENCY_SYMBOLS_BY_TYPE[item?.n_currencyType]}` : '-'}</span>
                                        </Tooltip>
                                    </>
                                }
                            </td>
                            <td className={styles.right_align}>
                                {
                                    moment(item?.o_expiration, 'MM/YYYY').format('MM/YYYY') === moment(item?.n_expiration, 'MM/YYYY').format('MM/YYYY') ?
                                        <Tooltip title={"Değerde değişiklik yapılmamış"}>
                                            <span
                                                className={styles.same}>{moment(item?.n_expiration, 'MM/YYYY').utcOffset('08:00').format('MMMM YYYY') || '-'}</span>
                                        </Tooltip>
                                        :
                                        <>
                                            <Tooltip title={"Eski değer"}>
                                                <span
                                                    className={styles.before}>{moment(item?.o_expiration, 'MM/YYYY').utcOffset('08:00').format('MMMM YYYY') || '-'}</span>
                                            </Tooltip>
                                            <Tooltip title={"Yeni değer"}>
                                                <span
                                                    className={styles.after}>{moment(item?.n_expiration, 'MM/YYYY').utcOffset('08:00').format('MMMM YYYY') || '-'}</span>
                                            </Tooltip>
                                        </>
                                }
                            </td>
                        </tr>
                    ))}
                </table>
            </section>

            {uploadedProductsError?.length > 0 &&
              <section className={styles.upload_product_conclusion_modal_error}>

                <h3 className={styles.error_title}>Yüklediğiniz excel verisinde hata mevcut. Lütfen aşağıdaki hataları
                  düzenleyip,
                  dosyanızı
                  tekrar yükleyiniz.</h3>

                  {uploadedProductsError.map((el, i) => (
                      <div key={i}>
                          <h4 className={styles.product_error_title}>{el[0]} ürünü için:</h4>
                          <ul>
                              {el[1]?.map((el, i) => (
                                  <li className={styles.product_error_description} key={i}>{el?.message}</li>
                              ))}
                          </ul>
                      </div>
                  ))}

              </section>
            }
        </Modal>
    )
}

export default UploadMyProductsConclusionModal;
