import React, {useState} from 'react';

import {Popconfirm as AntPopconfirm} from "antd";
import '@dv/scss/lib/Popconfirm.antd.css';

interface PopconfirmProps {
    placement?: any;
    children: any;
    title: any;
    loading?: any;
    onConfirm?: any;
    onCancel?: any;
}

const Popconfirm: React.FC<any> = (props: PopconfirmProps) => {
    const {placement, title, loading, onConfirm, onCancel} = props;

    const [open, setOpen] = useState(false);

    return (<AntPopconfirm
        placement={placement || "left"}
        title={title}
        popupVisible={open}
        visible={open}
        onConfirm={() => {
            setOpen(false);
            if (onConfirm) {
                onConfirm();
            }
        }}
        okButtonProps={{loading: loading}}
        onCancel={() => {
            setOpen(false);
            if (onCancel) {
                onCancel();
            }
        }}
    >
        {React.cloneElement(props.children, {onClick: () => setOpen(true)})}
    </AntPopconfirm>)
}

export default Popconfirm;
