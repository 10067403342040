import React, {useContext, useState} from 'react';
import styles from '@dv/scss/lib/Modal.module.scss';
import Modal from "@organisms/Modal";
import {Button, Checkbox} from "@dv/react";
import UserContext from "@contexts/User/UserContext";

const UploadProductInfoModal = ({title, show, setShow}) => {
    const userContext = useContext(UserContext);

    const [dontShowIntroAgain, setDontShowIntroAgain] = useState(localStorage.getItem('dont_show_upload_intro'));


    return (
        <Modal
            title={title}
            show={show}
            onClose={() => setShow(false)}
            onCancel={() => setShow(false)}
            onConfirm={() => {
                setShow(false);
            }}
            contentStyle={{
                maxInlineSize: '65vw',
                maxHeight: '80vh',
            }}
            footer={false}
        >
            <section className={styles.upload_product_info_modal}>
                <iframe height="500" src="https://www.youtube.com/embed/IiCPoN9GCJw?si=nsU0j77EGo79l0e1"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen></iframe>

                <div className={styles.upload_product_info_modal_action_section}>
                    <Checkbox id={'dontShowUploadIntro'} label={"Bu açıklamayı bir daha gösterme"} onChange={() => {
                        const dontShowUploadIntro = localStorage.getItem('dont_show_upload_intro');
                        if (dontShowUploadIntro === '1') {
                            localStorage.removeItem('dont_show_upload_intro');
                            setDontShowIntroAgain(null)
                        } else {
                            localStorage.setItem('dont_show_upload_intro', '1');
                            setDontShowIntroAgain('1');
                        }
                    }} checked={!!dontShowIntroAgain}/>
                    <Button onClick={() => {
                        userContext.getProductsExcel2();
                        setShow(false);
                    }}>Ürün Listesini İndir</Button>
                </div>
            </section>
        </Modal>
    )
}

export default UploadProductInfoModal;
