import {useReducer} from 'react';

import RefundOrderContext from './RefundOrderContext';
import RefundOrderReducer from './RefundOrderReducer';
import {ReducerTypes, IRefundOrderContext} from '../types/RefundOrder';

import {localApi} from '@utils/axiosInstances';
import notify from "@antd/Notification";

const RefundOrderState = (props: any) => {

    const initialState: IRefundOrderContext = {
        orders: {
            all: null,
            refundStarted: null,
            onCargo: null,
            receivedVendor: null,
            refundApproved: null,
            refundCanceled: null,
            refundCreatedShipment: null,
            refundDeliveredShipment: null,
            refundDelivered: null
        },
        selectedOrder: null,
        searchFilter: {
            status: null
        },
        filterInputs: {
            all: {
                status: '',
                orderNo: '',
                startDate: null,
                endDate: null
            },
            refundStarted: {
                status: 700,
                orderNo: '',
                startDate: null,
                endDate: null
            },
            onCargo: {
                status: 710,
                orderNo: '',
                startDate: null,
                endDate: null
            },
            receivedVendor: {
                status: 720,
                orderNo: '',
                startDate: null,
                endDate: null
            },
            refundApproved: {
                status: 730,
                orderNo: '',
                startDate: null,
                endDate: null
            },
            refundCanceled: {
                status: 740,
                orderNo: '',
                startDate: null,
                endDate: null
            },
            refundCreatedShipment: {
                status: 750,
                orderNo: '',
                startDate: null,
                endDate: null
            },
        },
        processStatus: null,
        loading: null,
        detailLoading: false,
        error: null
    };

    const [state, dispatch] = useReducer(RefundOrderReducer, initialState);

    // Get Canceled Orders
    const getRefundOrders = async () => {
        try {
            const res = await localApi.get(`/vendor/refund-orders`);
            dispatch({type: ReducerTypes.GET_REFUND_ORDERS, payload: res?.data});
        } catch (err) {
            dispatch({type: ReducerTypes.PROCESS_FAIL, payload: err?.response?.data?.message});
        }
    };

    // Get Canceled Orders
    const getRefundOrderDetail = async (orderId, orderRefundId, cb) => {
        try {
            await dispatch({type: ReducerTypes.SET_SELECTED_ORDER, payload: orderId});
            await setDetailLoading(true);
            const res = await localApi.get(`/vendor/refund-orders/${orderRefundId}/detail`);
            dispatch({type: ReducerTypes.GET_REFUND_ORDER_DETAIL});
            cb(res?.data);
        } catch (err) {
            dispatch({type: ReducerTypes.PROCESS_FAIL, payload: err?.response?.data?.message});
        }
    };

    // Search Canceled Orders
    const searchRefundOrders = async (type) => {
        try {
            const res = await localApi.post(`/vendor/refund-orders/search`, state.filterInputs[type]);
            dispatch({type: ReducerTypes.SEARCH_REFUND_ORDERS, payload: {data: res?.data, type}});
        } catch (err) {
            dispatch({type: ReducerTypes.PROCESS_FAIL, payload: err?.response?.data?.message});
        }
    };

    // Set Filter Inputs
    const setFilterInputs = async (inputType, key, value) => {
        try {
            dispatch({
                type: ReducerTypes.SET_FILTER_INPUTS,
                payload: {
                    [inputType]: {
                        ...state.filterInputs[`${inputType}`],
                        [key]: value
                    }
                }
            });
        } catch (err) {
            dispatch({type: ReducerTypes.PROCESS_FAIL, payload: err?.response?.data?.message});
        }
    };

    // Set Filter Input Dates
    const setFilterInputDates = async (inputType, values) => {
        try {
            dispatch({
                type: ReducerTypes.SET_FILTER_INPUTS,
                payload: {
                    [inputType]: {
                        ...state.filterInputs[inputType],
                        startDate: values[0],
                        endDate: values[1]
                    }
                }
            });
        } catch (err) {
            dispatch({type: ReducerTypes.PROCESS_FAIL, payload: err?.response?.data?.message});
        }
    };

    // Set Detail Loading
    const setDetailLoading = async (isLoading) => dispatch({type: ReducerTypes.SET_DETAIL_LOADING, payload: isLoading});

    // Set Order Status
    const setOrderStatus = async (orderId, status, cb) => {
        try {
            const res = await localApi.put(`/vendor/refund-orders/${orderId}/set-status`, {status});
            dispatch({type: ReducerTypes.SET_ORDER_STATUS, payload: {data: res?.data}});
            await getRefundOrders();

            notify({
                type: 'success',
                title: 'İşlem Başarılı',
                description: 'Sipariş durumu başarılı bir şekilde güncellendi.'
            });

            if (cb) {
                cb();
            }
        } catch (err) {

            notify({
                type: 'error',
                title: 'Bir hata ile karşılaşıldı',
                description: err?.response?.data?.detail
            });

            dispatch({type: ReducerTypes.PROCESS_FAIL, payload: err?.response?.data?.message});
        }
    };

    // Approve Payment Refund
    const setRefundOrderApprovement = async (formData, cb) => {
        try {
            // await localApi.put(`/vendor/refund-orders/${formData.orderId}/set-approvement`, {
            //     approvement: formData.approvement,
            //     orderStatusId: formData.orderStatusId || null
            // });
            await localApi.post(`/orders/refund-approvement`, {
                orderId: formData.orderId,
                orderRefundId: formData.orderRefundId,
                refundType: formData.refundType,
                approvement: formData.approvement
            });

            dispatch({type: ReducerTypes.SET_REFUND_ORDER_APPROVEMENT});
            await getRefundOrders();

            notify({
                type: 'success',
                title: 'İşlem Başarılı',
                description: `İade başarılı bir şekilde şekilde güncellendi. ${formData.approvement === 0 ? 'Dentalpiyasa onayından sonra siparişinizi Onaylananlar/Reddedilenler sekmesinde görebilirsiniz.' : ''}`
            });

            if (cb) {
                cb();
            }
        } catch (err) {

            notify({
                type: 'error',
                title: 'Bir hata ile karşılaşıldı',
                description: err?.response?.data?.detail
            });

            dispatch({type: ReducerTypes.PROCESS_FAIL, payload: err?.response?.data?.message});
        }
    };

    // Clear Errors
    const clearErrors = () => dispatch({type: ReducerTypes.CLEAR_ERRORS});

    return (
        <RefundOrderContext.Provider
            value={{
                state: {...state},
                getRefundOrders,
                getRefundOrderDetail,
                searchRefundOrders,
                setFilterInputs,
                setFilterInputDates,
                setOrderStatus,
                setDetailLoading,
                setRefundOrderApprovement,
                clearErrors
            }}
        >
            {props.children}
        </RefundOrderContext.Provider>
    );
};

export default RefundOrderState;
