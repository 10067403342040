import React, {Fragment, useEffect} from "react";
import Modal from "react-modal";

import {Icons, Button} from '@dv/react/lib';
import styles from '@dv/scss/lib/Modal.module.scss';

interface ModalProps {
    children?: any;
    title?: any;
    //description?: any;
    contentStyle?: any;
    inlineSize?: any;
    show?: any;
    //noFooter?: any;
    footer?: any;
    footerComponent?: any;
    //hasCloseIcon?: any;
    onClose?: any;
    onCancel?: any;
    onConfirm?: any;
}

const ModalComponent: React.FC<ModalProps> = ({
                                                  children,
                                                  title = "Modal Label",
                                                  //description,
                                                  contentStyle = {},
                                                  inlineSize,
                                                  show,
                                                  //noFooter,
                                                  footer = true,
                                                  footerComponent,
                                                  //hasCloseIcon,
                                                  onClose,
                                                  onCancel,
                                                  onConfirm
                                              }) => {

    const closeOnEscapeKeyDown = e => {
        if ((e.charCode || e.keyCode) === 27) {
            onClose();
        }
    };

    useEffect(() => {
        document.body.addEventListener("keydown", closeOnEscapeKeyDown);

        return () => document.body.removeEventListener("keydown", closeOnEscapeKeyDown);
    }, []);

    const modalStyles = (extraStyle) => ({
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: '12px',
        maxHeight: '75vh',
        inlineSize: '100%',
        maxInlineSize: '750px',
        overflow: 'auto',
        zIndex: '5 !important',
        ...extraStyle
    });

    return (
        <Modal
            isOpen={show}
            onRequestClose={onClose}
            ariaHideApp={false}
            overlayClassName={`${styles.modal} ${show ? styles["modal--show"] : null}`}
            contentLabel={title}
            style={{content: modalStyles({...contentStyle, inlineSize: inlineSize ? inlineSize : '100%'})}}
            closeTimeoutMS={250}
        >
            <div className={styles.modal_content}>
                <div className={styles.close_icon}>
                    {title}
                    <Icons.NotDoneIcon onClick={onClose}/>
                </div>
                <div className={styles.modal_body}>{children}</div>
                {footer && (
                    <div className={styles.modal_footer}>
                        {
                            footerComponent ? footerComponent : (
                                <Fragment>
                                    <Button variant="ghost inline cancel"
                                            onClick={onCancel ? onCancel : onClose}>Cancel</Button>
                                    <Button onClick={onConfirm}>Save</Button>
                                </Fragment>
                            )
                        }
                    </div>
                )}
            </div>
        </Modal>
    );
};

export default ModalComponent;
