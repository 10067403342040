import LoginForm from "@pages/Login/LoginForm";
import EmailVerificationForm from "@pages/Login/EmailVerificationForm";
import VendorVerification from "@pages/Login/VendorVerification";
import ForgotPasswordForm from "@pages/Login/ForgotPasswordForm";
import ForgotPasswordVerifyForm from "@pages/Login/ForgotPasswordVerifyForm";

const Forms = [
    {
        name: 'login',
        component: props => <LoginForm {...props}/>
    },
    {
        name: 'email-verification',
        component: props => <EmailVerificationForm {...props}/>
    },
    {
        name: 'vendor-verification',
        component: props => <VendorVerification {...props}/>
    },
    {
        name: 'forgot-password',
        component: props => <ForgotPasswordForm {...props}/>
    },
    {
        name: 'forgot-password-verify',
        component: props => <ForgotPasswordVerifyForm {...props}/>
    }
]
export default Forms;
