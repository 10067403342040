import React, {useContext, useEffect, useState} from 'react';
import {Button, Icons, Input, RadioItem, Title, Tooltip} from "@dv/react";
import {STEPS} from "@utils/constants/addCampaign.config";
import CampaignContext from "@contexts/Campaign/CampaignContext";
import RangePicker from "@antd/RangePicker";

const Step1: React.FC<any> = ({styles, campaignForm, setCampaignForm, setNavigation}) => {

    const campaignContext = useContext(CampaignContext);
    const newCampaign = campaignContext.state?.newCampaign;
    const [validation, setValidation] = useState(false);

    useEffect(() => {
        setNavigation(STEPS.step1);
        campaignContext.getBaseCampaigns();
    }, []);

    const onSubmit = (e) => {
        e.preventDefault();
        setNavigation(STEPS.step2);
    }

    useEffect(() => {
        setValidation((campaignForm.campaignType !== null && newCampaign.name !== ''/* && newCampaign.startDate !== null && newCampaign.endDate !== null*/));
    }, [campaignForm, newCampaign]);

    return <form className={styles.form} onSubmit={onSubmit}>
        <div className={styles.input_group}>
            <Title variant={"sub"} title={"KAMPANYA TÜRÜNÜ SEÇİNİZ"}/>
            <div className={styles.campaign_cards}>
                {/*
                <div
                    className={`${styles.campaign_card} ${campaignForm.campaignType === "percent_discount_on_basket" && styles.selected}`}>
                    <Icons.CampaignTypesIcon variant={"percentage"}/>
                    <div className={styles.content}>
                        <h4>Sepette Yüzde İndirim</h4>
                        <RadioItem key={1} name="campaign_type_radios"
                                   checked={campaignForm.campaignType === "percent_discount_on_basket"}
                                   setChecked={() => setCampaignForm({
                                       ...campaignForm,
                                       campaignType: 'percent_discount_on_basket'
                                   })}/>
                    </div>
                </div>
                */}
                {/*
                    <div
                        className={`${styles.campaign_card} ${campaignForm.campaignType === "basket_price_discount" && styles.selected}`}>
                        <Icons.CampaignTypesIcon variant={"amount"}/>
                        <div className={styles.content}>
                            <h4>Sepet Fiyat İndirimi</h4>
                            <RadioItem key={2} name="campaign_type_radios"
                                       checked={campaignForm.campaignType === "basket_price_discount"}
                                       setChecked={() => setCampaignForm({
                                           ...campaignForm,
                                           campaignType: 'basket_price_discount'
                                       })}/>
                        </div>
                    </div>
                */}
                <div
                    className={`${styles.campaign_card} ${campaignForm.campaignType === "discount_by_quantity" && styles.selected}`}>
                    <Icons.CampaignTypesIcon variant={"get_x_pay_m"}/>
                    <div className={styles.content}>
                        <h4>X AL Y ÖDE</h4>
                        <RadioItem key={2} name="campaign_type_radios"
                                   checked={campaignForm.campaignType === "discount_by_quantity"}
                                   setChecked={() => setCampaignForm({
                                       ...campaignForm,
                                       campaignType: 'discount_by_quantity'
                                   })}/>
                    </div>
                </div>
                <div
                    className={`${styles.campaign_card} ${campaignForm.campaignType === "buy_together_discount" && styles.selected}`}>
                    <Icons.CampaignTypesIcon variant={"buyTogether"}/>
                    <div className={styles.content}>
                        <h4>Birlikte Al</h4>
                        <RadioItem key={2} name="campaign_type_radios"
                                   checked={campaignForm.campaignType === "buy_together_discount"}
                                   setChecked={() => setCampaignForm({
                                       ...campaignForm,
                                       campaignType: 'buy_together_discount'
                                   })}/>
                    </div>
                </div>
            </div>
        </div>

        <div className={styles.input_group}>
            <Input label={"KAMPANYA İSMİ"} type={"text"} value={newCampaign.name} onChange={(e) => {

                campaignContext.setNewCampaign('name', e.target.value);

            }} placeholder={"Oluşturduğunuz kampanyaya ad verin"}/>
        </div>

        {/*
        <div className={styles.input_group}>
            <div className={styles.input_group_block}>

                <div className={`input_group`}>
                    <label>
                        BAŞLANGIÇ - BİTİŞ TARİHİ
                        <Tooltip
                            title="Siparişleri tarihe göre filtrelemenize yardımcı olur."><Icons.InfoIcon/></Tooltip>
                    </label>
                    <div className="">
                        <RangePicker onChange={(values, _dateString) => {
                            campaignContext.setNewCampaignDates({
                                startDate: values[0],
                                endDate: values[1]
                            });
                        }}/>
                    </div>
                </div>

            </div>
        </div>
        */}

        <div className={styles.actions}>
            <Button variant={"primary"} type={"submit"} disabled={!validation}>Devam Et</Button>
        </div>
    </form>
}

export default Step1;
