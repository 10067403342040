import {ReducerTypes} from '../types/RefundOrder';

const REFUND_ORDER_STATUS_TYPES = {
    700: 'refundStarted',
    710: 'onCargo',
    720: 'receivedVendor',
    730: 'refundApproved',
    740: 'refundCanceled',
    750: 'refundCreatedShipment',
    760: 'refundDeliveredShipment',
    770: 'refundDelivered'
};

export default (state, action) => {
    switch (action.type) {
        case ReducerTypes.GET_REFUND_ORDERS:
            return {
                ...state,
                orders: {
                    ...state.orders,
                    all: action.payload,
                    ...action.payload.reduce(
                        (accumulator, currentValue) => {
                            accumulator[REFUND_ORDER_STATUS_TYPES[currentValue.status]].push(currentValue);
                            return accumulator;
                        },
                        {
                            refundStarted: [],
                            onCargo: [],
                            receivedVendor: [],
                            refundApproved: [],
                            refundCanceled: [],
                            refundCreatedShipment: [],
                            refundDeliveredShipment: [],
                            refundDelivered: []
                        })
                },
                loading: false,
                error: null,
                processStatus: true
            };
        case ReducerTypes.SET_SELECTED_ORDER:
            return {
                ...state,
                selectedOrder: action.payload
            };
        case ReducerTypes.GET_REFUND_ORDER_DETAIL:
            return {
                ...state,
                detailLoading: false,
                error: null,
                processStatus: true
            };
        case ReducerTypes.SEARCH_REFUND_ORDERS:
            return {
                ...state,
                orders: {
                    ...state.orders,
                    [action.payload.type]: action.payload.data
                },
                loading: false,
                error: null,
                processStatus: true
            };
        case ReducerTypes.SET_FILTER_INPUTS:
            return {
                ...state,
                filterInputs: {
                    ...state.filterInputs,
                    ...action.payload
                }
            }
        case ReducerTypes.SET_REFUND_ORDER_APPROVEMENT:
            return {
                ...state
            }
        case ReducerTypes.PROCESS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload || null,
                processStatus: false,
            };
        case ReducerTypes.SET_DETAIL_LOADING:
            return {
                ...state,
                detailLoading: action.payload
            };
        case ReducerTypes.CLEAR_ERRORS:
            return {
                ...state,
                error: null
            };
        default:
            return state;
    }
};
