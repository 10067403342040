import {useContext, useEffect, useState, useRef} from 'react';
import styles from '@dv/scss/lib/Modal.module.scss';
import Modal from "@organisms/Modal";
import {Button, Icons} from "@dv/react";
import OrderContext from "@contexts/Order/OrderContext";
import makeID from "@utils/makeID";

const NewOrderCargoBoxesModal = ({title, show, setShow, orderStatusId, orderId, productsData, setDetail}) => {
    const orderContext = useContext(OrderContext);
    const [products, setProducts] = useState(productsData);
    const allItemsCheckboxRef = useRef();
    const [selectedItems, setSelectedItems] = useState([]);

    const [boxes, setBoxes] = useState([]);

    const onItemSelect = (orderItemId) => {
        const hasItem = selectedItems.find(el => el === orderItemId);
        if (hasItem) {
            setSelectedItems(selectedItems.filter(el => el !== orderItemId));
        } else {
            setSelectedItems((prevState) => [...prevState, orderItemId]);
        }
    }

    const onAllItemsSelect = () => {
        if (products?.length === selectedItems?.length) {
            setSelectedItems([]);
        } else {
            setSelectedItems(products?.filter(el => el.submittedForCancelApproval !== 1)?.map(el => el.orderItemId));
        }
    }

    useEffect(() => {
        if (allItemsCheckboxRef?.current) {
            if (selectedItems?.length > 0 && selectedItems?.length < products?.length) {
                // @ts-ignore
                allItemsCheckboxRef.current.checked = false;
                // @ts-ignore
                allItemsCheckboxRef.current.indeterminate = true;
            } else if (selectedItems?.length === products?.length) {
                // @ts-ignore
                allItemsCheckboxRef.current.checked = true;
                // @ts-ignore
                allItemsCheckboxRef.current.indeterminate = false;
            } else if (selectedItems?.length === 0) {
                // @ts-ignore
                allItemsCheckboxRef.current.checked = false;
                // @ts-ignore
                allItemsCheckboxRef.current.indeterminate = false;
            }
        }

        // console.log('products', products);
    }, [selectedItems]);

    const onApproved = () => {
        orderContext.submitForCancelApprovalBatch(orderStatusId, selectedItems, () => {
            orderContext.getOrderDetail(orderId, (orderDetail) => {
                setDetail(orderDetail);
                setShow(false);
            });
        })
    }

    const addNewBox = async () => {
        const boxID = await makeID(3);
        setBoxes(prevState => ([...prevState, {
            id: boxID,
            name: `Koli ${boxID}`,
            items: []
        }]))
    }

    const deleteBox = (id) => {
        const box = boxes.find(el => el.id == id);
        setBoxes(prevState => (prevState.filter(el => el.id !== id)));
        setProducts([...products, ...box?.items]);
    }

    // DRAG_DROP

    // useEffect(() => {
    //     console.log('BOXES', boxes);
    // }, [boxes]);

    const handleOnDrag = (e, orderItemId) => {
        console.log('_orderItemId', orderItemId);
        e.dataTransfer.setData("orderItemId", orderItemId);
    }

    const handleOnDrop = (e, boxId) => {
        const orderItemId = e.dataTransfer.getData("orderItemId");
        const item = products?.find(el => el.orderItemId == orderItemId);
        console.log('orderItemId', orderItemId, boxId);
        setBoxes(boxes.map(el => {
            return {
                ...el,
                items: el.id == boxId ? [...el.items, item] : [...el.items]
            }
        }));
        setProducts(products.filter(el => el.orderItemId != orderItemId));
    }

    const handleOnDragOver = (e, boxId) => {
        e.preventDefault();
        console.log('drag over', boxId);
    }


    return (
        <Modal
            title={title}
            show={show}
            onClose={() => setShow(false)}
            onCancel={() => setShow(false)}
            contentStyle={{
                maxInlineSize: '95vw',
                maxHeight: '90vh',
            }}
            footerComponent={
                <div className={styles.partial_cancel_modal_footer_section}>
                    <Button onClick={onApproved} disabled={selectedItems?.length === 0}>Seçili Ürünleri İptal
                        Et</Button>
                </div>
            }
        >
            <section className={styles.new_order_cargo_boxes_modal}>
                <h4 className={styles.subtitle}>
                    Kolilenecek Ürünler
                </h4>

                <table>
                    <tr>
                        <th><input ref={allItemsCheckboxRef} type={"checkbox"} onChange={onAllItemsSelect}/></th>
                        <th>Resim</th>
                        <th>Ürün Adı</th>
                        <th>Adet</th>
                        <th>Toplam Desi</th>
                        <th className={styles.right_align}>Birim Fiyat</th>
                        <th className={styles.right_align}>Toplam Fiyat</th>
                    </tr>
                    {products?.map((item, i) => (
                        <tr key={i} draggable onDragStart={(e) => handleOnDrag(e, item?.orderItemId)}>
                            <td id={item?.orderItemId}><input type={"checkbox"}
                                                              onChange={() => onItemSelect(item?.orderItemId)}
                                                              checked={!!selectedItems.find(el => el === item?.orderItemId)}
                                                              disabled={item?.submittedForCancelApproval === 1}/>
                            </td>
                            <td><img src={item?.thumbnail} height={50} width={50}/></td>
                            <td>{item.name}</td>
                            <td>{item.amount}</td>
                            <td>{item.amountDesi * item.amount}</td>
                            <td className={styles.right_align}>{item.amountPrice} ₺</td>
                            <td className={styles.right_align}>{item.totalPrice} ₺</td>
                        </tr>
                    ))}
                </table>

                <h4 className={styles.subtitle}>
                    Kolilenecek Ürünler
                    <span onClick={addNewBox}>+ Yeni Koli</span>
                </h4>


                <div className={styles.boxes}>
                    {
                        boxes?.map((el, i) => (
                            <div className={styles.box} key={i}>
                                <h5 className={styles.box_title}>
                                    {el?.name}
                                    <Icons.RedTrashIcon onClick={() => deleteBox(el?.id)}/>
                                </h5>
                                <div className={styles.box_content} onDrop={(e) => handleOnDrop(e, el?.id)}
                                     onDragOver={(e) => handleOnDragOver(e, el?.id)}>
                                    <table>
                                        <tr>
                                            <th><input ref={allItemsCheckboxRef} type={"checkbox"}
                                                       onChange={onAllItemsSelect}/></th>
                                            <th>Resim</th>
                                            <th>Ürün Adı</th>
                                            <th>Adet</th>
                                            <th>Toplam Desi</th>
                                            <th className={styles.right_align}>Birim Fiyat</th>
                                            <th className={styles.right_align}>Toplam Fiyat</th>
                                        </tr>
                                        {
                                            el?.items?.length > 0 ?
                                                (
                                                    el?.items?.map((item, i) => (
                                                        <tr key={i}>
                                                            <td id={item?.orderItemId}><input type={"checkbox"}
                                                                                              onChange={() => onItemSelect(item?.orderItemId)}
                                                                                              checked={!!selectedItems.find(el => el === item?.orderItemId)}
                                                                                              disabled={item?.submittedForCancelApproval === 1}/>
                                                            </td>
                                                            <td><img src={item?.thumbnail} height={50} width={50}/></td>
                                                            <td>{item.name}</td>
                                                            <td>{item.amount}</td>
                                                            <td>{item.amountDesi * item.amount}</td>
                                                            <td className={styles.right_align}>{item.amountPrice} ₺</td>
                                                            <td className={styles.right_align}>{item.totalPrice} ₺</td>
                                                        </tr>
                                                    ))
                                                )
                                                : <tr className={'flex jc-center'}>
                                                    Koli Boş
                                                </tr>
                                        }
                                    </table>
                                </div>
                            </div>
                        ))
                    }

                </div>

            </section>
        </Modal>
    )
}

export default NewOrderCargoBoxesModal;
