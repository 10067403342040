export const ARVATO_PRICE_TABLE_BY_DESI = [
  {
    minDesi: 1,
    maxDesi: 1,
    localPrice: 82.48
  },
  {
    minDesi: 2,
    maxDesi: 2,
    localPrice: 90.12
  },
  {
    minDesi: 3,
    maxDesi: 3,
    localPrice: 100.02
  },
  {
    minDesi: 4,
    maxDesi: 4,
    localPrice: 110.25
  },
  {
    minDesi: 5,
    maxDesi: 5,
    localPrice: 120.21
  },
  {
    minDesi: 6,
    maxDesi: 6,
    localPrice: 152.86
  },
  {
    minDesi: 7,
    maxDesi: 7,
    localPrice: 152.86
  },
  {
    minDesi: 8,
    maxDesi: 8,
    localPrice: 152.86
  },
  {
    minDesi: 9,
    maxDesi: 9,
    localPrice: 152.86
  },
  {
    minDesi: 10,
    maxDesi: 10,
    localPrice: 152.86
  },
  {
    minDesi: 11,
    maxDesi: 11,
    localPrice: 187.83
  },
  {
    minDesi: 12,
    maxDesi: 12,
    localPrice: 187.83
  },
  {
    minDesi: 13,
    maxDesi: 13,
    localPrice: 187.83
  },
  {
    minDesi: 14,
    maxDesi: 14,
    localPrice: 187.83
  },
  {
    minDesi: 15,
    maxDesi: 15,
    localPrice: 187.83
  },
  {
    minDesi: 16,
    maxDesi: 16,
    localPrice: 233.14
  },
  {
    minDesi: 17,
    maxDesi: 17,
    localPrice: 233.14
  },
  {
    minDesi: 18,
    maxDesi: 18,
    localPrice: 233.14
  },
  {
    minDesi: 19,
    maxDesi: 19,
    localPrice: 233.14
  },
  {
    minDesi: 20,
    maxDesi: 20,
    localPrice: 233.14
  },
  {
    minDesi: 21,
    maxDesi: 21,
    localPrice: 280.71
  },
  {
    minDesi: 22,
    maxDesi: 22,
    localPrice: 280.71
  },
  {
    minDesi: 23,
    maxDesi: 23,
    localPrice: 280.71
  },
  {
    minDesi: 24,
    maxDesi: 24,
    localPrice: 280.71
  },
  {
    minDesi: 25,
    maxDesi: 25,
    localPrice: 280.71
  },
  {
    minDesi: 26,
    maxDesi: 26,
    localPrice: 326.63
  },
  {
    minDesi: 27,
    maxDesi: 27,
    localPrice: 326.63
  },
  {
    minDesi: 28,
    maxDesi: 28,
    localPrice: 326.63
  },
  {
    minDesi: 29,
    maxDesi: 29,
    localPrice: 326.63
  },
  {
    minDesi: 30,
    maxDesi: 30,
    localPrice: 326.63
  },
  {
    minDesi: 31,
    maxDesi: 31,
    localPrice: 335.81
  },
  {
    minDesi: 32,
    maxDesi: 32,
    localPrice: 344.99
  },
  {
    minDesi: 33,
    maxDesi: 33,
    localPrice: 354.13
  },
  {
    minDesi: 34,
    maxDesi: 34,
    localPrice: 363.34
  },
  {
    minDesi: 35,
    maxDesi: 35,
    localPrice: 372.52
  },
  {
    minDesi: 36,
    maxDesi: 36,
    localPrice: 381.70
  },
  {
    minDesi: 37,
    maxDesi: 37,
    localPrice: 390.84
  },
  {
    minDesi: 38,
    maxDesi: 38,
    localPrice: 400.02
  },
  {
    minDesi: 39,
    maxDesi: 39,
    localPrice: 409.20
  },
  {
    minDesi: 40,
    maxDesi: 40,
    localPrice: 418.35
  },
  {
    minDesi: 41,
    maxDesi: 41,
    localPrice: 427.52
  },
  {
    minDesi: 42,
    maxDesi: 42,
    localPrice: 436.73
  },
  {
    minDesi: 43,
    maxDesi: 43,
    localPrice: 445.91
  },
  {
    minDesi: 44,
    maxDesi: 44,
    localPrice: 455.06
  },
  {
    minDesi: 45,
    maxDesi: 45,
    localPrice: 464.23
  },
  {
    minDesi: 46,
    maxDesi: 46,
    localPrice: 473.41
  },
  {
    minDesi: 47,
    maxDesi: 47,
    localPrice: 482.59
  },
  {
    minDesi: 48,
    maxDesi: 48,
    localPrice: 491.74
  },
  {
    minDesi: 49,
    maxDesi: 49,
    localPrice: 500.91
  },
  {
    minDesi: 50,
    maxDesi: 50,
    localPrice: 510.12
  },
  {
    minDesi: 51,
    maxDesi: 51,
    localPrice: 579.12
  },
  {
    minDesi: 52,
    maxDesi: 52,
    localPrice: 648.12
  },
];