import {useReducer} from 'react';

import {localApi} from '@utils/axiosInstances';

import PayoutContext from './PayoutContext';
import PayoutReducer from './PayoutReducer';
import {ReducerTypes, IPayoutContext} from '../types/Payout';
import getWednesdays from "@utils/getWednesdays";
import {utils} from "xlsx";
import XLSX from "sheetjs-style";
import moment from 'moment';

const PayoutState = (props: any) => {

    const initialState: IPayoutContext = {
        dashboard: null,
        payoutHistory: [],
        payoutHistoryItems: [],
        payoutOrders: [],
        searchedPayoutOrders: [],
        payoutDates: getWednesdays().map(el => ({
            date: el,
            selected: false
        })),
        selectedPayoutDate: null,
        selectedWeekPayout: null,
        searchedOrderItem: null,
        loading: false,
        error: null,
    };

    const [state, dispatch] = useReducer(PayoutReducer, initialState);

    // Get Payout Dashboard Data
    const getDashboard = async () => {
        try {
            await dispatch({type: ReducerTypes.GET_DASHBOARD});
            const res = await localApi.get('/vendor/payout-dashboard', {timeout: 60000});

            dispatch({
                type: ReducerTypes.GET_DASHBOARD_SUCCESS,
                payload: res?.data,
            });

        } catch (err) {
            dispatch({
                type: ReducerTypes.GET_DASHBOARD_FAIL,
                payload: err?.response?.data?.message
            });
        }
    };

    // Get Payout History
    const getPayoutOrders = async () => {
        try {
            await dispatch({type: ReducerTypes.GET_PAYOUT_ORDERS});

            const res = await localApi.post('/vendor/payout-orders/search', {
                orderNo: "",
                status: null,
                startDate: null,
                endDate: null
            });

            dispatch({
                type: ReducerTypes.GET_PAYOUT_ORDERS_SUCCESS,
                payload: res?.data
            });

        } catch (err) {
            dispatch({
                type: ReducerTypes.GET_PAYOUT_ORDERS_FAIL,
                payload: err?.response?.data?.message
            });
        }
    };

    const getPayoutWeek = async (date) => {
        try {
            await dispatch({type: ReducerTypes.GET_PAYOUT_WEEK});

            const res = await localApi.post(`/vendor/payout-history-by-week`, {
                date: date
            }, {timeout: 60000});

            dispatch({
                type: ReducerTypes.GET_PAYOUT_WEEK_SUCCESS,
                payload: res?.data
            });

        } catch (err) {
            dispatch({
                type: ReducerTypes.GET_PAYOUT_WEEK_FAIL,
                payload: err?.response?.data?.message
            });
        }
    }

    const setSelectedPayoutDate = async (date) => {
        dispatch({
            type: ReducerTypes.SET_SELECTED_PAYOUT_DATE,
            payload: date
        });
    }

    // Get Payout History
    const getPayoutHistory = async (startDate, endDate) => {
        try {
            await dispatch({type: ReducerTypes.GET_PAYOUT_HISTORY});

            const res = await localApi.post('/vendor/payout-history/search', {
                startDate: startDate || null,
                endDate: endDate || null
            }, {timeout: 60000});

            dispatch({
                type: ReducerTypes.GET_PAYOUT_HISTORY_SUCCESS,
                payload: res?.data
            });

        } catch (err) {
            dispatch({
                type: ReducerTypes.GET_PAYOUT_HISTORY_FAIL,
                payload: err?.response?.data?.message
            });
        }
    };

    const getPayoutHistoryExcel = async () => {

        console.log('abc', state?.payoutHistoryItems);
        const mappedData = state?.payoutHistoryItems?.map(el => ({
            "Sipariş No": el.orderId,
            "Ürün İsmi": `${el.productName} ${el.productVariantTitle}`,
            "Ödeme Tarihi": el.day,
            "Satış Tutarı": el.totalPrice,
            "DP İndirim": el.dpDiscountPrice || 0,
            "İade Tutarı": el.iyzicoSubmerchantRefundPrice || 0,
            "Kampanya Tutarı": el.campaignAppliedPrice || 0,
            "POS ve DP Komisyonu": el.commissionPrice,
            "Komisyon Yüzdesi": `%${el.commissionPercent}`,
            "Kargo Kesintisi": el.cargoPrice || 0,
            "DP Ödeme": el.dpDiscountPrice || 0,
            "Iyzico Ödeme": el.netPrice
        }));

        console.log('data', mappedData);

        // const dataLength = mappedData.length;

        const ws = utils.json_to_sheet(mappedData);

        /*for (let i = 2; i <= dataLength + 1; i++) {

            // Change cell A1 to the text "HI"
            ws[`J${i}`] = {
                // t: 's', // <-- t: 's' indicates the cell is a text cell
                // v: "=A2+B2" // <-- v holds the value
                f: `F${i}+G${i}`,
                l: {
                    Target: '#',
                    Tooltip: "Find us @ SheetJS.com!"
                }
            };

            // if (!ws[`B${i}`].c) ws[`B${i}`].c = [];
            // ws[`B${i}`].c.push({a: "SheetJS", t: "Bu ürün adıdır :-)"});


            if (!ws?.M2?.c) ws.M2.c = [];
            ws.M2.c.push({a: "SheetJS", t: "Bilgilendirme, not vs. istediğin her şey.", T: true});


            ws[`A${i}`].s = {
                fill: {fgColor: {rgb: "BDD7EE"}},
                alignment: {horizontal: "center", vertical: "center", wrapText: true},
                border: {
                    top: {style: "thin"},
                    bottom: {style: "thin"},
                    left: {style: "thin"},
                    right: {style: "thin"}
                }
            }

            ws[`L${i}`].t = 'd';
            ws[`L${i}`].z = "dd-mm-yyyy";
        }*/

        // ws['!cols'] = Array.from(new Array(12), () => ({wch: 20}));

        console.log('WS_', ws);

        const wb = utils.book_new();
        // utils.book_append_sheet(wb, ws, "Data");

        XLSX.utils.book_append_sheet(wb, ws, "Cari Ekstreleri | Ürünler");
        XLSX.writeFile(wb, `Depo Cari Ekstreleri - ${moment().format('DD-MM-YYYY_HH-mm')}.xlsx`);
    }

    // Search Order Item By Tx Id
    const searchOrderItemByTxId = async (paymentTransactionId) => {
        try {
            await dispatch({type: ReducerTypes.SEARCH_ORDER_ITEM_BY_TX_ID});

            const res = await localApi.post('/vendor/payout/search-order-item-by-tx-id', {
                paymentTransactionId: paymentTransactionId
            }, {timeout: 60000});

            dispatch({
                type: ReducerTypes.SEARCH_ORDER_ITEM_BY_TX_ID_SUCCESS,
                payload: res?.data
            });

        } catch (err) {
            dispatch({
                type: ReducerTypes.SEARCH_ORDER_ITEM_BY_TX_ID_FAIL,
                payload: err?.response?.data?.detail || err?.response?.data?.message
            });
        }
    }

    // Clear Errors
    const clearErrors = () => dispatch({type: ReducerTypes.CLEAR_ERRORS});

    return (
        <PayoutContext.Provider
            value={{
                state: {...state},
                getDashboard,
                getPayoutOrders,
                getPayoutWeek,
                setSelectedPayoutDate,
                getPayoutHistory,
                getPayoutHistoryExcel,
                clearErrors,
                searchOrderItemByTxId
            }}
        >
            {props.children}
        </PayoutContext.Provider>
    );
};

export default PayoutState;
