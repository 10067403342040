import {useReducer} from 'react';

import CanceledOrderContext from './CanceledOrderContext';
import CanceledOrderReducer from './CanceledOrderReducer';
import {ReducerTypes, ICanceledOrderContext} from '../types/CanceledOrder';

import {localApi} from '@utils/axiosInstances';
import notify from "@antd/Notification";

const CanceledOrderState = (props: any) => {

    const initialState: ICanceledOrderContext = {
        orders: {
            all: null,
            byCustomer: null,
            byVendor: null,
            byAdmin: null
        },
        selectedOrder: null,
        searchFilter: {
            status: null
        },
        filterInputs: {
            all: {
                canceledBy: '',
                orderNo: '',
                startDate: null,
                endDate: null
            },
            byCustomer: {
                canceledBy: 100,
                orderNo: '',
                startDate: null,
                endDate: null
            },
            byVendor: {
                canceledBy: 200,
                orderNo: '',
                startDate: null,
                endDate: null
            },
            byAdmin: {
                canceledBy: 300,
                orderNo: '',
                startDate: null,
                endDate: null
            }
        },
        processStatus: null,
        loading: null,
        detailLoading: false,
        error: null
    };

    const [state, dispatch] = useReducer(CanceledOrderReducer, initialState);

    // Get Canceled Orders
    const getCanceledOrders = async () => {
        try {
            const res = await localApi.get(`/vendor/canceled-orders`);
            dispatch({type: ReducerTypes.GET_CANCELED_ORDERS, payload: res?.data});
        } catch (err) {
            dispatch({type: ReducerTypes.PROCESS_FAIL, payload: err?.response?.data?.message});
        }
    };

    // Get Canceled Orders
    const getCanceledOrderDetail = async (orderId, cb) => {
        try {
            await dispatch({type: ReducerTypes.SET_SELECTED_ORDER, payload: orderId});
            await setDetailLoading(true);
            const res = await localApi.get(`/vendor/canceled-orders/${orderId}/detail`);
            dispatch({type: ReducerTypes.GET_CANCELED_ORDER_DETAIL});
            cb(res?.data);
        } catch (err) {
            dispatch({type: ReducerTypes.PROCESS_FAIL, payload: err?.response?.data?.message});
        }
    };

    // Search Canceled Orders
    const searchCanceledOrders = async (type) => {
        try {
            const res = await localApi.post(`/vendor/canceled-orders/search`, state.filterInputs[type]);
            dispatch({type: ReducerTypes.SEARCH_CANCELED_ORDERS, payload: {data: res?.data, type}});
        } catch (err) {
            dispatch({type: ReducerTypes.PROCESS_FAIL, payload: err?.response?.data?.message});
        }
    };

    // Set Filter Inputs
    const setFilterInputs = async (inputType, key, value) => {
        try {
            dispatch({
                type: ReducerTypes.SET_FILTER_INPUTS,
                payload: {
                    [inputType]: {
                        ...state.filterInputs[`${inputType}`],
                        [key]: value
                    }
                }
            });
        } catch (err) {
            dispatch({type: ReducerTypes.PROCESS_FAIL, payload: err?.response?.data?.message});
        }
    };

    // Set Filter Input Dates
    const setFilterInputDates = async (inputType, values) => {
        try {
            dispatch({
                type: ReducerTypes.SET_FILTER_INPUTS,
                payload: {
                    [inputType]: {
                        ...state.filterInputs[inputType],
                        startDate: values[0],
                        endDate: values[1]
                    }
                }
            });
        } catch (err) {
            dispatch({type: ReducerTypes.PROCESS_FAIL, payload: err?.response?.data?.message});
        }
    };

    // Set Detail Loading
    const setDetailLoading = async (isLoading) => dispatch({type: ReducerTypes.SET_DETAIL_LOADING, payload: isLoading});

    // Approve Payment Refund
    const approvePaymentRefund = async (formData, cb) => {
        try {
            await localApi.put(`/vendor/orders/${formData.orderStatusId}/cancel/approve-payment-refund`, {orderId: formData.orderId});
            dispatch({type: ReducerTypes.APPROVE_PAYMENT_REFUND});
            await getCanceledOrders();

            notify({
                type: 'success',
                title: 'İşlem Başarılı',
                description: 'Para iade süreci başarılı bir şekilde başlatıldı.'
            });

            if (cb) {
                cb();
            }
        } catch (err) {

            notify({
                type: 'error',
                title: 'Bir hata ile karşılaşıldı',
                description: err?.response?.data?.detail
            });

            dispatch({type: ReducerTypes.PROCESS_FAIL, payload: err?.response?.data?.message});
        }
    };

    // Clear Errors
    const clearErrors = () => dispatch({type: ReducerTypes.CLEAR_ERRORS});

    return (
        <CanceledOrderContext.Provider
            value={{
                state: {...state},
                getCanceledOrders,
                getCanceledOrderDetail,
                searchCanceledOrders,
                setFilterInputs,
                setFilterInputDates,
                setDetailLoading,
                approvePaymentRefund,
                clearErrors
            }}
        >
            {props.children}
        </CanceledOrderContext.Provider>
    );
};

export default CanceledOrderState;
