import {Fragment} from 'react';

const VendorRegisterAgreement = () => {

    return (
        <Fragment>

            <b>DENTALPİYASA “SATICI” ÜYELİK SÖZLEŞMESİ</b>
            <br/>
            <b> TARAFLAR</b>
            <br/>
            • İşbu Kurumsal Üyelik Sözleşmesi (Sözleşme) merkezi Archerson Köşkü, Şefikbey Sok. No:3 Kadıköy-İstanbul
            adresinde bulunan ve kayıtlı elektronik posta adresi dentalpiyasa@hs02.kep.tr olan Dentalpiyasa Elektronik
            Ticaret ve Bilişim Hizmetleri Anonim Şirketi (“Dentalpiyasa”) ile işbu Sözleşme'yi elektronik ortamda
            onaylayacak olan satıcı (Satıcı) arasında akdedilmiştir.
            <br/>
            • Dentalpiyasa ve Üye işbu Sözleşme'de münferiden "Taraf" ve müştereken "Taraflar" olarak anılacaklardır.
            <br/><br/>
            <b>TANIMLAR</b>
            <br/>
            <b>İşbu sözleşmede;</b>
            <br/>
            • Dentalpiyasa (Platform): sahibi olarak işletmekte olduğu mobil uygulamalar ve www.dentalpiyasa.com üst
            alan adlı çevrimiçi elektronik ticaret platformunda kurulu sanal pazaryerinde, Tıbbi Cihaz Satış, Reklam ve
            Tanıtım Yönetmeliği’nin 26. Maddesinde düzenlendiği şekilde; tıbbi cihazların tüketiciye hitap etmeden
            yalnızca üyelik esnasında sunulan belgelerle mesleki profesyoneller olduğu kanıtlanmış gerçek ve/veya tüzel
            kişileri alışveriş yapma amacıyla bir araya getirerek işbu sözleşme ile kapsamı belirlenen “Hizmetler”in,
            sunulduğu, Platform’da yer alan herhangi bir ürün veya hizmetin satıcısı konumunda olmayan, 6563 sayılı
            Elektronik Ticaretin Düzenlenmesi Hakkında Kanun uyarınca başkalarına ait iktisadi ve ticari faaliyetlerin
            yapılmasına elektronik ticaret ortamını sağlayan bir aracı hizmet sağlayıcıdır.
            <br/>
            • ALICI: Dentalpiyasa üzerinde SATICI tarafından satışa arz edilen mal ve/veya hizmetleri satın alan gerçek
            veya tüzel kişi A tipi ve B tipi özel ağız ve diş sağlığı merkezlerini, A tipi ADSM’ni: Mesleğini serbest
            icra etmek hak ve yetkisi olan diş hekimi/uzman veya birden fazla diş hekimi/uzman ve tabip ortaklığı veya
            en az % 51 hissesi diş hekimi/uzman ve tabip ortaklığı bulunan tüzel kişiler tarafından açılabilen ve ilgili
            yönetmelik ile belirlenen asgari şartları taşıyan sağlık kuruluşunu, A tipi poliklinik’leri: En az iki diş
            hekimi tarafından müştereken açılabilen ve işletilebilen, hizmet birimleri doğrudan birbiriyle bağlantılı
            olacak şekilde oluşturulan ve ilgili yönetmelik ile belirlenen asgari şartları taşıyan sağlık kuruluşunu,
            Diş Hekim’lerini: 11/4/1928 tarihli ve 1219 sayılı Tababet ve Şuabatı San’atlarının Tarzı İcrasına Dair
            Kanuna göre mesleğini serbest olarak icra etme yetkisine sahip serbest çalışan diş hekimlerini,
            Poliklinik’leri: A tipi ve B tipi özel ağız ve diş sağlığı polikliniklerini, Sağlık kuruluş’larını: Ağız ve
            diş sağlığı hizmeti verilen muayenehaneleri, poliklinikleri ve ADSM’leri, Uzman’ları: Diş hekimliği uzmanlık
            mevzuatına göre uzman diş hekimi unvanını haiz kişileri ifade eder.
            <br/>
            • SATICI: Dentalpiyasa üzerinde mal ve/veya hizmet satışa arz eden Tıbbi Cihaz Satış, Reklam ve Tanıtım
            Yönetmeliğinde tanımlanan şekliyle Satış Merkezleri’ni veya bahsedilen yönetmelik kapsamına girmeyen (kitap,
            tekstil, eğitim, seminer gibi) ürün ve/veya hizmetleri satışa arz eden tacir veya tüzel kişilikleri ifade
            eder. Tıbbi Cihaz Satış, Reklam ve Tanıtım Yönetmeliği kapsamına girmeyen satıcılar, bu yönetmelik sebebiyle
            sözleşmede öngörülen sorumlulukları yerine getirmekle yükümlü değillerdir. Ancak sözleşmenin mali ve
            platform’un işleyiş kurallarından sorumludurlar. Söz konusu yönetmelik kapsamına girmeyen satıcılar, hiçbir
            şekilde ilgili yönetmelik kapsamına giren ürünlerin satışını gerçekleştirmeyeceklerini, aksi durumda maddi,
            manevi, itibar kaybı ve yoksun kalınan kazanç da olmak üzere Dentalpiyasa’nın uğrayacağı tüm zararları ilk
            talepte herhangi bir kesinleşmiş ilama gerek olmaksızın tek seferde, gayri kabili rücu olmak üzere tazmin
            edeceklerini kabul beyan ve taahhüt ederler.
            <br/>

            • Hizmet: Alıcı ve Satıcı'nın işbu Sözleşme içerisinde tanımlı olan alım-satım işlemleri de dahil iş ve
            işlemlerini gerçekleştirmeleri ve/veya ürün/hizmetlerini yayınlamaları amacıyla Dentalpiyasa tarafından
            ücret karşılığında veya ücretsiz olarak Platformda sunulan tüm uygulamaları ifade eder.
            <br/>

            • Üye/Üyeler: İşbu sözleşme koşulları doğrultusunda “Dentalpiyasa”ya üye olan ve “Dentalpiyasa”da yer alan
            içerik, uygulama ve “Hizmetler"den yararlanan “ALICI” ya da “SATICI” olan gerçek ve tüzel kişileri ifade
            etmektedir.
            <br/><br/>

            <b>SÖZLEŞMENİN AMACI VE KAPSAMI</b>
            <br/>
            • İşbu Sözleşme’nin amacı, Platform üzerinde Dentalpiyasa ya da Dentalpiyasa’nın belirlediği iş ortağı
            tarafından sunulan Hizmetler’den, SATICI’nın yararlanmasına ilişkin şartların ve Taraflar'ın hak ve
            yükümlülüklerinin belirlenmesidir.
            <br/>
            • Dentalpiyasa, sahibi olarak işletmekte olduğu mobil uygulamalar ve www.dentalpiyasa.com üst alan adlı
            çevrimiçi elektronik ticaret platformunda kurulu sanal pazaryerinde, Tıbbi Cihaz Satış, Reklam ve Tanıtım
            Yönetmeliği’nin
            26. Maddesinde düzenlendiği şekilde; tıbbi cihazların tüketiciye hitap etmeden yalnızca üyelik esnasında
            sunulan belgelerle mesleki profesyoneller olduğu kanıtlanmış gerçek ve/veya tüzel kişileri alışveriş yapma
            amacıyla bir araya getirmektedir. Dentalpiyasa, Platform’da yer alan herhangi bir ürün veya hizmetin
            satıcısı konumunda olmayıp, 6563 sayılı Elektronik Ticaretin Düzenlenmesi Hakkında Kanun uyarınca
            başkalarına ait iktisadi ve ticari faaliyetlerin yapılmasına elektronik ticaret ortamını sağlayan bir aracı
            hizmet sağlayıcıdır.
            <br/>
            • Satıcı, Dentalpiyasa tarafından yönetilmekte olan mobil uygulamalar veya www.dentalpiyasa.com üst alan
            adlı elektronik ticaret platformuna üye olarak bu platformda kuracağı Sanal Mağazası ile halihazırda veri
            tabanında tanımlanmış ürünler üzerinden ilan vererek Satıcı sıfatıyla ürün ve/veya hizmetleri
            satabilecektir. Dentalpiyasa, Üyeler’in elektronik ticaret platformundan yararlanma karşılığında ödemeleri
            gerekebilecek komisyon oranlarını EK-3 Dentalpiyasa Güvenli Alışveriş Sistemi’nde belirtmiştir. Ancak bu
            komisyonlar üzerinde her zaman tek taraflı olarak Dentalpiyasa’nın değişiklik yapma hakkı saklıdır.
            Üyeler'in aralarındaki alım satım ilişkisi işbu Sözleşme’nin kapsamına girmemekte olup, Dentalpiyasan'nın
            Satıcı ve/veya Alıcı konumundaki Üyeler'in aralarındaki satım ilişkisinden kaynaklanan hiçbir sorumluluğu
            bulunmamaktadır.
            <br/>
            • İşbu Sözleşme kapsamında Üye, ticari ve mesleki amaçlarla alım satım işlemlerinde bulunduğunu,
            işlemlerinin 6502 sayılı Tüketicinin Korunması Hakkında Kanun'un kapsamına girmeyeceğini kabul ve taahhüt
            eder.
            <br/><br/>
            <b>SATICI’NIN GENEL HAK VE YÜKÜMLÜLÜKLERİ</b>
            <br/>
            • Üye olabilmek için, öncelikle EK-1'de bulunan “Üyelik İçin Gerekli Belge Listesi”nde yer alan belgelerin
            Dentalpiyasa adresine imzalı olarak yollanması, işbu Sözleşme'nin elektronik ortamda onaylanması ve Tıbbi
            Cihaz Satış, Reklam ve Tanıtım Yönetmeliğine ve sair diğer kanunlara uygun olarak düzenlenmiş, ilgili
            yönetmelikteki ifadesiyle “Yetki Belgesi’nin platforma yüklenmesi gerekmektedir. SATICI tarafından işbu
            belgeler eksiksiz olarak sunulmakla birlikte, Dentalpiyasa’nın SATICI’nın başvurusunu sebep göstermeksizin
            reddetme ve/veya ek belge talep etme hakkı saklıdır. Başvuru sırasında SATICI tarafından gerçeğe uygun
            olmayan bilgilerin verilmesi nedeniyle doğabilecek tüm zararlardan bizzat SATICI sorumlu olacaktır. Tüzel
            kişi SATICI adına işlem yapmaya yetkili kişiler kayıt esnasında ilgili formda bildirilecek ve bu kişilerin
            yapacağı her türlü işlem Dentalpiyasa nezdinde SATICI’yı temsilen yapılmış kabul edilecektir.
            <br/>

            • SATICI, platformda yapılacak her türlü işlemin sorumlu müdür tarafından yapıldığını/yapılacağını aksi
            durumda oluşabilecek herhangi bir maddi, manevi zarar ve cezai yaptırımın Dentalpiyasa’nın ilk talebi
            üzerine nakden ödeneceğini kabul beyan ve taahhüt eder.
            <br/>
            • SATICI’nın başvuru esnasında bildirmiş olduğu e-posta adresine ve sorumlu müdür cep telefonu numarasına,
            kimlik doğrulama amacıyla bir kod veya doğrulama bağlantısı gönderilecektir. SATICI tarafından 24 (yirmi
            dört) saat içerinde doğrulanmayan e-posta adresleri ve cep telefonu numaraları Dentalpiyasa tarafından kabul
            edilmeyebilir. SATICI, söz konusu doğrulamayı yapmasının ardından, ilgili e-posta adreslerinin ve cep
            telefonu numaralarının kendi ticari işletmesine özgülenmiş ticari işletmenin iletişim bilgileri olduğunu ve
            bunlardan yapacağı doğrulama ve iletişimin kendi sorumluluğunu doğuracağını kabul ve taahhüt eder.
            <br/>
            • SATICI, kendisine ait bilgiler başta olmak üzere temin etmiş olduğu belge ve bilgilerde meydana
            gelebilecek her türlü değişikliği, değişikliğin meydana gelmesinden itibaren en geç 15 (on beş) gün içinde,
            Dentalpiyasa’ ya bildirmekle ve sistemdeki kaydını buna uygun olarak güncellemekle yükümlüdür.
            <br/>
            • SATICI, Platform’a üye olduğu esnada bir kullanıcı adı ve şifre oluşturacak ve Platform’a erişmek ve/veya
            platform üzerindeki diğer işlemleri gerçekleştirebilmek için bu bilgileri kullanacaktır. Şifre ve kullanıcı
            adının güvenliği ve gizliliğini sağlama tamamen SATICI’nın sorumluluğunda olup, SATICI kendi profili
            üzerinden giriş yapılarak gerçekleştirilen tüm işlemlerden bizzat ve münhasıran sorumludur.
            <br/>
            • SATICI, Platform’u hukuka ve ahlaka aykırı olarak; bozma, değiştirme veya ters mühendislik yapma amacıyla;
            Platform’daki yorum ve puanlamaları Platform dışında yayınlamak gibi Platform dışı amaçlar için ve
            kullanılma amaçları dışında kullanarak; virüs veya Platform’a, Platform’un veri tabanına, Platform üzerinde
            yer alan herhangi bir içeriğe zarar verici herhangi başka bir teknoloji yayılmasına sebep verecek bir
            şekilde kullanmayacaktır. SATICI ayrıca, Platform tarafından belirlenmiş olan iletişimler ve teknik
            sistemler üzerinde makul olmayan veya orantısız derecede büyük yüklemeler yaratacak ya da teknik işleyişe
            zarar verecek faaliyetlerde bulunmayacak, Dentalpiyasa’nın önceden yazılı izni alınmaksızın Platform
            üzerinde otomatik program, robot, web crawler, örümcek, veri madenciliği (data mining) ve veri taraması
            (data crawling) gibi "screen scraping" yazılımları veya sistemleri kullanmayacak ve bu şekilde Platform’da
            yer alan herhangi bir içeriğin tamamını veya bir kısmını izinsiz kopyalamayacak, yayınlamayacak veya
            kullanmayacaktır.
            <br/>
            • SATICI, işlemlerini Platform’a teknik olarak hiçbir surette zarar vermeyecek şekilde yürütmekle
            yükümlüdür. SATICI, Platform’a sağlayacağı tüm bilgi, içerik, materyal ve sair içeriğin sisteme zarar
            verecek her türlü program, virüs, yazılım, lisansız ürün, truva atı vb. içermemesi için gerekli koruyucu
            yazılımları ve lisanslı ürünleri kullanmak da dâhil olmak üzere gerekli her türlü tedbiri aldığını sisteme
            robot veya otomatik giriş yöntemleriyle girmeyeceğini kabul ve taahhüt eder.
            <br/>
            • SATICI, 6698 sayılı Kişisel Verilerin Korunması Kanunu uyarınca kişisel verilerin hukuka aykırı olarak
            işlenmesini ve kişisel verilere hukuka aykırı olarak erişilmesini önlemek ve kişisel verilerin muhafazasını
            sağlamak amacıyla, uygun güvenlik düzeyini temin etmeye yönelik gerekli her türlü teknik, idari ve fiziki
            tedbiri almak zorundadır ve bu önlemlerin alınmasından sorumludur. Ek olarak, SATICI, Platform’u kendi veya
            üçüncü bir kişi adına, bir veri tabanı, kayıt veya rehber yaratmak, kontrol etmek, güncellemek, değiştirmek
            ve diğer üyelerin kişisel bilgilerine
            <br/>

            erişmek ve başka bir sisteme indirmek amacıyla kullanmamayı kabul, beyan ve taahhüt eder. SATICI, bu madde
            hükmünü ihlal etmesi halinde Sözleşme'nin Madde 7.5'inin uygulanacağını kabul eder.
            <br/>
            • SATICI, Platform’a kendisi tarafından iletilmiş olan her türlü ilan, içerik ve görsel üzerinde her zaman
            hak sahibi olmaya devam edecektir. Bununla birlikte SATICI, söz konusu içeriğin, reklam ve tanıtım için
            hazırlanan ekran görüntüleri ve her türlü görsel ve içerikte kullanılmasına (Sanal Mağaza'nın Platform’un
            reklamında ve dijital pazarlama faaliyetlerinde anlık olarak gösterilmesi gibi) yönelik olarak gerekli her
            türlü hak ve yetkiyi, süre ve yer kısıtlaması olmaksızın ve bedelsiz olarak Dentalpiyasa’ya tahsis ettiğini
            kabul, beyan ve taahhüt eder.
            <br/>
            • Dentalpiyasa, Üyeler arasındaki satış sözleşmesine taraf olmamakla birlikte, SATICI’yı malın teslimi
            karşılığında bedeli tahsil edememe; ALICI’yı ise bedelin ödenmesine karşılık, malın hiç veya istenilen
            nitelik ve şartlarda teslim edilmemesi riskine karşı koruma amaçlı “Dentalpiyasa Güvenli Alışveriş Sistemi”
            hizmeti sunmaktadır. Dentalpiyasa Güvenli Alışveriş Sistemini uyarınca, ALICI ürünü satın aldıktan sonra,
            ürün bedeli, SATICI adına ve hesabına Dentalpiyasa’nın anlaşmalı olduğu BDDK lisanslı ödeme kuruluşu
            tarafından tahsil edilir ve ilgili kuruluşun havuz hesabına aktarılır.
            <br/>
            • SATICI ürünü 2 iş günü içerisinde kargoya verip ALICI’ya ulaştırdıktan sonra, ALICI’nın ürünü teslim alıp,
            inceledikten ve SATICI’nın Tıbbi Cihaz Satış, Reklam ve Tanıtım Yönetmeliği kapsamındaki sorumluluklarını
            tamamen yerine getirdiğine ilişkin verdiği onayın akabinde, SATICI gerçekleştirdiği satış işlemi
            karşılığındaki ürün bedeline hak kazanır ve ödeme; lisanslı ödeme kuruluşunun havuz hesabından Satıcı'nın
            hesabına https://depo.dentalpiyasa.com/marketplace/marketplaceaccount/announcements/ linkinde yer alan
            tablolarda belirtilmiş olan süreler içinde Dentalpiyasa tarafından gerekli hizmet bedelleri mahsup edilerek
            aktarılması için talimat verilir. ALICI’nın ürünün kendine tesliminden itibaren 7 (yedi) gün içerisinde söz
            konusu onayı Platform üzerinden kendisine sunulan ilgili alandan vermemesi ve aksi bir iddiada da
            bulunmaması halinde, söz konusu onay ALICI tarafından verilmiş kabul edilerek ilgili talimat lisanslı ödeme
            kuruluşuna iletilir. SATICI, Dentalpiyasa Güvenli Alışveriş Sistemi’ne ilişkin tüm hüküm ve şartlara
            Platform üzerinden ve işbu sözleşmenin ayrılmaz bir parçası olan EK-3 Dentalpiyasa Güvenli Alışveriş Sistemi
            erişim sağlayarak bunları anladığını, bunlara uygun davranacağını ve Dentalpiyasa’yı söz konusu işlemleri
            gerçekleştirebilmesi için yetkilendirdiğini kabul ve taahhüt eder.
            <br/>
            • SATICI ve Dentalpiyasa, Satıcı yönetim panelindeki (Platform üzerindeki) Komisyon Oran Listesinde yer alan
            ücret ve ödeme koşullarında anlaşmışlardır. Platform’da ilan edilen ücret ve ödeme koşullarında Dentalpiyasa
            her zaman değişiklik yapabilecek olup, yapılacak değişikliklere ilişkin gerekli duyurular Dentalpiyasa
            tarafından SATICI’ya yapılacaktır. Ancak Satıcı yönetim panelindeki Komisyon Oran Listesinde belirtilmeyen
            ürün gruplarına ilişkin ücret ve ödeme koşullarını Dentalpiyasa tek taraflı olarak belirleyecek ve SATICI bu
            ücret ve ödeme koşullarını kendi Platform üzerinde görebilecektir. Bu ücret ve ödeme koşulları değişkenlik
            gösterebilecek olup SATICI’nın ürün satışı için ilgili Platform sayfasında fiyat girişi gerçekleştirdiği
            anda yayında olan ücret ve ödeme koşulları geçerli olacaktır. SATICI, Dentalpiyasa’nın sunduğu Hizmetlerden
            ancak “Dentalpiyasa’da beyan edilen söz konusu ücretleri yine Dentalpiyasa’da beyan edilen söz konusu ödeme
            koşulları ve araçları ile tam ve eksiksiz olarak ödemesi karşılığında faydalanabilecektir. Dentalpiyasa,
            Platform’da yer alan diğer Hizmetlerle ilgili olarak istediği takdirde ücret belirleyebilir; böyle bir
            durumda SATICI’nın ilgili Hizmetler’den faydalanması ücretin tam ve eksiksiz olarak ödenmesi koşuluna
            bağlıdır. Uygulamalarla ilgili ücretler, ödeme koşulları, ücretlerin yürürlük
            <br/>

            tarihleri Platform’un ilgili bölümlerinde ilan edilecektir. Dentalpiyasa tarafından her zaman Platform’da
            çeşitli Hizmetlerin ücretsiz veya indirimli olarak sunulması imkânı bulunmakta olup, Dentalpiyasa her zaman
            söz konusu indirimli veya ücretsiz Hizmet sunumundan tek taraflı olarak vazgeçip, Hizmet’i ücretli veya tam
            ücretli olarak sunmaya başlayabilir.
            <br/>
            • SATICI’nın belli bir ürünü Platform’daki satış fiyatı SATICI’nın kendi çevrimiçi satış kanalındaki satış
            fiyatından daha yüksek olamaz. SATICI’nın ürünü kendi çevrimiçi satış kanalında Platform’daki satış
            fiyatından daha düşük fiyatla sattığı tespit edildiğinde Dentalpiyasa’nın ürünün satışını durdurma hakkı
            saklıdır.
            <br/>
            • Üyeliği dondurulan, üyelikten uzaklaştırılan veya üyeliği süresiz olarak yasaklanan kişilerin başka bir
            kullanıcı hesabıyla üye olmaları yasaktır. Bu yasağa aykırılık halinde oluşabilecek zararlardan Dentalpiyasa
            sorumlu tutulamaz.
            <br/>
            • SATICI, satış akdinin tamamlanması ile ALICI ile arasında Mesafeli Satış Sözleşmesi’nin akdedilmiş
            sayılacağını (bu sözleşme içerisinde belirlenen SATICI konumunda olduğunu) kabul, beyan ve taahhüt eder.
            <br/>
            • SATICI, ALICI ile ticari ve mesleki amaçlarla yapacağı anlaşmalara Dentalpiyasa’nın taraf olmadığını kabul
            ve beyan eder. SATICI, ALICI ile yapacağı tüm anlaşmalara ilişkin ürünleri, kalitesinden, mevzuata ve
            özellikle Tıbbi Cihaz Satış, Reklam ve Tanıtım Yönetmeliği’ne uygunluğundan, garanti belgesi verilmesinden,
            faturalandırılmasından, cihazların imalatçısının belirlemiş olduğu ve uluslararası standartların öngördüğü
            koşullarda muhafaza edildiğinden, cihazların ilgili yönetmelikte belirtilen Kurum tarafından oluşturulan
            kayıt ve bilgi yönetim sistemine (ÜTS) kaydedildiğinden, kullanıcı eğitimi gerektiren cihazlar için cihazın
            teslimi sırasında ve sonrasında sağlık meslek mensuplarına, sağlık kurum ve kuruluşlarının bünyesinde tıbbi
            cihaz alanında çalışan teknik elemanlara veya gerçek kişilere cihazın kullanımı ile ilgili eğitimlerin
            verildiğini/verileceğini ve talep halinde bunları belgeleyeceğini ve sair diğer gereklilikleri sağladığını
            ve satış sonrası gereksinim duyulan servis vs. hizmetlerinden ve ürünlerin süresinde teslim edilmesinden
            bizzat ve münhasıran sorumlu olacağını, kabul, beyan ve taahhüt eder. SATICI, ALICI ile yaptığı anlaşmanın
            ürün/hizmet bedelinin kendi adına ve hesabına tahsili konusunda Dentalpiyasa’yı süresiz ve geri dönülemez
            şekilde yetkilendirdiğini kabul ve taahhüt eder.
            <br/>
            • SATICI, Dentalpiyasa ve Dentalpiyasa'nın yetkilendirdiği üçüncü kişiler tarafından, Platform, e-posta,
            çağrı merkezi, telefon, SMS (kısa mesaj) ve diğer her türlü iletişim kanalları aracılığıyla kendisine
            gönderilecek her türlü bilgilendirme, reklam, tanıtım ve pazarlama içerikli iletiye ve yapılacak diğer her
            türlü bildirime onay verdiğini kabul, beyan ve taahhüt eder.
            <br/>

            • SATICI, her türlü ürün satışı, reklam ve ilanın Palatfom’da yayınlanmasını sağlayacak gerekli teknik
            altyapının oluşturulması için Dentalpiyasa ile tam iş birliği içinde olmayı ve bunun için gerekli her türlü
            belgeyi temin etmeyi kabul ve taahhüt eder.
            <br/>
            • SATICI, kendisiyle Platform üzerinden iletişime geçen ALICI’yı Platform dışında alışveriş yapmaya teşvik
            etmeyecek ve kendisine bu yönde gelen teklifleri kabul etmeyecektir. SATICI, hiçbir surette Dentalpiyasa
            kampanya ve indirimlerini manipüle eden davranışlarda bulunmayacaktır. SATICI, ALICI’ya gönderilen ürün
            <br/>

            paketleri içerisinde kendi reklamını yapmak üzere herhangi bir broşür, el ilanı veya sipariş edilen ürün
            haricinde promosyon bir ürün eklemeyeceğini, aksi takdirde Dentalpiyasa’nın her türlü zararını
            karşılayacağını kabul, beyan ve taahhüt eder. Dentalpiyasa'nın, SATICI’nın işbu maddedeki yükümlülüklerine
            aykırı davranışlarda bulunduğunu tespit etmesi halinde; SATICI, ihlali oluşturan ürün toplam bedelinin veya
            Dentalpiyasa tarafından manipüle olduğu tespit edilen tutarın, sözleşmenin ihlalinden kaynaklanan cezai şart
            anlamında, 2 (iki) katı ceza ödeyecek veya bu tutar cari hesabından düşüleceğini, üyeliğinin silinerek,
            sözleşmesinin haklı olarak fesih de edilebileceğini veya e SATICI’ya ait mağazanın Dentalpiyasa tarafından
            süreli veya süresiz olarak kapatılabileceğini, tüm bu yaptırımların tamamen Dentalpiyasa’nın tasarrufunda
            olduğunu kabul beyan ve taahhüt eder.
            <br/>
            • SATICI, Platform üzerinde gerçekleştirdiği işlemlerde ve yazışmalarda, işbu SATICI Üyelik Sözleşmesi'nin
            hükümlerine, Platform’da belirtilen tüm koşullara, yürürlükteki mevzuata ve ahlak kurallarına uygun olarak
            hareket edeceğini kabul, beyan ve taahhüt eder. SATICI’nın Platform dâhilinde yaptığı işlem ve eylemlere
            ilişkin hukuki ve cezai sorumluluk kendisine aittir.
            <br/>
            • Dentalpiyasa, Platform’da sunulan hizmetleri ve içerikleri her zaman ve hiçbir bildirimde bulunmadan
            değiştirebilir; “Üye’lerin sisteme yükledikleri bilgileri ve içerikleri Kullanıcılar da dâhil olmak üzere
            üçüncü kişilerin erişimine kapatabilir ve silebilir, Üye’lerin Platform’a yüklediği içerikleri ve Üye’ler
            arasındaki Platform üzerinden gerçekleştirilen mesajlaşma da dâhil olmak üzere tüm iletişimi her zaman
            izleyebilir, kontrol edebilir ve bu iletişimi saklayabilir. SATICI, Platform üzerinden gerçekleştirdiği tüm
            iletişimi; Dentalpiyasa üzerinde gerçekleştirdiği faaliyetler doğrultusunda gerçekleştirdiğini ve bu
            iletişimi özel iletişim amacıyla veya başka amaçlarla kullanamayacağını kabul, beyan ve taahhüt eder.
            <br/>
            • SATICI, Sanal Mağazası'nın puanını yükseltmek için sahte işlem yapmayacağını, bu yükümlülüğün ihlali
            durumunda Dentalpiyasa'nın Sözleşme'yi derhal fesih hakkı doğacağını kabul ve taahhüt eder.
            <br/>
            • SATICI, Platform üzerinden ticari ve mesleki amaçlarla yapacağı satışlarda akdedilecek satış
            sözleşmelerinde Dentalpiyasa’nın taraf olmadığını; kendisine karşı sadece kendisinin yürürlükteki mevzuat
            kapsamında sorumlu olduğunu kabul ve beyan eder. SATICI, Sanal Mağazası'nda sergilenen ve satılan tüm
            ürünlerin kalitesinden, mevzuata uygunluğundan, garanti belgesi verilmesinden, faturalandırılmasından ve
            sair diğer gerekli belgenin tesliminden ve satış sonrası gereksinim duyulan servis vs. hizmetlerinden ve
            ürünlerin süresinde teslim edilmesinden bizzat ve münhasıran sorumlu olacaktır.
            <br/>
            • SATICI, Platform’da tüm Üyeler tarafından sağlanan içerikler de dâhil olmak üzere her türlü ilanın,
            bilginin, içeriğin ve görselin gerçekliğini, orijinalliğini, güvenliğini, doğruluğunu araştırma, bu içerik
            ve ilanların internet üzerinden teşhirinin hukuka uygun olup olmadığını tespit etme sorumluluğu
            bulunmadığını, söz konusu ilan ve içeriklerden ve bunlar sebebiyle ortaya çıkabilecek zararlardan dolayı
            Dentalpiyasa ve Dentalpiyasa çalışanlarının ve yöneticilerinin sorumluluğu bulunmadığını, bu hususlardaki
            her türlü sorumluluğun münhasıran SATICI’da olduğunu kabul, beyan ve taahhüt eder.
            <br/>
            • SATICI EK-2 Yasaklı Ürünler Listesi’nde belirtilen ürün ve hizmetleri, ikinci el ve son kullanım tarihi
            geçmiş ürünleri Platform üzerine ilan içeriği olarak yükleyemez, üçüncü kişilerle paylaşamaz, bu ürün ve
            hizmetlerin
            <br/>

            satışını veya pazarlamasını gerçekleştirmek amacıyla Platform üzerinden Üye’lere ve üçüncü kişilerle
            iletişime geçemez, başka internet sayfalarına veya iletişim bilgilerine yönlendirme yapamaz. SATICI
            tarafından bu ürünlerin satışının yapıldığı ve ALICI’ya gönderildiğinin tespit edilmesi halinde SATICI,
            kendisine ait mağazanın Dentalpiyasa tarafından süreli veya süresiz kapatılabileceğini kabul, beyan ve
            taahhüt eder.
            <br/>
            • SATICI, ALICI’nın siparişi kendisine geçmesini takiben, ilgili ürün için 2 iş günü içerisinde ürünü
            ALICI’ya teslimi için kargoya vermeyi kabul ve taahhüt eder. SATICI’nın, bahsi geçen süre içerisinde ürünü
            kargoya vermemesi; SATICI’nın ürünün stokta olmaması veya temin edilememesi sebebi ile sipariş için iptal
            talebi iletmesi veya sessiz kalması, SATICI’nın ürünü satın alan müşterinin iletişim bilgilerini kullanarak
            müşteriyle iletişime geçmesi ya da müşteri tarafından iade edilen ürünü kabul etmemesi, teslim almaması veya
            ürün faturasını göndermemesi durumunda, Dentalpiyasa tarafından her müşteriye ürünün bedeline göre,
            aşağıdaki tabloda bulunan tutarda hediye çeki tanımlanır ve bu miktar SATICI’nın hesabına Dentalpiyasa’ya
            ödenmek üzere borç kaydedilir.
            <br/>
            Ürün Bedeli
            <br/>
            0-20 TL
            <br/>
            21-200 TL
            <br/>
            201-500 TL
            <br/>
            500+ TL
            <br/>
            Çek Bedeli
            <br/>
            Ürün değeri kadar
            <br/>
            20 TL
            <br/>
            50 TL
            <br/>
            100 TL
            <br/>

            • SATICI kargo bedeli ve/veya bununla birlikte ortaya çıkacak tüm masraflar da dahil tüm bedeller kendisine
            ait olmak üzere, Dentalpiyasa’nın seçmiş olduğu kargo firmalarını ve sağladığı gönderi kodlarını kullanarak
            gönderi yapmayı ve gönderisi sırasında oluşan bedelleri doğrudan ilgili kargo firmasına ödemeyi kabul, beyan
            ve taahhüt eder. Dentalpiyasa tarafından sağlanan bu gönderi kodları ile SATICI tarafından yalnızca ilgili
            siparişlerin gönderisi sağlanacaktır. Dentalpiyasa tarafından oluşturulan gönderi kodları ve ürünler fiziken
            kargoya aynı anda teslim edilmelidir. Bu gönderi kodlarının ürün kargoya fiziken teslim edilmeksizin
            kullanılması/okutulması ve/veya SATICI tarafından ilgili sipariş için kullanılmadığının tespit edilmesi
            halinde SATICI’nın mağazası süresiz olarak askıya alınacaktır. Dentalpiyasa’nın işbu sebeple herhangi bir
            zarara uğraması/herhangi bir bedel ödemek zorunda kalması halinde SATICI, Dentalpiyasa’nın işbu sebeple
            uğradığı her türlü zarar/ziyanını herhangi bir ihtara gerek kalmaksızın ilk talepte derhal ödeyeceğini
            kabul, beyan ve taahhüt eder. Buna ek olarak Dentalpiyasa her müşteriye ürünün bedeline göre, yukarıdaki
            tabloda bulunan tutarda hediye çeki tanımlayacak olup, bu hediye çeki bedelleri için SATICI’ya rücu
            edilecektir.
            <br/>
            • SATICI stokunda bulunmayan ürünü satışa açmayacaktır. Dentalpiyasa, kendi ile tek taraflı olarak her zaman
            yukarıdaki tabloda yer alan hediye çeki tutarlarını değiştirme hak ve yetkisine sahiptir. Böyle bir durumda
            Dentalpiyasa, yeni tutarları SATICI’ya duyuracaktır.
            <br/>
            • Ayrıca SATICI, işbu Sözleşmenin 4.16. maddesinde belirtilen şekilde Platform’da sergilediği ve sattığı
            ürünler için garanti belgesi ve sair diğer gerekli belgeleri mevzuatın gerektirdiği şekilde ALICI’ya teslim
            etmemesi ve satış sonrası gereksinim duyulan servis vs. hizmetleri yerine getirmemesi halinde madde 4.16.‘da
            belirtilen her türlü zarar, idari para cezası ve benzeri bedellerin kendisine rücu edilmesine ek olarak
            Dentalpiyasa’nın ürünü iade alarak yukarıdaki tabloda belirtilen tutarlarda hediye çekini ALICI‘ya
            tanımlayacağını ve bu hediye çeki bedeli için SATICI’ya rücu edileceğini, buna ek olarak SATICI’nın
            mağazasının süresiz olarak askıya alınabileceğini kabul, beyan ve taahhüt eder.
            <br/>

            • SATICI’nın Platform üzerinde satmış olduğu ürünlere ilişkin tutarları Platform’a yanlış/hatalı girmesi
            halinde SATICI, ALICI’nın sipariş etmiş olduğu fiyat üzerinden ürünü ALICI’ya göndermekle yükümlüdür.
            <br/>
            • SATICI’nın söz konusu ürünü ALICI’nın sipariş ettiği fiyat üzerinden göndermekten imtina etmesi ve/veya
            ürünün SATICI tarafından temin edilememesi halinde ALICI tarafından sipariş edilen ürün, Dentalpiyasa
            tarafından Platform’da satış yapan ve ürünü ALICI’ya en kısa sürede teslim edebilecek başka bir SATICI’dan
            fiyat farkı gözetmeksizin temin edilecek ve aradaki fiyat farkı SATICI’ya fatura edilecektir. Ancak ALICI
            tarafından sipariş edilen ürünü Platform’da satan başka bir satıcı mevcut değil ise söz konusu ürünün piyasa
            satış bedeli Dentalpiyasa tarafından SATICI’ya fatura edilecektir.
            <br/>
            • SATICI, 2 iş günü içerisinde ürünü herhangi bir sebeple temin edemez ve/veya temin edemeyeceğini beyan
            ederse ALICI tarafından sipariş edilen ürün, Platform’da satış yapan ve ürünü ALICI’ya en kısa sürede teslim
            edebilecek başka bir SATICI’dan fiyat farkı gözetmeksizin temin edilecektir. Ancak ALICI tarafından sipariş
            edilen ürünü internet sitesinde satan başka bir satıcı mevcut değil ise ve söz konusu ürün ALICI’ya
            gönderilemeyecek ise böyle bir durumda ALICI’nın siparişi Dentalpiyasa tarafından iptal edilebilecektir.
            ALICI’nın siparişinin yukarıda açıklanan sebeplerle iptal edilmesi durumunda ALICI’ya madde 4.27’de
            belirtilen şekilde hediye çeki tanımlanabilecek olup söz konusu hediye çeki tutarı SATICI’ya fatura
            edilecektir. SATICI, söz konusu hediye çeki tutarım Dentalpiyasa’nın ilk talebinde derhal, nakden ve defaten
            ödeyeceğini veya Dentalpiyasa tarafından herhangi bir bildirimde bulunmaksızın cari hesabından mahsup
            edilebileceğini kabul ve taahhüt eder.
            <br/>
            • Başka bir SATICI’dan temin edilerek ALICI’ya gönderilen ürünün fiyatı SATICI tarafından temin
            edilemeyeceği belirtilen üründen daha fazla ise aradaki tutar SATICI’ya fatura edilecek olup SATICI,
            herhangi bir itiraz öne sürmeksizin söz konusu tutarı Dentalpiyasa’nın ilk talebinde derhal ve nakden
            ödeyecektir. Ancak başka bir Satıcıdan temin edilerek ALICI’ya gönderilen ürünün fiyatı SATICI tarafından
            temin edilemeyeceği belirtilen ürün tutarından daha az ise aradaki fiyat farkı Dentalpiyasa ekstra hizmet
            bedeli olarak SATICI’ya iade edilmez.
            <br/>
            • SATICI, ALICI’ya eksik ürün ve/veya eksik parçalı ürün veya ALICI’nın sipariş etmiş olduğu ürün haricinde
            başka/farklı bir ürünün gönderilmesi halinde Dentalpiyasa tarafından SATICI’ya barkod numarası verilerek 2
            (iki) iş günü içerisinde eksik ürünün/eksik parçanın ve/veya ALICI’nın sipariş etmiş olduğu doğru ürünün
            gönderimini yapmayı kabul, beyan ve taahhüt eder. Söz konusu gönderim SATICI tarafından belirtilen süre
            içerisinde yapılmaz ise ALICI tarafından sipariş edilen ürün, Platform’da satış yapan ve ürünü ALICI’ya en
            kısa sürede teslim edebilecek başka bir SATICI’dan fiyat farkı gözetmeksizin temin edilecektir. Ancak ALICI
            tarafından sipariş edilen ürünü Platform’da satan başka bir satıcı mevcut değil ise ve söz konusu ürün
            ALICI’ya gönderilemeyecek ise böyle bir durumda ALICI’nın siparişi Dentalpiyasa tarafından iptal
            edilebilecektir.
            <br/>
            • ALICI’nın siparişinin yukarıda açıklanan sebeplerle iptal edilmesi durumunda ALICI’ya madde 4.27’de
            belirtilen şekilde hediye çeki tanımlanabilecek olup söz konusu hediye çeki tutarı SATICI’ya fatura
            edilecektir. SATICI, söz konusu hediye çeki tutarım Dentalpiyasa’nın ilk talebinde derhal, nakden ve defaten
            ödeyeceğini veya Dentalpiyasa tarafından herhangi bir bildirimde bulunmaksızın cari hesabından mahsup
            edilebileceğini kabul ve taahhüt eder.
            <br/>

            • SATICI, Platform’da satışa çıkardığı tüm ürünlerin kargoya teslim tarihi itibariyle asgari miad
            sürelerinin en az 6 ay olduğunu kabul, beyan ve taahhüt etmektedir. Durumun ALICI tarafından aksi beyan
            edildiğinde, Dentalpiyasa, işbu sözleşmenin 4.35. maddesindeki gibi hareket edebileceği gibi madde 4.27’ye
            belirtilen şekilde hediye çeki tanımlaması da yapabilir ve/veya satıcıya ürün bedelinin 2 katı miktarında
            ceza bedeli ödenmesine karar verebilir. SATICI bu hükümleri kabul ettiğini, söz konusu yaptırımları uygulama
            insiyatifinin tamamen Dentalpiyasa’da olduğunu ve Dentalpiyasa’nın ilk talebinde derhal, nakden ve defaten
            belirtilen bedelleri ödeyeceğini veya Dentalpiyasa tarafından herhangi bir bildirimde bulunmaksızın cari
            hesabından mahsup edilebileceğini kabul ve taahhüt eder.
            <br/>
            • SATICI, satışa sunduğu tüm ürünlerin orijinal olduğunu taahhüt etmektedir. ALICI’nın aksi beyanları
            ve/veya Dentalpiyasa incelemesi sonucunda ürünlerin orijinal olmadıkları kanaati Dentalpiyasa’da oluşursa
            Dentalpiyasa’nın ilgili yargı mercilerine kendisi aleyhinde hemen bir başvuru yapacağını, herhangi bir uyarı
            yapılmaksızın üyelik kaydının silebileceğini, ALICI ve Dentalpiyasa’nın itibar tazminatı ve yoksun kalınan
            kazanç da olmak üzere tüm maddi ve manevi zararlarını SATICI’dan talep edilebileceğini, madde 4.35. ve/veya
            4.27. hükümlerinin uygulanabileceğini, SATICI bu hükümleri kabul ettiğini, söz konusu yaptırımları uygulama
            inisiyatifinin tamamen Dentalpiyasa’da olduğunu ve Dentalpiyasa’nın ilk talebinde derhal, nakden ve defaten
            belirtilen bedelleri ödeyeceğini veya Dentalpiyasa tarafından herhangi bir bildirimde bulunmaksızın cari
            hesabından mahsup edilebileceğini kabul ve taahhüt eder.
            <br/>
            <br/>
            • DENTALPİYASA’NIN HAK VE YÜKÜMLÜLÜKLERİ
            <br/>
            • Dentalpiyasa, 6698 sayılı Kişisel Verilerin Korunması Kanunu (“KVKK”) uyarınca veri sorumlusu sıfatıyla
            üyelik işlemi esnasında ve üyelik boyunca temin edilen Üye’ye ait kişisel ve ticari bilgileri
            kaydedebilecek, saklayabilecek, güncelleyebilecek, aşağıda belirtilen kişilere açıklayabilecek,
            devredebilecek, sınıflandırabilecek ve KVKK’da sayılan şekillerde işleyebilecektir. Dentalpiyasa’nın, bu
            bilgileri Hizmetler’in tam ve gereği gibi işletilmesi için gereken uygulamaların yürütülmesi, istatistiki
            değerlendirmelerin yapılması, kendisinin ve iş ortaklarının faaliyetlerinin, uygulamalarının reklamı,
            pazarlanması, ilanı ve sair amaçlarla kullanabileceği ve tahsilat yöntemleri kapsamında gerekli analizlerin
            yapılabilmesi adına bankalarla paylaşılması da dâhil olmak üzere belirtilen sebeplerle sınırlı olmak
            kaydıyla verileri üçüncü kişilere açıklayabileceği Üye tarafından kabul, beyan ve taahhüt edilmektedir. Bu
            anlamda Üye, kendine ait bilgilerin DP tarafından işbu madde hükmüne göre kullanılıp saklanmasına rıza
            göstermektedir.
            <br/>
            • Dentalpiyasa'nın Platform’da yer alan herhangi bir ürün veya hizmetin satıcısı konumunda olmaması ve 6563
            sayılı Elektronik Ticaretin Düzenlenmesi Hakkında Kanun uyarınca yalnızca "aracı hizmet sağlayıcı" ve 5651
            sayılı İnternet Ortamında Yapılan Yayınların Düzenlenmesi ve Bu Yayınlar Yoluyla İşlenen Suçlarla Mücadele
            Edilmesi Hakkında Kanun uyarınca "yer sağlayıcı" olması sebebiyle, Dentalpiyasa, Platform’da yer alan ve
            SATICI’lar tarafından yayınlanmamış hiçbir ürün, görsel, yazılı veya sair içeriğin gerçekliğinden,
            güvenilirliğinden, doğruluğundan ya da hukuka uygunluğundan sorumlu değildir ve söz konusu içeriklerin
            doğruluğunu kontrol etme gibi bir yükümlülüğü bulunmamaktadır. Dentalpiyasa, böyle bir yükümlülüğü
            olmamasına rağmen ve takdiri tamamen kendisine ait olmak üzere, söz konusu içerikleri dilediği zaman kontrol
            etme ve gerekli görürse erişime kapatma ve silme hakkına sahiptir. Böyle bir kapatma ya da silme durumunda
            Üye, her ne isim altında olursa olsun Dentalpiyasa’dan herhangi bir tazminat talep edemez. Platform’da
            yayınladığı herhangi bir görsel, yazılı ve sair

            içerikle Dentalpiyasa’nın ya da üçüncü kişilerin haklarını ihlal eden kişiler, Dentalpiyasa’ya ve/veya söz
            konusu üçüncü kişilere karşı sorumludur.
            <br/>
            • Dentalpiyasa, yürürlükteki mevzuat uyarınca kanunen yetkili makamların talebi halinde, Üye'nin
            Dentalpiyasa nezdinde bulunan bilgilerini söz konusu makamlarla paylaşabilecektir. Bu bilgiler Üyeler
            arasında çıkan uyuşmazlıklarda, tarafların yasal haklarını kullanabilmeleri amacıyla ve sadece bu kapsam ile
            sınırlı olmak üzere uyuşmazlığa konu işleme taraf olabilecek diğer Üyeler'e iletebilecektir.
            <br/>
            • Dentalpiyasa’nın bir işlemin çalıntı bir kredi kartı ile gerçekleştirildiğini veya işlemin suç işleme
            amacıyla bir araç olarak kullanıldığını tespit etmesi halinde, Dentalpiyasa söz konusu işlemi derhal iptal
            etme ve üyeliği askıya alma hakkına sahiptir. Dentalpiyasa’nın bu kişiler hakkında yargı mercilerine
            başvurma ve tazminat isteme hakkı saklıdır.
            <br/>
            • Dentalpiyasa'nın, Platform’daki ürün, hizmet veya kategorilerin tanıtımları için düzenlediği veya
            Dentalpiyasa’nın sözleşme ilişkisi içerisinde olduğu üçüncü kişiler tarafından düzenlenen kampanyaların ve
            bu kapsamda üretilen kuponların, hediye çeklerinin veya puanların kötüye kullanıldığının tespiti halinde,
            Dentalpiyasa söz konusu işlemi derhal iptal etme ve üyeliği askıya alma hakkına sahiptir. Dentalpiyasa’nın
            bu kişiler hakkında durumun niteliğine göre yargı mercilerine başvurma ve tazminat isteme hakkı saklıdır.
            <br/>
            • İşbu Sözleşme'nin Madde 7.5'i uyarınca, Dentalpiyasa, SATICI’nın işbu Sözleşme'yi ihlali nedeniyle maruz
            kaldığı her türlü zararı, kaybı, masrafı, gideri veya üçüncü kişilerin taleplerini takdiri tamamen
            kendisinde olmak üzere SATICI’dan bizzat tahsil edebilir.
            <br/>
            <br/>
            • MALİ HUSUSLAR
            <br/>
            • Üye, işbu Sözleşme'de belirtilen edimlerini tam ve gereği gibi yerine getirmesi koşuluna bağlı olarak
            Hizmetler’den faydalanabilecek ve SATICI sıfatına sahip Üye, Platform üzerinden gerçekleştireceği satış
            işlemleri karşılığındaki ücrete hak kazanacaktır. Dentalpiyasa tarafından SATICI’ya uygulanacak olan
            komisyon oranları ve vadeler https://depo.dentalpiyasa.com/marketplace/marketplaceaccount/announcements/
            linkinde yer alan tablolarda, ödeme koşulları ve sair mali şartlar ise Ek-3 Dentalpiyasa Güvenli Alışveriş
            Sistemi dokümanınada belirlenmiştir.
            <br/>
            • SATICI, 213 Sayılı Vergi Usul Kanunu Madde 230 ve 3065 sayılı Katma Değer Vergisi Kanunu Madde 8 uyarınca
            satılan ürün ve hizmetle ilgili fatura düzenlemek zorundadır. Bu anlamda SATICI, ALICI’ya gönderdiği ürün
            ve/veya hizmeti fatura keserek ALICI’ya veya ALICI’nın teslim için daha önceden belirlemiş olduğu kişiye
            teslim etmeyi kabul ve taahhüt eder.
            <br/>
            • SATICI bu sözleşme hükümlerine uygun davranmaması halinde Dentalpiyasa, bu sözleşmede öngörülen ürün veya
            hizmet bedelini SATICI’ya aktarmama hakkına sahip olacaktır.
            <br/>
            • İşbu Sözleşme'nin Madde 7.5'i uyarınca, Dentalpiyasa, SATICI’nın Sözleşme'yi ihlali nedeniyle maruz
            kaldığı her türlü zararı, kaybı, masrafı, gideri veya üçüncü kişilerin taleplerini takdiri tamamen
            kendisinde olmak üzere SATICI’nın cari hesabından mahsup edebilir veya SATICI’dan bizzat tahsil edilir.
            <br/><br/>

            <b> SÖZLEŞMENİN SÜRESİ, FESHİ VE TAZMİNAT</b>
            <br/>
            • İşbu Sözleşme, Üye tarafından elektronik ortamda onaylanmakla yürürlüğe girecek olup, yürürlük tarihinden
            itibaren belirsiz süreli olarak geçerlidir.
            <br/>
            • Taraflar, herhangi bir sebebe dayanmaksızın ve tazminat ödeme yükümlülüğü altında bulunmaksızın, yazılı
            olarak bildirimde bulunmak kaydıyla işbu Sözleşme'yi her zaman feshedebilir. Sözleşme’nin Dentalpiyasa
            tarafından bu suretle feshedilmesi halinde SATICI, fesih tarihine kadar Dentalpiyasa tarafından sunulan
            hizmete ilişkin bedelleri ödemeyi kabul ve taahhüt eder.
            <br/>
            • Taraflar'dan birinin iflas etmesi, iflasın ertelenmesi talebinde bulunması, acze düşmesi, konkordato
            teklifinde bulunması, kayyum atanması, tasfiyeye girmesi veya hakkında fesih davası açılması hallerinde,
            Sözleşme kendiliğinden sona erecektir.
            <br/>
            • Taraflar işbu Sözleşme ve eklerinde yer alan hüküm ve şartların her birinin esaslı olduğunu ve bu hüküm ve
            şartlara aykırılık halinde Dentalpiyasa’nın Sözleşme'yi derhal fesih hakkı bulunduğunu kabul eder. Bunların
            yanında Dentalpiyasa, Sözleşme'yi feshetme hakkını kullanmasa dahi, söz konusu aykırılık hallerinde
            Dentalpiyasa hizmetlerini geçici olarak durdurma ve askıya alma hakkına her zaman sahip olacaktır. Bununla
            beraber Dentalpiyasa, SATICI’nın işbu Sözleşme ve eklerinde yer alan hüküm ve şartlara aykırı davranması ve
            bu aykırılık sebebiyle Dentalpiyasa nezdinde zarar meydana gelmesi riskine karşılık SATICI’nın cari hesap
            alacağına bloke koyabilecektir.
            <br/>
            • SATICI’nın, işbu Sözleşme'nin hükümlerinden birini ihlal etmesi sebebiyle Dentalpiyasa’nın doğrudan veya
            dolaylı olarak uğramış olduğu her türlü zararı, kaybı, masrafı, gideri ve üçüncü kişilerin taleplerini
            Dentalpiyasa’nın herhangi bir makama başvurmasına gerek olmaksızın ve ilk talebi üzerine, tamamen ve derhal
            tazmin etmekle yükümlüdür. DentaPiyasa’nın söz konusu hakkını kullanması Dentalpiyasa’nın Sözleşme'yi
            feshedip feshetmemesinden bağımsızdır ve Dentalpiyasa Sözleşme'yi feshetmeksizin söz konusu tazminata hak
            kazanır.
            <br/>
            • SATICI, Dentalpiyasa aleyhine herhangi bir yasal takibin başlatılması veya kanunen yetkili makamlar
            tarafından herhangi bir bilgi veya belgenin talep edilmesi halinde gerekli tüm bilgi ve belgeleri derhal
            temin edecektir. Aksi halde SATICI, doğabilecek her türlü zarara karşı Dentalpiyasa’yı tazmin etme
            yükümlülüğü altında olacaktır. Ayrıca, Dentalpiyasa ’ya bir icra dairesinden ya da benzeri bir makamdan
            SATICI aleyhine düzenlenmiş bir haciz ihbarnamesi ve/veya benzeri yasal sorumluluk doğuran bir tebligat
            ulaşması halinde, Dentalpiyasa SATICI’nın cari hesabındaki bakiyeyi, yasal tebligatta belirtilen miktarla
            sınırlı olmak üzere ve SATICI’ya haber vermeksizin bloke edebilir.
            <br/>
            • İşbu Sözleşme’nin herhangi bir sebeple feshedilmesi halinde, fesih yalnızca Taraflar'ın ilişkisini
            etkileyecek; SATICI’nın ALICI’ya karşı mevcut olabilecek tüm sorumlulukları devam edecektir. Bununla beraber
            Dentalpiyasa, ALICI’dan gelebilecek iade talep hakkı süresi sona erene kadar, SATICI’nın cari hesap
            alacağına bloke koyabilecektir. Söz konusu durumda, iade süresinin sona ermesinin ardından, cari hesap
            alacağı üzerindeki blokaj kaldırılacaktır. Bu işlemler, Dentalpiyasa’nın hizmetlerine ilişkin olup, SATICI
            ile ALICI arasındaki hukuki ilişkinin kapsamında Dentalpiyasa’nın da olduğu anlamına gelmeyecektir.
            <br/><br/>

            <b>SÖZLEŞME DEĞİŞİKLİKLERİ VE BİLDİRİMLER</b>
            <br/>
            • SATICI, işbu Sözleşme ve eklerini elektronik ortamda onaylayarak, Sözleşme’ye taraf ve Sözleşme'nin
            hükümleriyle bağlı olacaktır. Dentalpiyasa, işbu Sözleşme ve eklerini tek taraflı olarak herhangi bir
            zamanda değiştirme hakkına sahiptir.
            <br/>
            • Söz konusu Sözleşme değişiklikleri Dentalpiyasa tarafından (i) SATICI’NIN Platform’a üye olurken
            bildirdiği elektronik posta adresine değişikliğe ilişkin bildirim gönderilmesi, (ii) SATICI ekranında
            değişikliğin duyurulması veya (iii) Platform’da değişikliğin yayınlanması yoluyla yapılabilir. İşbu
            Sözleşme’nin değişen hükümleri, ilan edildikleri tarihte geçerlilik kazanacak, geri kalan hükümler aynen
            yürürlükte kalarak hüküm ve sonuçlarını doğurmaya devam edecektir.
            <br/>
            • Dentalpiyasa Sözleşme'nin imza tarihinden sonra meydana gelen mevzuat değişiklikleri gereği yapmak zorunda
            olduğu Sözleşme değişikliklerini Üye'ye bildirmekle yükümlü değildir. Ayrıca Platform üzerinde sunulacak
            olan Hizmetler’in ve Dentalpiyasa tarafından uygulanan kampanyaların detayları, ürünlerin sergilenme
            süreleri, ek imkânlar ve teknik özellikler Dentalpiyasa tarafından önceden habersiz olarak
            değiştirilebilecektir. Dentalpiyasa tarafından yapılan değişiklikler yayınlanma tarihinde yürürlüğe girecek
            ve bağlayıcı olacaktır, Üye işbu değişikleri Platform’dan ve/veya SATICI ekranından takip etmek ve
            değişikliklerin gereklerini yerine getirmekle yükümlüdür.
            <br/>
            • SATICI, Dentalpiyasa tarafından herhangi bir ön bilgilendirme yapılmasını beklemeksizin Dentalpiyasa’nın
            Platform üzerinden duyurduğu ve uyguladığı kampanya ve indirimleri takip etmek, kampanya kapsamına dahil
            edilen ürün ve hizmetlerle ilgili olarak tüm kampanya koşullarını uygulamak ve tüm kampanya koşullarına
            vakıf olarak kendisine gelen ALICI taleplerini kampanyaya uygun olarak yönetmekle yükümlüdür.
            <br/>
            • Dentalpiyasa, SATICI’nın Platform’a üye olurken bildirdiği elektronik posta adresine elektronik posta veya
            fiziki posta adresine iadeli taahhütlü posta ile gönderilecek bildirimler aracılığıyla SATICI ile iletişim
            kuracaktır. SATICI’nın elektronik posta adresine elektronik posta gönderimi yazılı iletişim olarak kabul
            edilecek olup, SATICI elektronik posta adresini düzenli olarak kontrol etmekle yükümlüdür. SATICI’nın
            Platform’a üye olurken bildirdiği iletişim bilgilerinde değişiklik olması durumunda, derhal değişikliğin
            yazılı olarak Dentalpiyasa’ya bildirilmesi veya değişikliğe ilişkin kayıtların web-sitesi üzerinden
            güncellenmesi gerekmektedir. Aksi halde, Dentalpiyasa’nın SATICI’nın bildirmiş olduğu son iletişim adresine
            yaptığı bildirimler geçerli ve bağlayıcı kabul edilecektir.
            <br/>
            • Karşı Taraf'ın temerrüdüne, Sözleşme'nin feshine ve Sözleşme'den dönmeye ilişkin bildirimler hakkında da
            işbu Sözleşme'nin Madde 8.5'i uygulanacaktır.
            <br/><br/>
            <b>GİZLİLİK</b>
            <br/>
            • Taraflar’ın işbu Sözleşme’nin ifası sırasında birbirleri hakkında edindikleri her türlü ticari, finansal,
            patent ve know-how, bilgi, buluş, iş, metot, telif hakkı, marka, müşteri bilgileri ve sair tüm bilgiler
            “Gizli Bilgi” olarak kabul edilecek ve işbu Sözleşme süresince ve Sözleşme’nin sona ermesini takip eden 3
            (üç) yıl boyunca Gizli Bilgi olarak değerlendirilecektir.
            <br/>
            • Taraflar, Gizli Bilgi niteliğindeki bilgilerin gizliliğini sağlayacak ve Gizli Bilgi’nin sahibi Taraf'ın
            önceden yazılı onayının alınması veya söz konusu açıklamanın kanunlar veya bir mahkeme emri tarafından
            gerekli kılınması


            halleri haricinde, herhangi bir üçüncü tarafa açıklamayacaklardır. Dentalpiyasa’nın Gizli Bilgi'yi Üyeler
            arasında çıkan uyuşmazlıklarda diğer Taraf’ın yasal haklarını kullanabilmesi amacıyla ve sadece bu kapsamla
            sınırlı olmak üzere uyuşmazlığa taraf olabilecek diğer Taraf’a iletebilme hakkı saklıdır.
            <br/>
            • Gizli Bilgi ve 6698 sayılı Kişisel Verilen Korunması kapsamında kişisel verilere ilişkin düzenlemeler
            dentalpiyasa.com Gizlilik Politikası’nda detaylı olarak yer almakta olup, Gizlilik Politikası işbu
            Sözleşme'nin ayrılmaz bir parçasını teşkil eder ve işbu Sözleşme'yle birlikte göz önüne alınır ve
            yorumlanır.
            <br/><br/>
            <b>FİKRİ MÜLKİYET HAKLARI</b>
            <br/>
            • dentalpiyasa markası ve logosu, dentalpiyasa mobil uygulamasının ve Web-sitesi'nin tasarımı, yazılımı,
            alan adı ve bunlara ilişkin olarak Dentalpiyasa tarafından oluşturulan her türlü marka, tasarım, logo,
            ticari takdim şekli, slogan ve diğer tüm içeriğin her türlü fikri mülkiyet hakkı Dentalpiyasa’nın
            mülkiyetindedir. Üye, Dentalpiyasa’nın veya bağlı şirketlerinin mülkiyetine tabi fikri mülkiyet haklarını
            Dentalpiyasa’nın izni olmaksızın kullanamaz, paylaşamaz, dağıtamaz, sergileyemez, çoğaltamaz ve bunlardan
            türemiş çalışmalar yapamaz. Üye, dentalpiyasa mobil uygulamasının ve Web-sitesi'nin bütününü veya bir
            kısmını başka bir internet sitesinde izinsiz olarak kullanılamaz.
            <br/>
            • Üye, Platform aracılığıyla gerçekleştirdiği tüm faaliyetlerinde Dentalpiyasa ve üçüncü kişilerin fikri
            mülkiyet haklarını ihlal etmeyeceğini beyan ve taahhüt etmektedir. Dentalpiyasa, üçüncü kişilerin veya
            Dentalpiyasa’nın fikri mülkiyet haklarını ihlal edecek nitelikte faaliyet gösteren Üye’nin sözleşmesini
            feshetme hakkını saklı tutmaktadır. Üye, bu madde hükmünü ihlal etmesi halinde Sözleşme'nin Madde 7.5'inin
            uygulanacağını kabul eder.
            <br/><br/>
            <b>MÜCBİR SEBEP</b>
            <br/>
            • Ayaklanma, ambargo, devlet müdahalesi, isyan, işgal, savaş, seferberlik, grev, lokavt, iş eylemleri veya
            boykotlar dahil olmak üzere işçi-işveren anlaşmazlıkları, siber saldırı, iletişim sorunları, altyapı ve
            internet arızaları, sisteme ilişkin iyileştirme veya yenileştirme çalışmaları ve bu sebeple meydana
            gelebilecek arızalar, elektrik kesintisi, yangın, patlama, fırtına, sel, deprem, göç, salgın veya diğer bir
            doğal felaket veya Taraflar’dan birinin kontrolü dışında gerçekleşen, kusurundan kaynaklanmayan ve makul
            olarak öngörülemeyecek diğer olaylar (Mücbir Sebep) Taraflar'dan herhangi birinin (Etkilenen Taraf) bu
            Sözleşme'den doğan yükümlülüklerini ifa etmesini engeller veya geciktirirse, etkilenen Taraf, diğer Taraf'a
            yazılı olarak derhal bu Mücbir Sebep'i, bununla ilgili tüm detayları ve delilleri ve bu durumun muhtemel
            süresini bildirmekle yükümlüdür. Etkilenen Taraf Mücbir Sebep'in sonuçlarını engellemek veya hafifletmek
            için makul çabayı göstermekle yükümlüdür.
            <br/>
            • Etkilenen Taraf, bu maddede yer alan yükümlülüklerine uymuş olması kaydıyla, ifası Mücbir Sebep sonucunda
            engellenen veya geciken yükümlülüklerinden dolayı sorumlu tutulamaz ve bu durum işbu Sözleşme’nin bir ihlali
            olarak kabul edilmeyerek, ifa için gerekli olan süre, söz konusu gecikmenin süresine eşit bir süre ile
            uzatılır.
            <br/><br/>
            <b>UYGULANACAK HUKUK VE UYUŞMAZLIKLARIN ÇÖZÜMÜ</b>
            <br/>
            • İşbu sözleşme Türkiye Cumhuriyeti kanunlarına tabi olacaktır.
            <br/>

            • İşbu Sözleşme’den kaynaklanan veya işbu Sözleşme ile bağlantılı olan her türlü ihtilaf, İstanbul Anadolu
            Mahkemeleri ve İcra Müdürlükleri'nin münhasır yargı yetkisinde olacaktır.
            <br/><br/>
            <b>MUHTELİF HÜKÜMLER</b>
            <br/>
            • Delil Sözleşmesi
            <br/>
            • SATICI, işbu Sözleşme’den doğabilecek ihtilaflarda Taraflar'ın resmi defter ve ticari kayıtları ile
            Dentalpiyasa veri tabanında, sunucularında tutulan e-arşiv kayıtlarının, elektronik bilgilerin ve bilgisayar
            kayıtlarının, bağlayıcı, kesin ve münhasır delil teşkil edeceğini ve bu maddenin 6100 sayılı Hukuk
            Muhakemeleri Kanunu’nun 193. maddesi anlamında delil sözleşmesi niteliğinde olduğunu kabul eder.
            <br/>
            • Sözleşmenin Bütünlüğü
            <br/>
            • İşbu Sözleşme'nin ekleri, Sözleşme'nin ayrılmaz bir parçasını teşkil eder ve Sözleşme'yle birlikte göz
            önüne alınır ve yorumlanır. İşbu Sözleşme, Taraflar arasındaki anlaşmanın tamamını oluşturmakta ve
            Sözleşme’nin konusuna ilişkin olarak Taraflar arasındaki sözlü veya yazılı önceki tüm taslakları,
            anlaşmaları, düzenlemeleri ve mutabakatları ilga etmektedir.
            <br/>
            • Bölünebilirlik
            <br/>
            • İşbu Sözleşme’nin herhangi bir hükmünün yetkili herhangi bir mahkeme, tahkim heyeti veya idari makam
            tarafından tümüyle veya kısmen geçersiz veya uygulanamaz olduğu veya makul olmadığına karar verilmesi
            halinde, söz konusu geçersizlik, uygulanamazlık veya makul olmama ölçüsünde işbu Sözleşme bölünebilir olarak
            kabul edilecek ve diğer hükümler tümüyle yürürlükte kalmaya devam edecektir.
            <br/>
            • Sözleşmenin Devri
            <br/>
            • İş Sahibi, Dentalpiyasa’nın önceden yazılı onayını almaksızın ve mevzuata uygun olmaksızın işbu
            Sözleşme’deki haklarını veya yükümlülüklerini tümüyle veya kısmen devir ve temlik edemeyecektir.
            <br/>
            • Feragat
            <br/>
            • Taraflar'dan birinin Sözleşme'de kendisine verilen herhangi bir hakkı kullanmaması ya da icra etmemesi,
            söz konusu haktan feragat ettiği anlamına gelmeyecek veya söz konusu hakkın daha sonra kullanılmasını ya da
            icra edilmesini engellemeyecektir.
            <br/><br/>
            <b> EKLER:</b>
            <br/>
            EK-1: Üyelik İçin Gerekli Belge Listesi
            <br/>
            EK-2: Yasaklı Ürünler Listesi
            <br/>
            EK-3: Dentalpiyasa Güvenli Alışveriş Sistemi
            <br/><br/><br/><br/>

            <b> Ticaret şirketi iseniz:</b>
            <br/>
            <b>EK-1 Üyelik İçin Gerekli</b>
            <br/>
            <b> Belge Listesi</b>
            <br/>

            • Tıbbi Cihaz Satış Merkezi Yetki Belgesi
            <br/>
            • Vergi levhası fotokopisi
            <br/>
            • İmza sirküleri fotokopisi
            <br/>
            • Ticaret Sicil Gazetesi
            <br/>
            Tacir iseniz:
            <br/>
            • Tıbbi Cihaz Satış Merkezi Yetki Belgesi
            <br/>
            • İmza beyannamesi
            <br/>
            • Vergi levhası fotokopisi
            <br/><br/>

            Dentalpiyasa yukarıda sayılan belgelere ek olarak, ayrıca belge talep edilebilecek ve üyelik kabul kararını
            nihai olarak verme yetkisine sahip olacaktır.
            <br/><br/>
            <b>EK-2 Yasaklı Ürünler Listesi</b>
            <br/>
            <b>Yasadışı Ürünler</b>
            <br/>
            • Çalıntı mallar
            <br/>
            • Kaçak ve ithalatı yasak ürünler
            <br/>
            • Sahte para ve belgeler
            <br/>
            • Web ortamında saldırma ve program kırma yazılımları
            <br/>
            • Şifreli yayın çözücüler
            <br/>
            • İlaç testlerini geçmeye yönelik yardımcı maddeler
            <br/>
            • Yasaklı kitap ve yayınlar
            <br/><br/>
            <b>İnsan sağlığına zararlı ürünler</b>
            <br/>
            • Uyuşturucu maddeler
            <br/>
            • Alkollü içecekler
            <br/>
            • Tütün mamulleri, elektronik sigaralar ve elektronik sigaraların tamamlayıcı parçaları
            <br/><br/>
            <b>İlaçlar ve diğer benzeri sağlık ürünleri</b>
            <br/>
            • Reçeteli ve reçetesiz ilaçlar, geleneksel bitkisel tıbbi ürünler, homeopatik tıbbi ürünler, enteral
            beslenme ürünleri, özel tıbbi amaçlı diyet gıdalar, özel tıbbi amaçlı bebek mamaları
            <br/>
            • Numaralı gözlük camları ve numaralılensler
            <br/>
            • İşitme cihazları, ortopedik protez ve ortezleri
            <br/>
            • Veteriner tıbbi ürünler
            <br/>
            • Sağlık Bakanlığı tarafından satışı yasaklanmış ürünler (Türkiye İlaç ve Tıbbi Cihaz Kurumu’ndan izin
            alınmaksızın sağlık beyanıyla satışa sunulan ürünler, zayıflama hapları, etken madde içeren ürünler, yasak
            cinsel ilaçlar vb.)
            <br/><br/>
            <b>Silahlar, patlayıcı maddeler ve tehlike arz eden diğer ürünler</b>
            <br/>
            • Ateşli silahlar
            <br/>
            • Kama, hançer, saldırma, şişli baston, sustalı çakı, pala, kılıç, kasatura, süngü, sivri uçlu ve oluklu
            bıçaklar, topuz, topuzlu kamçı, boğma teli veya zinciri, muşta ile salt saldırı ve savunmada kullanılmak
            üzere özel nitelikteki benzeri aletler
            <br/>
            • Yanıcı ve patlayıcı maddeler
            <br/>
            • Biber gazı
            <br/>
            • Elektroşok cihazları
            <br/>
            • PCB (poliklorlu bifeniller) ve bunu içeren ürünler
            <br/>
            • Amonyum Nitrat ve kütlece %28’den daha fazla amonyum nitrattan gelen azot içeren gübreler
            Kanun ve mevzuat kapsamında satışı (veya internet üzerinden satışı) yasak veya denetime tabi olan ürünler
            <br/>
            • Alım-satımı devlet iznine tabi olan ürünler
            <br/>
            • Telsizler (Kısa Mesafe Erişimli Telsiz (KET) Cihazları Hakkında Yönetmelik kapsamında kalan kısa mesafe
            erişimli alçak güçlü telsiz cihaz ve sistemler hariç)
            <br/>
            • Askeri teçhizat
            <br/>
            • Resmi kıyafetler ve üniformalar
            <br/>
            • Radar dedektörleri
            <br/>
            • Airbag ve ekipmanları
            <br/>
            • Emniyet kemeri adaptörü
            <br/>
            • Taşıtların yönetim sistemine müdahale amacıyla kullanılan cihaz ve teçhizat
            <br/>
            • Maymuncuk ve kilit açıcılar
            <br/>
            • Şans oyunları biletleri
            <br/>
            • Sertifikasız tohumlar
            <br/>
            • Kültür ve tabiat varlıkları
            <br/>
            • Madalya ve nişanlar
            <br/><br/>
            Kişisel verilere ilişkin kuralları ihlal eder nitelikteki ürünler
            <br/>
            • Kişilik hakları
            <br/>
            • Yüz fotoğrafı, isimler ve imzalar
            <br/>
            • İnsan ve diğer canlı organları
            <br/>
            • Toplu elektronik posta adresleri ve listeleri
            <br/>
            • Sosyal ağ ve diğer internet üyelik hesapları
            <br/><br/>
            Fikri mülkiyet haklarını ihlal eder nitelikteki ürünler
            <br/>
            • Telif hakkını haiz ürünler
            <br/>
            • Lisanssız Oyunlar
            <br/>
            • Sahte veya replika ürünler
            <br/>
            • Seri numarası çıkarılmış, kopya ve bandrolsüz ürünler
            <br/><br/>
            <b>Dentalpiyasa üzerinden satışı yasak olan diğer ürünler</b>
            <br/>
            • Mucizevi tedavi ürün veya hizmetleri, falcılık ve büyücülük ve sair hizmetler
            <br/>
            • Yönlendirme içeriği olan ürün ilanları
            <br/>
            • İkinci el ürünler
            <br/>
            • Ürün özelliği taşımayan satışlar
            <br/>
            • Diğer kurum, kuruluş ve e-ticaret platformlarına ait hediye çeki ya da indirim kuponları
            <br/>
            • Deneme Boyu / numune (tester) ve kutusuz veya teşhir ürünü olan parfüm ve sair kozmetik ürünleri
            <br/>
            • Hisse senedi, tahvil, bono
            <br/>
            • Pornografik içerikli malzemeler
            <br/>
            • Casus kamera ve dinleme cihazları
            <br/>
            • Canlı hayvanlar
            <br/><br/><br/><br/>


                <b>EK-3 Dentalpiyasa Güvenli Alışveriş Sistemi </b>
            <br/>

                <b>A- GÜVENLİ ALIŞVERİŞ SİSTEMİNİN İŞLEYİŞİ</b>
            <br/>

            • Dentalpiyasa Güvenli Alışveriş Sistemi hizmeti işbu sözleşmede belirtilen ALICI ve SATICI sıfatına sahip
            Üye’lerin Platform’u kullanarak alım-satım konusunda çevrimiçi (on-line) ortamda anlaşmalarını sağlayacak
            alt yapının kendilerine sunulması; bu alt yapı üzerinden varılan anlaşma koşullarına uygun olarak tarafların
            yüklendikleri borcun eksiksiz ifa edilebilmesi için taraflarca işbu sözleşmede belirtilen koşullar dâhilinde
            SATICI’nın ürünü ALICI’ya göndermesi/hizmetin sağlanması ve ALICI tarafından Dentalpiyasa’nın çalışmakta
            olduğu BDDK lisanslı ödeme kuruluşunun Hesabına aktarılan ürün bedelinin hizmet bedeli ve hizmet komisyonu
            mahsup edilerek SATICI’nın hesabına transfer edilmesini amaçlayan (bu koşulların gerçekleşebilmesi ALICI ve
            SATICI’nın sorumluluğundadır) işbu sözleşmede, işbu sözleşmenin ayrılmaz bir parçası olan eklerinde veya
            Platform’da detayları açıklanan sistemdir.
            <br/>
            • Aşağıdaki hükümler, Dentalpiyasa Güvenli Alışveriş Sistemi Hizmeti’ni kullanan ALICI ve SATICI sıfatına
            sahip olan Üye’lerin hak ve yükümlülükleri ile Dentalpiyasa’nın bu hak ve yükümlülükler karşısındaki
            durumunu
            düzenlemektedir.
            <br/> <br/>
                <b>SATIŞ</b>
            <br/>
            • Dentalpiyasa Güvenli Alışveriş Sistemi Hizmeti’nde, ALICI, Platform’da ilanları gösterilen SATICI’ya ait
            ürün ve hizmet satın alma talebini; Platform’un alt kapısını kullanarak yapar. SATICI’nın mal veya
            hizmetlerini Platform’da satışa sunması; ilan Plaform’da yayınlandığı anda başlar.
            <br/>
            • ALICI’nın, SATICI’ya ait ürünü Platform’dan satın alarak ödemeyi yapması, işbu sözleşmenin kurulması
            anlamına
            gelir.
            <br/>
            • Satın alma gerçekleştiğinde satış bedeli Dentalpiyasa’nın çalışmakta olduğu BDDK lisanslı ödeme
            kuruluşunun Hesabı’nda tutulmak üzere Ödeme Kuruluşu tarafından ALICI’dan tahsil edilir. Bu süreçte
            Dentalpiyasa hiçbir para transferi işleminin tarafı olmamakta ancak Ödeme kuruluşuna, ALICI’dan ödemenin
            SATICI hesabına geçirilmesi açık onayı, ilgili süre boyunca itiraz edilmeyerek verilen örtülü onayı veya
            ödemenin yapılmaması ile ilgili itirazı iletmekle yükümlü olup başka hiçbir sorumluluğu bulunmamaktadır.
            SATICI Dentalpiyasa’nın tüm ödeme işlemlerinden, yukarıda sayılan durumları Ödeme Kuruluşuna iletmekten
            başka hiçbir sorumluluğu olmadığını, doğabilecek tüm ihtilaflarda husumeti Ödeme Kuruluşuna yönelteceğini
            kabul beyan ve taahhüt eder. Dentalpiyasa’ın işbu sözleşme tahtındaki hizmetlerinin karşılığı her bir işlem
            için işlem bedelinin %10’u oranında komisyondur. Dentalpiyasa bu oranları her zaman tek taraflı olarak
            değiştirebileceğini ve bu değişikliklerin satıcı panelindeki duyurular bölümünden satıcı tarafından takip
            edilmek zorunda olduğunu, satıcı beyan, kabul ve taahhüt eder.
            <br/> <br/>
                <b>PAKETLEME</b>
            <br/>
            • SATICI tarafından ALICI’ya gönderisi sağlanan ürünler hasar görmeyecek ve kargo aracında yer alan diğer
            ürünlere zarar vermeyecek şekilde paketlenmelidir. SATICI’nın ürünü hasar almayacak şekilde paketlediğini
            video ve resimlerle ispatlamadığı taktirde, oluşacak hasarlardan sorumlu olduğunu alıcı ve Dentalpiyasa’nın
            ilk talebinde oluşabilecek her türlü zararı tazmin edeceğini taahhüt eder. SATICI, paketleme işlemleri
            sırasında başka bir platforma veya şirket/tacire ait herhangi bir ambalaj/paket kullanmayacaktır. Aksi
            takdirde, Dentalpiyasa tarafından bu durumun tespit edilmesi halinde SATICI, Dentalpiyasa’nın inisiyatifine
            bağlı olarak kendisine ait mağazanın süreli veya süresiz olarak kapatılabileceğini kabul eder.
            KARGOLAMA
            <br/>
            • Platform ödemenin yapıldığını teyit ettikten sonra SATICI’ya ürünü göndermesi gerektiğini bildirir. Bu
            bildirimin akabinde SATICI, 2 iş günü içerisinde ürünü ALICI’nın belirttiği adrese gönderilmek üzere,
            Dentalpiyasa’nın seçmiş olduğu kargo firmalarına ve sağladığı gönderi kodlarını kullanarak ödemeyi şubede
            doğrudan kendi hesabına ve namına peşin olarak yaparak teslim eder. Tekraren belirtilmelidir ki
            Dentalpiyasa’da tüm kargo ücretleri SATICI’ya aittir ve SATICI ürün bedelini belirlerken işbu bedeli göz
            önünde bulundurmak zorundadır. ALICI’ya hiçbir surette kargo ücreti yansıtılamaz. Ürünün ALICI’ya
            ulaştığının kargo firması tarafından Dentalpiyasa’ya bildirilmesini takiben, ALICI’nın ürünün ya da mal veya
            hizmet ifasının ayıplı olduğu yönünde Platform’a bir bildirimde bulunmaması ve Platform’da ALICI’ya işbu
            sözleşme ile SATICI’nın sağladığı 7 günlük iade süreci içerisinde ALICI’nın cayma hakkını kullanmaması
            durumunda, satış bedeli Dentalpiyasa tarafından belirlenen süre içerisinde SATICI’nın hesabına aktarılır.
            <br/> <br/>

                <b>İADE</b>
            <br/>
            • ALICI’nın, ürün kendisine ulaştığından itibaren 7 gün içerisinde herhangi bir sebep göstermeksizin ürünü
            SATICI’ya iade etme hakkı bulunmaktadır. İşbu iade, belirtilen süre içerisinde Platform üzerinden yapılacak
            bir iade bildirimi kaydıyla olur. İade ihbar süresinin kullanılması için Platform üzerinden yapılacak ihbar
            yeterli olup, ürünlerin bu sürede Satıcı'ya ulaştırılması gerekmez. SATICI bu süre içerisinde kendisine iade
            edilen ürünü geri çeviremez ve süreç içerisinde yapmış olduğu masrafları veya iade sebebiyle uğramış olduğu
            herhangi bir zararı ne ALICI’dan ne de Dentalpiyasa’dan talep edemez.
            <br/>
            • İşbu sözleşmede belirlenen 7 günlük iade hakkının ötesinde, ayıp ve ayıba bağlı iade süreç ve şartları
            Türk Ticaret Kanunu, Türk Borçlar Kanunu, Mesafeli Sözleşmeler Yönetmeliği ve sair ilgili yasal mevzuat ile
            düzenlenmiş olup, SATICI söz konusu mevzuat hükümlerine uymayı kabul, beyan ve taahhüt eder. SATICI, ALICI
            tarafından geri gönderilen ürünü teslim aldığını Dentalpiyasa’ya 2 iş günü içerisinde bildirmek zorundadır.
            ALICI’nın cayma hakkını kullandığını Dentalpiyasa’ya bildirmesi halinde Dentalpiyasa ayrıca bir inceleme ve
            SATICI’dan açıklama veya savunma alma yükümlülüğü bulunmaksızın satılan ürün bedelini ve varsa ek zarar
            bedellerini ALICI’ya ödeyecek olup, söz konusu bedeller için SATICI’ya rücu edecektir.
            <br/>
            • SATICI tarafından ALICI’ya eksik ürün/ayıplı ürün gönderilmesi ve bu durumun ALICI tarafından
            Dentalpiyasa’ya veya SATICI’ya bildirilmesi durumunda SATICI, kendisine bildirimin yapılmasından itibaren 2
            iş günü içerisinde söz konusu aykırılığı gidermekle ve ALICI’nın sipariş ettiği ürünü tam, eksiksiz ve
            hasarsız olarak göndermekle yükümlüdür. Aksi takdirde Dentalpiyasa, ALICI tarafından kendisine bildirilen
            iade talebini onaylayarak ALICI’ya ürün bedelini ve varsa ek zarar bedellerini iade edecektir. İşbu bedeller
            için Dentalpiyasa tarafından SATICI’ya ayrıca rücu edilecektir.
            <br/>
            • SATICI, ALICI’dan gelen iadeleri kabul etmekle yükümlüdür. Ancak, ALICI tarafından SATICI’ya iade edilen
            ve SATICI tarafından teslim alınan ürünler iade edilmeye uygun değilse SATICI, Platform sitesi üzerinden
            Dentalpiyasa’ya iadeyi kabul etmeme sebebini bildirmekle yükümlüdür. SATICI, ALICI’Iardan gelen iadeleri
            kargodan teslim almaz veya kargodan teslim alıp Dentalpiyasa’ya bildirim yapmadan ve sebebini açıklamadan
            ALICI’ya ürünü geri gönderirse ve bu durum ALICI tarafından Dentalpiyasa’ya bildirilirse; Dentalpiyasa
            ALICI’dan ürünü yeniden iade etmesini talep eder. ALICI’nın ikinci defa ürünü iade etmesine karşı SATICI
            ürünü kargodan teslim almaktan imtina eder ve/veya teslim alıp yeniden ALICI’ya gönderirse Dentalpiyasa
            tarafından söz konusu iade onaylanır ve ürün bedeli ALICI’ya ödenip ödenen bedel SATICI’ya rücu edilir.
            <br/>
            • Rücu edilecek bedeller SATICI’ya işbu sözleşme kapsamında ödenecek tüm bedeller içerisinden mahsup
            edilebilir. Bu kapsamda "SATICI” “Dentalpiyasa”yı cayma hakkına ve ayıplı mala ilişkin bildirimlerin
            alınması ve bunlara ilişkin bedellerin “ALICI”ya ödenmesi hususunda yetkilendirmiştir. SATICI
            Dentalpiyasa’nın kendisine bu yönde hazırlayacağı ödeme bildirimi ve faturaya itiraz etmeyeceğin, ilk
            talepte herhangi bir kesinleşmiş ilama gerek kalmaksızın ödeyeceğini kabul, beyan ve taahhüt eder.
            <br/> <br/>
                <b>SATIŞIN KAPATILMASI</b>
            <br/>
            • SATICI, alım talebinin kendisine ulaşmasının akabinde 2 iş günü içerisinde ürünü kargoya teslim etmez ve
            taahhüt edilen süre içerisinde ALICI’nın sipariş ettiği ürünler ALICI’ya teslim edilmezse; "Dentalpiyasa”,
            satışı iptal etme, müşteriye hediye çeki verme, hediye çeki, tutarını SATICI’nın hesabına borç kaydetme,
            taahhüt edilen süre içerisinde ALICI’ya ürünün teslim edilmemesi halinde ceza faturası kesme ve SATICI’nın
            mağazasını kapatma yetkisine ve hakkına sahiptir. Dentalpiyasa, SATICI’nın ALICI’ya göndermiş olduğu
            ürünlerin
            eksik/ayıpIı/yanlış/hasarlı olup olmadığına, ALICI’lardan SATICI’dan sipariş edilen ürünlere ilişkin gelen
            ürün iadelerine ve SATICI tarafından iptal edilen siparişlere ilişkin her hafta sayısal bir raporlama
            yapacaktır. İşbu rapora göre ALICI’lara teslim edilen eksik/ayıpIı/yanlış/hasarlı ürünler veya ALICI’Iardan
            gelen iadeler, SATICI’nın son 6 (altı) haftada yapmış olduğu satış ortalaması oranını aşarsa
            Dentalpiyasa’nın kendi inisiyatifinde olmak üzere SATICI’nın mağazası süreli veya süresiz olarak
            kapatılabilecektir.
            <br/> <br/>
                <b> BEYANLARIN ESAS ALINMASI</b>
            <br/>
            • Platform, ürünün gönderilmesi ve ürün bedelinin "SATICI” hesabına transfer edilmesi de dâhil tüm
            süreçlerde ALICI ve SATICI’nın beyanlarının gerçeğe uygun olduğu varsayımıyla hareket eder. ALICI ve
            SATICI’nın beyanlarının gerçeğe uygun olmaması nedeniyle ortaya çıkabilecek tüm sonuçlardan ALICI ve SATICI
            doğrudan sorumludur. Platform’un, ürünün kargoya verilmesi veya ALICI’ya ulaşması da dâhil herhangi bir
            süreci ayrıca kontrol etme yükümlülüğü bulunmayıp bu yükümlülükler ALICI ve SATICI tarafından doğrudan
            yerine getirilir.
            <br/>
            • ALICI ve SATICI tüm bildirimlerini Platform üzerinden belirtildiği şekilde gerçekleştirmekle
            yükümlüdürler. Usulüne uygun yapılmayan bildirimler hiç yapılmamış sayılacaktır. SATICI ve ALICI’nın
            Platform üzerinden yapmaları gereken bildirimleri yapmaları gereken sürelerde yapmamaları halinde ortaya
            çıkacak tüm sonuçlardan kendileri sorumludur. Dentalpiyasa’nın, bu bildirimlerin süresinde yapılmamış olması
            nedeniyle ortaya çıkan sonuçlardan herhangi bir sorumluluğu yoktur.
            <br/> <br/>
                <b>TARAFLARIN HAK VE YÜKÜMLÜLÜKLERİ</b>
            <br/>
            • ALICI ve SATICI arasında yaşanabilecek bir anlaşmazlık durumunda, işbu dokümanla sözleşme ve ayrılmaz
            ekleriyle belirlenen süreçlerin yerine getirilmesi amacıyla Dentalpiyasa, anlaşmazlığa konu ürünün bedelini
            Dentalpiyasa, ödeme kuruluşuna vereceği talimatla bloke etme hakkına sahiptir. Dentalpiyasa bu durumlarda
            işbu doküman ve Üyelik Sözleşmesi hükümleri doğrultusunda ürün bedelini ilgili tarafa iletecektir. Platform,
            usulüne uygun olarak kullanılmadan, ürün satışıyla ilgili kabul veya ret beyanlarına ilişkin
            Dentalpiyasa’nın hiçbir sorumluluğu yoktur.
            <br/>
            • ALICI, Platform üzerinden Platform’un alt yapısını kullanarak yapmış olduğu satın alma talepleriyle SATICI
            tarafından belirlenmiş satış şartlarını ve usullerini de kabul etmiş sayılır. İşbu sözleşmede ve yasalarda
            tanınan istisnai haller dışında "ALICI" tarafından yapılan satın alma talepleri geri çekilemez veya iptal
            edilemez.
            <br/>
            • ALICI, Platform üzerinden yapmış olduğu satın alma işlemlerinde yalnızca, Dentalpiyasa’nın anlaşmalı
            olduğu ödeme kuruluşu hesabına para transferi yaparak para borcunun ifasını gerçekleştirebilir. ALICI,
            Dentalpiyasa’nın
            anlaşmalı olduğu ödeme kuruluşunun hesabına para transferlerini yaparak bedelin satıcıya aktarılması için
            temsilci olarak Dentalpiyasa’yı ve onun yetki vereceği kişi/kurumları yetkilendirmekte, SATICI ise işbu
            sözleşme
            ile belirlenen şartların oluşması halinde ALICI tarafından gönderilen bedelin temsilci sıfatıyla kendisine
            aktarılması için Dentalpiyasa’yı ve onun yetki vereceği kişi/kurumları yetkilendirmektedir. Para borcunun
            ifasının yukarıda anlatılan sistem dışında başka yollarla gerçekleştirilmesi durumunda Dentalpiyasa’nın,


            Dentalpiyasa Güvenli Alışveriş Hizmetine ilişkin hiçbir sorumluluğu bulunmadığı gibi ALICI ve SATICI’nın hak
            ve borçları da işbu sözleşmede belirtilen hükümlere tabi değildir.
            <br/>
            • Dentalpiyasa satış bedelinin ifasında ALICI’ya taksitle pazaryeri kolaylığı sunabilir. Bu durumda taksitli
            ödemeye ilişkin koşullar Dentalpiyasa tarafından ödemenin gerçekleştirilmesi sırasında veya öncesinde
            “Platform’un ilgili bölümünde ALICI’ya duyurulmaktadır. ALICI taksitli ödemeye karar verir ise Platform’un
            ilgili bölümünde belirtilen koşulları da kabul etmiş olmaktadır.
            <br/>
            • ALICI, SATICI tarafından satıma arz edilen ürün ve hizmetlerin ayıplı olup olmaması, listelenmesi, satışı
            yasaklı ürünlerden olup olmaması, niteliği, orijinalliği, ürünün tanıtımında kullanılan görsel, yazınsal ve
            işitsel açıklamaların doğruluğu veya tamlığı da dahil olmak üzere ürün ve hizmetle ilgili hiçbir konu
            hakkında
            Dentalpiyasa’nın bilgi sahibi olmadığı ve olması gerekmediğini ve bunları taahhüt ve garanti etmek
            yükümlülüğü bulunmadığını kabul ve beyan eder. SATICI, Platform üzerindeki satış panelinde, ürünlerini
            satışa arz ederken, ürün resmi, ismi, açıklaması ve ambalaj içeriği gibi bilgileri kontrol etmek, herhangi
            bir yanlışlık veya eksiklik
            durumunda ürünü satışa arz etmeden Dentalpiyasa’ya yazılı veya Platform üzerinden oluşturacağı bir formla
            ulaşarak gerekli eksiklikleri belirtip giderilmesini talep edecektir. SATICI’nın bu durumlara dikkat etmeden
            yapacağı satışlarda, SATICI’nın satışa arz ettiği ürünün ismi ve ambalaj içeriğindeki şekliyle ALICI’ya
            yollaması, bu durumdan imtina ettiği hallerde ise, ürünün belirtilen sürede yollanmamasına ilişkin
            hükümlerin SATICI’ya uygulanacağını kabul beyan ve taahhüt eder.
            <br/>
            • SATICI, Platform’da sunduğu ürün ve hizmetler üzerinde çekişmesiz bir şekilde mutlak olarak tasarrufta
            bulunma hak ve yetkisine sahip olduğunu; bahsi geçen ürün ve hizmetlerin kendisi tarafından satılmasına
            karşı herhangi bir hukuki veya fiili engel bulunmadığını kabul ve taahhüt eder. SATICI Platform’da sunduğu
            ürün ve
            hizmetlerin satışı, ilanı yasak ürünler de dâhil olmak üzere “Üyelik Sözleşmesi”nde belirlenen veya
            “Platform’un ilgili yerlerinde belirtilen Platform’un vePlatform’da sunulan hizmetlerin kullanımına ilişkin
            kurallara ve üyeliğe ilişkin koşullara aykırı olmadığını ve yürürlükteki tüm mevzuata uygun olduğunu kabul
            ve taahhüt eder. SATICI, Platform’a sunduğu ürün ve hizmetlerin tüm hukuki sorumluğunun kendisine ait
            olduğunu, Dentalpiyasa’nın ürün ve hizmetlerin arzı ve satışı konusunda, hiçbir sıfat altında kendisiyle
            doğrudan ve dolaylı ilişki içinde
            olmadığını kabul eder. SATICI, Platform’da sunduğu ürün ve hizmetlerle ilgili satın alma talebinde bulunan
            ALICI’ya söz konusu ürünün satışını ilanında belirttiği şekilde gerçekleştireceğini, ÜTS ile ilgili tüm
            sorumluluklarını yerine getireceğini kabul ve taahhüt eder.
            <br/>

            • SATICI, satışa arz ettiği ve ilanında belirttiği ürün veya hizmetlerin yasaklı ürünlerden olmasından, ürün
            veya hizmetin üretilmesinin, çoğaltılmasının, satılmasının, dağıtılmasının veya herhangi bir şekilde satışa
            arzına
            ilişkin bir fiilde bulunulmasının yürürlükteki herhangi bir düzenlemeye aykırı olmasından dolayı doğabilecek
            her türlü hukuki, ticari ve idari talebe karşı sorumlu olduğunu, bu yönde Dentalpiyasa’ya yapılan taleplere
            karşı
            Dentalpiyasa’yı her türlü adli, ticari ve idari makam önünde savunma yükümlülüğü olduğunu kabul, beyan ve
            taahhüt eder.

            <br/>
            • SATICI, ALICI’ya ürün veya hizmeti zamanında ve satışa arzında belirttiği niteliklere uygun ve ayıptan arî
            olarak göndereceğini ve teslimini sağlayacağını; ürün veya hizmetin ALICI’ya satımı, mülkiyetinin ve ilgili
            haklarının devri ile özellikle İlaç ve Tıbbi Cihaz mevzuatı başta olmak üzere tüm mevzuattan kaynaklanan tüm
            yükümlülüklerin sadece kendisine ait olduğunu kabul eder. SATICI işbu madde içerisinde belirtilen gönderme
            ve teslim yükümlülüğünü, Dentalpiyasa tarafından onaylanmayan kargo firmaları aracılığıyla ve
            Dentalpiyasa’nın vereceği kargo barkod numarasını kullanmadan yerine getiremez.

        </Fragment>
    )
};

export default VendorRegisterAgreement
