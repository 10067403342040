import React from "react";
import LOADERS from "@organisms/Loader/Loaders.types";
import styles from "@dv/scss/lib/Loader.module.scss";

export interface LoaderProps {
    type?: 'barChart' | 'barChart2' | 'blockChart' | 'blockChartWithRatio' | 'qualityMetric' | 'ratingMetric' | 'questionsMetric' | 'actionRequestMetrics' | 'paymentDashboardPrice';
}

const Loader: React.FC<LoaderProps> = ({type = "barChart"}) => {
    return LOADERS[type];
};

export default Loader;
