export const ReducerTypes = {
    SEARCH_PRODUCTS: 'SEARCH_PRODUCTS',
    SELECT_PRODUCT: 'SELECT_PRODUCT',
    UPDATE_PRODUCTS: 'UPDATE_PRODUCTS',
    PROCESS_FAIL: 'PROCESS_FAIL',
    CLEAR_ERRORS: 'CLEAR_ERRORS'
}

export interface IProductContext {
    products?: object[];
    selectedProduct?: object;
    selectedProductVariants?: object[];
    processStatus?: boolean;
    loading?: boolean;
    error?: any;
}

export type ProductContextType = {
    state?: IProductContext | any;
    selectProduct: (productId: any) => void,
    updateProducts: (formData: any, cb?: () => void) => void;
    clearErrors: () => void;
};


