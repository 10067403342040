import axios from 'axios';
import {localApi} from './axiosInstances';

const setAuthToken = token => {
  if (token) {
    localApi.defaults.headers.common['x-dental-vendor-token'] = token;
    axios.defaults.headers.common['x-dental-vendor-token'] = token;
  } else {
    delete localApi.defaults.headers.common['x-dental-vendor-token'];
    delete axios.defaults.headers.common['x-dental-vendor-token'];
  }
};

export default setAuthToken;
