import React, {useState, useContext, useEffect} from 'react';
import Logo from '@assets/images/BrandLogo.jpg';

import styles from '@dv/scss/lib/Login.module.scss';
import {Link, useNavigate} from "react-router-dom";
import BarcodeLogo from "@assets/images/barcode_logo.png";
import PaymentLogos from "@assets/images/payment_logos.png";

import AuthContext from "@contexts/Auth/AuthContext";
import Forms from "@pages/Login/Forms";

import notify from "@antd/Notification";

const Login: React.FC<any> = () => {

    const navigate = useNavigate();
    const authContext = useContext(AuthContext);
    const [formPhase, setFormPhase] = useState('login');
    const currentForm = Forms.find(el => el.name == formPhase);
    const DynamicComponent = currentForm?.component;

    useEffect(() => {
        if (authContext.state.error !== null)
            notify({
                type: 'error',
                title: 'Bir hata ile karşılaşıldı',
                description: authContext.state.error
            });
        authContext.clearErrors();
    }, [authContext.state.error]);


    useEffect(() => {
        if (authContext.state.isVerified === false) {
            setFormPhase('email-verification');
        } else {
            if (authContext.state.isConfirmed === false) {
                setFormPhase('vendor-verification');
            } else {
                if (authContext.state.isAuthenticated) {
                    window.location.href = '/';
                }
            }
        }
    }, [authContext.state]);

    return (
        <div className={styles.page}>
            <section className={styles.navbar}>
                <Link to="/"><img alt="logo" src={Logo} width={200} height={50}/></Link>
            </section>
            <section className={styles.content}>
                <div className={styles.advices}>
                    <div className={styles.advice}>
                        <div className={styles.logo}>
                            <img src={"https://depo.dentalpiyasa.com/media/wysiwyg/magebig/login/secure.png"}/>
                        </div>
                        <div className={styles.content}>
                            <div className={styles.title}>
                                Güvenli Alışveriş
                            </div>
                            <div className={styles.description}>
                                Tüm ödemelerini Lisanslı ödeme kuruluşu güvencesiyle gerçekleştirir, bütün işlemlerini
                                anında takip edersin.
                            </div>
                        </div>
                    </div>
                    <div className={styles.advice}>
                        <div className={styles.logo}>
                            <img src={"https://depo.dentalpiyasa.com/media/wysiwyg/magebig/login/quality.png"}/>
                        </div>
                        <div className={styles.content}>
                            <div className={styles.title}>
                                Kusursuz Hizmet Garantisi
                            </div>
                            <div className={styles.description}>
                                Her ürün, onaylanmış diş depoları tarafından; kutulu, orijinal ve en az 6 ay miat
                                garantisi ile Dentalpiyasa güvencesiyle satışa sunulur.
                            </div>
                        </div>
                    </div>
                    <div className={styles.advice}>
                        <div className={styles.logo}>
                            <img src={"https://depo.dentalpiyasa.com/media/wysiwyg/magebig/login/economic.png"}/>
                        </div>
                        <div className={styles.content}>
                            <div className={styles.title}>
                                Ekonomik Çözümler
                            </div>
                            <div className={styles.description}>
                                Aynı ürün için birçok farklı depodaki fiyatları görüntüler, karşılaştırır ve istediğini
                                anında satın alırsın.
                            </div>
                        </div>
                    </div>

                </div>
                <div className={styles.login}>
                    <DynamicComponent styles={styles} setFormPhase={setFormPhase}/>
                </div>
            </section>
            <section className={styles.footer}>
                <ul className={styles.footer_links}>
                    <li><a href="https://dentalpiyasa.com/islem-rehberi/" target="_blank" rel="noopener noreferrer">İşlem
                        Rehberi</a></li>
                    <li><a href="https://dentalpiyasa.com/sartlar-kosullar/" target="_blank" rel="noopener noreferrer">Şartlar
                        ve Koşullar</a></li>
                    <li><a href="https://dentalpiyasa.com/iade-politikasi/" target="_blank" rel="noopener noreferrer">İade
                        Politikası</a></li>
                    <li><a href="https://dentalpiyasa.com/kisisel-verilerin-korunmasi/" target="_blank"
                           rel="noopener noreferrer">Kişisel Verilerin Korunması</a></li>
                    <li><a href="https://dentalpiyasa.com/mesafeli-satis-sozlesmesi/" target="_blank"
                           rel="noopener noreferrer">Satış Sözleşmesi &amp; Teslimat ve İade</a></li>
                </ul>
                <div className={styles.footer_copyright}>
                    <div className={styles.footer_copyright_text}>
                        © Copyright 2023 Dental Piyasa. Tüm Haklı Saklıdır. V1.4.92
                    </div>
                    <div className={styles.footer_copyright_sponsors}>
                        <img src={BarcodeLogo}/>
                        <img src={PaymentLogos}/>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Login;
