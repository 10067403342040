import React from 'react';
import styles from '@dv/scss/lib/Modal.module.scss';
import Modal from "@organisms/Modal";

const ZoomImageModal = ({title, show, setShow, imageLink}) => {

    return (
        <Modal
            title={title}
            show={show}
            onClose={() => setShow(false)}
            onCancel={() => setShow(false)}
            onConfirm={() => {
                setShow(false);
            }}
            footer={false}
        >
            <section className={styles.zoom_image_modal}>
                <img src={imageLink} width={'100%'} height={'100%'}/>
            </section>
        </Modal>
    )
}

export default ZoomImageModal;
