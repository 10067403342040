import React, {useState} from "react";
import styles from "@dv/scss/lib/TabPane.module.scss";

export interface TabPaneProps {
    key: number,
    children: any,
}

const TabPane: React.FC<TabPaneProps> = ({children}) => {
    return children;
};

export default TabPane;
