import React from 'react';
import {Steps as AntSteps, Popover} from "antd";

import '@dv/scss/lib/Step.antd.css';

const customDot = (dot, {status, index}) => (
    <Popover
        content={
            <span>
        step {index} status: {status}
      </span>
        }
    >
        {dot}
    </Popover>
);

enum StepDirection {
    horizontal = "horizontal",
    vertical = "vertical",
}

interface StepProps {
    items: { title?: string | React.ReactNode }[] | any;
    current: number | any;
    direction?: "horizontal" | "vertical";
    hasDots?: boolean;
}

const Step: React.FC<StepProps> = ({items, current, direction, hasDots, ...rest}) => {
    return (
        <AntSteps className="steps" current={current} direction={direction}
                  style={{width: hasDots ? "500px" : "auto"}} {...rest}>
            {items?.map((el, i) => (
                <AntSteps.Step key={i} title={el?.title} className={"step"}/>
            ))}
        </AntSteps>
    )
};

export default Step;
