import React, {useState, useEffect, useContext} from 'react';

import OrderContext from "@contexts/Order/OrderContext";
import {useSearchParams} from "react-router-dom";

import styles from '@dv/scss/lib/Orders.module.scss';

import Layout from "@organisms/Layout";
import {Title, Icons, Input, Button, Tooltip, Tag} from '@dv/react/lib';

import Tabs from "@organisms/Tabs";
import TabPane from "@organisms/Tabs/TabPane";
import TabContent from "@organisms/Tabs/TabContent";
import CardTable from "@organisms/CardTable";
import Modal from "@organisms/Modal";
import RangePicker from "@antd/RangePicker";
import CargoDesiPriceTableModal from "@organisms/Modal/CargoDesiPriceTableModal";


const OrdersPage: React.FC<any> = () => {
    let [searchParams, setSearchParams] = useSearchParams();
    const orderContext = useContext(OrderContext);

    const [currentTab, setCurrentTab] = useState(2);
    const _ordersCount = {100: 0, 200: 0, 250: 0, 300: 0, 400: 0, 500: 0};
    const [ordersCount, setOrdersCount] = useState(_ordersCount);
    const [selectedOrderId, setSelectedOrderId] = useState(null);

    useEffect(() => {
        setCurrentTab(parseInt(searchParams.get('tab')) || 2);
    }, [searchParams.get('tab')]);

    const getOrdersCountByStatus = (orders) => {
        for (const order of orders) {
            setOrdersCount({
                ...ordersCount,
                [order.status]: ordersCount[order.status] + 1
            });
        }
    }

    const [modals, setModals] = useState({
        approveModal: false,
        declineOrderModal: false
    });

    const updateModal = (key, value) => {
        setModals({
            ...modals,
            [key]: value
        })
    }

    useEffect(() => {
        orderContext.getOrders();
    }, []);

    useEffect(() => {
        if (orderContext.state.orders.all !== null) {
            setOrdersCount(_ordersCount);
            getOrdersCountByStatus(orderContext.state.orders.all);
        }
    }, [orderContext.state.orders]);

    const getOrdersWithFilter = (e) => {
        e.preventDefault();
        const formType = e.target.getAttribute('form-type');
        orderContext.searchOrders(formType);
    }

    /*
    useEffect(() => {
        const interval = setInterval(() => {
            orderContext.getOrders();
        }, 60000);
        return () => clearInterval(interval);
    }, []);
    */

    return (
        <Layout title="Orders">
            <div className={styles.container}>
                <Title variant="badge" title={"SİPARİŞLER"} icon={<Icons.FastDeliveryIcon/>}/>
                <Tabs
                    current={currentTab}
                    setCurrent={setCurrentTab}
                    tabs={[
                        /*<TabPane key={1}>
                            <div className={styles.tab_pane_content}>
                                <h4>TÜM SİPARİŞLER</h4>
                                <h5>{orderContext.state.orders.all !== null && `${orderContext.state.orders.all.length} PAKET`}</h5>
                            </div>
                        </TabPane>,*/
                        <TabPane key={2}>
                            <div className={styles.tab_pane_content}>
                                <h4>YENİ</h4>
                                {orderContext.state.orders.all !== null &&
                                  <h5>{orderContext.state.orders.all.filter(el => el.status === 100).length} PAKET</h5>
                                }
                            </div>
                        </TabPane>,
                        <TabPane key={3}>
                            <div className={styles.tab_pane_content}>
                                <h4>HAZIRLANANLAR</h4>
                                {orderContext.state.orders.all !== null &&
                                  <h5>{orderContext.state.orders.all.filter(el => el.status === 200).length} PAKET</h5>
                                }
                            </div>
                        </TabPane>,
                        <TabPane key={4}>
                            <div className={styles.tab_pane_content}>
                                <h4>PAKETLENENLER</h4>
                                {orderContext.state.orders.all !== null &&
                                  <h5>{orderContext.state.orders.all.filter(el => el.status === 250).length} PAKET</h5>
                                }
                            </div>
                        </TabPane>,
                        <TabPane key={5}>
                            <div className={styles.tab_pane_content}>
                                <h4>KARGOLANANLAR</h4>
                                {orderContext.state.orders.all !== null &&
                                  <h5>{orderContext.state.orders.all.filter(el => el.status === 300).length} PAKET</h5>
                                }
                            </div>
                        </TabPane>,
                        <TabPane key={6}>
                            <div className={styles.tab_pane_content}>
                                <h4>DAĞITIMDA</h4>
                                {orderContext.state.orders.all !== null &&
                                  <h5>{orderContext.state.orders.all.filter(el => el.status === 400).length} PAKET</h5>
                                }
                            </div>
                        </TabPane>,
                        <TabPane key={7}>
                            <div className={styles.tab_pane_content}>
                                <h4>TESLİM EDİLENLER</h4>
                                {orderContext.state.orders.all !== null &&
                                  <h5>{orderContext.state.orders.all.filter(el => el.status === 500).length} PAKET</h5>
                                }
                            </div>
                        </TabPane>,
                        <TabPane key={8}>
                            <div className={styles.tab_pane_content}>
                                <h4>ÖDEMESİ ONAYLANANLAR</h4>
                                {orderContext.state.orders.all !== null &&
                                  <h5>{orderContext.state.orders.all.filter(el => el.status === 510).length} PAKET</h5>
                                }
                            </div>
                        </TabPane>
                    ]}>
                    {/*
                    <TabContent key={1}>
                        <div className="tab_content">
                            <form form-type="all" onSubmit={getOrdersWithFilter} className={styles.filter_section}>
                                <Input label={"SİPARİŞ NO"} type={"text"}
                                       value={orderContext.state.filterInputs.all.orderNo}
                                       placeholder={"Sipariş Numarası"}
                                       tooltip={<Tooltip title="Açıklama"><Icons.InfoIcon/></Tooltip>}
                                       onChange={(e) => {
                                           orderContext.setFilterInputs('all', 'orderNo', e.target.value);
                                       }}/>

                                <div className={`input_group`}>
                                    <label>
                                        SİPARİŞ TARİHİ
                                        <Tooltip
                                            title="Siparişleri tarihe göre filtrelemenize yardımcı olur."><Icons.InfoIcon/></Tooltip>
                                    </label>
                                    <div className="">
                                        <RangePicker onChange={(values, _dateString) => {
                                            orderContext.setFilterInputDates('all', values);
                                        }}/>
                                    </div>
                                </div>

                                <Button variant={"primary"} loading={false} disabled={false}>Ara</Button>
                                <Button variant={"primary-green"} loading={false} disabled={false}>Excel İndir</Button>
                            </form>
                        </div>
                        <CardTable variant={"order"} onConfirm={(selectedId) => {
                            updateModal('approveModal', true);
                            setSelectedOrderId(selectedId);
                        }} onCancel={(selectedId) => {
                            updateModal('declineOrderModal', true);
                            setSelectedOrderId(selectedId);
                        }}
                                   filters={true}
                                   pagination={{
                                       total: 50,
                                       defaultPageSize: 25,
                                       defaultCurrent: 1,
                                       setSizeChanger: null
                                   }} data={orderContext.state.orders.all}/>
                    </TabContent>
                    */}
                    <TabContent key={2}>
                        <div className="tab_content">
                            <form form-type="new" onSubmit={getOrdersWithFilter} className={styles.filter_section}>
                                <Input label={"SİPARİŞ NO"} type={"text"}
                                       value={orderContext.state.filterInputs.new.orderNo}
                                       placeholder={"Sipariş Numarası"}
                                       tooltip={<Tooltip title="Açıklama"><Icons.InfoIcon/></Tooltip>}
                                       onChange={(e) => {
                                           orderContext.setFilterInputs('new', 'orderNo', e.target.value);
                                       }}/>

                                <div className={`input_group`}>
                                    <label>
                                        SİPARİŞ TARİHİ
                                        <Tooltip
                                            title="Siparişleri tarihe göre filtrelemenize yardımcı olur."><Icons.InfoIcon/></Tooltip>
                                    </label>
                                    <div className="">
                                        <RangePicker onChange={(values, _dateString) => {
                                            orderContext.setFilterInputDates('new', values);
                                        }}/>
                                    </div>
                                </div>

                                <Button variant={"primary"} loading={false} disabled={false}>Ara</Button>
                                <Button variant={"primary-green"} loading={false} disabled={false}>Excel İndir</Button>
                            </form>
                        </div>
                        <CardTable variant={"order"} onConfirm={(selectedId) => {
                            updateModal('approveModal', true);
                            setSelectedOrderId(selectedId);
                        }} onCancel={(selectedId) => {
                            updateModal('declineOrderModal', true);
                            setSelectedOrderId(selectedId);
                        }}
                                   filters={true}
                                   pagination={{
                                       total: 50,
                                       defaultPageSize: 25,
                                       defaultCurrent: 1,
                                       setSizeChanger: null
                                   }} data={orderContext.state.orders.new}/>

                    </TabContent>
                    <TabContent key={3}>
                        <div className="tab_content">
                            <form form-type="willBeShipped" onSubmit={getOrdersWithFilter}
                                  className={styles.filter_section}>
                                <Input label={"SİPARİŞ NO"} type={"text"}
                                       value={orderContext.state.filterInputs.willBeShipped.orderNo}
                                       placeholder={"Sipariş Numarası"}
                                       tooltip={<Tooltip title="Açıklama"><Icons.InfoIcon/></Tooltip>}
                                       onChange={(e) => {
                                           orderContext.setFilterInputs('willBeShipped', 'orderNo', e.target.value);
                                       }}/>

                                <div className={`input_group`}>
                                    <label>
                                        SİPARİŞ TARİHİ
                                        <Tooltip
                                            title="Siparişleri tarihe göre filtrelemenize yardımcı olur."><Icons.InfoIcon/></Tooltip>
                                    </label>
                                    <div className="">
                                        <RangePicker onChange={(values, _dateString) => {
                                            orderContext.setFilterInputDates('willBeShipped', values);
                                        }}/>
                                    </div>
                                </div>

                                <Button variant={"primary"} loading={false} disabled={false}>Ara</Button>
                                <Button variant={"primary-green"} loading={false} disabled={false}>Excel İndir</Button>
                            </form>
                        </div>
                        <div className={styles.table_wrapper}>

                            <CardTable variant={"order-will-be-shipped"} onConfirm={(selectedId) => {
                                setSelectedOrderId(selectedId);
                                orderContext.setOrderStatus(selectedId, 250, () => {
                                    setSearchParams({tab: '4'});
                                    // alert(`/orders/${selectedId}/cargo-print`);
                                    window.open(`/orders/${selectedId}/cargo-print`, '_blank');
                                });
                            }}
                                       filters={true}
                                       pagination={{
                                           total: 50,
                                           defaultPageSize: 25,
                                           defaultCurrent: 1,
                                           setSizeChanger: null
                                       }} data={orderContext.state.orders.willBeShipped}/>
                        </div>
                    </TabContent>
                    <TabContent key={4}>
                        <div className="tab_content">
                            <form form-type="shippingCreated" onSubmit={getOrdersWithFilter}
                                  className={styles.filter_section}>
                                <Input label={"SİPARİŞ NO"} type={"text"}
                                       value={orderContext.state.filterInputs.shippingCreated.orderNo}
                                       placeholder={"Sipariş Numarası"}
                                       tooltip={<Tooltip title="Açıklama"><Icons.InfoIcon/></Tooltip>}
                                       onChange={(e) => {
                                           orderContext.setFilterInputs('shippingCreated', 'orderNo', e.target.value);
                                       }}/>
                                <div className={`input_group`}>
                                    <label>
                                        SİPARİŞ TARİHİ
                                        <Tooltip
                                            title="Siparişleri tarihe göre filtrelemenize yardımcı olur."><Icons.InfoIcon/></Tooltip>
                                    </label>
                                    <div className="">
                                        <RangePicker onChange={(values, _dateString) => {
                                            orderContext.setFilterInputDates('shippingCreated', values);
                                        }}/>
                                    </div>
                                </div>

                                <Button variant={"primary"} loading={false} disabled={false}>Ara</Button>
                                <Button variant={"primary-green"} loading={false} disabled={false}>Excel İndir</Button>
                            </form>
                        </div>
                        <div className={styles.table_wrapper}>
                            <CardTable variant={"order-shipping-record-created"} onConfirm={(selectedId) => {
                                setSelectedOrderId(selectedId);
                                orderContext.setOrderStatus(selectedId, 300, () => {
                                    setSearchParams({tab: '5'});
                                });
                            }}
                                       filters={true}
                                       pagination={{
                                           total: 50,
                                           defaultPageSize: 25,
                                           defaultCurrent: 1,
                                           setSizeChanger: null
                                       }} data={orderContext.state.orders.shippingCreated}/>
                        </div>
                    </TabContent>
                    <TabContent key={5}>
                        <div className="tab_content">
                            <form form-type="shipped" onSubmit={getOrdersWithFilter}
                                  className={styles.filter_section}>
                                <Input label={"SİPARİŞ NO"} type={"text"}
                                       value={orderContext.state.filterInputs.shipped.orderNo}
                                       placeholder={"Sipariş Numarası"}
                                       tooltip={<Tooltip title="Açıklama"><Icons.InfoIcon/></Tooltip>}
                                       onChange={(e) => {
                                           orderContext.setFilterInputs('shipped', 'orderNo', e.target.value);
                                       }}/>
                                <div className={`input_group`}>
                                    <label>
                                        SİPARİŞ TARİHİ
                                        <Tooltip
                                            title="Siparişleri tarihe göre filtrelemenize yardımcı olur."><Icons.InfoIcon/></Tooltip>
                                    </label>
                                    <div className="">
                                        <RangePicker onChange={(values, _dateString) => {
                                            orderContext.setFilterInputDates('shipped', values);
                                        }}/>
                                    </div>
                                </div>

                                <Button variant={"primary"} loading={false} disabled={false}>Ara</Button>
                                <Button variant={"primary-green"} loading={false} disabled={false}>Excel İndir</Button>
                            </form>
                        </div>
                        <div className={styles.table_wrapper}>
                            <CardTable variant={"order-shipped"} onConfirm={(selectedId) => {
                                setSelectedOrderId(selectedId);
                                orderContext.setOrderStatus(selectedId, 400, () => {
                                    setSearchParams({tab: '6'});
                                });
                            }}
                                       filters={true}
                                       pagination={{
                                           total: 50,
                                           defaultPageSize: 25,
                                           defaultCurrent: 1,
                                           setSizeChanger: null
                                       }} data={orderContext.state.orders.shipped}/>
                        </div>
                    </TabContent>
                    <TabContent key={6}>
                        <div className="tab_content">
                            <form form-type="inDistributed" onSubmit={getOrdersWithFilter}
                                  className={styles.filter_section}>

                                <Input label={"SİPARİŞ NO"} type={"text"}
                                       value={orderContext.state.filterInputs.inDistributed.orderNo}
                                       placeholder={"Sipariş Numarası"}
                                       tooltip={<Tooltip title="Açıklama"><Icons.InfoIcon/></Tooltip>}
                                       onChange={(e) => {
                                           orderContext.setFilterInputs('inDistributed', 'orderNo', e.target.value);
                                       }}/>
                                <div className={`input_group`}>
                                    <label>
                                        SİPARİŞ TARİHİ
                                        <Tooltip
                                            title="Siparişleri tarihe göre filtrelemenize yardımcı olur."><Icons.InfoIcon/></Tooltip>
                                    </label>
                                    <div className="">
                                        <RangePicker onChange={(values, _dateString) => {
                                            orderContext.setFilterInputDates('inDistributed', values);
                                        }}/>
                                    </div>
                                </div>


                                <Button variant={"primary"} loading={false} disabled={false}>Ara</Button>
                                <Button variant={"primary-green"} loading={false} disabled={false}>Excel İndir</Button>
                            </form>
                        </div>
                        <div className={styles.table_wrapper}>
                            <CardTable variant={"order-in-distributed"} onConfirm={(selectedId) => {
                                setSelectedOrderId(selectedId);
                                orderContext.setOrderStatus(selectedId, 500, () => {
                                    setSearchParams({tab: '7'});
                                });
                            }}
                                       filters={true}
                                       pagination={{
                                           total: 50,
                                           defaultPageSize: 25,
                                           defaultCurrent: 1,
                                           setSizeChanger: null
                                       }} data={orderContext.state.orders.inDistributed}/>
                        </div>
                    </TabContent>
                    <TabContent key={7}>
                        <div className="tab_content">
                            <form form-type="delivered" onSubmit={getOrdersWithFilter}
                                  className={styles.filter_section}>

                                <Input label={"SİPARİŞ NO"} type={"text"}
                                       value={orderContext.state.filterInputs.delivered.orderNo}
                                       placeholder={"Sipariş Numarası"}
                                       tooltip={<Tooltip title="Açıklama"><Icons.InfoIcon/></Tooltip>}
                                       onChange={(e) => {
                                           orderContext.setFilterInputs('delivered', 'orderNo', e.target.value);
                                       }}/>
                                <div className={`input_group`}>
                                    <label>
                                        SİPARİŞ TARİHİ
                                        <Tooltip
                                            title="Siparişleri tarihe göre filtrelemenize yardımcı olur."><Icons.InfoIcon/></Tooltip>
                                    </label>
                                    <div className="">
                                        <RangePicker onChange={(values, _dateString) => {
                                            orderContext.setFilterInputDates('delivered', values);
                                        }}/>
                                    </div>
                                </div>


                                <Button variant={"primary"} loading={false} disabled={false}>Ara</Button>
                                <Button variant={"primary-green"} loading={false} disabled={false}>Excel İndir</Button>
                            </form>
                        </div>
                        <div className={styles.table_wrapper}>
                            <CardTable variant={"order-delivered"} onConfirm={(selectedId) => {
                                setSelectedOrderId(selectedId);
                                orderContext.setOrderStatus(selectedId, 500);
                            }}
                                       filters={true}
                                       pagination={{
                                           total: 50,
                                           defaultPageSize: 25,
                                           defaultCurrent: 1,
                                           setSizeChanger: null
                                       }} data={orderContext.state.orders.delivered}/>
                        </div>
                    </TabContent>
                    <TabContent key={8}>
                        <div className="tab_content">
                            <form form-type="payoutApproved" onSubmit={getOrdersWithFilter}
                                  className={styles.filter_section}>

                                <Input label={"SİPARİŞ NO"} type={"text"}
                                       value={orderContext.state.filterInputs.payoutApproved.orderNo}
                                       placeholder={"Sipariş Numarası"}
                                       tooltip={<Tooltip title="Açıklama"><Icons.InfoIcon/></Tooltip>}
                                       onChange={(e) => {
                                           orderContext.setFilterInputs('payoutApproved', 'orderNo', e.target.value);
                                       }}/>
                                <div className={`input_group`}>
                                    <label>
                                        SİPARİŞ TARİHİ
                                        <Tooltip
                                            title="Siparişleri tarihe göre filtrelemenize yardımcı olur."><Icons.InfoIcon/></Tooltip>
                                    </label>
                                    <div className="">
                                        <RangePicker onChange={(values, _dateString) => {
                                            orderContext.setFilterInputDates('payoutApproved', values);
                                        }}/>
                                    </div>
                                </div>


                                <Button variant={"primary"} loading={false} disabled={false}>Ara</Button>
                                <Button variant={"primary-green"} loading={false} disabled={false}>Excel İndir</Button>
                            </form>
                        </div>
                        <div className={styles.table_wrapper}>
                            <CardTable variant={"payout-approved"} onConfirm={(selectedId) => {
                                setSelectedOrderId(selectedId);
                            }}
                                       filters={true}
                                       pagination={{
                                           total: 50,
                                           defaultPageSize: 25,
                                           defaultCurrent: 1,
                                           setSizeChanger: null
                                       }} data={orderContext.state.orders.payoutApproved}/>
                        </div>
                    </TabContent>
                </Tabs>
            </div>

            <Modal title={"Yeni Sipariş Onayla"} show={modals.approveModal}
                   onConfirm={() => {
                       updateModal('approveModal', false);
                   }}
                   onClose={() => updateModal('approveModal', false)}
                   onCancel={() => updateModal('approveModal', false)}
                   footer
                   footerComponent={
                       <div className={"modal_footer"}>
                           <Button variant="primary-gray"
                                   onClick={() => updateModal('approveModal', false)}
                                   disabled={orderContext.state.loading}>Vazgeç</Button>
                           <Button onClick={() => {
                               orderContext.setOrderStatus(selectedOrderId, 200, () => {
                                   updateModal('approveModal', false);
                                   setSearchParams({tab: '3'});
                               });
                           }} disabled={orderContext.state.loading}>Siparişi Onayla</Button>
                       </div>
                   }
            >
                <div className={styles.approve_modal_content}>
                    <div className={styles.title}>
                        Siparişi onaylamak istediğinize emin misiniz?
                    </div>
                    <div className={styles.description}>
                        Siparişinizi onayladıktan sonra sipariş iptal edilemez.
                    </div>
                </div>
            </Modal>

            <Modal title={"Sipariş İptali"} show={modals.declineOrderModal}
                   onConfirm={() => {
                       updateModal('declineOrderModal', false);
                   }}
                   onClose={() => updateModal('declineOrderModal', false)}
                   onCancel={() => updateModal('declineOrderModal', false)}
                   footer
                   footerComponent={
                       <div className={"modal_footer"}>
                           <Button variant="primary-gray"
                                   onClick={() => updateModal('declineOrderModal', false)}>Vazgeç</Button>
                           <Button onClick={() => {
                               orderContext.cancelOrderStatus(selectedOrderId);
                               updateModal('declineOrderModal', false);
                           }}>Siparişi İptal Et</Button>
                       </div>
                   }
            >
                <div className={styles.approve_modal_content}>
                    <div className={styles.title}>
                        Siparişi iptal etmek istediğinize emin misiniz?
                    </div>
                    <div className={styles.description}>
                        Siparişi reddetmek depo puanınızı ve satışlarınızı olumsuz etkileyecektir.
                    </div>
                </div>
            </Modal>

            <CargoDesiPriceTableModal title={"Kargo Desi Fiyatları"} show={orderContext.state.cargoDesiPriceModalToggle}
                                      setShow={orderContext.setCargoDesiPriceModalToggle}/>

        </Layout>
    )
}

export default OrdersPage;
