export const ReducerTypes = {
    GET_CANCELED_ORDERS: 'GET_CANCELED_ORDERS',
    SET_SELECTED_ORDER: 'SET_SELECTED_ORDER',
    GET_CANCELED_ORDER_DETAIL: 'GET_CANCELED_ORDER_DETAIL',
    SEARCH_CANCELED_ORDERS: 'SEARCH_CANCELED_ORDERS',
    SET_FILTER_INPUTS: 'SET_FILTER_INPUTS',
    PROCESS_FAIL: 'PROCESS_FAIL',
    SET_DETAIL_LOADING: 'SET_DETAIL_LOADING',
    APPROVE_PAYMENT_REFUND: 'APPROVE_PAYMENT_REFUND',
    CLEAR_ERRORS: 'CLEAR_ERRORS'
}

export interface ICanceledOrderContext {
    orders?: {
        all: object[],
        byCustomer: object[],
        byVendor: object[],
        byAdmin: object[]
    };
    selectedOrder: number;
    searchFilter?: object;
    filterInputs?: {
        all: object,
        byCustomer: object,
        byVendor: object,
        byAdmin: object
    };
    processStatus?: boolean;
    loading?: boolean;
    detailLoading?: boolean;
    error?: any;
}

export type CanceledOrderContextType = {
    state?: ICanceledOrderContext | any;
    getCanceledOrders: () => void;
    getCanceledOrderDetail: (orderId: string, cb: (orderDetail) => void) => void;
    searchCanceledOrders: (type: string) => void;
    setFilterInputs: (inputType: string, key: string, value: any) => void;
    setFilterInputDates: (inputType: string, values: object[]) => void;
    setDetailLoading: (isLoading: boolean) => void;
    approvePaymentRefund: (formData: any, cb?: () => void) => void;
    clearErrors: () => void;
};


