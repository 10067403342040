export default class QS {
  //queryObject;
  //queryString;
  //inappropriateValues = [null, undefined, ''];

  constructor(queryObject = {}) {
    this.queryObject = queryObject;
    this.queryString = '';
    this.inappropriateValues = [null, undefined, '', []];
    this.createQueryString();
  }

  createQueryString() {
    let queryString = '?';
    for (let [key, value] of Object.entries(this.queryObject)) {
      if (
        this.inappropriateValues.includes(key) ||
        this.inappropriateValues.includes(value) || value.length < 1
      )
        this.eraseQueryObjectKey(key);
      else {
        if (Array.isArray(value)) {
          for (let val of value) {
            queryString += `${key}=${val}&`;
          }
        } else {
          queryString += `${key}=${value}&`;
        }
      }
    }
    this.queryString = queryString.slice(0, -1);
  }

  getQueryObject() {
    return this.queryObject;
  }

  setQueryObject(queryObject) {
    this.queryObject = {
      ...this.queryObject,
      ...queryObject,
    };
    this.createQueryString();
  }

  eraseQueryObjectKey(key) {
    delete this.queryObject[`${key}`];
    this.createQueryString();
  }

  getQueryString() {
    return this.queryString;
  }

  setQueryString(queryString) {
    this.queryString = queryString;
  }
}
