export const initialStateData = {
    vendorRating: null,
    questions: {
        answered: null,
        pendingAnswer: null,
    },
    orderMetrics: {
        new: null,
        readyToShipping: null,
        shipped: null,
        canceled: {
            metric: null,
            ratio: null,
        },
    },
    productMetrics: {
        listing: null,
        outOfStock: null,
        nonListing: null,
    },
    performanceMetrics: {
        today: {
            metric: null,
            ratio: null,
        },
        last7Days: {
            metric: null,
            ratio: null,
        },
        last30Days: {
            metric: null,
            ratio: null,
        },
        nextProgressPayment: {
            metric: null,
            date: null,
        },
    },
    qualityMetrics: {
        lateOrders: null,
        pendingActionsOfReturn: null,
        averageDeliveryTimeToCargo: null,
        nonSupplyRatio: null,
        returnRatio: null
    },
};

export const ReducerTypes = {
    GET_DATA: 'GET_DATA',
    GET_DATA_SUCCESS: 'GET_DATA_SUCCESS',
    GET_DATA_FAIL: 'GET_DATA_FAIL',
    DASHBOARD_ERROR: 'DASHBOARD_ERROR',
    CLEAR_ERRORS: 'CLEAR_ERRORS'
}

export interface IDashboardContext {
    data?: object;
    loading?: boolean;
    error?: any;
}

export type DashboardContextType = {
    state?: IDashboardContext | any;
    getData: () => void;
    clearErrors: () => void;
};


