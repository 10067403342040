import {useReducer} from 'react';

import UserContext from './UserContext';
import UserReducer from './UserReducer';
import {ReducerTypes, IUserContext} from '../types/User';

import {localApi, excelServiceApi} from '@utils/axiosInstances';
import notify from "@antd/Notification";
import {read, utils, writeFileXLSX} from "xlsx";
import XLSX from 'sheetjs-style';

import {CURRENCIES_BY_TYPE} from '@utils/constants/currencies.config';
import moment from "moment";

const UserState = (props: any) => {

    const initialState: IUserContext = {
        products: null,
        allProducts: null,
        uploadProductsPayload: null,
        uploadedProducts: null,
        uploadedProductsError: [],
        searchedProducts: null,
        productPriceAnalyses: null,
        idForPriceAnalysis: null,
        searchProductsText: '',
        currencies: [],
        processStatus: null,
        loading: null,
        error: null
    };

    const [state, dispatch] = useReducer(UserReducer, initialState);

    // Get Products
    const getProducts = async () => {
        try {
            await setLoading(true);
            const res = await localApi.get(`/vendor/products`, {timeout: 60000});
            await dispatch({type: ReducerTypes.GET_PRODUCTS, payload: res?.data});
            await setLoading(false);
            if (state.searchProductsText !== '') {
                await filterProductsBySearchText(state.searchProductsText);
            }
        } catch (err) {
            dispatch({type: ReducerTypes.PROCESS_FAIL, payload: err?.response?.data?.message});
        }
    };

    // Download Excel of Products
    const getProductsExcel = async () => {
        try {
            await setLoading(true);
            const res = await localApi.get(`/vendor/products-excel`, {timeout: 60000});
            console.log('data', res?.data);
            await dispatch({type: ReducerTypes.GET_PRODUCTS_EXCEL, payload: res?.data});

            const mappedData = res?.data.map(el => ({
                // "Ürün ID": el.productId,
                "Ürün Stok ID": el.productStockId,
                "Ürün Adı": el.name,
                "Ürün Varyant Adı": el.variantTitle,
                "Katalog Numarası": el.catalogNumber,
                "Döviz Türü": el.currencyName,
                "Stok Miktarı": el.amount,
                "Piyasa Satış Fiyatı": el.marketPrice,
                "Dentalpiyasa Satış Fiyatı": el.price,
                "Net Depo Geliri": el.warehouseIncome,
                "Çok Al Az Öde Miktarı": el.minBatchAmount,
                "Çok Al Az Öde Fiyatı": el.batchUnitPrice,
                "Ürün Miadı": el.expiration,
                '-': '-'
            }));

            const dataLength = mappedData.length;

            const ws = utils.json_to_sheet(mappedData);

            for (let i = 2; i <= dataLength + 1; i++) {

                // Change cell A1 to the text "HI"
                ws[`J${i}`] = {
                    // t: 's', // <-- t: 's' indicates the cell is a text cell
                    // v: "=A2+B2" // <-- v holds the value
                    f: `F${i}+G${i}`,
                    l: {
                        Target: '#',
                        Tooltip: "Find us @ SheetJS.com!"
                    }
                };

                // if (!ws[`B${i}`].c) ws[`B${i}`].c = [];
                // ws[`B${i}`].c.push({a: "SheetJS", t: "Bu ürün adıdır :-)"});


                if (!ws?.M2?.c) ws.M2.c = [];
                ws.M2.c.push({a: "SheetJS", t: "Bilgilendirme, not vs. istediğin her şey.", T: true});


                ws[`A${i}`].s = {
                    fill: {fgColor: {rgb: "BDD7EE"}},
                    alignment: {horizontal: "center", vertical: "center", wrapText: true},
                    border: {
                        top: {style: "thin"},
                        bottom: {style: "thin"},
                        left: {style: "thin"},
                        right: {style: "thin"}
                    }
                }

                ws[`L${i}`].t = 'd';
                ws[`L${i}`].z = "dd-mm-yyyy";
            }

            ws['!cols'] = Array.from(new Array(12), () => ({wch: 20}));

            console.log('WS_', ws);

            const wb = utils.book_new();
            // utils.book_append_sheet(wb, ws, "Data");

            XLSX.utils.book_append_sheet(wb, ws, "Dentalpiyasa Ürünleriniz");
            XLSX.writeFile(wb, `Satıştaki Ürünlerim_${new Date().getTime()}.xlsx`);

            // writeFileXLSX(wb, `Satıştaki Ürünlerim_${new Date().getTime()}.xlsx`);

            await setLoading(false);
        } catch (err) {
            dispatch({type: ReducerTypes.PROCESS_FAIL, payload: err?.response?.data?.message});
        }
    };

    const getProductsExcel2 = async () => {
        await setLoading(true);

        const response = await localApi.get(`/vendor/products-excel`, {timeout: 240000});
        console.log('data', response?.data);

        if (response?.data?.status === 'ok') {
            const link = document.createElement('a');
            link.href = response?.data?.url;
            link.setAttribute('download', `Satıştaki Ürünlerim_${new Date().getTime()}.xlsx`); //or any other extension
            document.body.appendChild(link);
            link.click();
        } else {
            notify({
                type: 'error',
                title: 'Bir hata ile karşılaşıldı',
                description: response?.data?.description
            });
        }

        /*
        const response = await excelServiceApi.post(`/upload-vendor-excel-data`, JSON.stringify(res?.data), {
            responseType: 'arraybuffer',
            headers: {
                'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            },
            timeout: 60000
        });
        */

        await setLoading(false);

        // var file = window.URL.createObjectURL(new Blob([response.data]));
        // window.location.assign(file);
    }

    // Prepare Product Conclusion Data before upload
    const prepareProductUploadConclusion = async (e, cb) => {
        try {
            dispatch({type: ReducerTypes.CLEAR_EXCEL_ERROR});

            await setLoading(true);

            const productsExcelRes = await localApi.get(`/vendor/products-excel-data`, {timeout: 60000});
            const vendorProducts = productsExcelRes?.data;

            const file = await e.target.files[0].arrayBuffer();
            console.log('FILE', file);
            const wb = read(file, {cellFormula: true});
            console.log('WB', wb);
            const ws = wb.Sheets[wb.SheetNames[0]]; // get the first worksheet
            console.log('WS BEFORE', ws);
            console.log('VP_LENGTH', vendorProducts.length);
            /*
            for (let i = 2; i <= vendorProducts.length + 1; i++) {
                console.log('İ', i);
                console.log('W', ws[`N${i}`]?.w || ws[`N${i}`]);
                if (ws[`A${i}`]?.v) {
                    ws[`N${i}`].h = ws[`N${i}`].w;
                    ws[`N${i}`].r = `<t>${ws[`N${i}`].w}</t>`;
                    ws[`N${i}`].t = 'd';
                    ws[`N${i}`].v = ws[`N${i}`].w;
                    ws[`N${i}`].z = "dd/mm/yyyy";
                } else {
                    break;
                }
            }
            */

            for (let i = 2; i <= Object.keys(ws).filter(el => el[0] === 'N')?.length + 1; i++) {
                if (ws[`N${i}`]) {
                    ws[`N${i}`].h = ws[`N${i}`]?.w;
                    ws[`N${i}`].r = `<t>${ws[`N${i}`]?.w}</t>`;
                    ws[`N${i}`].t = 'd';
                    ws[`N${i}`].v = ws[`N${i}`]?.w;
                    ws[`N${i}`].z = "dd/mm/yyyy";
                }
            }

            console.log('WS', ws);
            const data: any = utils.sheet_to_json(ws); // generate objects
            console.log('INCOMING EXCEL DATA', data);

            const mappedData = await data?.map((el) => ({
                productId: parseInt(el["Ürün ID"]),
                dpCommission: el['DP Komisyon'],
                productStockId: el["Ürün Stok ID"],
                currencyType: CURRENCIES_BY_TYPE[el["Döviz Türü"]],
                amount: parseFloat(String(el["Stok Miktarı"])),
                marketPrice: parseFloat(el["Piyasa Satış Fiyatı"]),
                price: parseFloat(el["Dentalpiyasa Satış Fiyatı"]),
                warehouseIncome: (el["Dentalpiyasa Satış Fiyatı"] - (el["Dentalpiyasa Satış Fiyatı"] * (parseFloat(el['DP Komisyon'].replace('%', '')) / 100)))?.toFixed(2),  //el["Net Depo Geliri"],
                minBatchAmount: parseFloat(el["Çok Al Az Öde Miktarı"]) || 0,
                batchUnitPrice: parseFloat(el["Çok Al Az Öde Fiyatı"]) || 0,
                expiration: el["Ürün Miadı"]
            }));

            console.log('vendorProducts', vendorProducts);
            console.log('uploadedProducts', mappedData);

            const lastData = await mappedData.reduce((acc, value) => {

                const f = vendorProducts.find(el => el.productStockId === value.productStockId);
                if (f) {
                    acc.push({
                        name: f.name,
                        variantTitle: f.variantTitle,
                        catalogNumber: f.catalogNumber,
                        productId: f.productId,
                        baseProductId: f.baseProductId,
                        dpCommission: value.dpCommission,
                        n_amount: value.amount,
                        n_batchUnitPrice: value.batchUnitPrice,
                        n_currencyType: value.currencyType,
                        n_expiration: value.expiration,
                        n_marketPrice: value.marketPrice,
                        n_minBatchAmount: value.minBatchAmount,
                        n_price: value.price,
                        n_productId: value.productId,
                        n_productStockId: value.productStockId,
                        n_warehouseIncome: value.warehouseIncome,
                        o_amount: f.amount,
                        o_batchUnitPrice: f.batchUnitPrice || 0,
                        o_currencyType: f.currencyType,
                        o_expiration: moment(f.expiration).format('MM/YYYY'),
                        o_marketPrice: f.marketPrice,
                        o_minBatchAmount: f.minBatchAmount || 0,
                        o_price: f.price,
                        o_productId: f.productId,
                        o_productStockId: f.productStockId,
                        o_warehouseIncome: f.warehouseIncome,
                    });
                }

                return acc;
            }, []);

            console.log('last_data', lastData);

            await setLoading(false);

            if (cb) {
                cb(lastData);
            }

        } catch (err) {
            console.log('ERROR!', err);
            dispatch({type: ReducerTypes.PROCESS_FAIL, payload: err?.response?.data?.message});
        }
    }

    // Upload Excel of Products
    const uploadProductsExcelAsFile = async (e, cb) => {
        try {
            await setLoading(true);
            const file = await e.target.files[0].arrayBuffer();
            console.log('FILE', file);
            const wb = read(file);
            console.log('WB', wb);
            const ws = wb.Sheets[wb.SheetNames[0]]; // get the first worksheet
            console.log('WS', ws);
            const data: any = utils.sheet_to_json(ws); // generate objects

            // const f = await (await fetch("https://sheetjs.com/pres.xlsx")).arrayBuffer();
            // const wb = read(f); // parse the array buffer
            // const ws = wb.Sheets[wb.SheetNames[0]]; // get the first worksheet
            // const data = utils.sheet_to_json(ws); // generate objects

            const mappedData = JSON.stringify(await data?.map((el) => ({
                productId: parseInt(el["Ürün ID"]),
                productStockId: el["Ürün Stok ID"],
                currencyType: CURRENCIES_BY_TYPE[el["Döviz Türü"]],
                amount: parseFloat(String(el["Stok Miktarı"])),
                marketPrice: parseFloat(el["Piyasa Satış Fiyatı"]),
                price: parseFloat(el["Dentalpiyasa Satış Fiyatı"]),
                warehouseIncome: el["Net Depo Geliri"],
                minBatchAmount: parseFloat(el["Çok Al Az Öde Miktarı"]) || 0,
                batchUnitPrice: parseFloat(el["Çok Al Az Öde Fiyatı"]) || 0,
                expiration: el["Ürün Miadı"]
            })));

            const res = await localApi.put('/vendor/products/upload', mappedData, {timeout: 60000});

            console.log('RESULT!', res);

            dispatch({
                type: ReducerTypes.UPLOAD_PRODUCTS_EXCEL,
                payload: res?.data
            });

            await setLoading(false);

            if (cb) {
                cb();
            }

            notify({
                type: 'success',
                title: 'İşlem Başarılı',
                description: 'Ürün başarılı bir şekilde eklendi/güncellendi.'
            });

        } catch (err) {
            console.log('erro', err);
            dispatch({type: ReducerTypes.PROCESS_FAIL, payload: err?.response?.data?.message});
        }
    };

    const uploadProductsExcel = async (data, cb) => {
        try {

            dispatch({type: ReducerTypes.CLEAR_EXCEL_ERROR});

            const res = await localApi.put('/vendor/products/upload', data, {timeout: 60000});

            console.log('RESULT!', res?.data?.result);

            if (res?.data.status === 'error' && res?.data.error === 'validation') {

                dispatch({
                    type: ReducerTypes.UPLOAD_PRODUCTS_EXCEL_ERROR,
                    payload: Object.entries(res?.data?.result)
                });

                notify({
                    type: 'error',
                    title: 'Bir hata ile karşılaşıldı',
                    description: 'Yüklediğiniz excel verisinde eksiklikler/hata mevcut.'
                });

            } else {

                cb(false);

                notify({
                    type: 'success',
                    title: 'İşlem Başarılı',
                    description: 'Ürün başarılı bir şekilde eklendi/güncellendi.'
                });

                dispatch({
                    type: ReducerTypes.UPLOAD_PRODUCTS_EXCEL,
                    payload: res?.data
                });
            }

            await setLoading(false);

            if (cb) {
                // cb();
            }

        } catch (err) {
            console.log('erro', err);
            dispatch({type: ReducerTypes.PROCESS_FAIL, payload: err?.response?.data?.message});
        }
    };

    // Get All Products
    const getAllProducts = async () => {
        try {
            await setLoading(true);
            const res = await localApi.get(`/vendor/all-products`, {timeout: 60000});
            await dispatch({type: ReducerTypes.GET_ALL_PRODUCTS, payload: res?.data});
            await setLoading(false);
        } catch (err) {
            dispatch({type: ReducerTypes.PROCESS_FAIL, payload: err?.response?.data?.message});
        }
    };

    // Set Base Product Id for Price Analysis
    const setIdForPriceAnalysis = async (baseProductId) => {
        try {
            await dispatch({type: ReducerTypes.SET_ID_FOR_PRICE_ANALYSIS, payload: baseProductId});
        } catch (err) {
            dispatch({type: ReducerTypes.PROCESS_FAIL, payload: err?.response?.data?.message});
        }
    };

    // Get Product Analyses
    const getProductPriceAnalyses = async (cb) => {
        try {
            if (state.idForPriceAnalysis !== null) {
                await setLoading(true);
                const res = await localApi.get(`/vendor/products/${state.idForPriceAnalysis}/price-analyses`, {timeout: 60000});
                await dispatch({type: ReducerTypes.GET_PRODUCT_PRICE_ANALYSES, payload: res?.data});

                if (cb) {
                    cb();
                }

                await setLoading(false);
            }
        } catch (err) {
            dispatch({type: ReducerTypes.PROCESS_FAIL, payload: err?.response?.data?.message});
        }
    };

    // Set Search Products Text
    const setSearchProductsText = async (text) => {
        await filterProductsBySearchText(text);
        dispatch({
            type: ReducerTypes.SET_SEARCH_PRODUCTS_TEXT,
            payload: text
        });
    }

    // Filter Products By Search Text
    const filterProductsBySearchText = async (text) => dispatch({
        type: ReducerTypes.FILTER_PRODUCTS_BY_SEARCH_TEXT,
        payload: text
    });

    // Get Currencies
    const getCurrencies = async () => {
        try {
            const res = await localApi.get(`/vendor/currencies`);

            dispatch({type: ReducerTypes.GET_CURRENCIES, payload: res?.data});
        } catch (err) {
            dispatch({type: ReducerTypes.PROCESS_FAIL, payload: err?.response?.data?.message});
        }
    };

    // Delete Product
    const deleteProduct = async listingId => {
        try {

            dispatch({type: ReducerTypes.SET_LOADING, payload: true});

            await localApi.delete('/vendor/products/set-stocks/' + listingId);

            dispatch({type: ReducerTypes.DELETE_PRODUCT});

            dispatch({type: ReducerTypes.SET_LOADING, payload: false});

            await getProducts();
            await setSearchProductsText(state.searchProductsText);

            notify({
                type: 'success',
                title: 'İşlem Başarılı',
                description: 'Ürünler başarılı bir şekilde kaldırıldı.'
            });

        } catch (err) {
            console.log('product not deleted', err);
            dispatch({
                type: ReducerTypes.PROCESS_FAIL,
                payload: err?.response?.data?.message
            });
        }
    };

    const test = async (e) => {
        console.log('HELLO11');
        const file = await e.target.files[0].arrayBuffer();
        const wb = read(file, {
            cellFormula: true,
            cellHTML: true,
            cellNF: true,
            cellText: true,
        });
        console.log('HELLO2');
        console.log('WB', wb);

        const ws_name = wb.SheetNames[0];
        var ws = wb.Sheets[ws_name];

        // Change cell A1 to the text "HI"
        ws["K2"] = {
            // t: 's', // <-- t: 's' indicates the cell is a text cell
            // v: "=A2+B2" // <-- v holds the value
            f: 'A2+B2'
        };

        ws.K2.l = {
            Target: '#',
            Tooltip: "Find us @ SheetJS.com!"
        }

        if (!ws.C2.c) ws.C2.c = [];
        ws.C2.c.push({a: "SheetJS", t: "Bu ürün adıdır :-)"});

        const el = document.createElement("div");
        el.innerHTML = `<b>hello</b>`

        // ws.A6.h = el;
        // ws.A6.r = el;

        ws.B2.s = {
            fill: {fgColor: {rgb: "BDD7EE"}},
            alignment: {horizontal: "center", vertical: "center", wrapText: true},
            border: {
                top: {style: "thin"},
                bottom: {style: "thin"},
                left: {style: "thin"},
                right: {style: "thin"}
            }
        }


        // const wb = utils.book_new();
        // utils.book_append_sheet(wb, ws, "Data");

        XLSX.utils.book_append_sheet(wb, ws, "SheetName");
        XLSX.writeFile(wb, 'FileName.xlsx');
        // writeFileXLSX(wb, `DENEME_${new Date().getTime()}.xlsx`);

    }

    // Set Loading
    const setLoading = (isLoading) => dispatch({type: ReducerTypes.SET_LOADING, payload: isLoading});

    // Clear Errors
    const clearErrors = () => dispatch({type: ReducerTypes.CLEAR_ERRORS});

    return (
        <UserContext.Provider
            value={{
                state: {...state},
                getProducts,
                getProductsExcel,
                getProductsExcel2,
                prepareProductUploadConclusion,
                uploadProductsExcelAsFile,
                uploadProductsExcel,
                getAllProducts,
                setIdForPriceAnalysis,
                getProductPriceAnalyses,
                filterProductsBySearchText,
                setSearchProductsText,
                getCurrencies,
                deleteProduct,
                setLoading,
                clearErrors,
                test
            }}
        >
            {props.children}
        </UserContext.Provider>
    );
};

export default UserState;
