import axios from "axios";
import QS from "@utils/QS";

export const SIGNED_URL = 'https://file-service.kotuz.dev/uploads';

export const AWS_IMAGE_BUCKETS = {
  NAMES: {
    DOCUMENT: {
      name: 'dental-piyasa-documents',
      PATHS: {
        vendor: (filename) => `vendors/${filename}`, // certificate.jpg OR document.jpg, etc.
        vendorInvoices: (filename) => `vendor-invoices/${filename}`, // certificate.jpg OR document.jpg, etc.
        user: (filename) => `users/${filename}`, // certificate.jpg OR document.jpg, etc.
      }
    },
    PRODUCT: {
      name: 'dental-product-images',
      PATHS: {
        baseProduct: (filename) => `base-products/${filename}`, // thumbnail.jpg AND 1,2,3.jpg
        product: (filename) => `products/${filename}` // thumbnail.jpg AND  1,2,3.jpg
      }
    },
    PUBLIC: {
      name: 'dental-public-images',
      PATHS: {
        vendor: (filename) => `vendors/${filename}`, // thumbnail.jpg OR 1,2,3.jpg
        user: (filename) => `users/${filename}`, // thumbnail.jpg OR 1,2,3.jpg
        brand: (filename) => `brands/${filename}`, // thumbnail.jpg OR 1,2,3.jpg
        slider: (filename) => `sliders/${filename}`, // thumbnail.jpg OR 1,2,3.jpg
        category: (filename) => `categories/${filename}`, // thumbnail.jpg OR 1,2,3.jpg
        return: (filename) => `returns/${filename}`, // thumbnail.jpg OR 1,2,3.jpg
        review: (filename) => `reviews/${filename}`, // thumbnail.jpg OR 1,2,3.jpg
        suggestion: (filename) => `suggestions/${filename}`, // thumbnail.jpg OR 1,2,3.jpg
        change: (filename) => `changes/${filename}`, // thumbnail.jpg OR 1,2,3.jpg
      }
    }
  },
  OPERATIONS: {
    FETCH: 'GET',
    UPLOAD: 'PUT'
  }
};

export const createBucketRequest = async (bucketOptions, file, filename, setProgress) => {
  return new Promise(async (resolve, reject) => {
    const qs = new QS(bucketOptions);
    const formData = new FormData();
    formData.append('file', file, filename ? filename : file.name);

    axios.request({
      method: "POST",
      url: SIGNED_URL + qs.getQueryString(),
      data: formData,
      onUploadProgress: (p) => {
        setProgress(p.loaded / p.total);
      }
    }).then((response) => {
      setProgress(1.0);
      resolve(response.data);
    })
      .catch(err => reject(err));
  });
};
