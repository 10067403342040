import {ReducerTypes, newProductStockInputEmptyFields} from '../types/AddProduct';

export default (state, action) => {
    switch (action.type) {
        case ReducerTypes.SET_SEARCH_TERM:
            return {
                ...state,
                searchTerm: action.payload,
            };
        case ReducerTypes.SEARCH_PRODUCTS:
            return {
                ...state,
                products: action.payload,
                loading: false,
                error: null,
                processStatus: true
            };
        case ReducerTypes.SELECT_PRODUCT:
            return {
                ...state,
                baseProductId: action.payload.id,
                newProductStockInputs: newProductStockInputEmptyFields,
                selectedProduct: action.payload,
                beforeCurrencyType: action.payload.variants.find(el => el?.currencyShortName !== null)?.currencyShortName || null,
                newProductStocks: [...action.payload.variants.map((el) => ({
                    checked: false,
                    onValidation: false,
                    packageContentShow: true,
                    id: el?.id,
                    productStockId: el?.productStockId,
                    name: el?.name,
                    variantTitle: el?.variantTitle,
                    catalogNo: el?.catalogNo,
                    packageContent: el?.packageContent,
                    minPrice: el?.minPrice || 0,
                    minPriceUSD: el?.minPriceUSD || 0,
                    minPriceEUR: el?.minPriceEUR || 0,
                    currencyType: el?.currencyType || null,
                    currencyShortName: el?.currencyShortName || null,
                    amount: el?.amount || 0,
                    marketPrice: el?.originalMarketPrice || 0,
                    price: el?.originalPrice || 0,
                    warehouseIncome: el?.originalWarehouseIncome || 0,
                    propertyValues: el?.propertyValues || null,
                    minBatchAmount: el?.minBatchAmount || 0,
                    batchUnitPrice: el?.originalBatchUnitPrice || 0,
                    expiration: el?.expiration || '',
                    hasExpiration: true,
                }))],
                loading: false,
                error: null,
                processStatus: true
            };
        case ReducerTypes.ADD_PRODUCTS:
            return {
                ...state,
                loading: false,
                error: null,
                processStatus: true
            };
        case ReducerTypes.SET_PRODUCT_STOCK_INPUTS:
            return {
                ...state,
                newProductStockInputs: {
                    ...state.newProductStockInputs,
                    ...action.payload
                }
            };
        case ReducerTypes.MUTATE_NEW_PRODUCT_STOCKS:
            return {
                ...state,
                newProductStocks: state.newProductStocks.map(el => {
                    if (el.checked || action.payload === 'currencyType' || action.payload === 'currencyShortName') {
                        const mappedObj = {
                            ...el,
                            [action.payload]: state.newProductStockInputs[action.payload]
                        }
                        mappedObj.onValidation = (mappedObj.marketPrice > 0 || mappedObj.price > 0 || mappedObj.warehouseIncome > 0)
                        return mappedObj;
                    } else return el;
                })
            };
        case ReducerTypes.VALIDATE_NEW_PRODUCT_STOCKS:
            return {
                ...state,
                newProductStocksValidation: action.payload
            };
        case ReducerTypes.SELECT_NEW_PRODUCT_STOCKS:
            return {
                ...state,
                newProductStocks: state.newProductStocks?.map(el => ({
                    ...el,
                    checked: action.payload
                }))
            };
        case ReducerTypes.SET_NEW_PRODUCT_STOCK:
            return {
                ...state,
                newProductStocks: state.newProductStocks?.map(el => {
                    if (el.id == action.id) {
                        const mappedObj = {
                            ...el,
                            ...action.payload
                        }
                        mappedObj.onValidation = (mappedObj.marketPrice > 0 || mappedObj.price > 0 || mappedObj.warehouseIncome > 0)
                        return mappedObj;
                    } else return el;
                })
            };
        case ReducerTypes.SET_LOADING:
            return {
                ...state,
                loading: action.payload
            };
        case ReducerTypes.PROCESS_FAIL:
            return {
                ...state,
                token: null,
                isAuthenticated: false,
                loading: false,
                user: null,
                error: action.payload || null,
            };
        case ReducerTypes.CLEAR_ERRORS:
            return {
                ...state,
                error: null
            };
        default:
            return state;
    }
};
