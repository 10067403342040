import {ReducerTypes} from '../types/Payout';

export default (state, action) => {
    switch (action.type) {
        case ReducerTypes.GET_PAYOUT_ORDERS:
            return {
                ...state,
                payoutOrders: null,
                loading: true,
                error: null,
            };
        case ReducerTypes.GET_PAYOUT_ORDERS_SUCCESS:
            return {
                ...state,
                ...action.payload,
                payoutOrders: action.payload,
                searchedPayoutOrders: action.payload,
                loading: false,
                error: null,
            };
        case ReducerTypes.GET_PAYOUT_WEEK_SUCCESS:
            return {
                ...state,
                selectedWeekPayout: action.payload,
                loading: false,
                error: null,
            };
        case ReducerTypes.GET_PAYOUT_WEEK_FAIL:
            return {
                ...state,
                selectedWeekPayout: null,
                loading: false,
                error: action.payload || null,
            };
        case ReducerTypes.SET_SELECTED_PAYOUT_DATE:
            return {
                ...state,
                selectedPayoutDate: action.payload
            }
        case ReducerTypes.PAYOUT_ORDERS_ERROR:
        case ReducerTypes.GET_PAYOUT_ORDERS_FAIL:
            return {
                ...state,
                loading: false,
                payoutOrders: null,
                searchedPayoutOrders: null,
                error: action.payload || null,
            };
        case ReducerTypes.GET_PAYOUT_HISTORY:
            return {
                ...state,
                payoutHistory: null,
                loading: true,
                error: null,
            };
        case ReducerTypes.GET_PAYOUT_HISTORY_SUCCESS:
            return {
                ...state,
                ...action.payload,
                payoutHistory: action.payload.days,
                payoutHistoryItems: action.payload.items,
                loading: false,
                error: null,
            };
        case ReducerTypes.SEARCH_ORDER_ITEM_BY_TX_ID:
            return {
                ...state,
                searchedOrderItem: null,
                loading: true,
                error: null,
            };
        case ReducerTypes.SEARCH_ORDER_ITEM_BY_TX_ID_SUCCESS:
            return {
                ...state,
                ...action.payload,
                searchedOrderItem: action.payload,
                loading: false,
                error: null,
            };
        case ReducerTypes.SEARCH_ORDER_ITEM_BY_TX_ID_FAIL:
        case ReducerTypes.GET_PAYOUT_HISTORY_FAIL:
        case ReducerTypes.PAYOUT_HISTORY_ERROR:
            return {
                ...state,
                loading: false,
                payoutHistory: null,
                searchedOrderItem: null,
                error: action.payload || null,
            };
        case ReducerTypes.GET_DASHBOARD:
            return {
                ...state,
                dashboard: null,
                loading: true,
                error: null,
            };
        case ReducerTypes.GET_DASHBOARD_SUCCESS:
            return {
                ...state,
                ...action.payload,
                dashboard: action.payload,
                loading: false,
                error: null,
            };
        case ReducerTypes.DASHBOARD_ERROR:
        case ReducerTypes.GET_DASHBOARD_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload || null,
            };
        case ReducerTypes.CLEAR_ERRORS:
            return {
                ...state,
                error: null
            };
        default:
            return state;
    }
};
