import {useContext, useEffect, useState, useRef} from 'react';
import styles from '@dv/scss/lib/Modal.module.scss';
import Modal from "@organisms/Modal";
import {Button} from "@dv/react";
import OrderContext from "@contexts/Order/OrderContext";

const PartialCancelModal = ({title, show, setShow, orderStatusId, orderId, products, setDetail}) => {
    const orderContext = useContext(OrderContext);

    const allItemsCheckboxRef = useRef();
    const [selectedItems, setSelectedItems] = useState([]);
    const [showProducts, setShowProducts] = useState([]);
    const [allItemsChecked, setAllItemsChecked] = useState(false);

    const onItemSelect = (orderItemId, cOrderItemId, cancelAmount, cAmount) => {
        const values = [{orderItemId: orderItemId, cancelAmount: cancelAmount}];
        if (cOrderItemId) {
            values.push({orderItemId: cOrderItemId, cancelAmount: cAmount || cancelAmount});
        }
        const hasItem = selectedItems.find(el => el.orderItemId === orderItemId);
        if (hasItem) {
            setSelectedItems(selectedItems.filter(el => el.orderItemId !== orderItemId && el.orderItemId !== cOrderItemId));
        } else {
            setSelectedItems((prevState) => [...prevState, ...values]);
        }
    }

    const onAllItemsSelect = () => {
        if (selectedItems?.length >= showProducts?.length) {
            setSelectedItems([]);
        } else {
            setSelectedItems(showProducts?.filter(el => el.submittedForCancelApproval !== 1)?.map(el => {
                if (el.cOrderItemId) {
                    return [{
                        orderItemId: el.orderItemId,
                        cancelAmount: el?._cancelAmount
                    }, {orderItemId: el.cOrderItemId, cancelAmount: el?.cAmount}]
                } else {
                    return {orderItemId: el.orderItemId, cancelAmount: el?._cancelAmount}
                }
            }).flat());
        }
    }

    useEffect(() => {
        if (allItemsCheckboxRef?.current) {
            if (selectedItems?.length > 0 && selectedItems?.length < showProducts?.length) {
                // @ts-ignore
                allItemsCheckboxRef.current.checked = false;
                // @ts-ignore
                allItemsCheckboxRef.current.indeterminate = true;
            } else if (selectedItems?.length === showProducts?.length) {
                // @ts-ignore
                allItemsCheckboxRef.current.checked = true;
                // @ts-ignore
                allItemsCheckboxRef.current.indeterminate = false;
            } else if (selectedItems?.length === 0) {
                // @ts-ignore
                allItemsCheckboxRef.current.checked = false;
                // @ts-ignore
                allItemsCheckboxRef.current.indeterminate = false;
            }
        }
    }, [selectedItems]);

    useEffect(() => {
        setShowProducts(products?.reduce((acc, value) => {

            value._cancelAmount = value.amount;

            if (value.hasCampaign === false && value.isGift === 0) {
                acc.push(value);
                /*
                const items = products?.filter(el => el.productId === value.productId);

                if (items.length === 1) {
                    console.log(1.1);
                    acc.push(value);
                } else {
                    console.log(1.2);
                }
                */
            } else if (value.isGift === 0 && value.hasCampaign === true && value.isCampaignApplied === false) {
                acc.push(value);
            } else if (value.hasCampaign === true && value.isGift === 0) {
                // x al y öde kuralı
                if (value.campaign.campaignRuleTemplate === "discount_by_quantity") {
                    const hasItem = products?.find(el => ((el.hasCampaign === false && el.isGift === 0) && el.productId === value.campaign.preConditionBaseProductId));
                    if (hasItem) {
                        acc.push({
                            ...value,
                            cOrderItemId: hasItem.orderItemId,
                            cAmount: hasItem.amount
                        });
                    } else {
                        acc.push(value);
                    }
                }

                // x alana y hediye kuralı
                else if (value.campaign.campaignRuleTemplate === "buy_together_discount") {
                    const hasItem = products?.find(el => el.isGift === 1 && el.listingId === value.campaign.giftBaseProductId);
                    if (hasItem) {
                        acc.push({
                            ...value,
                            cOrderItemId: hasItem.orderItemId,
                            cAmount: hasItem.amount
                        });
                    }
                }

            } else {
                console.log(3, value.name);
            }
            return acc;
        }, []));
    }, [products]);

    const changeProductCancelAmount = (e, orderItemId) => {
        e.preventDefault();
        setShowProducts(showProducts.map(el => ({
            ...el,
            _cancelAmount: el?.orderItemId == orderItemId ? parseInt(e.target.value) || 0 : el?._cancelAmount
        })));
        changeSelectedItemCancelAmount(orderItemId, parseInt(e.target.value));
    }

    const changeSelectedItemCancelAmount = (orderItemId, cancelAmount) => {
        setSelectedItems(selectedItems.map(el => ({
            ...el,
            cancelAmount: el?.orderItemId == orderItemId ? cancelAmount || 0 : el?.cancelAmount
        })))
    };

    const onApproved = () => {
        console.log(orderStatusId);
        orderContext.submitForCancelApprovalBatch(orderId, selectedItems, () => {
            orderContext.getOrderDetail(orderId, (orderDetail) => {
                setDetail(orderDetail);
                setShow(false);
            });
        })
    }

    return (
        <Modal
            title={title}
            show={show}
            onClose={() => setShow(false)}
            onCancel={() => setShow(false)}
            footerComponent={
                <div className={styles.partial_cancel_modal_footer_section}>
                    <Button onClick={onApproved} disabled={selectedItems?.length === 0}>Seçili Ürünleri İptal
                        Et</Button>
                </div>
            }
        >
            <section className={styles.partial_cancel_modal}>
                <table>
                    <tr>
                        <th><input ref={allItemsCheckboxRef} type={"checkbox"} onChange={onAllItemsSelect}/></th>
                        <th>id</th>
                        <th>Ürün Adı</th>
                        <th>Kampanya</th>
                        <th>Ürün Adeti</th>
                        <th>İptal Edilecek Adet Sayısı</th>
                        <th className={styles.right_align}>Birim Fiyat</th>
                        <th className={styles.right_align}>Toplam Fiyat</th>
                    </tr>
                    {showProducts?.map((item, i) => (
                        <tr key={i}>
                            <th id={item?.orderItemId}><input type={"checkbox"}
                                                              onChange={() => onItemSelect(item?.orderItemId, item?.cOrderItemId || null, item?._cancelAmount, item?.cAmount)}
                                                              checked={!!selectedItems.find(el => el?.orderItemId === item?.orderItemId)}
                                                              disabled={item?.submittedForCancelApproval === 1}/>
                            </th>
                            <td>{item.orderItemId}</td>
                            <td>{item.name} {item.variantTitle}</td>
                            <td>{item.hasCampaign ? (item.isCampaignApplied ? 'Var' : 'Kriterler Karşılanmadı') : 'Yok'}</td>
                            <td>{item.amount}</td>
                            <td><input className={styles.cancel_amount_input}
                                       disabled={!selectedItems.find(el => el?.orderItemId === item?.orderItemId)}
                                       type="number" value={item?._cancelAmount} max={item?.amount} min={1}
                                       onChange={(e) => changeProductCancelAmount(e, item?.orderItemId)}/></td>
                            <td className={styles.right_align}>{item.amountPrice?.toFixed(2)}₺</td>
                            <td className={styles.right_align}>{item.totalPrice?.toFixed(2)}₺</td>
                        </tr>
                    ))}
                </table>
            </section>
        </Modal>
    )
}

export default PartialCancelModal;
