import React from 'react';
import {Select as AntSelect} from 'antd';

import '@dv/scss/lib/Select.antd.css';

interface OptionProps {
    value: string;
    label: string;
}

interface SelectProps {
    theme?: string;
    defaultValue?: string;
    value?: any;
    options: OptionProps[];
    onChange: (e: any, b: any) => void;
    onSelect?: (e: any, b: any) => void;
    placeholder?: string | React.ReactNode;
    showSearch?: boolean;
    onSearch?: any;
    filterOption?: any;
    loading?: boolean;
    width?: string;
    required?: boolean;
    disabled?: boolean;
}

const Select: React.FC<SelectProps> = ({
                                           theme,
                                           defaultValue,
                                           value,
                                           placeholder,
                                           showSearch,
                                           onSearch,
                                           filterOption,
                                           width,
                                           options,
                                           onChange,
                                           onSelect,
                                           required,
                                           disabled,
                                           ...rest
                                       }) => {
    const {Option} = AntSelect;

    return (
        <AntSelect defaultValue={defaultValue} className={`select_primary ${theme}`} onChange={onChange}
                   placeholder={placeholder} onSearch={onSearch} value={value} onSelect={onSelect}
                   filterOption={filterOption ||= (input, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                   showSearch={showSearch} style={{width: 'auto'}} aria-required={required || false}
                   disabled={disabled || false} {...rest}>
            {options.map((el, i) => <Option key={i} value={el.value}>{el.label}</Option>)}
        </AntSelect>
    );
};

export default Select;
