import React from "react";
import styles from "@dv/scss/lib/Table.module.scss";
import Pagination from "@antd/Pagination";

import Select from "@antd/Select";

export interface TableProps {
    children: any;
    headers?: any;
    rows?: any;
    pagination?: any;
    filters?: any;
    type?: any;
}


export const Header: React.FC<any> = ({children, type, fitContent, ...others}) => {
    switch (type) {
        case 'complex':
            return <div className={`${styles.header} ${styles.complex}`} {...others}>{children}</div>;
        case 'standard':
        default:
            return <div className={styles.header} {...others}>{children}</div>;
    }
}

export const Row: React.FC<any> = ({children, type, ...others}) => {
    switch (type) {
        case 'complex':
            return <div className={`${styles.row} ${styles.complex}`} {...others}>{children}</div>;
        case 'standard':
        default:
            return <div className={styles.row} {...others}>{children}</div>;
    }
}

export const Cell: React.FC<any> = ({children, type, fitContent, ...others}) => {
    switch (type) {
        case 'complex':
            return <div
                className={`${styles.column} ${styles.complex} ${fitContent && 'fit-content'}`} {...others}>{children}</div>;
        case 'standard':
        default:
            return <div className={`${styles.column} ${fitContent && 'fit-content'}`} {...others}>{children}</div>;
    }
}

const Table: React.FC<TableProps> = ({type = undefined, children, headers, pagination, filters}) => {

    switch (type) {
        case 'complex':
            return (
                <div className={styles.table_wrapper}>
                    {
                        (filters || pagination) && <div className={styles.filter}>
                            {filters &&
                              <Select
                                options={[
                                    {value: 'date_desc', label: 'Sipariş Tarihi (Eskiden Yeniye)'},
                                    {value: 'date_asc', label: 'Sipariş Tarihi (Yeniden Eskiye)'}
                                ]}
                                showSearch
                                placeholder="Filtre Seçiniz"
                                onChange={() => {
                                }}
                              />
                            }
                            {
                                pagination &&
                              <Pagination total={pagination?.total} defaultPageSize={pagination.defaultPageSize}
                                          defaultCurrent={pagination.defaultCurrent}
                                          setSizeChanger={(e) => pagination.setSizeChanger(e)}/>
                            }
                      </div>
                    }

                    <div className={`${styles.table} ${styles.complex}`}>
                        <div className={styles.headers}>
                            {headers}
                        </div>
                        <div className={styles.content}>
                            {children}
                        </div>
                    </div>
                </div>
            )
        case 'standard':
        default:
            return (
                <div className={styles.table_wrapper}>
                    {
                        (filters || pagination) && <div className={styles.filter}>
                            {filters &&
                              <Select
                                options={[
                                    {value: 'date_desc', label: 'Sipariş Tarihi (Eskiden Yeniye)'},
                                    {value: 'date_asc', label: 'Sipariş Tarihi (Yeniden Eskiye)'}
                                ]}
                                showSearch
                                placeholder="Filtre Seçiniz"
                                onChange={() => {
                                }}
                              />
                            }
                            {
                                pagination &&
                              <Pagination total={pagination?.total} defaultPageSize={pagination.defaultPageSize}
                                          defaultCurrent={pagination.defaultCurrent}
                                          setSizeChanger={(e) => pagination.setSizeChanger(e)}/>
                            }
                      </div>
                    }

                    <div className={styles.table}>
                        <div className={styles.headers}>
                            {headers}
                        </div>
                        <div className={styles.content}>
                            {children}
                        </div>
                    </div>
                </div>
            )
    }
};

export default Table;
