import ContentLoader from "react-content-loader";
import React from "react";

const LOADERS = {
    barChart: <ContentLoader width={200} height={200} backgroundColor="#f4dfc3"
                             foregroundColor="#faa31e" viewBox="0 0 200 200">
        <rect x="30" y="160" rx="0" ry="0" width="25" height="40"/>
        <rect x="60" y="145" rx="0" ry="0" width="25" height="55"/>
        <rect x="90" y="126" rx="0" ry="0" width="25" height="74"/>
        <rect x="120" y="80" rx="0" ry="0" width="25" height="120"/>
        <rect x="150" y="142" rx="0" ry="0" width="25" height="58"/>
    </ContentLoader>,
    barChart2: <ContentLoader width={170} height={150} backgroundColor="#f4dfc3"
                              foregroundColor="#faa31e" viewBox="0 0 170 150">
        <rect x="30" y="120" rx="0" ry="0" width="25" height="40"/>
        <rect x="60" y="105" rx="0" ry="0" width="25" height="55"/>
        <rect x="90" y="86" rx="0" ry="0" width="25" height="74"/>
        <rect x="120" y="40" rx="0" ry="0" width="25" height="120"/>
        <rect x="150" y="102" rx="0" ry="0" width="25" height="58"/>
    </ContentLoader>,
    blockChart: <ContentLoader
        speed={2}
        width={160}
        height={100}
        viewBox="0 0 160 100"
        backgroundColor="#f4dfc3"
        foregroundColor="#faa31e"
    >
        <rect x="5" y="10" rx="3" ry="3" width="130" height="10"/>
        <rect x="5" y="35" rx="3" ry="3" width="80" height="12"/>
        <rect x="5" y="75" rx="3" ry="3" width="100" height="10"/>
    </ContentLoader>,
    blockChartWithRatio: <ContentLoader
        speed={2}
        width={160}
        height={100}
        viewBox="0 0 160 100"
        backgroundColor="#f4dfc3"
        foregroundColor="#faa31e"
    >
        <rect x="5" y="10" rx="3" ry="3" width="130" height="10"/>
        <rect x="5" y="48" rx="3" ry="3" width="80" height="12"/>
        <rect x="5" y="75" rx="3" ry="3" width="100" height="10"/>
        <rect x="5" y="30" rx="3" ry="3" width="30" height="8"/>
        <circle cx="45" cy="34" r="5"/>
    </ContentLoader>,
    qualityMetric: <ContentLoader
        width={700}
        height={350}
        viewBox="0 0 700 350"
        backgroundColor="#f4dfc3"
        foregroundColor="#faa31e"
    >
        <rect x="4" y="8" rx="3" ry="3" width="8" height="317"/>
        <rect x="7" y="317" rx="3" ry="3" width="669" height="8"/>
        <rect x="669" y="9" rx="3" ry="3" width="7" height="313"/>
        <rect x="5" y="8" rx="3" ry="3" width="669" height="7"/>
        <rect x="114" y="52" rx="6" ry="6" width="483" height="15"/>
        <circle cx="77" cy="60" r="15"/>
        <rect x="116" y="105" rx="6" ry="6" width="420" height="15"/>
        <circle cx="78" cy="113" r="15"/>
        <rect x="115" y="158" rx="6" ry="6" width="483" height="15"/>
        <circle cx="78" cy="166" r="15"/>
        <rect x="117" y="211" rx="6" ry="6" width="444" height="15"/>
        <circle cx="79" cy="219" r="15"/>
        <rect x="117" y="263" rx="6" ry="6" width="483" height="15"/>
        <circle cx="80" cy="271" r="15"/>
    </ContentLoader>,
    ratingMetric: <ContentLoader
        speed={2}
        width={300}
        height={60}
        viewBox="0 0 300 60"
        backgroundColor="#f4dfc3"
        foregroundColor="#faa31e"
    >
        <rect x="80" y="8" rx="3" ry="3" width="200" height="8"/>
        <rect x="80" y="24" rx="3" ry="3" width="160" height="8"/>
        <rect x="80" y="44" rx="3" ry="3" width="50" height="6"/>
        <circle cx="30" cy="30" r="30"/>
    </ContentLoader>,
    questionsMetric: <ContentLoader
        speed={2}
        width={300}
        height={60}
        viewBox="0 0 300 60"
        backgroundColor="#f4dfc3"
        foregroundColor="#faa31e"
    >
        <rect x="80" y="8" rx="3" ry="3" width="80" height="8"/>
        <rect x="80" y="24" rx="3" ry="3" width="100" height="8"/>
        <rect x="200" y="24" rx="3" ry="3" width="100" height="8"/>
        <rect x="80" y="44" rx="3" ry="3" width="50" height="6"/>
        <circle cx="30" cy="30" r="30"/>
    </ContentLoader>,
    actionRequestMetrics: <ContentLoader viewBox="0 0 450 100" backgroundColor="#f4dfc3"
                                         foregroundColor="#faa31e">
        <rect x="0" y="10" width="67" height="8" rx="3"/>
        <rect x="76" y="10" width="140" height="8" rx="3"/>
        <rect x="18" y="33" width="140" height="8" rx="3"/>
        <rect x="166" y="33" width="173" height="8" rx="3"/>
        <rect x="127" y="58" width="53" height="8" rx="3"/>
        <rect x="187" y="58" width="72" height="8" rx="3"/>
        <rect x="18" y="58" width="100" height="8" rx="3"/>
        <rect x="0" y="81" width="37" height="8" rx="3"/>
    </ContentLoader>,
    paymentDashboardPrice: <ContentLoader viewBox="0 0 50 50" backgroundColor="#f4dfc3"
                                          foregroundColor="#faa31e">
        <rect x="0" y="81" width="100" height="8" rx="3"/>
    </ContentLoader>
}

export default LOADERS;
