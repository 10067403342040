import React, {useContext, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";

import styles from '@dv/scss/lib/AddProduct.module.scss';

import Layout from "@organisms/Layout";
import {Icons, Title, Card, Button, Input, Tooltip, Tag} from "@dv/react";
import Table, {Cell, Header, Row} from "@organisms/Table";
import Select from "@antd/Select";
import DatePicker from "@antd/DatePicker";

import ProductContext from "@contexts/Product/ProductContext";
import UserContext from "@contexts/User/UserContext";
import notify from "@antd/Notification";
import moment from "moment";

const UpdateProduct: React.FC<any> = () => {
    let {productId} = useParams();
    const navigate = useNavigate();
    const exampleCommission = 10;


    const productContext = useContext(ProductContext);
    const userContext = useContext(UserContext);
    const selectedProduct = productContext.state.selectedProduct;

    useEffect(() => {
        if (!(userContext.state.currencies.length > 0)) {
            userContext.getCurrencies();
        }

        productContext.selectProduct(productId);
    }, []);

    useEffect(() => {
        if (productContext.state.error !== null)
            notify({
                type: 'error',
                title: 'Bir hata ile karşılaşıldı',
                description: productContext.state.error
            });
        productContext.clearErrors();
    }, [productContext.state.error]);

    const [product, setProduct] = useState({
        thumbnail: undefined,
        name: undefined,
        category: undefined,
        brand: undefined,
        packageContent: undefined
    });

    const [productStocks, setProductStocks] = useState({
        currencyType: '0',
        amount: 1,
        marketPrice: '',
        originalMarketPrice: '',
        price: undefined,
        originalPrice: undefined,
        warehouseIncome: undefined,
        originalWarehouseIncome: undefined,
        minBatchAmount: undefined,
        batchUnitPrice: undefined,
        originalBatchUnitPrice: undefined,
        expiration: undefined
    });

    useEffect(() => {
        setProduct({
            thumbnail: selectedProduct?.thumbnail,
            name: selectedProduct?.name,
            category: selectedProduct?.category?.name,
            brand: selectedProduct?.brand,
            packageContent: selectedProduct?.packageContent
        });

        setProductStocks({
            currencyType: selectedProduct?.currencyType || '0',
            amount: selectedProduct?.amount || 1,
            marketPrice: selectedProduct?.originalMarketPrice || '',
            originalMarketPrice: selectedProduct?.originalMarketPrice || '',
            price: selectedProduct?.originalPrice || undefined,
            originalPrice: selectedProduct?.originalPrice || '',
            warehouseIncome: selectedProduct?.originalWarehouseIncome || undefined,
            originalWarehouseIncome: selectedProduct?.originalWarehouseIncome || undefined,
            minBatchAmount: selectedProduct?.minBatchAmount || undefined,
            batchUnitPrice: selectedProduct?.originalBatchUnitPrice || undefined,
            originalBatchUnitPrice: selectedProduct?.originalBatchUnitPrice || undefined,
            expiration: selectedProduct?.expiration || undefined
        });
    }, [selectedProduct]);

    const updateProductStock = (e) => {
        e.preventDefault();

        productContext.updateProducts([{
            listingId: parseInt(productId),
            currencyType: parseInt(productStocks?.currencyType),
            amount: parseFloat(String(productStocks.amount)),
            marketPrice: parseFloat(productStocks.marketPrice),
            price: parseFloat(productStocks.price),
            warehouseIncome: productStocks.warehouseIncome,
            minBatchAmount: parseFloat(productStocks.minBatchAmount),
            batchUnitPrice: parseFloat(productStocks.batchUnitPrice),
            expiration: productStocks.expiration
        }], () => {
            navigate('/products');
        });

        return false;
    }

    const navigateProducts = () => {
        navigate('/products');
    }

    return (
        <Layout title="Update Product">
            <div className={styles.container}>

                <div className={styles.badge_with_navigator} onClick={navigateProducts}>
                    <Title variant="badge" title={"GERİ"}
                           icon={<Icons.GoBackIcon bgColor={"transparent"} color={"white"}/>}/>
                    <Title variant="badge" title={"ÜRÜN GÜNCELLE"} icon={<Icons.CopyIcon color={'white'}/>}/>
                </div>

                <Card>
                    <div className={styles.product_detail}>
                        <div className={styles.image}>
                            <img src={selectedProduct?.thumbnail}/>
                        </div>

                        <div className={styles.detail}>
                            <div className={styles.name}>
                                {selectedProduct?.name} <b>{selectedProduct?.variantTitle}</b>
                            </div>
                            <div className={styles.category}>
                                <b>Kategori:</b> {selectedProduct?.category?.name} | <b>Komisyon
                                Oranı:</b> %{exampleCommission}
                            </div>
                            <div className={styles.ingredient}>
                                AMBALAJ İÇERİĞİ : <span>{selectedProduct?.packageContent}</span>
                            </div>
                        </div>

                        {/*
                        <div className={styles.badge}>
                            <Tag label={"AÇILIŞA ÖZEL %7.5 KOMİSYON (3 Ay İçin Geçerli)"} icon={"DeliveryBoxIcon"}/>
                        </div>
                        */}

                        <div className={styles.logo}>
                            <img src={selectedProduct?.brand?.logo}/>
                        </div>
                    </div>
                </Card>

                <Card>
                    <div className={styles.option_section}>
                        <form className={styles.filter_section} onSubmit={updateProductStock}>
                            <div className={styles.select_with_label}>
                                <label>
                                    PARA CİNSİ <Tooltip title="Açıklama"><Icons.InfoIcon/></Tooltip>
                                </label>
                                <Select
                                    theme={"input"}
                                    options={userContext.state.currencies.map((c) => ({value: c.id, label: c.name}))}
                                    defaultValue={productContext.state.selectedProduct?.currencyType}
                                    showSearch
                                    placeholder="Para Cinsi"
                                    onChange={e => {
                                        setProductStocks({
                                            ...productStocks,
                                            currencyType: e
                                        });
                                    }}
                                />
                            </div>
                            <Input label={"MİKTAR"} type={"number"} min={1} value={productStocks.amount}
                                   tooltip={<Tooltip
                                       title="Satışa çıkaracağınız ürünün adedini giriniz. Bu kısmı 0 olarak ayarlarsanız ürününüz satıştan kalkmış olacaktır, ayrıca fiyatı sıfırlamak gerekmez. Dentalpiyasa'da yalnızca stoklu ürün satılabilmektedir."><Icons.InfoIcon/></Tooltip>}
                                   onChange={(e) => {
                                       setProductStocks({
                                           ...productStocks,
                                           amount: e.target.value
                                       })
                                   }} width={130} required/>
                            <Input label={"PİYASA FİYATI"} type={"text"} value={productStocks.marketPrice}
                                   tooltip={<Tooltip title="Açıklama"><Icons.InfoIcon/></Tooltip>} onChange={(e) => {
                                setProductStocks({
                                    ...productStocks,
                                    marketPrice: e.target.value
                                })
                            }} width={130}/>
                            <Input label={"DP FİYATI"} type={"text"} value={productStocks.price}
                                   tooltip={<Tooltip title="Açıklama"><Icons.InfoIcon/></Tooltip>} onChange={(e) => {
                                const price = e.target.value;
                                const warehouseIncome = price - (price * exampleCommission / 100);
                                setProductStocks({
                                    ...productStocks,
                                    price,
                                    warehouseIncome
                                });
                            }} width={130} required/>
                            <Input label={"NET DEPO GELİRİ"} type={"text"} value={productStocks.warehouseIncome}
                                   tooltip={<Tooltip title="Açıklama"><Icons.InfoIcon/></Tooltip>} onChange={(e) => {
                                const warehouseIncome = e.target.value;
                                const price = (100 * warehouseIncome / 99) + (100 * exampleCommission / 99);
                                setProductStocks({
                                    ...productStocks,
                                    price,
                                    warehouseIncome
                                });
                            }} width={130} required disabled/>
                            <Input label={"AS. TOPLU SATIŞ"} type={"text"} value={productStocks.minBatchAmount}
                                   tooltip={<Tooltip
                                       title="Asgari Toplu Satış Miktarı. Belli bir alım üzerine indirim yapmak istiyorsanız, indirimin uygulanması için alınacak ürün fiyatı belirleyiniz. Örnek: 5 yazarsanız 5 adet ve üzerindeki alımlar için indirimli fiyat uygulanacaktır. Bu alanı boş bırakır veya 0 yaparsanız, ürün çoklu satışa kapalı olacaktır."><Icons.InfoIcon/></Tooltip>}
                                   onChange={(e) => {
                                       setProductStocks({
                                           ...productStocks,
                                           minBatchAmount: e.target.value
                                       });
                                   }} width={130} required/>
                            <Input label={"TOPLU SATIŞ BF."} type={"text"} value={productStocks.batchUnitPrice}
                                   tooltip={<Tooltip
                                       title="Toplu Satış Birim Fiyatı. Belirlediğiniz adet ve üzerindeki satışlarda uygulanacak birim fiyat. Örnek: Ürünün normal fiyatı 10 TL. 5 ve üzeri alımlarda 9 TL. Lütfen tek bir ürün için indirimli fiyat giriniz, sistem gerisini otomatik hesaplayacaktır."><Icons.InfoIcon/></Tooltip>}
                                   onChange={(e) => {
                                       setProductStocks({
                                           ...productStocks,
                                           batchUnitPrice: e.target.value
                                       });
                                   }} width={130} required/>


                            <div className={styles.select_with_label}>
                                <label>
                                    ÜRÜN MİAD <Tooltip title="Açıklama"><Icons.InfoIcon/></Tooltip>
                                </label>
                                <DatePicker defaultValue={productStocks.expiration}
                                            onChange={(date) => {
                                                setProductStocks({
                                                    ...productStocks,
                                                    expiration: moment(date).format('YYYY-MM-DD')
                                                });
                                            }} picker="month" format="MMMM YYYY"/>
                            </div>
                            <Button variant={"primary"} loading={false} disabled={false}>Ürünü Güncelle</Button>
                        </form>
                        <div className={styles.options}>
                            <div className={styles.option}>
                                <div className={styles.name}>
                                    EN DÜŞÜK FİYAT <Tooltip
                                    title={" Halihazırda bu ürünü satan diğer depoların en düşük ücreti. Hekimin ilk tercihi olabilmek için daha düşük bir ücret yazmanızı tavsiye ederiz. Daha düşük fiyat yazamıyorsanız bile, sizden önceki deponun stoklarının tükenebileceği ve sizin 1. sıraya çıkabileceğinizi unutmayınız."}>
                                    <Icons.InfoIcon/>
                                </Tooltip>
                                </div>
                                <div className={styles.values}>
                                    260.27 ₺ / 17.50 USD / 15.93 EUR
                                </div>
                            </div>
                        </div>
                    </div>
                </Card>
            </div>

        </Layout>
    );
};

export default UpdateProduct;
