import {useReducer} from 'react';

import OrderContext from './OrderContext';
import OrderReducer from './OrderReducer';
import {ReducerTypes, IOrderContext} from '../types/Order';

import {localApi} from '@utils/axiosInstances';
import notify from "@antd/Notification";

const OrderState = (props: any) => {

    const initialState: IOrderContext = {
        orders: {
            all: null,
            new: null,
            willBeShipped: null,
            shippingCreated: null,
            shipped: null,
            inDistributed: null,
            delivered: null,
            payoutApproved: null,
        },
        selectedOrder: null,
        searchFilter: {
            status: null
        },
        filterInputs: {
            all: {
                status: null,
                orderNo: '',
                startDate: null,
                endDate: null
            },
            new: {
                status: 100,
                orderNo: '',
                startDate: null,
                endDate: null
            },
            willBeShipped: {
                status: 200,
                orderNo: '',
                startDate: null,
                endDate: null
            },
            shippingCreated: {
                status: 250,
                orderNo: '',
                startDate: null,
                endDate: null
            },
            shipped: {
                status: 300,
                orderNo: '',
                startDate: null,
                endDate: null
            },
            inDistributed: {
                status: 400,
                orderNo: '',
                startDate: null,
                endDate: null
            },
            delivered: {
                status: 500,
                orderNo: '',
                startDate: null,
                endDate: null
            },
            payoutApproved: {
                status: 510,
                orderNo: '',
                startDate: null,
                endDate: null
            }
        },
        processStatus: null,
        loading: null,
        detailLoading: false,
        cargoDesiPriceModalToggle: false,
        error: null
    };

    const [state, dispatch] = useReducer(OrderReducer, initialState);

    // Get Orders
    const getOrders = async () => {
        try {
            const res = await localApi.get(`/vendor/orders`);

            dispatch({type: ReducerTypes.GET_ORDERS, payload: res?.data});
        } catch (err) {
            dispatch({type: ReducerTypes.PROCESS_FAIL, payload: err?.response?.data?.message});
        }
    };

    // Get Canceled Orders
    const getOrderDetail = async (orderId, cb) => {
        try {
            await dispatch({type: ReducerTypes.SET_SELECTED_ORDER, payload: orderId});
            await setDetailLoading(true);
            const res = await localApi.get(`/vendor/orders/${orderId}/detail`);
            await setDetailLoading(false);
            cb(res?.data);
            dispatch({type: ReducerTypes.GET_ORDER_DETAIL});
        } catch (err) {
            dispatch({type: ReducerTypes.PROCESS_FAIL, payload: err?.response?.data?.message});
        }
    };

    // Search Orders
    const searchOrders = async (type) => {
        try {
            const res = await localApi.post(`/vendor/orders/search`, state.filterInputs[type]);
            dispatch({type: ReducerTypes.SEARCH_ORDERS, payload: {data: res?.data, type}});
        } catch (err) {
            dispatch({type: ReducerTypes.PROCESS_FAIL, payload: err?.response?.data?.message});
        }
    };

    // Set Filter Inputs
    const setFilterInputs = async (inputType, key, value) => {
        try {
            dispatch({
                type: ReducerTypes.SET_FILTER_INPUTS,
                payload: {
                    [inputType]: {
                        ...state.filterInputs[`${inputType}`],
                        [key]: value
                    }
                }
            });
        } catch (err) {
            dispatch({type: ReducerTypes.PROCESS_FAIL, payload: err?.response?.data?.message});
        }
    };

    // Set Filter Input Dates
    const setFilterInputDates = async (inputType, values) => {
        try {
            dispatch({
                type: ReducerTypes.SET_FILTER_INPUTS,
                payload: {
                    [inputType]: {
                        ...state.filterInputs[inputType],
                        startDate: values[0],
                        endDate: values[1]
                    }
                }
            });
        } catch (err) {
            dispatch({type: ReducerTypes.PROCESS_FAIL, payload: err?.response?.data?.message});
        }
    };

    // Set Detail Loading
    const setLoading = async (isLoading) => dispatch({type: ReducerTypes.SET_LOADING, payload: isLoading});

    // Set Detail Loading
    const setDetailLoading = async (isLoading) => dispatch({type: ReducerTypes.SET_DETAIL_LOADING, payload: isLoading});

    // Set Order Status
    const setOrderStatus = async (orderId, status, cb) => {
        try {
            await setLoading(true);
            const res = await localApi.put(`/vendor/orders/${orderId}/set-status`, {status}, {timeout: 30000});
            dispatch({type: ReducerTypes.SET_ORDER_STATUS, payload: {data: res?.data}});
            await getOrders();
            await setLoading(false);

            notify({
                type: 'success',
                title: 'İşlem Başarılı',
                description: status === 250 ? 'Kargo görevlisi paketinizi almak üzere 24 saat içerisinde adresinize gelecektir.' : 'Sipariş durumu başarılı bir şekilde güncellendi.'
            });

            if (cb) {
                cb();
            }
        } catch (err) {
            await setLoading(false);

            notify({
                type: 'error',
                title: 'Bir hata ile karşılaşıldı',
                description: err?.response?.data?.detail
            });

            dispatch({type: ReducerTypes.PROCESS_FAIL, payload: err?.response?.data?.message});
        }
    };

    // Cancel Order Status
    const cancelOrderStatus = async (orderStatusId, cb) => {
        try {
            await localApi.post(`/orders/cancel`, {
                orderStatusId
            });
            dispatch({type: ReducerTypes.CANCEL_ORDER_STATUS});
            await getOrders();

            notify({
                type: 'success',
                title: 'İşlem Başarılı',
                description: 'Sipariş için iptal talebi gönderildi.'
            });

            if (cb) {
                cb();
            }
        } catch (err) {

            notify({
                type: 'error',
                title: 'Bir hata ile karşılaşıldı',
                description: err?.response?.data?.detail
            });

            dispatch({type: ReducerTypes.PROCESS_FAIL, payload: err?.response?.data?.message});
        }
    };

    const submitForCancelApproval = async (orderStatusId, orderItemId, cb) => {
        try {
            await localApi.put(`/vendor/orders/${orderStatusId}/submit-for-cancel-approval`, {
                orderItemId
            });
            dispatch({type: ReducerTypes.CANCEL_ORDER_STATUS});
            await getOrders();

            notify({
                type: 'success',
                title: 'İşlem Başarılı',
                description: 'Ürün başarılı bir şekilde iptal onayına gönderildi.'
            });

            if (cb) {
                cb();
            }
        } catch (err) {

            notify({
                type: 'error',
                title: 'Bir hata ile karşılaşıldı',
                description: err?.response?.data?.detail
            });

            dispatch({type: ReducerTypes.PROCESS_FAIL, payload: err?.response?.data?.message});
        }
    };

    const submitForCancelApprovalBatch = async (orderId, orderItemIds, cb) => {
        try {
            await localApi.post(`/orders/${orderId}/request-partial-cancel`, {
                orderItemIds
            });
            dispatch({type: ReducerTypes.CANCEL_ORDER_STATUS});
            await getOrders();

            notify({
                type: 'success',
                title: 'İşlem Başarılı',
                description: 'Ürünler başarılı bir şekilde iptal onayına gönderildi.'
            });

            if (cb) {
                cb();
            }
        } catch (err) {

            notify({
                type: 'error',
                title: 'Bir hata ile karşılaşıldı',
                description: err?.response?.data?.detail
            });

            dispatch({type: ReducerTypes.PROCESS_FAIL, payload: err?.response?.data?.message});
        }
    };


    const setCargoDesiPriceModalToggle = (toggle) => dispatch({
        type: ReducerTypes.SET_CARGO_DESI_PRICE_MODAL_TOGGLE,
        payload: toggle
    });

    const addOrderInvoice = async (orderId, url, cb) => {
        try {
            await localApi.post(`/vendor/orders/${orderId}/add-invoice`, {
                orderId,
                url
            });
            dispatch({type: ReducerTypes.ADD_ORDER_INVOICE});

            await getOrderDetail(orderId, (data) => {
                cb(data);
            });

            notify({
                type: 'success',
                title: 'İşlem Başarılı',
                description: 'Fatura başarılı bir şekilde eklendi.'
            });

        } catch (err) {

            notify({
                type: 'error',
                title: 'Fatura eklenemedi.',
                description: err?.response?.data?.detail
            });

            dispatch({type: ReducerTypes.PROCESS_FAIL, payload: err?.response?.data?.message});
        }
    }

    // Clear Errors
    const clearErrors = () => dispatch({type: ReducerTypes.CLEAR_ERRORS});

    return (
        <OrderContext.Provider
            value={{
                state: {...state},
                getOrders,
                getOrderDetail,
                searchOrders,
                setFilterInputs,
                setFilterInputDates,
                setOrderStatus,
                cancelOrderStatus,
                submitForCancelApproval,
                submitForCancelApprovalBatch,
                setCargoDesiPriceModalToggle,
                addOrderInvoice,
                setLoading,
                setDetailLoading,
                clearErrors
            }}
        >
            {props.children}
        </OrderContext.Provider>
    );
};

export default OrderState;
