import React, {useState} from 'react';
import styles from '@dv/scss/lib/Modal.module.scss';

import {Icons, Checkbox, Button, Input, RadioItem} from "@dv/react/lib";
import Select from "@antd/Select";
import Modal from "@organisms/Modal";

const AddressModal = ({show, setShow}) => {

    const [newAddress, setNewAddress] = useState({
        useOnBill: false
    });

    const [newAddressTypes, setNewAddressType] = useState({
        individual: true,
        corporate: false
    });

    const handleNewAddressType = (type) => {
        if (type === 'individual') {
            setNewAddressType({
                individual: true,
                corporate: false
            });
        } else {
            setNewAddressType({
                individual: false,
                corporate: true
            });
        }
    }

    return (
        <Modal
            title={"Adres Detayları"}
            show={show}
            onClose={() => setShow(false)}
            onCancel={() => setShow(false)}
            onConfirm={() => {
                setShow(false);
            }}
            footer={false}
        >
            <section className={styles.new_address_modal}>
                <form onSubmit={(e) => {
                    e.preventDefault();
                }}>
                    <div className={styles.title}>
                        Fatura Tipi
                    </div>

                    <div className={styles.radio_group}>
                        <RadioItem label="Bireysel" setChecked={() => handleNewAddressType('individual')}
                                   checked={newAddressTypes.individual}/>
                        <RadioItem label="Kurumsal" setChecked={() => handleNewAddressType('corporate')}
                                   checked={newAddressTypes.corporate}/>
                    </div>

                    <div className={styles.title}>
                        Adres Bilgileriniz
                    </div>

                    <div className={styles.form_input}>
                        <Select
                            options={[
                                {value: 'chocolate', label: 'Chocolate'},
                                {value: 'strawberry', label: 'Strawberry'},
                                {value: 'vanilla', label: 'Vanilla'}
                            ]}
                            showSearch
                            placeholder="Şehir"
                            onChange={() => {
                            }}
                        />
                    </div>

                    <div className={styles.form_input}>
                        <Select
                            options={[
                                {value: 'chocolate', label: 'Chocolate'},
                                {value: 'strawberry', label: 'Strawberry'},
                                {value: 'vanilla', label: 'Vanilla'}
                            ]}
                            showSearch
                            placeholder="İlçe"
                            onChange={() => {
                            }}
                        />
                    </div>

                    <div className={styles.form_input}>
                        <Select
                            options={[
                                {value: 'chocolate', label: 'Chocolate'},
                                {value: 'strawberry', label: 'Strawberry'},
                                {value: 'vanilla', label: 'Vanilla'}
                            ]}
                            showSearch
                            placeholder="Vergi Dairesi"
                            onChange={() => {
                            }}
                        />
                    </div>

                    <div className={styles.form_input}>
                        <Input value={""} type="text" placeholder="Ticari Ünvan"/>
                    </div>

                    <div className={styles.form_input}>
                        <Input value={""} type="text" placeholder="Vergi Numarası"/>
                    </div>

                    <div className={styles.form_input}>
                        <Input value={""} type={"textarea"} label="Adres" rows={6}
                               placeholder="Adres"/>
                    </div>

                    <div className={styles.description}>
                        Bu adrese bir ad verin
                    </div>

                    <div className={styles.form_input}>
                        <Input value={""} type="text" placeholder="Örnek: Ofis, Ev"/>
                    </div>

                    <div className={styles.form_input}>
                        <div className={styles.custom_check}>
                            <Checkbox id={"0"} label="Bu adresi fatura bilgilerimde de kullan"
                                      checked={newAddress.useOnBill}/>
                        </div>
                    </div>

                    <div className={styles.info}>
                        Fatura için seçilen adres sadece bilgi amaçlıdır. Ürünleriniz “Teslimat adresi” bölümünde
                        seçtiğiniz
                        adrese teslim edilir.
                    </div>

                    <Button onClick={() => setShow(false)}>Adresi Kaydet</Button>
                    <Button variant={"outlined-primary"} onClick={() => setShow(false)}>Vazgeç</Button>

                </form>
            </section>
        </Modal>
    )
}

export default AddressModal;
