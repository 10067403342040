import React, {useState} from "react";
import styles from "@dv/scss/lib/TabContent.module.scss";

export interface TabContentProps {
    key: number,
    children: any,
}

const TabContent: React.FC<TabContentProps> = ({children}) => {
    return children;
};

export default TabContent;
