import React, {useEffect, useState} from 'react';
import moment from "moment";
import Barcode from 'react-barcode';
import styles from '@dv/scss/lib/CargoPrint.module.scss';
import Logo from '@assets/images/BrandLogo.jpg';
import {SHIPPERS} from "@utils/constants/shippers.config";

const PrintTestPage: React.FC<any> = () => {
    const [cargoPrint, setCargoPrint] = useState(null);

    useEffect(() => {
        setCargoPrint({
            receiverName: 'Abdulvahap Demir',
            receiverAddress: "Lalapaşa mahallesi terminal caddesi Migros karşısı,köşem apartmanı 44/A kat:1 ERZURUM/YAKUTİYE, PK:0",
            receiverCityName: 'ERZURUM',
            receiverTownName: 'YAKUTİYE',
            receiverPostalCode: '0',
            integrationId: '2023120625576069',
            shipmentId: '2023120654E557CE',
            orderId: '2808'
        });
    }, []);

    const printPage = () => {
        window.print();
    }

    return (
        <section className={styles.cargo_print}>
            <table>
                <tr className={styles.header}>
                    <h3><b>KARGO FİRMASI:</b> {SHIPPERS[cargoPrint?.shipperId]?.name}</h3>
                    <h4>{moment(cargoPrint?.createdAt).format('DD MMMM YYYY dddd, HH:mm')}</h4>
                </tr>
                <tr className={styles.logo}>
                    <img alt="logo" src={Logo}/>
                </tr>
                <tr className={styles.description}>
                    <div className={styles.row}>
                        <span className={styles.label}><b>Alıcı Bilgileri</b></span>
                        <span className={styles.text}></span>
                    </div>

                    <div className={styles.row}>
                        <span className={styles.label}>Ad Soyad:</span>
                        <span className={styles.text}>{cargoPrint?.receiverName}</span>
                    </div>

                    <div className={styles.row}>
                        <span className={styles.label}>Adres:</span>
                        <span className={styles.text}>{cargoPrint?.receiverAddress}</span>
                    </div>

                    <div className={styles.row}>
                        <span className={styles.label}>İl:</span>
                        <span className={styles.text}>{cargoPrint?.receiverCityName}</span>
                    </div>

                    <div className={styles.row}>
                        <span className={styles.label}>İlçe:</span>
                        <span className={styles.text}>{cargoPrint?.receiverTownName}</span>
                    </div>

                    <div className={styles.row}>
                        <span className={styles.label}>Posta Kodu:</span>
                        <span className={styles.text}>{cargoPrint?.receiverPostalCode}</span>
                    </div>
                    {/*
                    <div className={styles.row}>
                        <span className={styles.label}>Ev/Cep Telefonu:</span>
                        <span className={styles.text}>{cargoPrint?.receiverPhone}</span>
                    </div>

                    <div className={styles.row}>
                        <span className={styles.label}>E-posta:</span>
                        <span className={styles.text}>{cargoPrint?.receiverEmail}</span>
                    </div>
                    */}
                </tr>
                <tr className={styles.integration_codes}>
                    {cargoPrint?.integrationId &&
                      <div className={styles.integration_code}>
                        <h3>Entegrasyon Kodu: {/*<span>{cargoPrint?.integrationId}</span>*/}</h3>
                        <Barcode value={cargoPrint?.integrationId}/>
                      </div>
                    }
                    {cargoPrint?.shipmentId &&
                      <div className={styles.integration_code}>
                        <h3>Gönderi Kodu: {/*<span>{cargoPrint?.shipmentId}</span>*/}</h3>
                        <Barcode value={cargoPrint?.shipmentId}/>
                      </div>
                    }
                </tr>
                <tr className={styles.order_description}>
                    <div className={styles.row}>
                        <span className={styles.label}><b>Sipariş Bilgileri</b></span>
                        <span className={styles.text}></span>
                    </div>

                    <div className={styles.row}>
                        <span className={styles.label}>Sipariş Numarası:</span>
                        <span className={styles.text}>{cargoPrint?.orderId}</span>
                    </div>

                    <div className={styles.row}>
                        <span className={styles.label}>Kargo Referans Numarası:</span>
                        <span className={styles.text}>{cargoPrint?.integrationId}</span>
                    </div>
                </tr>

                <div className="flex jc-center">
                    <button id="printButton" className={styles.print_button} onClick={() => {
                        printPage();
                    }}>
                        YAZDIR
                    </button>
                </div>
            </table>
        </section>
    )
}

export default PrintTestPage;
