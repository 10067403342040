import React, {useContext, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";

import 'moment/locale/tr';
import moment from 'moment';
import locale from 'antd/es/locale/tr_TR';

import styles from '@dv/scss/lib/AddProduct.module.scss';

import Layout from "@organisms/Layout";
import {Icons, Title, Card, Button, Input, Tooltip, Tag, Checkbox} from "@dv/react";

import Table, {Cell, Header, Row} from "@organisms/Table";
import AddProductTable, {APCell, APHeader, APRow} from "@organisms/AddProductTable";
import Select from "@antd/Select";
import DatePicker from "@antd/DatePicker";

import {VariantProducts} from "@utils/constants/addProduct.config";
import AddProductContext from "@contexts/AddProduct/AddProductContext";
import UserContext from "@contexts/User/UserContext";

import notify from "@antd/Notification";
import {Switch} from "antd";
import ZoomImageModal from "@organisms/Modal/ZoomImageModal";

const AddProduct: React.FC<any> = () => {
    let {productId} = useParams();
    let navigate = useNavigate();

    const userContext = useContext(UserContext);
    const addProductContext = useContext(AddProductContext);
    const {newProductStockInputs} = addProductContext.state;
    const [selectAllNewProductStocks, setSelectAllNewProductStocks] = useState(false);
    const [modals, setModals] = useState({zoomImage: false});
    const updateModal = (key, value) => {
        setModals({
            ...modals,
            [key]: value
        });
    }
    const commissionPercent = addProductContext.state.selectedProduct?.commissionPercent || null;

    useEffect(() => {
        if (!(userContext.state.currencies.length > 0)) {
            userContext.getCurrencies();
        }
        addProductContext.selectProduct(productId);
    }, []);

    // @ts-ignore
    const handleBatchProductInputs = async (e, onChangeEvent, onTable = false) => {
        let disabled = false;
        if (e === 'currencyType') {
            if (onChangeEvent) {
                onChangeEvent();
            }
        } else {
            if (addProductContext.state.newProductStockInputs.currencyType === undefined && e !== 'currencyType') {
                if (onTable) {
                    alert('Ürün girişi yapmak için önce para cinsini seçiniz.');
                    disabled = true;
                } else {
                    alert('Toplu ürün girişi yapmak için önce para cinsini seçiniz.');
                    disabled = true;
                }
            } else {

                let hasCheckedRow = false;
                for (const row of addProductContext.state.newProductStocks) {
                    if (row.checked) {
                        hasCheckedRow = true;
                    }
                }

                if (hasCheckedRow === false && onTable === false) {
                    disabled = true;
                    alert('Toplu ürün girişi yapmak için önce aşağıdaki tablodan eklemek istediğiniz ürünleri seçiniz. Eğer tüm ürünlere giriş yapacaksanız Tümünü Seç\'i tercih ediniz.');
                } else {
                    if (onChangeEvent) {
                        onChangeEvent();
                    }
                }
            }

            console.log('DİSABLED?', disabled);
            return disabled;
        }
    };


    useEffect(() => {
        if (addProductContext.state.error !== null)
            notify({
                type: 'error',
                title: 'Bir hata ile karşılaşıldı',
                description: addProductContext.state.error
            });
        addProductContext.clearErrors();
    }, [addProductContext.state.error]);

    useEffect(() => {
        addProductContext.validateNewProductStocks();
    }, [addProductContext.state.newProductStocks]);

    useEffect(() => {
        addProductContext.mutateNewProductStocks('onValidation');
    }, [addProductContext.state.newProductStockInputs.onValidation]);

    useEffect(() => {
        addProductContext.mutateNewProductStocks('amount');
    }, [addProductContext.state.newProductStockInputs.amount]);

    useEffect(() => {
        addProductContext.mutateNewProductStocks('marketPrice');
    }, [addProductContext.state.newProductStockInputs.marketPrice]);

    useEffect(() => {
        addProductContext.mutateNewProductStocks('price');
    }, [addProductContext.state.newProductStockInputs.price]);

    useEffect(() => {
        addProductContext.mutateNewProductStocks('warehouseIncome');
    }, [addProductContext.state.newProductStockInputs.warehouseIncome]);

    useEffect(() => {
        addProductContext.mutateNewProductStocks('minBatchAmount');
    }, [addProductContext.state.newProductStockInputs.minBatchAmount]);

    useEffect(() => {
        addProductContext.mutateNewProductStocks('batchUnitPrice');
    }, [addProductContext.state.newProductStockInputs.batchUnitPrice]);

    useEffect(() => {
        addProductContext.mutateNewProductStocks('expiration');
    }, [addProductContext.state.newProductStockInputs.expiration]);

    const validateNumberMinZero = (stringNumber, fractionDigits?) => {
        let number: any = parseFloat(stringNumber);
        if ((isNaN(number) || number < 1) || typeof number != 'number') {
            return false;
        }

        if (fractionDigits) {
            number = number?.toFixed(fractionDigits);
        }

        return number;
    }

    const navigateProductSearch = () => {
        navigate(-1);
    }

    const addProductStock = (e) => {
        e.preventDefault();

        addProductContext.addProducts(() => {
            navigateProductSearch();
        });

        return false;
    }

    return (
        <Layout title="Add Product">
            <div className={styles.container}>

                <ZoomImageModal title={"Ürün Resmi"} show={modals.zoomImage} setShow={(value) => {
                    updateModal('zoomImage', value);
                }} imageLink={addProductContext.state.selectedProduct?.thumbnail}/>

                <div className={styles.badge_with_navigator}>
                    <span onClick={navigateProductSearch}>
                    <Title variant="badge" title={"GERİ"}
                           icon={<Icons.GoBackIcon bgColor={"transparent"} color={"white"}/>}/>
                    </span>
                    <Title variant="badge" title={"ÜRÜN YÖNETİMİ"} icon={<Icons.PlusCircleIcon/>}/>
                </div>


                <Card>
                    <div className={styles.product_detail}>
                        <div className={styles.image} onClick={() => {
                            updateModal('zoomImage', true);
                        }}>
                            <Tooltip placement={'rightBottom'}
                                     title={<img src={addProductContext.state.selectedProduct?.thumbnail} width={350}
                                                 height={350}/>}>
                                <img src={addProductContext.state.selectedProduct?.thumbnail}/>
                            </Tooltip>
                        </div>

                        <div className={styles.detail}>
                            <div className={styles.name}>
                                {addProductContext.state.selectedProduct?.name}
                            </div>
                            <div className={styles.category}>
                                <b>Kategori:</b> {addProductContext.state.selectedProduct?.category?.name} | <b>Komisyon
                                Oranı:</b> %{commissionPercent}
                            </div>
                            <div className={styles.ingredient}>
                                AMBALAJ İÇERİĞİ : <span>{addProductContext.state.selectedProduct?.packageContent}</span>
                            </div>
                        </div>

                        {/*
                        <div className={styles.badge}>
                            <Tag label={"AÇILIŞA ÖZEL %7.5 KOMİSYON (3 Ay İçin Geçerli)"} icon={"DeliveryBoxIcon"}/>
                        </div>
                        */}

                        <div className={styles.logo}>
                            <img height={110} width={110} src={addProductContext.state.selectedProduct?.brand?.logo}/>
                        </div>
                    </div>
                </Card>


                <Title variant="badge" title={`Toplu Ürün Ekleme Alanı`}
                       icon={<Icons.PlusCircleIcon/>}/>

                <Card>
                    <div className={styles.option_section}>
                        <form id={"batchProductInputs"}
                              className={styles.filter_section} onSubmit={addProductStock}>
                            <div className={styles.select_with_label}>
                                <label>
                                    Para Birimi <Tooltip title="Açıklama"><Icons.InfoIcon/></Tooltip>
                                </label>
                                <Select
                                    theme={"input"}
                                    options={userContext.state.currencies.map((c) => ({
                                        value: c.id,
                                        label: c.shortName
                                    }))}
                                    showSearch
                                    placeholder="Para Birimi"
                                    onChange={(_, e) => {
                                        handleBatchProductInputs('currencyType', () => {
                                            addProductContext.setProductStockInputs({currencyType: e.value});
                                            addProductContext.setProductStockInputs({currencyShortName: e.children});
                                            addProductContext.mutateNewProductStocks('currencyType');
                                            addProductContext.mutateNewProductStocks('currencyShortName');
                                        });
                                    }}
                                />
                            </div>

                            {addProductContext.state.selectedProduct?.hasVariant === 1 &&
                              <>
                                <Input label={"Stok"} type={"number"} lang="tr" step={1} min={1} minLength={1}
                                       noIndicator
                                       value={newProductStockInputs.amount}
                                       tooltip={<Tooltip
                                           title="Satışa çıkaracağınız ürünün adedini giriniz. Bu kısmı 0 olarak ayarlarsanız ürününüz satıştan kalkmış olacaktır, ayrıca fiyatı sıfırlamak gerekmez. Dentalpiyasa'da yalnızca stoklu ürün satılabilmektedir."><Icons.InfoIcon/></Tooltip>}
                                       onChange={(e) => {
                                           handleBatchProductInputs(e, () => {
                                               const amount = parseInt(e.target.value);
                                               addProductContext.setProductStockInputs({amount}, () => {
                                                   if (Number.isNaN(amount)) {
                                                       e.target.select();
                                                   }
                                               });
                                           });
                                       }} width={130} required/>

                                <Input label={"Piyasa Fiyatı"} type={"number"} lang="tr" step={0.01} noIndicator
                                       value={newProductStockInputs.marketPrice}
                                       tooltip={<Tooltip title="Açıklama"><Icons.InfoIcon/></Tooltip>}
                                       onChange={(e) => {
                                           handleBatchProductInputs(e, () => {
                                               const marketPrice = parseFloat(e.target.value);
                                               addProductContext.setProductStockInputs({marketPrice: Number.isNaN(marketPrice) ? 0 : parseFloat(marketPrice?.toFixed(2))}, () => {
                                                   if (Number.isNaN(marketPrice)) {
                                                       e.target.select();
                                                   }
                                               });
                                           });
                                       }} width={130}/>

                                <Input label={"Dentalpiyasa Satış Fiyatı"} type={"number"} lang="tr" step={0.01}
                                       noIndicator
                                       value={newProductStockInputs.price}
                                       tooltip={<Tooltip title="Açıklama"><Icons.InfoIcon/></Tooltip>}
                                       onChange={(e) => {
                                           handleBatchProductInputs(e, () => {
                                               const price = parseFloat(parseFloat(e.target.value)?.toFixed(2));
                                               const cp = commissionPercent / 100;
                                               const warehouseIncome = price - (price * cp);
                                               addProductContext.setProductStockInputs({
                                                   price: Number.isNaN(price) ? 0 : parseFloat(price?.toFixed(2)),
                                                   warehouseIncome: Number.isNaN(warehouseIncome) ? 0 : parseFloat(warehouseIncome?.toFixed(2))
                                               }, () => {
                                                   if (Number.isNaN(price)) {
                                                       e.target.select();
                                                   }
                                               });
                                           });
                                       }} width={130} required/>

                                <Input label={"Net Depo Geliri"} type={"number"} lang="tr" step={0.01} noIndicator
                                       value={newProductStockInputs.warehouseIncome}
                                       tooltip={<Tooltip title="Açıklama"><Icons.InfoIcon/></Tooltip>}
                                       onChange={(e) => {
                                           handleBatchProductInputs(e, () => {
                                               const warehouseIncome = parseFloat(e.target.value);
                                               const cp = commissionPercent / 100;
                                               //const price = (100 * warehouseIncome / 99) + (100 * commissionPercent / 99);
                                               const price = warehouseIncome / (1 - cp);
                                               addProductContext.setProductStockInputs({
                                                   price: Number.isNaN(price) ? 0 : parseFloat(price?.toFixed(2)),
                                                   warehouseIncome: Number.isNaN(warehouseIncome) ? 0 : parseFloat(warehouseIncome?.toFixed(2))
                                               }, () => {
                                                   if (Number.isNaN(warehouseIncome)) {
                                                       e.target.select();
                                                   }
                                               });
                                           });

                                       }} width={130} required/>

                                <Input label={"Çok Al Az Öde Miktarı"} type={"number"} min={2} minLength={1}
                                       step={1}
                                       noIndicator
                                       value={newProductStockInputs.minBatchAmount}
                                       tooltip={<Tooltip
                                           title="Asgari Toplu Satış Miktarı. Belli bir alım üzerine indirim yapmak istiyorsanız, indirimin uygulanması için alınacak ürün fiyatı belirleyiniz. Örnek: 5 yazarsanız 5 adet ve üzerindeki alımlar için indirimli fiyat uygulanacaktır. Bu alanı boş bırakır veya 0 yaparsanız, ürün çoklu satışa kapalı olacaktır"><Icons.InfoIcon/></Tooltip>}
                                       onChange={(e) => {
                                           handleBatchProductInputs(e, () => {
                                               const minBatchAmount = parseInt(e.target.value);
                                               addProductContext.setProductStockInputs({minBatchAmount}, () => {
                                                   if (Number.isNaN(minBatchAmount)) {
                                                       e.target.select();
                                                   }
                                               });
                                           });
                                       }} width={130} required/>

                                <Input label={"Çok Al Az Öde Fiyatı"} type={"number"} lang="tr" step={0.01} noIndicator
                                       value={newProductStockInputs.batchUnitPrice}
                                       tooltip={<Tooltip
                                           title="Toplu Satış Birim Fiyatı. Belirlediğiniz adet ve üzerindeki satışlarda uygulanacak birim fiyat. Örnek: Ürünün normal fiyatı 10 TL. 5 ve üzeri alımlarda 9 TL. Lütfen tek bir ürün için indirimli fiyat giriniz, sistem gerisini otomatik hesaplayacaktır."><Icons.InfoIcon/></Tooltip>}
                                       onChange={(e) => {
                                           handleBatchProductInputs(e, () => {
                                               const batchUnitPrice = parseFloat(e.target.value)
                                               addProductContext.setProductStockInputs({
                                                   batchUnitPrice: Number.isNaN(batchUnitPrice) ? 0 : parseFloat(batchUnitPrice?.toFixed(2))
                                               }, () => {
                                                   if (Number.isNaN(batchUnitPrice)) {
                                                       e.target.select();
                                                   }
                                               });
                                           });
                                       }} width={130} required/>
                                <div className={styles.select_with_label}>
                                  <label>
                                    Ürün Miadı <Tooltip title="Açıklama"><Icons.InfoIcon/></Tooltip>
                                  </label>
                                  <DatePicker onChange={(date) => {
                                      addProductContext.setProductStockInputs({expiration: date.toDate()});
                                  }} picker="month" format="MMMM YYYY"/>
                                </div>
                              </>
                            }


                            {addProductContext.state.selectedProduct?.hasVariant === 0 &&
                              <div className={styles.options}>
                                <div className={styles.option}>
                                  <div className={styles.name}>
                                    EN DÜŞÜK FİYAT
                                  </div>
                                  <div className={styles.values}>
                                      {addProductContext.state.newProductStocks[0].minPrice?.toFixed(2)} ₺
                                    / {addProductContext.state.newProductStocks[0].minPriceUSD?.toFixed(2)} $
                                    / {addProductContext.state.newProductStocks[0].minPriceEUR?.toFixed(2)} €
                                  </div>
                                  <div>
                                    <Tooltip
                                      title={"Halihazırda bu ürünü satan diğer depoların en düşük ücreti. Hekimin ilk tercihi olabilmek için daha düşük bir ücret yazmanızı tavsiye ederiz. Daha düşük fiyat yazamıyorsanız bile, sizden önceki deponun stoklarının tükenebileceği ve sizin 1. sıraya çıkabileceğinizi unutmayınız."}>
                                      <Icons.InfoIcon/>
                                    </Tooltip>
                                  </div>
                                </div>
                              </div>
                            }
                        </form>


                    </div>
                </Card>

                <Title variant="badge"
                       title={
                           <div className={"flex ai-center jc-center gap-15"}>
                               <div>{addProductContext.state.selectedProduct?.name}</div>
                               <div className={"tags"}>
                                   <div className={"tag tag-l orange no_background"}>
                                       {addProductContext.state.selectedProduct?.hasVariant === 1 ? "Varyant Ürünleri" : "Varyantsız Ürün"}
                                   </div>
                               </div>
                           </div>
                       }
                       icon={<Icons.PlusCircleIcon/>}/>

                <div className="flex ai-center gap-10">
                    {addProductContext.state.selectedProduct?.hasVariant === 1 &&
                      <Tag
                        variant={"warning"}
                        label={"Lütfen toplu olarak fiyat ve stok girmek istediğiniz ürünlere tik atın ve ardından toplu ürün ekle alanından bilgileri girin"}
                        icon={"InfoIcon"}/>
                    }
                </div>

                {addProductContext.state.beforeCurrencyType !== null &&
                  <Tag
                    variant={"danger"}
                    label={<>Listede eklenmiş ürünler için geçerli para
                        birimi <h2
                            className={"no-margin color_primary_red"}>{addProductContext.state.beforeCurrencyType}</h2></>}
                    icon={"InfoIcon"}/>
                }

                <AddProductTable headersClassName={styles.sticky_header} type="complex" headers={[
                    <APHeader style={{width: 550}}>
                        <Checkbox variant={"primary"} id={`choose_all_checkbox`} checked={selectAllNewProductStocks}
                                  onChange={() => {
                                      addProductContext.selectNewProductStocks(!selectAllNewProductStocks);
                                      setSelectAllNewProductStocks(!selectAllNewProductStocks);
                                  }}/>
                    </APHeader>,
                    <APHeader>Ürün Adı</APHeader>,
                    <APHeader>Katalog Numarası</APHeader>,
                    <APHeader>Stok <Icons.InfoIcon color={"white"}/></APHeader>,
                    <APHeader>Piyasa Fiyatı <Icons.InfoIcon color={"white"}/></APHeader>,
                    <APHeader>Dentalpiyasa Satış Fiyatı <Icons.InfoIcon color={"white"}/></APHeader>,
                    <APHeader>Net Kazanç</APHeader>,
                    <APHeader>Ürün Seçenekleri</APHeader>,
                    <APHeader>En İyi Fiyat <Icons.InfoIcon color={"white"}/></APHeader>,
                    <APHeader>Çok Al Az Öde Miktarı <Icons.InfoIcon color={"white"}/></APHeader>,
                    <APHeader>Çok Al Az Öde Fiyatı <Icons.InfoIcon color={"white"}/></APHeader>,
                    <APHeader>Ürün Miadı <Icons.InfoIcon color={"white"}/></APHeader>
                ]}>
                    {addProductContext.state.newProductStocks?.map((product, i) => (
                        <APRow key={product.id} id={product.id}>
                            <APCell style={{width: 50}}>
                                <Checkbox id={`${i}`} refId={product.id} checked={product.checked}
                                          onChange={(_e, isChecked, refId) => {
                                              addProductContext.setNewProductStock(refId, {
                                                  checked: !isChecked
                                              });
                                          }}/>
                            </APCell>
                            <APCell>
                                <div className={styles.tcell_product}>
                                    <div className={styles.tcell_product_info}>
                                        <div className={styles.tcell_product_info_name}>
                                            {product.name} <b>{product.variantTitle}</b>
                                        </div>

                                        {/*
                                            addProductContext.state.newProductStocks[i]?.currencyType !== null &&
                                          <div className={styles.tcell_product_info_description}>
                                            <span className={styles.tcell_product_info_description_toggle}>Mevcut Para Birimi</span>
                                            <span className={styles.tcell_product_info_description_content}>
                                                {addProductContext.state.newProductStocks[i]?.currencyShortName}
                                              </span>
                                          </div>
                                        */}

                                        {addProductContext.state.selectedProduct?.hasVariant === 1 &&
                                          <div className={styles.tcell_product_info_description}>
                                            <span className={styles.tcell_product_info_description_toggle}
                                                  onClick={() => {
                                                      addProductContext.setNewProductStock(product.id, {
                                                          packageContentShow: !addProductContext.state.newProductStocks[i].packageContentShow
                                                      });
                                                  }}>Ambalaj İçeriği</span>
                                              {addProductContext.state.newProductStocks[i].packageContentShow &&
                                                <span className={styles.tcell_product_info_description_content}>
                                                {product.packageContent}
                                              </span>
                                              }
                                          </div>
                                        }
                                    </div>
                                </div>
                            </APCell>
                            <APCell>
                                <div className={styles.tcell_catalog_number}>
                                    {product.catalogNo}
                                </div>
                            </APCell>
                            <APCell>
                                <Input type={"number"} step={1} min={1} minLength={1} noIndicator
                                       value={addProductContext.state.newProductStocks[i].amount}
                                       ref-id={product.id} onChange={e => {
                                    handleBatchProductInputs(e, () => {
                                        addProductContext.setNewProductStock(e.target.getAttribute('ref-id'), {
                                            amount: parseInt(e.target.value)
                                        });
                                    }, true);
                                }}/>
                                {product.onValidation && (product.amount === '') &&
                                  <label className={"validation-error-text text-s"}>Boş olamaz!</label>}
                            </APCell>
                            <APCell>
                                <Input type={"number"} noIndicator
                                       value={addProductContext.state.newProductStocks[i].marketPrice}
                                       ref-id={product.id} onChange={e => {
                                    handleBatchProductInputs(e, () => {
                                        const marketPrice = parseFloat(e.target.value);
                                        addProductContext.setNewProductStock(e.target.getAttribute('ref-id'), {
                                            marketPrice: Number.isNaN(marketPrice) ? 0 : parseFloat(marketPrice?.toFixed(2))
                                        }, () => {
                                            if (Number.isNaN(marketPrice)) {
                                                e.target.select();
                                            }
                                        });
                                    }, true);

                                    /*
                                    const value = validateNumberMinZero(e.target.value, 2);
                                    if (value) {
                                        addProductContext.setNewProductStock(e.target.getAttribute('ref-id'), {
                                            marketPrice: e.target.value
                                        });
                                    }
                                    */
                                }}/>
                                {product.onValidation && (product.marketPrice == '' || product.marketPrice < 1) &&
                                  <label className={"validation-error-text text-s"}>Boş olamaz!</label>}
                            </APCell>
                            <APCell>
                                <Input type={"number"} lang="tr" step={0.01} noIndicator
                                       value={addProductContext.state.newProductStocks[i].price}
                                       ref-id={product.id} onChange={e => {
                                    handleBatchProductInputs(e, () => {
                                        const price = parseFloat(e.target.value);
                                        const warehouseIncome = price - (price * commissionPercent / 100);
                                        addProductContext.setNewProductStock(e.target.getAttribute('ref-id'), {
                                            warehouseIncome: Number.isNaN(warehouseIncome) ? 0 : parseFloat(warehouseIncome?.toFixed(2)),
                                            price: Number.isNaN(price) ? 0 : parseFloat(price?.toFixed(2))
                                        }, () => {
                                            if (Number.isNaN(price)) {
                                                e.target.select();
                                            }
                                        });
                                    }, true);
                                }}/>
                                {product.onValidation && product.price == '' &&
                                  <label className={"validation-error-text text-s"}>Boş olamaz!</label>}
                                {product.onValidation && product.price > product.marketPrice &&
                                  <label className={"validation-error-text text-s"}>Bu değer piyasa fiyatından yüksek
                                    olamaz!</label>}
                            </APCell>
                            <APCell>
                                <Input type={"number"} lang="tr" step={0.01} noIndicator
                                       value={addProductContext.state.newProductStocks[i].warehouseIncome}
                                       ref-id={product.id} onChange={e => {
                                    handleBatchProductInputs(e, () => {
                                        const warehouseIncome = parseFloat(e.target.value);
                                        const cp = commissionPercent / 100;
                                        // const price = (100 * warehouseIncome / 99) + (100 * commissionPercent / 99);
                                        const price = warehouseIncome / (1 - cp);
                                        addProductContext.setNewProductStock(e.target.getAttribute('ref-id'), {
                                            warehouseIncome: Number.isNaN(warehouseIncome) ? 0 : parseFloat(warehouseIncome?.toFixed(2)),
                                            price: Number.isNaN(price) ? 0 : parseFloat(price?.toFixed(2))
                                        }, () => {
                                            if (Number.isNaN(warehouseIncome)) {
                                                e.target.select();
                                            }
                                        });
                                    }, true);
                                }}/>
                                {product.onValidation && product.warehouseIncome == '' &&
                                  <label className={"validation-error-text text-s"}>Boş olamaz!</label>}
                            </APCell>
                            <APCell>
                                {product?.propertyValues !== null ? (product?.propertyValues?.length > 0 && <>
                                  <div className={"tags fd-column"}>
                                      {product?.propertyValues?.map((el, i) =>
                                          <div key={i} className={"tag tag-s orange has_border has_hover"}>
                                              {el}
                                          </div>
                                      )}
                                  </div>
                                </>) : 'YOK'}
                            </APCell>
                            <APCell>
                                <div className={styles.tcell_lowest_price}>
                                    {product?.minPrice !== 0 ? <>{product?.minPrice} ₺ <Tooltip
                                        title={<>{product?.minPrice} ₺ / {product?.minPriceUSD?.toFixed(2)} $
                                            / {product?.minPriceEUR?.toFixed(2)} €</>}><Icons.InfoIcon/></Tooltip></> : 'SATIŞI YOK'}
                                </div>
                            </APCell>
                            <APCell>
                                <Input type={"number"} step={1} min={2} minLength={1} noIndicator
                                       value={addProductContext.state.newProductStocks[i].minBatchAmount}
                                       ref-id={product.id} onChange={e => {
                                    handleBatchProductInputs(e, () => {
                                        addProductContext.setNewProductStock(e.target.getAttribute('ref-id'), {
                                            minBatchAmount: parseInt(e.target.value)
                                        });
                                    }, true);
                                }}/>
                                {(product.onValidation && (product.minBatchAmount !== '' && product.minBatchAmount != 0 && product.minBatchAmount !== null)) && (product.minBatchAmount < 2) &&
                                  <label className={"validation-error-text text-s"}>Asgari Toplu Satış Miktarı
                                    2&quot;den az olamaz!</label>}
                            </APCell>
                            <APCell>
                                <Input type={"number"} lang="tr" step={0.01} noIndicator
                                       value={addProductContext.state.newProductStocks[i].batchUnitPrice}
                                       ref-id={product.id} onChange={e => {
                                    handleBatchProductInputs(e, () => {
                                        const batchUnitPrice = parseFloat(e.target.value);
                                        addProductContext.setNewProductStock(e.target.getAttribute('ref-id'), {
                                            batchUnitPrice: Number.isNaN(batchUnitPrice) ? 0 : parseFloat(batchUnitPrice?.toFixed(2))
                                        }, () => {
                                            if (Number.isNaN(batchUnitPrice)) {
                                                e.target.select();
                                            }
                                        });
                                    }, true);
                                }}/>
                                {/*
                                {product.onValidation && product.batchUnitPrice == '' &&
                                  <label className={"validation-error-text text-s"}>Boş olamaz!</label>}
                                    */}
                                {product.onValidation && product.batchUnitPrice >= product.price &&
                                  <label className={"validation-error-text text-s"}>Bu değer DP fiyatından yüksek veya
                                    eşit
                                    olamaz!</label>}
                            </APCell>
                            <APCell>
                                <div className={styles.tcell_expiration}>
                                    {/*
                                    <div className={styles.tcell_expiration_switch}>
                                        {addProductContext.state.newProductStocks[i].hasExpiration ? 'Var' : 'Yok'}<Switch
                                        checked={addProductContext.state.newProductStocks[i].hasExpiration}
                                        id={product.id}
                                        onChange={(e) => {
                                            handleBatchProductInputs(e, () => {
                                                addProductContext.setNewProductStock(product.id, {
                                                    hasExpiration: !addProductContext.state.newProductStocks[i].hasExpiration
                                                });
                                            }, true);
                                        }}/>
                                    </div>
                                    */}
                                    {addProductContext.state.newProductStocks[i].hasExpiration &&
                                      <>
                                          {product?.expiration ? moment(product?.expiration).format('MMMM YYYY') : ''}
                                        <DatePicker
                                          refId={product.id}
                                          onChange={(date, _p, refId) => {
                                              handleBatchProductInputs(date, () => {
                                                  addProductContext.setNewProductStock(refId, {expiration: date.toDate()});
                                              }, true);
                                          }} onClick={(_a) => {
                                        }} picker="month" format="MMMM YYYY"/>
                                          {product.onValidation && product.expiration == '' &&
                                            <label className={"validation-error-text text-s"}>Boş olamaz!</label>}
                                      </>
                                    }
                                </div>
                            </APCell>
                        </APRow>
                    ))}
                </AddProductTable>

                <div className={styles.variants_action}>
                    <Button variant={"primary"} loading={addProductContext.state.loading}
                            disabled={!addProductContext.state.newProductStocksValidation} onClick={addProductStock}>Ürünleri
                        Güncelle/Kaydet</Button>
                </div>
            </div>

        </Layout>
    )
        ;
};

export default AddProduct;
