import React, {useContext} from 'react';
import styles from '@dv/scss/lib/Modal.module.scss';
import Modal from "@organisms/Modal";
import {Button} from "@dv/react";
import OrderContext from "@contexts/Order/OrderContext";
import {useSearchParams} from "react-router-dom";

const ApproveOrderModal = ({title, show, setShow, selectedOrderId}) => {
    let [_, setSearchParams] = useSearchParams();
    const orderContext = useContext(OrderContext);

    return (
        <Modal
            title={title}
            show={show}
            onClose={() => setShow(false)}
            onCancel={() => setShow(false)}
            footerComponent={
                <div className={"modal_footer"}>
                    <Button variant="primary-gray"
                            onClick={() => setShow(false)}
                            disabled={orderContext.state.loading}>Vazgeç</Button>
                    <Button onClick={() => {
                        orderContext.setOrderStatus(selectedOrderId, 200, () => {
                            setShow(false);
                            setSearchParams({tab: '3'});
                        });
                    }} disabled={orderContext.state.loading}>Siparişi Onayla</Button>
                </div>
            }
        >
            <div className={styles.approve_modal_content}>
                <div className={styles.title}>
                    Siparişi onaylamak istediğinize emin misiniz?
                </div>
                <div className={styles.description}>
                    Siparişinizi onayladıktan sonra sipariş iptal edilemez.
                </div>
            </div>
        </Modal>
    )
}

export default ApproveOrderModal;
