import React, {useState, useEffect} from 'react';
import {useParams} from "react-router-dom";

const LoginAsVendorPage: React.FC<any> = () => {
    let {token} = useParams();
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        if (window.location.pathname.includes('/login-as-vendor') && token) {
            localStorage.setItem('dp_vendor_token', token)
            window.location.href = '/';
        } else {
            setHasError(true)
        }
    });

    return (
        <section>
            {hasError ? hasError : 'Anasayfaya yönlendirileceksiniz...'}
        </section>
    )
}

export default LoginAsVendorPage;
