import React, {useContext, useEffect, useState} from 'react';

import styles from '@dv/scss/lib/Profile.module.scss';

import AuthContext from "@contexts/Auth/AuthContext";

import Layout from "@organisms/Layout";
import InputMask from 'react-input-mask';
import {Title, Icons, Input} from '@dv/react/lib';

import Tabs from "@organisms/Tabs";
import TabPane from "@organisms/Tabs/TabPane";
import TabContent from "@organisms/Tabs/TabContent";

import {Addresses} from "@utils/constants/profile.config";
import AddressModal from "@organisms/Modal/AddressModal";
import {Dropdown, Menu, Switch} from "antd";
import Select from "@antd/Select";
import axiosAPI from "@utils/axiosAPI";
import notify from "@antd/Notification";

const Profile: React.FC<any> = () => {

    const authContext = useContext(AuthContext);
    const {user} = authContext?.state;
    const [currentTab, setCurrentTab] = useState(1);
    const [addressModal, setAddressModal] = useState(false);
    const [warehouseStatus, setWarehouseStatus] = useState(true);
    const [vendorStatus, setVendorStatus] = useState(undefined);
    const [taxOffices, setTaxOffices] = useState([]);

    const [warehouseInfoForm, _setWarehouseInfoForm] = useState({
        email: undefined,
        phone: undefined,
        name: undefined,
        taxOffice: undefined,
        taxNumber: undefined,
        iban: undefined,
        commercialTitle: undefined,
        mersisNo: undefined
    });

    const [passwordForm, setPasswordForm] = useState({
        oldPassword: '',
        newPassword: ''
    });

    useEffect(() => {
        _setWarehouseInfoForm({
            name: user?.name,
            email: user?.contact?.email,
            phone: user?.contact?.phone,
            taxOffice: user?.commercial?.taxOffice,
            taxNumber: user?.commercial?.taxNo,
            iban: user?.commercial?.iban,
            commercialTitle: user?.commercial?.title,
            mersisNo: user?.commercial?.mersisNo
        });
    }, [user]);

    const setWarehouseInfoForm = (key, value) => {
        _setWarehouseInfoForm({
            ...warehouseInfoForm,
            [key]: value
        });
    }

    useEffect(() => {
        Promise.all([
            axiosAPI({
                url: '/public/tax-offices',
                requestMethod: "GET",
                noToken: true
            })
        ]).then((res) => {
            setTaxOffices(res[0].data);
        }).catch((err) => {
            console.log('ERR', err);
        });
    }, []);

    const changeVendorStatus = (isClosed) => {
        authContext.setVendorStatus(isClosed);
    }

    useEffect(() => {
        setVendorStatus(user?.isClosed === 1);
    }, [user]);

    const addressDetailMenu = (
        <Menu>
            <Menu.Item key="1" onClick={() => setAddressModal(true)}>Düzenle</Menu.Item>
            <Menu.Item key="2" onClick={() => {
            }}>Sil</Menu.Item>
        </Menu>
    )

    useEffect(() => {
        if (authContext.state.error !== null)
            notify({
                type: 'error',
                title: 'Bir hata ile karşılaşıldı',
                description: authContext.state.error
            });
        authContext.clearErrors();
    }, [authContext.state.error]);

    return (
        <Layout title="Profile">
            <AddressModal show={addressModal} setShow={setAddressModal}/>
            <div className={styles.container}>
                <Title variant="badge" title={"KULLANICI BİLGİLERİM"} icon={<Icons.UserIcon/>}/>
                <Tabs current={currentTab} setCurrent={setCurrentTab} tabs={[
                    <TabPane key={1}>
                        <div className={styles.tab_pane_content}>
                            <h4>KULLANICI BİLGİLERİ</h4>
                        </div>
                    </TabPane>,
                    <TabPane key={2}>
                        <div className={styles.tab_pane_content}>
                            <h4>DEPO BİLGİLERİ</h4>
                        </div>
                    </TabPane>,
                    <TabPane key={3}>
                        <div className={styles.tab_pane_content}>
                            <h4>ADRES & İLETİŞİM</h4>
                        </div>
                    </TabPane>
                ]}>
                    <TabContent key={1}>
                        <div className="tab_content">
                            <div className={styles.user_section}>
                                <form className={styles.user_section_form}>
                                    <Input label={"E-POSTA ADRESİM"} type={"text"} value={warehouseInfoForm.email}
                                           onChange={(e) => {
                                               setWarehouseInfoForm('email', e.target.value);
                                           }} width={260} fullWidth disabled/>
                                    <InputMask mask="999 999 99 99" value={warehouseInfoForm.phone} maskChar={''}
                                               onChange={(e) => {
                                                   setWarehouseInfoForm('phone', e.target.value);
                                               }} disabled>
                                        {(inputProps) =>
                                            <Input label={"Telefon Numarası"} {...inputProps} disableUnderline
                                                   type={"phone"}
                                                   width={260}
                                                   placeholder={'5** *** ** **'}
                                                   fullWidth
                                                   required/>
                                        }
                                    </InputMask>

                                    <Input label={"ŞİFREM"} type={'password'}
                                           placeholder={"Eski Şifreniz"}
                                           value={passwordForm.oldPassword} onChange={(e) => {
                                        setPasswordForm({
                                            ...passwordForm,
                                            oldPassword: e.target.value
                                        });
                                    }} width={260} fullWidth/>
                                    <Input type={'password'}
                                           placeholder={"Yeni Şifreniz"}
                                           value={passwordForm.newPassword} onChange={(e) => {
                                        setPasswordForm({
                                            ...passwordForm,
                                            newPassword: e.target.value
                                        });
                                    }} updateButtonClick={() => {
                                        authContext.changePassword(passwordForm, () => {
                                            window.location.reload();
                                        });
                                    }} width={260} fullWidth updateButton/>
                                </form>

                                <div className={styles.close_warehouse}>
                                    <div className={styles.option}>
                                        <Switch checked={!vendorStatus}
                                                onClick={() => {
                                                    setVendorStatus(!vendorStatus);
                                                    changeVendorStatus(vendorStatus === true ? 0 : 1)
                                                }} disabled={true}/>
                                        {
                                            !vendorStatus ? 'Depoyu Bir Süreliğine Kapat' : 'Depo tatil modunda, açmak için tıklayınız.'
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </TabContent>
                    <TabContent key={2}>
                        <div className="tab_content">
                            <form className={styles.warehouse_section}>
                                <div className={styles.block}>
                                    <Input label={"Depo Adı"} type={"text"} value={warehouseInfoForm.name}
                                           onChange={(e) => {
                                               setWarehouseInfoForm('name', e.target.value)
                                           }} fullWidth disabled/>
                                    <div className="input_group full_width">
                                        <label>
                                            Vergi Dairesi
                                        </label>
                                        <Select
                                            theme={"input"}
                                            options={taxOffices.map(e => ({value: e.id, label: e.name}))}
                                            placeholder="Vergi Dairesi Seçiniz"
                                            showSearch
                                            loading={taxOffices.length < 1}
                                            defaultValue={warehouseInfoForm.taxOffice}
                                            onChange={(value) => {
                                                setWarehouseInfoForm('taxOffice', value);
                                            }}
                                            required
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className={styles.block}>
                                    <Input label={"Cari Ünvanı"} type={"text"} value={warehouseInfoForm.commercialTitle}
                                           onChange={(e) => {
                                               setWarehouseInfoForm('commercialTitle', e.target.value);
                                           }} fullWidth disabled/>
                                    <InputMask mask={"99999999999"} value={warehouseInfoForm.taxNumber} maskChar={''}
                                               onChange={(e) => {
                                                   setWarehouseInfoForm('taxNumber', e.target.value);
                                               }} disabled>
                                        {(inputProps) => <Input label={"Vergi Numarası"} {...inputProps} type={"text"}
                                                                fullWidth/>}
                                    </InputMask>
                                </div>
                                <div className={styles.block}>
                                    <InputMask mask="TR\ 99 9999 9999 9999 9999 9999 99"
                                               maskChar={''}
                                               value={warehouseInfoForm.iban}
                                               onChange={(e) => {
                                                   setWarehouseInfoForm('iban', e.target.value);
                                               }} disabled>
                                        {(inputProps) => <Input label={"IBAN Numarası"}
                                                                {...inputProps}
                                                                type={"text"}
                                                                fullWidth required/>}
                                    </InputMask>
                                    <Input label={"Mersis No"} type={"text"} value={warehouseInfoForm.mersisNo}
                                           onChange={(e) => {
                                               setWarehouseInfoForm('mersisNo', e.target.value)
                                           }} fullWidth disabled/>
                                </div>
                            </form>

                        </div>
                    </TabContent>
                    <TabContent key={3}>
                        <div className="tab_content">
                            <div className={styles.addresses_section}>

                                <div className={styles.title}>
                                    ADRESLERİM
                                </div>

                                {/*
                                <div className={styles.addresses}>

                                    <div className={styles.card_add} onClick={() => setAddressModal(true)}>
                                        <div className={styles.add_icon}>
                                            <Icons.PlusCircleIcon/>
                                        </div>
                                        <div className={styles.title}>
                                            Yeni Adres Ekle
                                        </div>
                                    </div>

                                    {Addresses.shipping.map((address, i) => (
                                        <div key={i}
                                             className={address.selected ? `${styles.card} ${styles.selected}` : styles.card}>
                                            <div className={styles.more_icon}>
                                                <Dropdown overlay={addressDetailMenu} trigger={["click"]}
                                                          placement={"bottomRight"}>
                                                    <div>
                                                        <Icons.CardDetailsIcon color="var(--primary-gray)"/>
                                                    </div>
                                                </Dropdown>
                                            </div>
                                            <div className={styles.title}>
                                                {address.title}
                                            </div>
                                            <div className={styles.address}>
                                                {address.address}
                                            </div>
                                        </div>
                                    ))}

                                    {Addresses.bill.map((address, i) => (
                                        <div key={i}
                                             className={address.selected ? `${styles.card} ${styles.selected}` : styles.card}>
                                            <div className={styles.more_icon}>
                                                <Dropdown overlay={addressDetailMenu} trigger={["click"]}
                                                          placement={"bottomRight"}>
                                                    <div>
                                                        <Icons.CardDetailsIcon color="var(--primary-gray)"/>
                                                    </div>
                                                </Dropdown>
                                            </div>
                                            <div className={styles.title}>
                                                {address.title}
                                            </div>
                                            <div className={styles.address}>
                                                {address.address}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                */}

                                <div className={styles.addresses_group}>
                                    <div className={styles.address}>
                                        <div className={styles.title}>
                                            Şirket Merkezi
                                        </div>
                                        <div className={styles.content}>
                                            <b>Adres Satırı:</b> {user?.mainAddress?.addressLine}
                                        </div>
                                        <div className={styles.content}>
                                            <b>Şehir:</b> {user?.mainAddress?.cityName}
                                        </div>
                                        <div className={styles.content}>
                                            <b>İlçe:</b> {user?.mainAddress?.townName}
                                        </div>
                                        <div className={styles.content}>
                                            <b>Posta Kodu:</b> {user?.mainAddress?.postalCode}
                                        </div>

                                        <div className={styles.content}>
                                            <b>Sorumlu Müdür:</b> {user?.contact.firstname} {user?.contact.lastname}
                                        </div>
                                        <div className={styles.content}>
                                            <b>Telefon:</b> {user?.contact.phone}
                                        </div>
                                        <div className={styles.content}>
                                            <b>E-posta:</b> {user?.contact.email}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </TabContent>
                </Tabs>
            </div>
        </Layout>
    )
}

export default Profile;
