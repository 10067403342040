export const OWNER_TYPES = {
    100: 'Hekim',
    200: 'Depo',
    300: 'Dentalpiyasa'
}

export const ORDER_STATUSES = {
    100: 'Siparişiniz Hazırlanıyor',
    200: 'Siparişiniz Onaylandı',
    300: 'Siparişiniz Tamamlandı',
    400: 'Siparişiniz İptal Sürecinde',
    500: 'Siparişiniz İade Sürecinde'
};

export const ORDER_STATUS_STEPS = {
    100: 0,
    200: 1,
    250: 2,
    300: 3,
    400: 4,
    500: 5,
    510: 6,
};

export const ORDER_STATUS_STEP_LABELS = [
    {
        title: 'İşleme Alındı'
    },
    {
        title: 'Hazırlanıyor'
    },
    {
        title: 'Paketlendi'
    },
    {
        title: 'Kargoya Verildi'
    },
    {
        title: 'Dağıtıma Çıktı'
    },
    {
        title: 'Teslim Edildi'
    },
    {
        title: 'Ödeme Onayı Alındı'
    },
]

export const ORDER_CANCELLATION_STEPS = {
    600: 0,
    610: 1,
};

export const ORDER_CANCELLATION_STEP_LABELS = [
    {title: 'İptal Süreci Başlatıldı'},
    {title: 'Ödeme İade Edildi'},
];

// REFUND

export const REFUND_ORDER_STEPS = {
    700: 0,
    710: 1,
    720: 2,
    730: 3,
    740: 4,
};

export const REFUND_ORDER_STEP_LABELS = [
    {title: 'İade Süreci Başlatıldı'},
    {title: 'Ürünler Kargoya Verildi'},
    {title: 'Ürün Depoya Ulaştı'},
    {title: 'İade Red/Onay Durumu'},
];

// EXCHANGE

export const EXCHANGE_ORDER_STEPS = {
    700: 0,
    710: 1,
    720: 2,
    730: 3,
    740: 3,
};

export const EXCHANGE_ORDER_STEP_LABELS = [
    {title: 'İade Süreci Başlatıldı'},
    {title: 'Ürünler Kargoya Verildi'},
    {title: 'Ürün Depoya Ulaştı'},
    {title: 'Ürün Red/Onay Durumu'},
];

export const EXCHANGE_ORDER_ACCEPTED_STEPS = {
    700: 0,
    710: 1,
    720: 2,
    730: 3,
    750: 4,
    760: 5,
    770: 6,
};

export const EXCHANGE_ORDER_ACCEPTED_STEP_LABELS = [
    {title: 'Değişim Süreci Başlatıldı'},
    {title: 'Ürünler Kargoya Verildi'},
    {title: 'Aksiyon Bekleniyor'},
    {title: 'Değişim Onaylandı'},
    {title: 'Kargo Kaydı Oluşturuldu'},
    {title: 'Kargoya Verildi'},
    {title: 'Ürün Teslim Edildi'},
];

export const EXCHANGE_ORDER_REFUSED_STEPS = {
    700: 0,
    710: 1,
    720: 2,
    740: 3,
    750: 4,
    760: 5,
    770: 6,
};

export const EXCHANGE_ORDER_REFUSED_STEP_LABELS = [
    {title: 'Değişim Süreci Başlatıldı'},
    {title: 'Ürünler Kargoya Verildi'},
    {title: 'Aksiyon Bekleniyor'},
    {title: 'Değişim Reddedildi'},
    {title: 'Kargo Kaydı Oluşturuldu'},
    {title: 'Kargoya Verildi'},
    {title: 'Ürün Teslim Edildi'},
]

export const PAYOUT_STATUS_STEPS = {
    500: 0,
    510: 1,
    520: 2
};

export const PAYOUT_STATUS_STEP_LABELS = [
    {title: "Hekim Onayı Bekleniyor"}, // 500
    {title: 'İyzico Bekleniyor'}, // 510
    {title: 'Ödeme Yapıldı'} // 520
]
